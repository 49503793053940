import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'

import { useFormZod } from 'hooks/useFormZod'
import { uploadFile } from 'util/services/upload'
import { BotAttachmentSchemaType } from 'panel/chat-bot/schema/chat-bot.attachment.schema'
import { createChatBotAttachment } from 'panel/chat-bot/service/chat-bot.attachment.service'
import { BotAttachmentSchema, BotAttachmentType } from 'panel/chat-bot/schema/chat-bot.attachment.schema'

import { AttachmentAudio } from './upload/attachment.audio'
import { AttachmentImage } from './upload/attachment.image'
import { AttachmentFile } from './upload/attachment.file'
import { useSearchParams } from 'util/hooks'

export const AttachmentForm: React.FC = () => {
  const [file, setFile] = useState<File>()
  const [attachmentUrl, setAttachmentUrl] = useState('')

  const { value: selectedAttachmentType } = useSearchParams('attachmentType')
  const { control, handleSubmit, reset, formState: { isValid } } = useFormZod(BotAttachmentSchema)

  const { mutate: handleCreate, isLoading: isCreating } = createChatBotAttachment()

  const { field: typeField } = useController({ control, name: 'type' })
  const { field: titleField } = useController({ control, name: 'title' })

  function handleSelectAttachment (file?: File) {
    if (file) {
      setFile(file)
      const fReader = new FileReader()

      fReader.onload = (e) => {
        setAttachmentUrl(e.target?.result?.toString() || '')
      }
      fReader.readAsDataURL(file)
    } else {
      setFile(undefined)
      setAttachmentUrl('')
    }
  }

  const onSubmit = (form: BotAttachmentSchemaType) => {
    if (!file) return

    uploadFile('/bot/attachment/pre-sign', file, 'public-read').then(url => {
      handleCreate({ ...form, url }, {
        onSuccess: () => reset({ title: null, type: null })
      })
    })
  }

  const handleClear = () => {
    reset()
  }

  useEffect(() => {
    if (selectedAttachmentType) {
      typeField.onChange(selectedAttachmentType)
    }
  }, [selectedAttachmentType])

  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-control grow">
        <label>Título</label>
        <input
          required
          type="text"
          value={titleField.value || ''}
          onChange={(e) => titleField.onChange(e.target.value)}
        />
      </div>
      <div className="form-control">
        <label>Tipo</label>
        <select
          required
          value={typeField.value || ''}
          onChange={(e): void => typeField.onChange(e.target.value)}
        >
          <option>Selecione</option>
          <option value={BotAttachmentType.AUDIO}>Áudio</option>
          <option value={BotAttachmentType.IMAGE}>Imagem</option>
          <option value={BotAttachmentType.FILE}>Arquivo</option>
        </select>
      </div>

      {
        typeField.value === BotAttachmentType.AUDIO && (
          <AttachmentAudio url={attachmentUrl} onSelect={handleSelectAttachment} />
        )
      }
      {
        typeField.value === BotAttachmentType.IMAGE && (
          <AttachmentImage onSelect={handleSelectAttachment} />
        )
      }
      {
        typeField.value === BotAttachmentType.FILE && (
          <AttachmentFile url={attachmentUrl} onSelect={handleSelectAttachment} />
        )
      }

      <div className="row margin-top-16 form-buttons" style={{ gap: 8 }}>
        <span className="secondary button" onClick={handleClear}>Limpar</span>
        <button className="gradient button" disabled={isCreating || !isValid || !file}>Adicionar</button>
      </div>
    </form>
  )
}
