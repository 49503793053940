import { ScheduleState, ScheduleActionTypes, ScheduleTypes } from './types'
import { Reducer } from 'redux'

const initialState: ScheduleState = {
  schedules: [],
  sends: [],
  modes: {
    ALL: 'Todos',
    GROUP: 'Grupo',
    CONTACT: 'Contatos'
  },
  status: {
    PENDING: { alias: 'Pendente', bgColor: '#efefef', color: 'gray' },
    RUNNING: { alias: 'Executando', bgColor: '#f9ac004d', color: '#cc2222' },
    PAUSED: { alias: 'Pausado', bgColor: '#fff3b5', color: '#cc2222' },
    FINISHED: { alias: 'Finalizado', bgColor: '#0FB76B1F', color: '#22CC62' }
  }
}

type ReducerOpts = Reducer<ScheduleState, ScheduleActionTypes>
const reducer: ReducerOpts = (state = initialState, action: ScheduleActionTypes): ScheduleState => {
  let index = -1
  switch (action.type) {
    case ScheduleTypes.SCHEDULE_RESET:
      return initialState
    case ScheduleTypes.SCHEDULE_CREATE:
      return {
        ...state,
        schedule: action.payload,
        schedules: [...state.schedules, action.payload]
      }
    case ScheduleTypes.SCHEDULE_UPDATE:
      index = state.schedules.findIndex((item) => item.id === action.payload.id)

      return {
        ...state,
        schedule: !state.schedule || (state.schedule && state.schedule.id === action.payload.id)
          ? action.payload : undefined,
        schedules: [
          ...state.schedules.slice(0, index), action.payload, ...state.schedules.slice(index + 1)]
      }
    case ScheduleTypes.SCHEDULE_UPDATE_SEND:
      return {
        ...state,
        sends: state.sends.map(send => {
          if (send.id === action.payload.id) return action.payload
          return send
        })
      }
    case ScheduleTypes.SCHEDULE_DELETE:
      index = state.schedules.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        schedule: undefined,
        schedules: [...state.schedules.slice(0, index), ...state.schedules.slice(index + 1)]
      }
    case ScheduleTypes.SCHEDULE_GET:
      return {
        ...state,
        schedule: action.payload,
        schedules: state.schedules.map(schedule => {
          if (schedule.id === action.payload.id) {
            return action.payload
          }
          return schedule
        })
      }
    case ScheduleTypes.SCHEDULE_GET_LIST:
      return { ...state, schedules: action.payload }
    case ScheduleTypes.SCHEDULE_GET_SENDS:
      return { ...state, sends: action.payload }
    default:
      return state
  }
}

export default reducer
