import React, { useState } from 'react'

import Modal from 'util/modules/Modal'
import { Conversation } from 'panel/conversation/redux/types'
import { formatDateTime } from 'util/masks'

const ConversationAttendants: React.FC<{ conversation: Conversation }> = ({ conversation }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const onClose = () => setModalOpen(false)

  if (!conversation.attendants.length) return null

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={onClose}>
        <div className="row panel no-padding" style={{ width: 600 }}>
          <div className='row panel-header'>
            <p className="flex padding-left-8"><i className="fa fa-users"/> Atendentes no atendimento</p>
            <span className="button-times pointer" onClick={onClose}><i className="fa fa-times"/></span>
          </div>
          <div className="row panel-body">
            <div className="simple-table">
              {
                conversation.attendants.map((attendant, i) => {
                  return (
                    <div className="row" key={i}>
                      <div className="flex">
                        <i className="fa fa-user-tie highlight-text"/> { attendant.user?.person?.name }
                      </div>
                      <div className="flex" style={{ width: 300 }}>
                        <i className="fa fa-clock highlight-text"/> { formatDateTime(attendant.createdAt) }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </Modal>
      <span className="button white bold attendants" style={{ color: '#1f1f1f' }} onClick={() => setModalOpen(true)}>
        <div className="quantity">{ conversation.attendants.length }</div>
        <i className="fas fa-users highlight-text" />
      </span>
    </>
  )
}

export default ConversationAttendants
