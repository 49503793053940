import React, { useState, useEffect } from 'react'

import './styles/Schedule.scss'

import { useParams, useHistory } from 'react-router-dom'
import { ApplicationState } from 'AppReducer'
import { useSelector, useDispatch } from 'react-redux'
import { getSchedule, getScheduleSends } from './redux/actions'

import Modal from 'util/modules/Modal'
import ScheduleForm from './components/ScheduleForm'
import ScheduleList from './components/ScheduleList'
import ScheduleExecution from './components/ScheduleExecution'
import { hasPermission } from 'util/services/AuthService'

const Schedule: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { location: { search } } = history

  const [isFormOpen, setFormOpen] = useState(false)
  const [isConfigOpen, setConfigOpen] = useState(false)

  const { id, action } = useParams<{ [x: string]: string }>()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleCloseForms () {
    setFormOpen(false)
    setConfigOpen(false)
    history.push('/panel/schedule')
  }

  useEffect(() => {
    if (action === 'edit') setFormOpen(true)
    if (action === 'config') setConfigOpen(true)
  }, [action])

  useEffect(() => {
    if (selected && id) {
      getSchedule(selected, id)(dispatch)
      getScheduleSends(selected, id)(dispatch)
    }
  }, [selected, id])

  useEffect(() => {
    const query = new URLSearchParams(search)
    if (query.get('client')) setFormOpen(true)
  }, [search])

  return (
    <div className="row panel bordered">
      <div className="row schedule-container">
        <div className="row padding-24">
          <p className="grow" style={{ fontSize: 18 }}>Mensagens Agendadas</p>
          {
            hasPermission('SCHEDULE:CREATE') &&
            <button className="gradient radius" onClick={() => setFormOpen(true)}>
              Cadastrar
            </button>
          }
        </div>
        { hasPermission('SCHEDULE:LIST') && <ScheduleList /> }

        <Modal isOpen={isFormOpen} closeModal={handleCloseForms}>
          <ScheduleForm onClose={handleCloseForms} />
        </Modal>
        <Modal isOpen={isConfigOpen} closeModal={handleCloseForms}>
          <ScheduleExecution onClose={handleCloseForms} />
        </Modal>
      </div>
    </div>
  )
}

export default Schedule
