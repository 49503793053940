import { ConfigState, ConfigTypes, ConfigActionTypes } from './types'
import { Reducer } from 'redux'

const initialState: ConfigState = {
}

type ReducerOpts = Reducer<ConfigState, ConfigActionTypes>
const reducer: ReducerOpts = (state = initialState, action: ConfigActionTypes): ConfigState => {
  switch (action.type) {
    case ConfigTypes.CONFIG_GET:
      return { ...state, config: action.payload }
    case ConfigTypes.CONFIG_UPDATE:
      return { ...state, config: action.payload }
    default:
      return state
  }
}

export default reducer
