/* eslint-disable @typescript-eslint/no-use-before-define */
import Axios from 'axios'
import { Dispatch } from 'redux'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'
import { ActionResponse, Id } from 'util/types/index'
import { Sector, SectorTypes } from './types'
import { getAttendant } from 'panel/attendant/redux/actions'

export function createSector (storeId: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Sector>> => {
    return Axios
      .post(`/store/${storeId}/sector`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: SectorTypes.SECTOR_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar o setor')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateSector (storeId: Id, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Sector>> => {
    return Axios
      .put(`/store/${storeId}/sector/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: SectorTypes.SECTOR_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o setor')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteSector (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Sector>> => {
    return Axios
      .delete(`/store/${storeId}/sector/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: SectorTypes.SECTOR_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o setor')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getSector (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Sector>> => {
    return Axios
      .get(`/store/${storeId}/sector/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: SectorTypes.SECTOR_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar o setor')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getSectorList (storeId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Sector>> => {
    return Axios
      .get(`/store/${storeId}/sector`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: SectorTypes.SECTOR_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}

export function addAttendant (storeId: Id, sectorId: Id, attendantId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Sector>> => {
    return Axios
      .post(`/store/${storeId}/sector/${sectorId}/attendant/${attendantId}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          getSector(storeId, sectorId)(dispatch)
          getAttendant(storeId, attendantId)(dispatch)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao adicionar o atendente no setor')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function delAttendant (storeId: Id, sectorId: Id, attendantId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Sector>> => {
    return Axios
      .delete(`/store/${storeId}/sector/${sectorId}/attendant/${attendantId}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          getSector(storeId, sectorId)(dispatch)
          getAttendant(storeId, attendantId)(dispatch)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o atendente no setor')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}
