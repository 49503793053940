import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import { rejectRedirectRequest, acceptRedirectRequest } from '../redux/actions'

const Pending: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { logged } = useSelector((state: ApplicationState) => state.userReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { redirects } = useSelector((state: ApplicationState) => state.conversationRedirectReducer)

  const list = redirects.filter(item => item.to?.user?.id === logged?.id).filter(item => item.status === 'PENDING')

  function handleReject (id: number) {
    if (selected) {
      rejectRedirectRequest(selected, id)(dispatch)
    }
  }

  function handleAccept (id: number) {
    if (selected) {
      acceptRedirectRequest(selected, id)(dispatch).then(res => {
        const redirect = redirects.find(item => item.id === id)
        if (res?.status === 'success' && redirects.length <= 1 && redirect?.conversation) {
          onClose()
          history.push(`/panel/conversation/${redirect.conversation.id}`)
        }
      })
    }
  }

  return (
    <div className="row margin-top-16">
      {
        list.map((redirect, i) => {
          return (
            <div className="row margin-bottom-8" style={{ alignItems: 'flex-end' }} key={i}>
              <div className="grow">
                <div className="row">
                  <p className="row justify-start margin-top-8">
                    <i className="fa fa-user-tie highlight-text" />
                    De &nbsp;<b>{redirect.from?.user?.person?.name || 'REMOVIDO'}</b>

                    <i className="fa fa-arrow-right highlight-text" />
                    Para &nbsp;<b>{redirect.to?.user?.person?.name || 'REMOVIDO'}</b>

                    &nbsp;&nbsp;| Setor: &nbsp;<b>{redirect.conversation.sector?.name}</b>
                  </p>
                </div>
                {
                  !!redirect.message && (
                    <div className="row hero margin-top-8" style={{ padding: 8, height: 'auto', minHeight: 'unset' }}>
                      <i className="fa fa-comment highlight-text"/> Mensagem: &nbsp;<b>{redirect.message}</b>
                    </div>
                  )
                }
              </div>
              <span className="button secondary radius margin-lr-8" onClick={() => handleReject(redirect.id)}>
                <i className="fa fa-times" /> Recusar
              </span>
              <span className="button highlight radius" onClick={() => handleAccept(redirect.id)}>
                <i className="fa fa-check" style={{ color: 'var(--primary-text)' }} /> Aceitar
              </span>
            </div>
          )
        })
      }
      { list.length === 0 && <div className="hero">Nenhum redirecionamento</div> }
    </div>
  )
}

export default Pending
