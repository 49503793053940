import React from 'react'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'

const Accepted: React.FC = () => {
  const { logged } = useSelector((state: ApplicationState) => state.userReducer)
  const { redirects } = useSelector((state: ApplicationState) => state.conversationRedirectReducer)

  const list = redirects.filter(item => item.to?.user?.id === logged?.id).filter(item => item.status === 'ACCEPTED')

  return (
    <div className="row margin-top-16">
      {
        list.map((redirect, i) => {
          return (
            <div className="row margin-bottom-8" key={i}>
              <div className="grow">
                <div className="row">
                  <p className="col-2">
                    {redirect.conversation.sector?.name}
                  </p>
                  <p className="col-5">
                    <i className="fa fa-user-tie highlight-text" />
                    De <b>{redirect.from?.user?.person?.name || 'REMOVIDO'}</b>
                  </p>
                  <p className="col-5">
                    <i className="fa fa-arrow-right highlight-text" />
                    Para <b>{redirect.to?.user?.person?.name || 'REMOVIDO'}</b>
                  </p>
                </div>
              </div>
              <span className="button highlight radius" style={{ width: 125 }}>
                <i className="fa fa-check" style={{ color: 'var(--primary-text)' }} /> Aceito
              </span>
            </div>
          )
        })
      }
      { list.length === 0 && <div className="hero">Nenhum redirecionamento</div> }
    </div>
  )
}

export default Accepted
