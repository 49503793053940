import { Client } from 'panel/client/redux/types'
import { Plan } from 'panel/plan/redux/types'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'
import { PaginationOpts } from 'util/types/index'

/**
* Data Types
*/
export interface Store {
  id: number,
  client: Partial<Client>,
  isActive: boolean,
  isBlocked: boolean,
  isExpired: boolean,
  useNewBotVersion: boolean,
  name: string,
  picture: string,
  docType: string,
  docNumber: string,
  dueDate: string,
  daysToExpire: number,
  plan: Partial<Plan>,
  createdAt: string,
  updatedAt?: string,
  deletedAt?: string
}

/**
* State Type
*/

export interface StoreState {
  readonly store?: Store
  readonly stores: Store[]
  readonly seller: { items: Store[], pagination?: PaginationOpts }
  readonly selected?: number
}

/**
* Action Enum Types
*/

export enum StoreTypes {
  STORE_RESET = 'STORE/RESET',
  STORE_CREATE = 'STORE/CREATE',
  STORE_UPDATE = 'STORE/UPDATE',
  STORE_DELETE = 'STORE/DELETE',
  STORE_GET = 'STORE/GET',
  STORE_GET_LIST = 'STORE/GET/LIST',
  STORE_GET_SELLER_LIST = 'STORE/GET/SELLER/LIST',
  STORE_GET_CLIENT_LIST = 'STORE/GET/CLIENT/LIST',

  STORE_SELECT = 'STORE/SELECT',
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof StoreTypes.STORE_RESET
}

interface CreateAction {
  type: typeof StoreTypes.STORE_CREATE,
  payload: Store
}

interface UpdateAction {
  type: typeof StoreTypes.STORE_UPDATE,
  payload: Store
}

interface DeleteAction {
  type: typeof StoreTypes.STORE_DELETE,
  payload: number
}

interface GetAction {
  type: typeof StoreTypes.STORE_GET,
  payload: Store
}

interface GetListAction {
  type: typeof StoreTypes.STORE_GET_LIST,
  payload: Store[]
}

interface GetClientAction {
  type: typeof StoreTypes.STORE_GET_CLIENT_LIST,
  payload: Store[]
}

interface GetSellerListAction {
  type: typeof StoreTypes.STORE_GET_SELLER_LIST,
  payload: Store[],
  pagination: PaginationOpts
}

interface SelectAction {
  type: typeof StoreTypes.STORE_SELECT,
  payload: number
}

export type StoreActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction |
  GetAction | GetListAction | GetSellerListAction | GetClientAction | SelectAction
)
