import { PaginationOpts } from 'util/types/index'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
* Data Types
*/

export type MaritalStatus = 'single' | 'married' | 'divorced' | 'widower' | 'commonLawMarriage'
export type PersonTypes = 'STANDARD' | 'BUSINESS'
export type GenderTypes = 'M' | 'F' | 'O'
export type DocType = 'CPF' | 'CNPJ' | 'IE' | 'CNH' | 'RG' | 'Passport'

export interface Person {
  id: number,
  owner: Partial<User>,
  isEmailValidated: boolean,
  isPhoneValidated: boolean,
  isDocumentValidated: boolean,
  code: string,
  picture: string,
  name: string,
  firstName: string,
  lastName: string,
  email: string,
  docType: DocType,
  docNumber: string,
  phone: string,
  birthday: string,
  gender: GenderTypes,
  maritalStatus: MaritalStatus,
  personType: PersonTypes,
  createdAt: string,
  updatedAt: string
}

export interface User {
  id: number,
  isActive: boolean,
  needUpdatePassword: boolean,
  username: string,
  password?: string,
  salt: string,
  secret: string,
  people: Partial<Person>[],
  person: Partial<Person>,
  roles: string[],
  permissions: string[],
  createdAt: string,
  updatedAt: string
}

export interface Role {
  isActive: boolean,
  name: string,
  description: string
}

export interface RoleGrant {
  id: number,
  role: Role,
  inherits: Role[],
  permissions: Role[]
}

/**
* Action Data
*/

export interface AuthData {
  username: string,
  password: string,
}

export interface AuthRefreshData {
  id: number,
  token: string
}
export interface UpdatePassword {
  oldPassword: string,
  newPassword: string
}

export interface Recovery {
  name: string,
  isSend: boolean,
  recovery: { type: 'email' | 'phone', value: string }[]
}

/**
* Action Responses
*/

export interface AuthResponse {
  user: User,
  token: string,
  refreshToken: string
}

/**
* State Type
*/

export interface UserState {
  readonly logged?: User
  readonly user?: User
  readonly users: { items: User[], pagination?: PaginationOpts }
  readonly rolePermissions: RoleGrant[]
}

/**
* Action Enum Types
*/

export enum UserTypes {
  USER_RESET = 'USER/RESET',
  USER_AUTH = 'USER/AUTH',
  USER_GET_LOGGED = 'USER/GET/LOGGED',
  USER_GET = 'USER/GET',
  USER_GET_LIST = 'USER/GET/LIST',
  USER_GET_PERMISSIONS = 'USER/GET/PERMISSIONS',
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof UserTypes.USER_RESET
}

interface AuthAction {
  type: typeof UserTypes.USER_AUTH,
  payload: User
}

interface GetAction {
  type: typeof UserTypes.USER_GET,
  payload: User
}

interface GetListAction {
  type: typeof UserTypes.USER_GET_LIST,
  payload: User[],
  pagination: PaginationOpts
}

interface GetLoggedAction {
  type: typeof UserTypes.USER_GET_LOGGED,
  payload: User
}

interface GetRolePermissionsAction {
  type: typeof UserTypes.USER_GET_PERMISSIONS,
  payload: RoleGrant[]
}

export type UserActionTypes = (
  AlertActionTypes | ResetAction | AuthAction | GetRolePermissionsAction |
  GetAction | GetListAction | GetLoggedAction
)
