import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Conversation } from '../redux/types'
import { ApplicationState } from 'AppReducer'
import { getConversationList } from '../redux/actions'

import NewConversation from './modals/NewConversation'
import SearchConversation from './modals/SearchConversation'

type Props = { onStatusChange: (status?: Conversation['status']) => void }
const ConversationButtons: React.FC<Props> = ({ onStatusChange }) => {
  const dispatch = useDispatch()

  const [status, setStatus] = useState<Conversation['status']>()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { conversations } = useSelector((state: ApplicationState) => state.conversationReducer)

  const items: { status: Conversation['status'], icon: string, label: string }[] = [
    { status: 'QUEUE', icon: 'user', label: 'Pendentes' },
    { status: 'RUNNING', icon: 'clock', label: 'Atendendo' },
    { status: 'IDLE', icon: 'robot', label: 'ChatBot' }
  ]

  useEffect(() => {
    onStatusChange(status)
  }, [status])

  useEffect(() => {
    if (selected) {
      getConversationList(selected)(dispatch)
    }
  }, [selected, dispatch])

  return (
    <div className="row conversation-buttons">
      <NewConversation />

      <span className={`sidebar-item ${!status ? 'active' : ''}`} onClick={() => setStatus(undefined)}>
        <div className="row center-center">
          <i className="fa fa-comments fa-fw" />
          <p>Todos</p>
        </div>
        <span className="qtd">{conversations.length}</span>
      </span>

      {
        items.map((item, i) => {
          const isActive = status === item.status ? 'active' : ''
          const qtd = conversations.filter(con => con.status === item.status).length
          return (
            <span key={i} className={`sidebar-item ${isActive}`} onClick={() => setStatus(item.status)}>
              <div className="row center-center">
                <i className={`fa fa-${item.icon} fa-fw`} />
                <p>{item.label}</p>
              </div>
              <span className="qtd">{qtd}</span>
            </span>
          )
        })
      }
    </div>
  )
}

export default ConversationButtons
