import Axios from 'axios'
import React, { useEffect, useState } from 'react'

import '../styles/Search.scss'

import { useDebounce } from 'util/hooks'

type Filter = 'filter' | 'filter_like' | 'filter_not' | 'filter_or'
type SearchOpts<T> = {
  url: string,
  filters: { type: Filter, column: keyof T, value?: string|number|boolean, match?: ('start'|'end')[] }[],
  fields: string,
  limit?: number,
  required?: boolean,
  defaultParams?: { [x: string]: string },
  value?: string,
  onSelect: (item: T) => void,
  isActive: (item: T) => boolean
  formatter: (item: T) => string
}
function Search<T> (props: SearchOpts<T>): React.ReactElement {
  const [search, setSearch] = useState('')
  const [list, setList] = useState<T[]>([])

  const debouce = useDebounce()

  const { url, value, filters, fields, limit, required } = props
  const { onSelect, isActive, formatter, defaultParams } = props

  function handleClick (item: T): void {
    setList([])
    onSelect(item)
  }

  function handleSearch (search: string): void {
    debouce(() => {
      const params: any = {
        ...(defaultParams || {}),
        page: 1,
        fields: fields,
        limit: limit || 5
      }

      filters.forEach(({ type, column, value, match = [] }) => {
        if (search) {
          const filter = `${type}[${String(column)}]`

          const regexStart = match?.find(opt => opt === 'start') ? '^' : ''
          const regexEnd = match?.find(opt => opt === 'end') ? '$' : ''
          params[filter] = `${regexStart}${(value || search)}${regexEnd}`
        }
      })

      Axios
        .get(url, { params })
        .then(res => res.data)
        .then(res => {
          if (res?.status === 'success' && res.data) {
            setList(res.data)
          }
        })
    }, 500)
  }

  useEffect(() => {
    setSearch(value || '')
  }, [value])

  return (
    <div className="row search">
      <input
        type="text"
        value={search}
        required={required}
        onChange={(e): void => {
          setSearch(e.target.value)
          handleSearch(e.target.value)
        }}
      />
      {
        list.length > 0 &&
        <div className="search-box">
          {
            list.map((item, i) => {
              return (
                <div
                  key={i}
                  onClick={(e): void => handleClick(item)}
                  className={`search-item ${isActive(item) ? 'active' : ''}`}
                >
                  { formatter(item) }
                </div>
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default Search
