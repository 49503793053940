import React from 'react'

import { Message } from 'panel/conversation/redux/types'

type Props = { message?: Partial<Message>, close: () => void }
const Reply: React.FC<Props> = ({ message, close }) => {
  if (!message) return null

  return (
    <div className="row chat-reply" onClick={close}>
      <div className="row item">
        <div className="flex padding-right-16">
          <strong className="row justify-start">{message.sender?.name}</strong>
          <p className="row justify-start">
            {message.message?.replace(`*${message.sender?.name}:*`, '')}
          </p>
        </div>

        <span className="button gray"><i className="fa fa-times"/></span>
      </div>
    </div>
  )
}

export default Reply
