import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Tab } from '../AttendantTab'
import { UploadRef } from 'util/modules/Upload'
import { AttendantTypes } from 'panel/attendant/redux/types'
import { ApplicationState } from 'AppReducer'
import { passwordValidate } from 'util/services/Validator'
import { updateAttendant, createAttendant } from 'panel/attendant/redux/actions'

import Upload from 'util/modules/Upload'
import { normalizeAccents, getRandomInt } from 'util/index'

type Props = { onUnsaved: (tab?: Tab) => void, changeTab: (tab: Tab) => void }

const initialState = { firstName: '', lastName: '', email: '', username: '', password: '', _password: '' }
const AttendantData: React.FC<Props> = ({ onUnsaved, changeTab }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [form, setForm] = useState(initialState)
  const [picture, setPicture] = useState<File>()
  const [fetching, setFetching] = useState(false)

  const { id } = useParams<{ id: string }>()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { attendant } = useSelector((state: ApplicationState) => state.attendantReducer)

  const uploadRef = useRef<UploadRef>(null)

  const handleClear = useCallback(() => {
    if (uploadRef.current) {
      setForm(initialState)
      uploadRef.current.clear()
      history.push('/panel/attendant')
      dispatch({ type: AttendantTypes.ATTENDANT_GET, payload: undefined })
    }
  }, [uploadRef, history])

  function getSavedState () {
    const user = attendant?.user
    const person = user?.person

    if (id && user && person) {
      if (uploadRef.current && person.picture) uploadRef.current.setPreviewImage(person.picture)

      return {
        email: person.email || '',
        username: user.username || '',
        firstName: person.firstName || '',
        lastName: person.lastName || '',
        password: '',
        _password: ''
      }
    }

    return initialState
  }

  function getUsername (firstName: string, lastName: string) {
    const sanitize = (v: string) => normalizeAccents(v).toLowerCase().trim().replace(/[^a-z]/g, '')

    const parts: string[] = []
    if (firstName) parts.push(sanitize(firstName))
    if (lastName) parts.push(sanitize(lastName))

    const name = parts.join('_')
    parts.length = 0

    parts.push(name.slice(0, 20))
    if (parts.length) parts.push(getRandomInt(1000, 9999).toString())

    const username = parts.join('_')
    return username
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (!selected) return undefined
    if (!id && !form.password) return undefined

    const formData = new FormData()

    if (picture) formData.set('picture', picture)
    if (form.username) formData.set('username', form.username)
    if (form.password) formData.set('password', form.password)

    formData.set('firstName', form.firstName)
    formData.set('lastName', form.lastName)
    formData.set('email', form.email)

    setFetching(true)
    if (id) {
      await updateAttendant(selected, id, formData)(dispatch)
    } else {
      await createAttendant(selected, formData)(dispatch).then(res => {
        if (res?.status === 'success' && res?.data) {
          history.push(`/panel/attendant/${res.data.id}`)
          changeTab('COMPLEMENTARY')
        }
      })
    }
    setFetching(false)
  }

  useEffect(() => {
    setForm(getSavedState())
  }, [attendant])

  // Handle Unsaved form
  useEffect(() => {
    const isUnsaved = JSON.stringify(form) !== JSON.stringify(getSavedState())
    onUnsaved(isUnsaved ? 'DATA' : undefined)
  }, [form, attendant, initialState])

  useEffect(() => {
    return () => {
      handleClear()
    }
  }, [handleClear])

  const validation = passwordValidate(form.password, form._password)

  return (
    <form className="row margin-top-16 attendant-data" onSubmit={handleSubmit}>
      <div className="row">
        <div className="row" style={{ width: 120, height: 120 }}>
          <i className="fa fa-edit upload-button" />
          <Upload ref={uploadRef} placeholder={false} onChange={setPicture} />
        </div>
      </div>
      <div className="row align-end name-wrapper">
        <div className="form-control grow">
          <label>Primeiro Nome</label>
          <input
            required
            type="text"
            value={form.firstName}
            onChange={(e): void => {
              setForm(form => ({
                ...form,
                firstName: e.target.value,
                username: getUsername(e.target.value, form.lastName)
              }))
            }}
          />
        </div>
        <div className="form-control grow">
          <label>Sobrenome</label>
          <input
            required
            type="text"
            value={form.lastName}
            onChange={(e): void => {
              setForm(form => ({
                ...form,
                lastName: e.target.value,
                username: getUsername(form.firstName, e.target.value)
              }))
            }}
          />
        </div>
      </div>

      <div className="form-control row">
        <label>Usuário</label>
        <input
          type="text"
          required
          disabled
          value={form.username}
        />
      </div>

      <div className="form-control row">
        <label>E-mail</label>
        <input
          required
          type="text"
          value={form.email}
          onChange={(e): void => setForm({ ...form, email: e.target.value })}
        />
      </div>

      {
        !id &&
        <div className="row">
          <div className="form-control col-6">
            <label>Senha</label>
            <input
              required={true}
              type="password"
              value={form.password}
              autoComplete="new-password"
              onChange={(e): void => setForm({ ...form, password: e.target.value })}
            />
          </div>

          <div className="form-control col-6">
            <label>Confirmar Senha</label>
            <input
              required={!id}
              type="password"
              value={form._password}
              autoComplete="new-password"
              onChange={(e): void => setForm({ ...form, _password: e.target.value })}
            />
          </div>

          <p className="col-12 text-center">{validation.warnings?.[0] || <>&nbsp;</>}</p>
        </div>
      }

      <div className="row margin-top-16 form-buttons">
        <span className="secondary button" onClick={handleClear}>Limpar</span>
        <button className="gradient button" disabled={fetching}>Salvar</button>
      </div>
    </form>
  )
}

export default AttendantData
