import { UserState, UserTypes, UserActionTypes } from './types'
import { Reducer } from 'redux'

const initialState: UserState = {
  users: { items: [] },
  rolePermissions: []
}

type ReducerOpts = Reducer<UserState, UserActionTypes>
const reducer: ReducerOpts = (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case UserTypes.USER_RESET:
      return initialState
    case UserTypes.USER_AUTH:
      return { ...state, logged: action.payload }
    case UserTypes.USER_GET:
      return { ...state, user: action.payload }
    case UserTypes.USER_GET_LIST:
      return { ...state, users: { items: action.payload, pagination: action.pagination } }
    case UserTypes.USER_GET_LOGGED:
      return { ...state, logged: action.payload }
    case UserTypes.USER_GET_PERMISSIONS:
      return { ...state, rolePermissions: action.payload }
    default:
      return state
  }
}

export default reducer
