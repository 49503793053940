import { AlertActionTypes } from 'util/modules/Alert/redux/types'
import { BotOption } from './option'

/**
* Data Types
*/
export interface Bot {
  id: number,
  isActive: boolean,
  autoInitiation: boolean,
  runInBackground: boolean,
  skipOldContact: boolean,
  order: number,
  name: string,
  behavior: 'EVER'|'ONCE_PER_DAY'|'ONCE_PER_CONTACT',
  attempts: number,
  options: BotOption[],
  invalidMessage: string,
  description: string,
  createdAt: string,
  updatedAt?: string
}

/**
* State Type
*/

export interface BotState {
  readonly bot?: Bot
  readonly bots: Bot[]
}

/**
* Action Enum Types
*/

export enum BotTypes {
  BOT_RESET = 'BOT/RESET',
  BOT_CREATE = 'BOT/CREATE',
  BOT_UPDATE = 'BOT/UPDATE',
  BOT_DELETE = 'BOT/DELETE',
  BOT_GET = 'BOT/GET',
  BOT_GET_LIST = 'BOT/GET/LIST'
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof BotTypes.BOT_RESET,
}

interface CreateAction {
  type: typeof BotTypes.BOT_CREATE,
  payload: Bot
}

interface UpdateAction {
  type: typeof BotTypes.BOT_UPDATE,
  payload: Bot
}

interface DeleteAction {
  type: typeof BotTypes.BOT_DELETE,
  payload: number
}

interface GetAction {
  type: typeof BotTypes.BOT_GET,
  payload: Bot
}

interface GetListAction {
  type: typeof BotTypes.BOT_GET_LIST,
  payload: Bot[]
}

export type BotActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction
)
