import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Gateways } from '../redux/types'
import { ModalHeader } from 'util/modules/Modal'
import { ApplicationState } from 'AppReducer'
import { checkoutInvoice, getInvoice } from '../redux/actions'

import AsaasPix from './AsaasPix'
import { formatDateTime } from 'util/masks'

const availableGateways: { method: Gateways, type: string}[] = [
  { method: 'AsaasPix', type: 'PIX' }
]

const InvoiceOpen: React.FC<{ onClose(): void }> = ({ onClose }) => {
  const dispatch = useDispatch()

  const { id } = useParams<{ [x: string]: string }>()

  const [isFetching, setFetching] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState<Gateways>('AsaasPix')

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { invoice, gateways, labels } = useSelector((state: ApplicationState) => state.invoiceReducer)

  async function handleCheckout (): Promise<void> {
    if (!id || !selected || !paymentMethod) return undefined

    setFetching(true)
    await checkoutInvoice(selected, id, paymentMethod)(dispatch).then(res => {
      if (res?.status === 'success') {
        setPaymentMethod('AsaasPix')
      }
    })
    setFetching(false)
  }

  useEffect(() => {
    if (selected && id) {
      getInvoice(selected, id)(dispatch)
    }
  }, [dispatch, selected, id])

  const processing = invoice?.payments.find(item => ['PENDING', 'PROCESSING'].includes(item.status))

  return (
    <div className="row panel no-padding custom-payment-wrapper">
      <ModalHeader title="Fatura" onClose={onClose} />
      <div className="row panel-body">
        {
          processing
            ? (
              <div className="row">
                { processing?.gateway === 'AsaasPix' && <AsaasPix payment={processing} /> }
              </div>
            )
            : (
              (!invoice || invoice.statusPayment !== 'CONFIRMED') &&
              <div className="row">
                <h3>Selecione um método de pagamento: </h3>
                {
                  availableGateways.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="row payment-tile"
                        data-active={paymentMethod === item.method}
                        onClick={() => setPaymentMethod(item.method)}
                      >
                        <span className="radio" />
                        <div className="name">
                          <p className="method">{ gateways[item.method] }</p>
                          <p className="type">{ item.type }</p>
                        </div>
                      </div>
                    )
                  })
                }

                <div className="row justify-end margin-top-16">
                  <button className="gradient" onClick={handleCheckout} disabled={isFetching || !paymentMethod}>
                    {
                      isFetching
                        ? <><i className="fa fa-spinner fa-spin"/> Processando. Aguarde...</>
                        : <><i className="fa fa-angle-right"/> Prosseguir</>
                    }

                  </button>
                </div>
              </div>
            )
        }

        {
          !!(invoice?.payments && invoice.payments.length) &&
          <div className="row margin-top-32">
            <h2><i className="fa fa-undo"/> Histórico de Pagamentos</h2>

            <div className="list">
              <div className="list-header" style={{ gridTemplateColumns: '100px 1fr 1fr 200px' }}>
                <div>#</div>
                <div>Status</div>
                <div>Método</div>
                <div>Data</div>
              </div>
              {
                invoice.payments.map((payment, i) => {
                  return (
                    <div className="list-row" key={i}>
                      <div data-content="">{ payment.id }</div>
                      <div className="status" data-content="Status" data-status={payment.status}>
                        { labels[payment.status] }
                      </div>
                      <div data-content="Método">{ gateways[payment.gateway] }</div>
                      <div data-content="Data">{ formatDateTime(payment.updatedAt) }</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default InvoiceOpen
