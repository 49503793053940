import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { saveConfig } from 'panel/config/redux/actions'
import { ApplicationState } from 'AppReducer'

const initialState = {
  conversationMessageJoin: '',
  conversationMessageFinish: '',
  conversationMessageRedirect: '',
  finishStuckConversationMessage: '',

  shouldFinishStuckConversation: false,

  isConversationMessageJoinEnabled: false,
  isConversationMessageRedirectEnabled: false,

  checkAttendant: false,
  checkAttendantMessage: ''
}
const ConfigMessage: React.FC = () => {
  const dispatch = useDispatch()

  const [form, setForm] = useState(initialState)

  const { config } = useSelector((state: ApplicationState) => state.configReducer)

  function handleSaveConfig (): void {
    saveConfig(form)(dispatch)
  }

  useEffect(() => {
    if (config) {
      setForm({
        conversationMessageJoin: config.conversationMessageJoin || '',
        conversationMessageFinish: config.conversationMessageFinish || '',
        conversationMessageRedirect: config.conversationMessageRedirect || '',
        finishStuckConversationMessage: config.finishStuckConversationMessage || '',

        shouldFinishStuckConversation: config.shouldFinishStuckConversation,

        isConversationMessageJoinEnabled: config.isConversationMessageJoinEnabled,
        isConversationMessageRedirectEnabled: config.isConversationMessageRedirectEnabled,

        checkAttendant: config.checkAttendant,
        checkAttendantMessage: config.checkAttendantMessage
      })
    } else {
      setForm(initialState)
    }
  }, [config])

  return (
    <div className="row">
      <div className="form-control">
        <label>Finalização de atendimento</label>
        <textarea
          rows={4}
          required
          onBlur={handleSaveConfig}
          value={form.conversationMessageFinish || ''}
          onChange={(e): void => setForm({ ...form, conversationMessageFinish: e.target.value })}
        />
      </div>

      <div className="form-control">
        <label>Mensagem de ausência</label>
        <label className="switch" style={{ position: 'absolute', right: 0, top: 0 }}>
          <input
            type="checkbox"
            checked={form.checkAttendant}
            onChange={(): void => {
              saveConfig({ checkAttendant: !form.checkAttendant })(dispatch)
            }}
          />
          <span className="slider round" />
        </label>

        <textarea
          rows={4}
          required
          onBlur={handleSaveConfig}
          value={form.checkAttendantMessage || ''}
          disabled={!form.checkAttendant}
          onChange={(e): void => setForm({ ...form, checkAttendantMessage: e.target.value })}
        />
      </div>

      <div className="form-control">
        <label>
          Ingressar no atendimento
        </label>
        <label className="switch" style={{ position: 'absolute', right: 0, top: 0 }}>
          <input
            type="checkbox"
            checked={form.isConversationMessageJoinEnabled}
            onChange={(): void => {
              saveConfig({ isConversationMessageJoinEnabled: !form.isConversationMessageJoinEnabled })(dispatch)
            }}
          />
          <span className="slider round" />
        </label>

        <textarea
          rows={4}
          required
          onBlur={handleSaveConfig}
          value={form.conversationMessageJoin || ''}
          disabled={!form.isConversationMessageJoinEnabled}
          onChange={(e): void => setForm({ ...form, conversationMessageJoin: e.target.value })}
        />
      </div>

      <div className="form-control">
        <label>Aviso do encerramento</label>
        <textarea
          rows={4}
          required
          onBlur={handleSaveConfig}
          value={form.finishStuckConversationMessage || ''}
          disabled={!form.shouldFinishStuckConversation}
          onChange={(e): void => setForm({ ...form, finishStuckConversationMessage: e.target.value })}
        />

        <label className="switch" style={{ position: 'absolute', right: 0, top: 0 }}>
          <input
            type="checkbox"
            checked={form.shouldFinishStuckConversation}
            onChange={(): void => {
              saveConfig({
                shouldFinishStuckConversation: !form.shouldFinishStuckConversation
              })(dispatch)
            }}
          />
          <span className="slider round" />
        </label>
      </div>
    </div>
  )
}

export default ConfigMessage
