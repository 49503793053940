import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { Plan } from '../redux/types'
import { ModalHeader } from 'util/modules/Modal'
import { inputMaskMoney } from 'util/masks'
import { createPlan, getPlan, updatePlan } from '../redux/actions'
import FormButtons from 'components/FormButtons'
import TagInput from 'util/modules/Form/TagInput'
import { ApplicationState } from 'AppReducer'
import { getSellerStoreList } from 'panel/store/redux/actions'

const initialState: Partial<Plan> & { priceFormatted: string, priceOldFormatted: string } = {
  name: '',
  order: 0,
  price: 0,
  priceOld: 0,
  isActive: true,
  isPersonal: false,
  bestOffer: false,
  limitChannel: 1,
  toleranceDays: 5,
  limitAttendants: 5,
  effectiveDays: 365,
  priceFormatted: 'R$ 0,00',
  priceOldFormatted: 'R$ 0,00'
}
const PlanForm: React.FC<{ onClose (): void }> = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { id } = useParams<{ id: string }>()
  const [form, setForm] = useState(initialState)
  const [isFetching, setIsFetching] = useState(false)

  const [personalForStoreIds, setStoreIds] = useState<number[]>([])
  const { seller: { items } } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleAddStore (id: number) {
    const isIdOnList = personalForStoreIds.find(item => item === id)
    if (!isIdOnList) {
      setStoreIds([...personalForStoreIds, id])
    }
  }

  function handleDelStore (id: number) {
    setStoreIds(personalForStoreIds.filter(item => item !== id))
  }

  const handleClear = useCallback(() => {
    setForm(initialState)
    history.push('/panel/plan')
  }, [history])

  async function handleForm (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    setIsFetching(true)
    if (id) {
      await updatePlan(id, { ...form, personalForStoreIds } as any)(dispatch).then((res) => {
        if (res?.status === 'success') {
          setIsFetching(false)
        }
      })
    } else {
      await createPlan({ ...form, personalForStoreIds } as any)(dispatch).then(res => {
        if (res?.status === 'success') {
          handleClear()
          setIsFetching(false)
        }
      })
    }
  }

  useEffect(() => {
    if (id) {
      getPlan(id)(dispatch).then(res => {
        const plan = res?.data
        if (res?.status === 'success' && plan) {
          const [priceFormatted] = inputMaskMoney(plan.price, 'R$')
          const [priceOldFormatted] = inputMaskMoney(plan.priceOld, 'R$')
          setForm({
            name: plan.name,
            order: plan.order,
            price: plan.price,
            priceOld: plan.priceOld,
            isActive: plan.isActive,
            isPersonal: plan.isPersonal,
            bestOffer: plan.bestOffer,
            effectiveDays: plan.effectiveDays,
            toleranceDays: plan.toleranceDays,
            limitAttendants: plan.limitAttendants,
            limitChannel: plan.limitChannel,
            priceFormatted,
            priceOldFormatted
          })
          setStoreIds(plan.personalFor.map(item => item.store.id))
        }
      })
    } else {
      handleClear()
    }
  }, [id, dispatch, handleClear])

  useEffect(() => {
    getSellerStoreList({ page: 1, limit: 10000 })(dispatch)
  }, [])

  return (
    <form className="row panel no-padding" onSubmit={handleForm}>
      <ModalHeader title="Configurações de Plano" onClose={onClose} />
      <div className="row panel-body align-end">
        <div className="form-control col-10">
          <label>Nome do Plano</label>
          <input
            required
            type="text"
            value={form.name}
            onChange={(e): void => setForm({ ...form, name: e.target.value })}
          />
        </div>
        <div className="form-control col-2">
          <label>Ordem</label>
          <input
            min={1}
            step={1}
            type="number"
            value={form.order}
            onChange={(e): void => setForm({ ...form, order: parseInt(e.target.value) })}
          />
        </div>

        <div className="form-control col-6">
          <label>Preço Anterior</label>
          <input
            type="text"
            value={form.priceOldFormatted}
            onChange={(e): void => {
              const [priceOldFormatted, priceOld] = inputMaskMoney(e.target.value, 'R$')
              setForm({ ...form, priceOld, priceOldFormatted })
            }}
          />
        </div>
        <div className="form-control col-6">
          <label style={{ fontWeight: 'bold', color: 'green' }}>Preço Atual</label>
          <input
            required
            type="text"
            value={form.priceFormatted}
            onChange={(e): void => {
              const [priceFormatted, price] = inputMaskMoney(e.target.value, 'R$')
              setForm({ ...form, price, priceFormatted })
            }}
          />
        </div>
        <div className="form-control col-6">
          <label>Vigência</label>
          <input
            required
            min={1}
            step={1}
            type="number"
            value={form.effectiveDays}
            onChange={(e): void => setForm({ ...form, effectiveDays: parseInt(e.target.value) })}
          />
        </div>
        <div className="form-control col-6">
          <label>Tolerância</label>
          <input
            required
            min={1}
            step={1}
            type="number"
            value={form.toleranceDays}
            onChange={(e): void => setForm({ ...form, toleranceDays: parseInt(e.target.value) })}
          />
        </div>
        <div className="form-control col-6">
          <label>Limite Canais</label>
          <input
            required
            min={1}
            step={1}
            type="number"
            value={form.limitChannel}
            onChange={(e): void => setForm({ ...form, limitChannel: parseInt(e.target.value) })}
          />
        </div>
        <div className="form-control col-6">
          <label>Limite Atendentes</label>
          <input
            required
            min={1}
            step={1}
            type="number"
            value={form.limitAttendants}
            onChange={(e): void => setForm({ ...form, limitAttendants: parseInt(e.target.value) })}
          />
        </div>

        <div className="row justify-end margin-top-16">
          <div className="row justify-start gap-10">
            <div className='flex-1'>
              <div className='row justify-start'>
                <label className="switch">
                  <input
                    type='checkbox'
                    checked={form.isActive}
                    onChange={(e) => setForm({ ...form, isActive: e.target.checked })}
                  />
                  <span className="slider round" />
                </label>
                <div className="label padding-left-8">Ativo?</div>
              </div>
            </div>
            <div className='flex-1'>
              <div className='row justify-start'>
                <label className="switch">
                  <input
                    type='checkbox'
                    checked={form.bestOffer}
                    onChange={(e) => setForm({ ...form, bestOffer: e.target.checked })}
                  />
                  <span className="slider round" />
                </label>
                <div className="label padding-left-8">Destaque?</div>
              </div>
            </div>
            <div className='flex-1'>
              <div className='row justify-start'>
                <label className="switch">
                  <input
                    type='checkbox'
                    checked={form.isPersonal}
                    onChange={(e) => setForm({ ...form, isPersonal: e.target.checked })}
                  />
                  <span className="slider round" />
                </label>
                <div className="label padding-left-8">Personalizado?</div>
              </div>
            </div>
          </div>

          {
            form.isPersonal && (
              <div className="row">
                <div className="form-control">
                  <label>
                Lojas a incluir
                  </label>
                  <TagInput
                    onSelect={handleAddStore}
                    onDelete={handleDelStore}
                    filled={personalForStoreIds}
                    items={items.map(({ id, name }) => ({ id: id, label: name }))}
                  />
                </div>
              </div>
            )
          }

          <div className='row justify-end gap-10 margin-top-8'>
            <FormButtons onClear={handleClear} isFetching={isFetching} />
          </div>
          {/* <div className='row justify-end'>
            <span className="secondary button margin-right-8" onClick={handleClear}>Limpar</span>
            <button className="gradient button">Salvar</button>
          </div> */}
        </div>
      </div>
    </form>
  )
}

export default PlanForm
