import { Store } from 'panel/store/redux/types'
import { Attendant } from 'panel/attendant/redux/types'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'
import { Conversation } from 'panel/conversation/redux/types'

/**
 * Data Types
 */

export interface ConversationRedirect {
  id: number,
  status: 'PENDING' | 'ACCEPTED' | 'REJECTED',
  store: Partial<Store>,
  from?: Partial<Attendant>,
  to?: Partial<Attendant>,
  conversation: Partial<Conversation>,
  message: string,
  createdAt: string,
  updatedAt?: string
}

/**
 * State Type
 */

export interface ConversationRedirectState {
  readonly redirects: ConversationRedirect[]
}

/**
 * Action Enum Types
 */

export enum ConversationRedirectTypes {
  CONVERSATION_REDIRECT_LIST = 'CONVERSATION/REDIRECT/LIST'
}

/**
 * Action Types
 */

interface GetListAction {
  type: typeof ConversationRedirectTypes.CONVERSATION_REDIRECT_LIST,
  payload: ConversationRedirect[]
}

export type ConversationRedirectActionTypes = AlertActionTypes | GetListAction
