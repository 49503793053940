import { Buffer } from 'buffer'
import { AuthResponse } from 'panel/user/redux/types'
import { ActionResponse, Payload } from '../types'

export const storeToken = (token: string, refreshToken: string): void => {
  localStorage.setItem('@App:Token', token)
  localStorage.setItem('@App:RefreshToken', refreshToken)
}

export const getToken = (): { token: string | null, refreshToken: string | null } => {
  const token = localStorage.getItem('@App:Token')
  const refreshToken = localStorage.getItem('@App:RefreshToken')
  return { token, refreshToken }
}

export const getPayload = (): Payload => {
  const token = localStorage.getItem('@App:Token')
  if (token) {
    const parts = token.split('.')
    if (parts && parts[1]) {
      const utf8 = decodeURIComponent(Buffer.from(parts[1], 'base64').toString())
      return JSON.parse(utf8)
    }
  }
  return {
    exp: 0,
    iat: 0,
    sub: 0,
    name: '',
    username: '',
    roles: [],
    permissions: []
  }
}

export const hasPermission = (permission: string): boolean => {
  const payload = getPayload()

  const hasRole = payload.roles.includes(permission)
  const hasPermission = payload.permissions.includes(permission)

  return hasRole || hasPermission
}

export const clearToken = (): void => {
  localStorage.removeItem('@App:Token')
  localStorage.removeItem('@App:RefreshToken')
}

export const signOut = (): void => {
  clearToken()
  window.location.href = `/sign-in?to=${window.location.pathname}`
}

export const getTokenExpiry = (): number => {
  const { token } = getToken()
  if (token) {
    const payload = getPayload()
    const now = Math.floor(Date.now() / 1000)
    const expiration = payload.exp
    const seconds = expiration - now

    return seconds
  }

  return 0
}

export const isExpired = (): boolean => {
  return getTokenExpiry() <= 0
}

export const handleRefreshToken = (callback: () => Promise<ActionResponse<AuthResponse>>): void => {
  const { token } = getToken()
  if (token) {
    const seconds = getTokenExpiry()

    setTimeout(() => {
      callback().then(res => {
        if (res?.status === 'success') {
          handleRefreshToken(callback)
        } else {
          signOut()
        }
      })
    }, (seconds - 20) * 1000)
  }
}

export const isAuthenticated = (): boolean => {
  const { token } = getToken()
  return !!token && !isExpired()
}
