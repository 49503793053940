import React from 'react'
import { useParams } from 'react-router-dom'

type Props = { active: Tab, onSelect: (tab: Tab) => void }
export type Tab = 'PENDING' | 'ACCEPTED' | 'REJECTED'
const RedirectTab: React.FC<Props> = ({ active, onSelect }) => {
  const { id } = useParams<{ [x: string]: string }>()

  const items: { tab: Tab, label: string }[] = [
    { tab: 'PENDING', label: 'Pendentes' },
    { tab: 'ACCEPTED', label: 'Aceitos' },
    { tab: 'REJECTED', label: 'Recusados' }
  ]

  return (
    <div className="row redirect-tab">
      {
        items.map((item, i) => {
          const isActive = item.tab === active

          return (
            <span
              key={i}
              onClick={() => onSelect(item.tab)}
              className={`tab-option ${active === item.tab ? 'active' : ''}`}
            >
              { item.label }
            </span>
          )
        })
      }
    </div>
  )
}

export default RedirectTab
