import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sleep } from 'util/index'
import { Channel } from 'panel/channel/redux/types'
import { refreshChannel } from 'panel/channel/redux/actions'
import { ApplicationState } from 'AppReducer'

const ReconnectButton: React.FC<{ channel: Partial<Channel> }> = ({ channel }) => {
  const dispatch = useDispatch()

  const [isFetching, setFetching] = useState(false)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  async function handleReconnect (): Promise<void> {
    if (selected && channel.id) {
      setFetching(true)
      await refreshChannel(selected, channel.id)(dispatch)
      await sleep(1000)
      setFetching(false)
    }
  }

  const isChannelConnecting = channel?.status === 'CONNECTING'
  const isConnecting = isFetching || isChannelConnecting

  return (
    <div
      onClick={handleReconnect}
      style={{ background: 'gray', color: 'white', pointerEvents: isConnecting ? 'none' : 'all', width: 'auto' }}
      className="span button radius secondary margin-lr-8"
    >
      {
        isConnecting
          ? <><i className="fa fa-spinner fa-spin" /> Conectando</>
          : <><i className="fa fa-undo" /> Reconectar</>
      }
    </div>
  )
}

export default ReconnectButton
