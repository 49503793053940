import { Sector } from 'panel/sector/redux/types'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
* Data Types
*/
export interface Overview {
  time: {
    attendance: { hours: number, minutes: number },
    conversation: { hours: number, minutes: number },
  },
  attendants: {
    id: number,
    name: string,
    picture: string,
    attendance: number,
    sectors: Partial<Sector>[]
  }[],
  sectors: {
    id: number,
    name: string,
    attendants: number
  }[]
}

export interface Statistics {
  conversations: {
    total: number,
    idle: number,
    queue: number,
    running: number,
    finished: number
  },
  quantities: {
    bots: number,
    tags: number,
    groups: number,
    sectors: number,
    channels: number,
    contacts: number,
    messages: number,
    attendants: number,
    schedulesSend: number,
    messageDatabase: number,
    schedulesPending: number,
  }
}

/**
* State Type
*/

export interface ReportState {
  readonly overview: { [storeId: number]: Overview }
  readonly statistics: { [storeId: number]: Statistics }
}

/**
* Action Enum Types
*/

export enum ReportTypes {
  REPORT_OVERVIEW = 'REPORT/OVERVIEW',
  REPORT_STATISTICS = 'REPORT/STATISTICS'
}

/**
* Action Types
*/

interface OverviewAction {
  type: typeof ReportTypes.REPORT_OVERVIEW,
  payload: Overview
  storeId: number
}

interface StatisticsAction {
  type: typeof ReportTypes.REPORT_STATISTICS,
  payload: Statistics
  storeId: number
}

export type ReportActionTypes = (
  AlertActionTypes | OverviewAction | StatisticsAction
)
