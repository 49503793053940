import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Handle, NodeProps, Position } from 'reactflow'

import { ApplicationState } from 'AppReducer'
import { useOnChangeValue } from './helpers/on-change-value.hook'
import { DeleteNode } from './delete-node'
import { FormControlNode, ItemWithIcon } from '../form-control.node'

interface Props extends NodeProps {
  data: {
    value: string
  }
}
const SetConversationTagNode: React.FC<Props> = ({ id, data }) => {
  const { tags } = useSelector((state: ApplicationState) => state.tagReducer)

  const onChange = useOnChangeValue(id)

  return (
    <div className="node" style={{ width: 300 }}>
      <DeleteNode id={id} />

      <FormControlNode>
        <ItemWithIcon icon="fa-arrow-right">
          <p className="custom-drag-handle" style={{ margin: 0 }}>
            Adicionar etiqueta: atendimento
          </p>
        </ItemWithIcon>
      </FormControlNode>
      <FormControlNode pb>
        <select value={data.value} onChange={onChange}>
          <option></option>
          {tags.map((tag, i) => <option key={i} value={tag.id}>{tag.name}</option>)}
        </select>
      </FormControlNode>

      <Handle
        id="left"
        type="target"
        position={Position.Left}
        style={{ left: -5, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="top"
        type="target"
        position={Position.Top}
        style={{ top: 3, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="bottom"
        type="source"
        position={Position.Bottom}
        style={{ bottom: 3, width: 12, height: 12, background: '#ff4081' }}
      />
      <Handle
        id="right"
        type="source"
        position={Position.Right}
        style={{ right: -5, width: 12, height: 12, background: '#ff4081' }}
      />
    </div>
  )
}

export default memo(SetConversationTagNode)
