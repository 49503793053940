import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
 * Data Types
 */

export interface Plan {
  id: number,
  order: number,
  isActive: boolean,
  isPersonal: boolean,
  bestOffer: boolean,
  name: string,
  price: number,
  priceOld: number,
  toleranceDays: number,
  effectiveDays: number,
  limitChannel: number,
  limitAttendants: number
  createdAt: string,
  updatedAt?: string,

  personalFor: Array<{ id: number, plan: { id: number }, store: { id: number } }>,
}

/**
 * State Type
 */

export interface PlanState {
  readonly plan?: Plan
  readonly plans: Plan[]
  readonly freePlan?: Plan
  readonly available: Plan[]
}

/**
 * Action Enum Types
 */

export enum PlanTypes {
  PLAN_RESET = 'PLAN/RESET',
  PLAN_CREATE = 'PLAN/CREATE',
  PLAN_UPDATE = 'PLAN/UPDATE',
  PLAN_DELETE = 'PLAN/DELETE',
  PLAN_GET = 'PLAN/GET',
  PLAN_GET_LIST = 'PLAN/GET/LIST',
  PLAN_GET_FREE = 'PLAN/GET/FREE',
  PLAN_GET_AVAILABLE = 'PLAN/GET/AVAILABLE'
}

/**
 * Action Types
 */

interface ResetAction {
  type: typeof PlanTypes.PLAN_RESET
}

interface CreateAction {
  type: typeof PlanTypes.PLAN_CREATE
  payload: Plan
}

interface UpdateAction {
  type: typeof PlanTypes.PLAN_UPDATE,
  payload: Plan
}

interface DeleteAction {
  type: typeof PlanTypes.PLAN_DELETE,
  payload: number
}

interface GetAction {
  type: typeof PlanTypes.PLAN_GET,
  payload: Plan
}

interface GetFreeAction {
  type: typeof PlanTypes.PLAN_GET_FREE,
  payload: Plan
}

interface GetListAction {
  type: typeof PlanTypes.PLAN_GET_LIST,
  payload: Plan[]
}

interface GetAvailableListAction {
  type: typeof PlanTypes.PLAN_GET_AVAILABLE,
  payload: Plan[]
}

export type PlanActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction |
  GetAction | GetListAction | GetAvailableListAction | GetFreeAction
)
