import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'

export const FilterAttendant: React.FC<{ onChange: (id: number) => void }> = ({ onChange }) => {
  const [selected, setSelected] = useState<number>(0)
  const { attendants: list } = useSelector((state: ApplicationState) => state.attendantReducer)

  return (
    <div className="form-control" onClick={e => e.stopPropagation()}>
      <select
        value={selected}
        onChange={e => {
          onChange(Number(e.target.value))
          setSelected(Number(e.target.value))
        }}
      >
        <option value={0}>Selecione</option>
        { list.map(item => <option key={item.id} value={item.id}>{ item.user?.person?.name }</option>) }
      </select>
    </div>
  )
}
