import { ChannelState, ChannelTypes, ChannelActionTypes } from './types'
import { Reducer } from 'redux'

const initialState: ChannelState = {
  channelDb: {},
  channels: [],
  statusList: {
    CONNECTED: { icon: 'check', label: 'Conectado', color: 'green' },
    CONNECTING: { icon: 'spin fa-spinner', label: 'Aguardando Conexão', color: 'gray' },
    DISCONNECTED: { icon: 'times', label: 'Desconectado', color: 'red' },
    NOT_CONNECTED: { icon: 'server', label: 'Servidor Desconectado', color: 'firebrick' },
    IDLE: { icon: 'network-wired', label: 'Canal Desconectado', color: 'orange' }
  }
}

type ReducerOpts = Reducer<ChannelState, ChannelActionTypes>
const reducer: ReducerOpts = (state = initialState, action: ChannelActionTypes): ChannelState => {
  let index = -1
  switch (action.type) {
    case ChannelTypes.CHANNEL_RESET:
      return initialState
    case ChannelTypes.CHANNEL_CREATE:
      return {
        ...state,
        channelDb: {
          ...state.channelDb,
          [action.payload.id]: action.payload
        },
        channels: [...state.channels, action.payload]
      }
    case ChannelTypes.CHANNEL_UPDATE:
      index = state.channels.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        channelDb: {
          ...state.channelDb,
          [action.payload.id]: action.payload
        },
        channels: [...state.channels.slice(0, index), action.payload, ...state.channels.slice(index + 1)]
      }
    case ChannelTypes.CHANNEL_DELETE:
      index = state.channels.findIndex((item) => item.id === action.payload)
      delete state.channelDb[action.payload]

      return {
        ...state,
        channelDb: state.channelDb,
        channels: [...state.channels.slice(0, index), ...state.channels.slice(index + 1)]
      }
    case ChannelTypes.CHANNEL_GET:
      return {
        ...state,
        channelDb: {
          ...state.channelDb,
          [action.payload.id]: action.payload
        }
      }
    case ChannelTypes.CHANNEL_GET_CONNECTION_QR:
      return { ...state, connectionQr: action.payload }
    case ChannelTypes.CHANNEL_GET_LIST:
      return { ...state, channels: action.payload }
    default:
      return state
  }
}

export default reducer
