import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Contact } from 'panel/person/redux/types/contact'
import { maskPhone } from 'util/masks'
import { createChat } from 'panel/conversation/redux/actions'
import { ApplicationState } from 'AppReducer'

import Modal from 'util/modules/Modal'
import Search from 'util/modules/Pagination/components/Search'
import NewContact from './NewContact'
import { hasPermission } from 'util/services/AuthService'

const initialState = { contact: 0, channel: 0, sector: 0 }
const NewConversation: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [form, setForm] = useState(initialState)
  const [isFetching, setFetching] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [visibleNewConversationModal, setVisibleNewConversationModal] = useState(false)

  const [filter, setFilterType] = useState('person.name')

  // Selected Contact Name
  const [contact, setContact] = useState('')

  const { sectors } = useSelector((state: ApplicationState) => state.sectorReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { channels } = useSelector((state: ApplicationState) => state.channelReducer)

  function onClose () {
    setModalOpen(false)
  }

  async function handleSubmit (): Promise<void> {
    if (selected) {
      setFetching(true)
      await createChat(selected, form)(dispatch).then(res => {
        if (res?.status === 'success' && res.data) {
          setModalOpen(false)
          history.push(`/panel/conversation/${res.data.id}`)
        }
      })
      setFetching(false)
    }
  }

  useEffect(() => {
    if (!isModalOpen) {
      setForm(initialState)
      setContact('')
    }
  }, [isModalOpen])

  if (!hasPermission('CONVERSATION:CREATE')) return null

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={onClose}>
        <div className="panel no-padding">
          <div className="panel-header">
            <div className="row">
              <p className="flex"><i className="fa fa-comments" style={{ margin: 0 }} /> Novo Atendimento</p>
              <span className="button-times pointer" onClick={onClose}><i className="fa fa-times" /></span>
            </div>
          </div>

          <div className="panel-body">
            <div className="row align-end">
              <div className="grow">
                <div className="row">
                  <div className="col-3 form-control">
                    <label>Buscar Por</label>
                    <select value={filter} onChange={(e) => setFilterType(e.target.value)} required>
                      <option value="person.name">Nome</option>
                      <option value="value">Telefone</option>
                    </select>
                  </div>
                  <div className="col-9 form-control justify-start">
                    <label>Selecione o Cliente</label>
                    <Search<Contact>
                      required
                      url={`/store/${selected}/contact`}
                      value={contact}
                      fields='id,value,person.name'
                      isActive={(item) => form.contact === item.id}
                      formatter={({ value, person }) => `${person?.name} - ${maskPhone(value)}`}
                      onSelect={({ id, value, person }): void => {
                        setForm({ ...form, contact: id })
                        setContact(`${person?.name} - ${maskPhone(value)}`)
                      }}
                      limit={9999}
                      filters={[
                        { type: 'filter', column: 'isValidated', value: 1 },
                        { type: 'filter_like', column: filter as any, match: ['start', 'end'] },
                        { type: 'filter_or', column: 'person.alias' as any, match: ['start', 'end'] }
                      ]}
                    />
                  </div>
                </div>
              </div>
              <span
                className="button gradient"
                title="Novo Contato"
                onClick={() => {
                  setVisibleNewConversationModal(true)
                }}
              >
                <i className="fa fa-plus"></i>
              </span>
            </div>
            <div className="form-control justify-start">
              <label>Selecione o Canal</label>
              <select
                required
                value={form.channel}
                onChange={(e) => setForm({ ...form, channel: Number(e.target.value) })}
              >
                <option></option>
                {channels.map((channel, i) => <option key={i} value={channel.id}>{channel.name}</option>)}
              </select>
            </div>
            <div className="form-control justify-start">
              <label>Selecione o Setor</label>
              <select
                required
                value={form.sector}
                onChange={(e) => setForm({ ...form, sector: Number(e.target.value) })}
              >
                <option></option>
                {sectors.map((sector, i) => <option key={i} value={sector.id}>{sector.name}</option>)}
              </select>
            </div>
            <div className="row justify-end form-control">
              {
                isFetching
                  ? (
                    <button disabled className="gradient">
                      <i className="fa fa-spinner fa-spin" /> Iniciando atendimento e Validando Contato. Aguarde...
                    </button>
                  )
                  : <button className="gradient" onClick={handleSubmit}>Iniciar atendimento</button>
              }
            </div>
          </div>
        </div>
      </Modal>

      <NewContact
        isModalOpen={visibleNewConversationModal}
        setModalOpen={setVisibleNewConversationModal}
        onValidate={({ id, person, value }) => {
          if (id) {
            setForm({ ...form, contact: id })
            setContact(`${person?.name} - ${maskPhone(value)}`)
          }
        }}
      />

      <span className="sidebar-item" onClick={(): void => setModalOpen(true)}>
        <div className="row center-center">
          <i
            className="fa fa-plus fa-fw"
            style={{ background: 'unset', border: '1px solid var(--highlight)', fontSize: 24 }}
          />
          <p>Novo</p>
        </div>
      </span>
    </>
  )
}

export default NewConversation
