import React, { memo } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'

import { ChatBotNode } from '../sidebar'
import { useOnChangeOption } from './helpers/on-change-value.hook'
import { DeleteNode } from './delete-node'
import { FormControlNode } from '../form-control.node'

interface Props extends NodeProps {
  data: {
    type: [
      ChatBotNode.FILL_CPF,
      ChatBotNode.FILL_CNPJ,
      ChatBotNode.FILL_NAME,
      ChatBotNode.FILL_EMAIL
    ],
    options: Array<{ id: string, key: string, value: string }>
  }
}
const FillDataNode: React.FC<Props> = ({ id, data, isConnectable, ...props }) => {
  const onChange = useOnChangeOption(id)

  return (
    <div className="node" style={{ width: 300 }}>
      <p className="custom-drag-handle pt px">
        <i className="fa fa-keyboard" /> Preenche Dados
        <DeleteNode id={id} />
      </p>

      <FormControlNode>
        <select value={data.type} disabled>
          <option value={ChatBotNode.FILL_CPF}>CPF</option>
          <option value={ChatBotNode.FILL_CNPJ}>CNPJ</option>
          <option value={ChatBotNode.FILL_NAME}>Nome</option>
          <option value={ChatBotNode.FILL_EMAIL}>E-mail</option>
        </select>
      </FormControlNode>

      {
        data.options.map((option, i) => (
          <FormControlNode key={option.id} label={`Mensagem: ${option.key}`} pb={data.options.length - 1 === i}>
            <input value={option.value} onChange={evt => onChange(option.key, evt.target.value)} />
          </FormControlNode>
        ))
      }

      <Handle
        id="left"
        type="target"
        position={Position.Left}
        style={{ left: -5, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="top"
        type="target"
        position={Position.Top}
        style={{ top: 3, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="bottom"
        type="source"
        position={Position.Bottom}
        style={{ bottom: 3, width: 12, height: 12, background: '#ff4081' }}
      />
      <Handle
        id="right"
        type="source"
        position={Position.Right}
        style={{ right: -5, width: 12, height: 12, background: '#ff4081' }}
      />
    </div>
  )
}

export default memo(FillDataNode)
