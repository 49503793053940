import Axios from 'axios'
import { Dispatch } from 'redux'

import { ActionResponse, Id } from 'util/types/index'
import { Gateways, Invoice, InvoiceTypes } from './types'
import { handleError, createAlert, clearAlert } from 'util/modules/Alert/redux/actions'

export function createInvoice (storeId: Id, planId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Invoice>> => {
    return Axios
      .post(`/store/${storeId}}/plan/${planId}/invoice`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: InvoiceTypes.INVOICE_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao gerar a fatura')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function checkoutInvoice (storeId: Id, invoiceId: Id, paymentMethod: Gateways) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Invoice>> => {
    return Axios
      .post(`/store/${storeId}/invoice/${invoiceId}/checkout/${paymentMethod}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: InvoiceTypes.INVOICE_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao gerar o pagamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function cancelInvoicePayment (storeId: Id, invoiceId: Id, paymentId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Invoice>> => {
    return Axios
      .delete(`/store/${storeId}/invoice/${invoiceId}/payment/${paymentId}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: InvoiceTypes.INVOICE_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cancelar o pagamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function cancelInvoice (storeId: Id, invoiceId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Invoice>> => {
    return Axios
      .delete(`/store/${storeId}/invoice/${invoiceId}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: InvoiceTypes.INVOICE_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cancelar o pagamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getInvoice (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Invoice>> => {
    return Axios
      .get(`/store/${storeId}/invoice/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: InvoiceTypes.INVOICE_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar pela fatura')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getInvoiceList (storeId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Invoice>> => {
    return Axios
      .get(`/store/${storeId}/invoice`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: InvoiceTypes.INVOICE_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}
