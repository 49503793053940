/* eslint-disable @typescript-eslint/no-use-before-define */
import Axios from 'axios'
import { Dispatch } from 'redux'

import { Store, StoreTypes } from './types'
import { ActionResponse, Id } from 'util/types/index'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'

export function createStore (clientId: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Store>> => {
    return Axios
      .post(`/client/${clientId}/store`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: StoreTypes.STORE_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar a loja')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateStore (clientId: Id, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Store>> => {
    return Axios
      .put(`/client/${clientId}/store/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: StoreTypes.STORE_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar a loja')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteStore (clientId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Store>> => {
    return Axios
      .delete(`/client/${clientId}/store/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: StoreTypes.STORE_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover a loja')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getStore (clientId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Store>> => {
    return Axios
      .get(`/client/${clientId}/store/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: StoreTypes.STORE_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar a loja')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getStoreList (clientId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Store>> => {
    return Axios
      .get(`/client/${clientId}/store`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: StoreTypes.STORE_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}

export function getSellerStoreList (params: any = {}) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Store>> => {
    return Axios
      .get('/store', { params })
      .then(res => res.data)
      .then(res => {
        dispatch({ type: StoreTypes.STORE_GET_SELLER_LIST, payload: res.data || [], pagination: res.pagination })
        return res
      })
  }
}

export function getSellerClientList () {
  return async (dispatch: Dispatch): Promise<ActionResponse<Store[]>> => {
    return Axios
      .get('/store/my')
      .then(res => res.data)
      .then(res => {
        dispatch({ type: StoreTypes.STORE_GET_CLIENT_LIST, payload: res.data || [] })
        return res
      })
  }
}

export function selectStore (id: number) {
  return (dispatch: Dispatch): void => {
    localStorage.setItem('@App:Store', String(id))
    dispatch({ type: StoreTypes.STORE_SELECT, payload: id })
  }
}
