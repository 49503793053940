import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { formatDateTime } from 'util/masks'
import { ApplicationState } from 'AppReducer'
import { deleteCommand, getCommand } from '../redux/actions'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { hasPermission } from 'util/services/AuthService'

const CommandBotList: React.FC<{ onOpenExecution: (commandId: number) => void }> = ({ onOpenExecution }) => {
  const dispatch = useDispatch()

  const [deleteId, setDeleteId] = useState<number>()
  const [isFetching, setFetching] = useState({ delete: false, fetching: false, run: false })

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { commands } = useSelector((state: ApplicationState) => state.commandReducer)

  async function handleRemove (id: number) {
    if (selected) {
      setFetching({ ...isFetching, delete: true })
      await deleteCommand(selected, id)(dispatch)
      setFetching({ ...isFetching, delete: false })
      setDeleteId(undefined)
    }
  }

  async function handleDownload (id: number) {
    if (selected) {
      setFetching({ ...isFetching, fetching: true })
      const command = await getCommand(selected, id)(dispatch).then(res => res.data)
      setFetching({ ...isFetching, fetching: false })

      if (command) {
        const filename = `${command.name}.json`
        const blob = new Blob([command.command], { type: 'text/csv' })
        try {
          (window.navigator as any).msSaveBlob(blob, filename)
        } catch {
          const elem = window.document.createElement('a')
          elem.href = window.URL.createObjectURL(blob)
          elem.download = filename
          document.body.appendChild(elem)
          elem.click()
          document.body.removeChild(elem)
        }
      }
    }
  }

  return (
    <div className="list">
      <div className="list-header">
        <div>Nome</div>
        <div>Data</div>
        <div>Ações</div>
      </div>
      { commands.length === 0 && <div className="row empty">Nenhum backup encontrado</div>}
      {
        commands.map((command, i) => {
          return (
            <div className="list-row" key={i}>
              <div>{command.name}</div>
              <div>{formatDateTime(command.createdAt)}</div>
              <div className="row buttons-list-row">
                {
                  hasPermission('CHAT_BOT:BACKUP_DELETE') &&
                  <button disabled={isFetching.delete} className="secondary" onClick={() => setDeleteId(command.id)}>
                    { isFetching.delete ? <i className="fa fa-spin fa-spinner" /> : <i className="fa fa-trash" />}
                  </button>
                }
                {
                  hasPermission('CHAT_BOT:BACKUP_GET') &&
                  <button
                    disabled={isFetching.fetching} className="secondary" onClick={() => handleDownload(command.id)}>
                    { isFetching.fetching ? <i className="fa fa-spin fa-spinner" /> : <i className="fa fa-download" />}
                  </button>
                }
                {
                  hasPermission('CHAT_BOT:BACKUP_EXECUTE') &&
                  <button onClick={() => onOpenExecution(command.id)}><i className="fa fa-cog" /></button>
                }
              </div>
            </div>
          )
        })
      }
      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </div>
  )
}

export default CommandBotList
