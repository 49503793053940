import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import './styles/Pagination.scss'

import { PaginationOpts } from 'util/types/index'

type PaginationComponentOpts = {
  onClick?: (params: { [x: string]: string }) => void
  pagination?: PaginationOpts
}
const Pagination: React.FC<PaginationComponentOpts> = ({ pagination, onClick }) => {
  const [pages, setPages] = useState<number[]>([])

  const history = useHistory()
  function handleOpen (link: string): void {
    if (!onClick) {
      const { pathname } = history.location
      history.push(`${pathname}?${link}`)
    } else if (onClick) {
      const params = Object.fromEntries(new URLSearchParams(link))
      onClick(params)
    }
  }

  useEffect(() => {
    if (pagination) {
      const { page, total, limit } = pagination
      const maxPage = Math.ceil(total / limit)

      const pages: number[] = []

      // Loop left
      for (let i = page - 1; i > page - 5; i--) if (i > 0) pages.unshift(i)

      pages.push(page)

      // Loop right
      for (let i = page + 1; i < page + 5; i++) if (i <= maxPage) pages.push(i)

      setPages(pages)
    }
  }, [pagination])

  if (!pagination) return null

  return (
    <div className="row pagination">
      <span className='white' style={{ marginLeft: 16, fontWeight: 'normal', backgroundColor: 'transparent' }}>
        {pagination.limit} de {pagination.page * pagination.limit} em {pagination.total} Resultados
      </span>

      <div className="flex">
        <div className="row justify-end">
          <span onClick={(): void => handleOpen(pagination.links.prev)} className="button">
            Anterior
          </span>
          {
            pages.map(number => {
              const uri = new URLSearchParams(pagination.links.first)
              uri.set('page', number.toString())

              return (
                <span
                  key={number}
                  onClick={(): void => handleOpen(uri.toString())}
                  className={`button ${number === pagination.page ? 'active' : ''}`}
                >
                  {number}
                </span>
              )
            })
          }
          <span onClick={(): void => handleOpen(pagination.links.next)} className="button">
            Próxima
          </span>
        </div>
      </div>
    </div>
  )
}

export default Pagination
