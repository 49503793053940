import Axios from 'axios'
import { Dispatch } from 'redux'
import { handleError, createAlert, clearAlert } from 'util/modules/Alert/redux/actions'
import { ActionResponse, Id } from 'util/types/index'
import { Tag, TagTypes } from './types'

export function createTag (storeId: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Tag>> => {
    return Axios
      .post(`/store/${storeId}/tag`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: TagTypes.TAG_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar a tag')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateTag (storeId: Id, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Tag>> => {
    return Axios
      .put(`/store/${storeId}/tag/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: TagTypes.TAG_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar a tag')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteTag (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Tag>> => {
    return Axios
      .delete(`/store/${storeId}/tag/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: TagTypes.TAG_GET, payload: undefined })
          dispatch({ type: TagTypes.TAG_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover a tag')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getTag (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Tag>> => {
    return Axios
      .get(`/store/${storeId}/tag/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: TagTypes.TAG_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar a tag')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getTagList (storeId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Tag>> => {
    return Axios
      .get(`/store/${storeId}/tag`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: TagTypes.TAG_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}
