import { ContactState, ContactTypes, ContactActionTypes } from '../types/contact'
import { Reducer } from 'redux'

const initialState: ContactState = {
  contacts: { items: [] }
}

type ReducerOpts = Reducer<ContactState, ContactActionTypes>
const reducer: ReducerOpts = (state = initialState, action: ContactActionTypes): ContactState => {
  let index = -1
  switch (action.type) {
    case ContactTypes.CONTACT_RESET:
      return initialState
    case ContactTypes.CONTACT_CREATE:
      return {
        ...state,
        contacts: {
          pagination: state.contacts.pagination,
          items: [
            ...state.contacts.items,
            action.payload
          ]
        }
      }
    case ContactTypes.CONTACT_UPDATE:
      index = state.contacts.items.findIndex(obj => obj.id === action.payload.id)
      return {
        ...state,
        contacts: {
          pagination: state.contacts.pagination,
          items: [
            ...state.contacts.items.slice(0, index),
            action.payload,
            ...state.contacts.items.slice(index + 1)
          ]
        }
      }
    case ContactTypes.CONTACT_DELETE:
      index = state.contacts.items.findIndex(obj => obj.id === action.payload)
      return {
        ...state,
        contacts: {
          pagination: state.contacts.pagination,
          items: [
            ...state.contacts.items.slice(0, index),
            ...state.contacts.items.slice(index + 1)
          ]
        }
      }
    case ContactTypes.CONTACT_GET:
      return { ...state, contact: action.payload }
    case ContactTypes.CONTACT_GET_LIST:
      return { ...state, contacts: { items: action.payload, pagination: action.pagination } }
    default:
      return state
  }
}

export default reducer
