import { InvoiceState, InvoiceActionTypes, InvoiceTypes } from './types'
import { Reducer } from 'redux'

const initialState: InvoiceState = {
  invoices: [],
  labels: {
    PENDING: 'Pendente',
    REFUNDED: 'Estornado',
    REJECTED: 'Rejeitado',
    CONFIRMED: 'Confirmado',
    PROCESSING: 'Processando',
    FINISHED: 'Finalizado',
    ACTIVE: 'Ativo',
    EXPIRED: 'Expirado',
    INACTIVE: 'Inativo',
    CANCELLED: 'Cancelado'
  },
  gateways: {
    AsaasPix: 'Pix',
    AsaasTicket: 'Boleto'
  }
}

type ReducerOpts = Reducer<InvoiceState, InvoiceActionTypes>
const reducer: ReducerOpts = (state = initialState, action: InvoiceActionTypes): InvoiceState => {
  switch (action.type) {
    case InvoiceTypes.INVOICE_RESET:
      return initialState
    case InvoiceTypes.INVOICE_CREATE:
      return { ...state, invoice: action.payload, invoices: [...state.invoices, action.payload] }
    case InvoiceTypes.INVOICE_UPDATE:
      return {
        ...state,
        invoice: action.payload,
        invoices: state.invoices.map(invoice => {
          return (invoice.id === action.payload.id) ? action.payload : invoice
        })
      }
    case InvoiceTypes.INVOICE_GET:
      return { ...state, invoice: action.payload }
    case InvoiceTypes.INVOICE_DELETE:
      return { ...state, invoices: state.invoices.filter(item => item.id !== action.payload) }
    case InvoiceTypes.INVOICE_GET_LIST:
      return { ...state, invoices: action.payload }
    default:
      return state
  }
}

export default reducer
