import React, { useState } from 'react'
import { Message } from 'panel/conversation/redux/types'

const File: React.FC<{ message: Partial<Message> }> = ({ message }) => {
  if (!message.attachment) return null

  return (
    <>
      <a href={message.attachment.url} target="_blank" rel="noreferrer" className="attachment-file">
        <i className="fa fa-file fa-3x"/>
        {
          message.attachment.fileName && message.attachment.fileName !== message.message &&
          <p>{ message.attachment.fileName }</p>
        }
      </a>
    </>
  )
}

export default File
