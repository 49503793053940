import { AttendantState, AttendantTypes, AttendantActionTypes } from './types'
import { Reducer } from 'redux'

const initialState: AttendantState = {
  attendants: []
}

type ReducerOpts = Reducer<AttendantState, AttendantActionTypes>
const reducer: ReducerOpts = (state = initialState, action: AttendantActionTypes): AttendantState => {
  let index = -1
  switch (action.type) {
    case AttendantTypes.ATTENDANT_RESET:
      return initialState
    case AttendantTypes.ATTENDANT_CREATE:
      return { ...state, attendant: action.payload, attendants: [...state.attendants, action.payload] }
    case AttendantTypes.ATTENDANT_UPDATE:
      index = state.attendants.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        attendant: action.payload,
        attendants: [...state.attendants.slice(0, index), action.payload, ...state.attendants.slice(index + 1)]
      }
    case AttendantTypes.ATTENDANT_DELETE:
      index = state.attendants.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        attendant: undefined,
        attendants: [...state.attendants.slice(0, index), ...state.attendants.slice(index + 1)]
      }
    case AttendantTypes.ATTENDANT_GET:
      return { ...state, attendant: action.payload }
    case AttendantTypes.ATTENDANT_GET_LIST:
      return { ...state, attendants: action.payload }
    default:
      return state
  }
}

export default reducer
