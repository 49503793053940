import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import './styles/Group.scss'

import Modal from 'util/modules/Modal'
import GroupForm from './components/GroupForm'
import GroupList from './components/GroupList'

import { ApplicationState } from 'AppReducer'
import { getContactStoreList } from 'panel/person/redux/action/contact'
import { hasPermission } from 'util/services/AuthService'

const Group: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isFormOpen, setFormOpen] = useState(false)

  const { action } = useParams<{ [x: string]: string }>()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleCloseForm () {
    setFormOpen(false)
    history.push('/panel/group')
  }

  useEffect(() => {
    if (action === 'edit') setFormOpen(true)
  }, [action])

  useEffect(() => {
    if (selected) {
      getContactStoreList(selected, {
        fields: 'id,value,person.name',
        limit: 20,
        page: 1
      })(dispatch)
    }
  }, [selected, dispatch])

  return (
    <div className="row panel bordered">
      <div className="row groups-container">
        <div className="row padding-24">
          <p className="grow" style={{ fontSize: 18 }}>Grupos Cadastrados</p>
          {
            hasPermission('GROUP:CREATE') &&
            <button className="gradient radius" style={{ width: 120 }} onClick={() => setFormOpen(true)}>
              Cadastrar
            </button>
          }
        </div>
        { hasPermission('GROUP:LIST') && <GroupList /> }

        <Modal isOpen={isFormOpen} onClose={handleCloseForm}>
          <GroupForm onClose={handleCloseForm} />
        </Modal>
      </div>
    </div>
  )
}

export default Group
