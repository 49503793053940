import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Modal from 'util/modules/Modal'
import StoreForm from './components/StoreForm'
import StoreList from './components/StoreList'

import './styles/Store.scss'

const Store: React.FC = () => {
  const history = useHistory()

  const [isFormOpen, setFormOpen] = useState(false)

  const { action } = useParams<{ [x: string]: string }>()

  useEffect(() => {
    if (action === 'edit') setFormOpen(true)
  }, [action])

  const query = new URLSearchParams(window.location.search)
  useEffect(() => {
    if (!isFormOpen) {
      query.delete('name')
      query.delete('client')
      console.log(`/panel/store?${query.toString()}`)
      history.push(`/panel/store?${query.toString()}`)
    }
  }, [isFormOpen])

  return (
    <div className="global-style-container">
      <div className="row store-manage">
        <div className="row margin-bottom-16">
          <p className="grow white" style={{ fontSize: 18, backgroundColor: 'transparent' }}>Lojas Cadastrados</p>
          <button className="gradient radius" onClick={() => setFormOpen(true)}>
            Cadastrar
          </button>
        </div>
        <StoreList />
        <Modal isOpen={isFormOpen} onClose={() => setFormOpen(false)}>
          <StoreForm isOpen={isFormOpen} onClose={() => setFormOpen(false)} />
        </Modal>
      </div>
    </div>
  )
}

export default Store
