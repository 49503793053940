import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import '../styles/Navbar.scss'

// ICONS
import { ReactComponent as Bars } from 'assets/icons/bars.svg'
import { ReactComponent as Robot } from 'assets/icons/robot.svg'
import { ReactComponent as Calls } from 'assets/icons/user-check.svg'
import { ReactComponent as Calendar } from 'assets/icons/calendaralt.svg'

import { img } from 'assets/online'
import { signOut } from 'util/services/AuthService'
import { getBotList } from 'panel/chatbot/redux/action/bot'
import { getScheduleList } from 'panel/schedule/redux/actions'
import { getAttendantList } from 'panel/attendant/redux/actions'
import { ApplicationState } from 'AppReducer'

type props = {
  onSidebarCollapsedChange(): void;
};

const Navbar: React.FC<props> = ({ onSidebarCollapsedChange }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isInfoOpen, setInfoOpen] = useState<boolean>(false)
  const selected = useSelector(
    (state: ApplicationState) => state.storeReducer.selected
  )
  const [themeMode, setThemeMode] = useState<boolean>(false)

  const { logged } = useSelector(
    (state: ApplicationState) => state.userReducer
  )
  const bots = useSelector((state: ApplicationState) => state.botReducer.bots)
  const schedules = useSelector(
    (state: ApplicationState) => state.scheduleReducer.schedules
  )
  const conversations = useSelector(
    (state: ApplicationState) => state.conversationReducer.conversations
  )

  const theme = localStorage.getItem('data-theme')

  const isClient = !!logged?.roles.includes('ROLE_CLIENT')

  const [navApp, setNavApp] = useState([
    {
      icon: Calendar,
      to: '/panel/schedule',
      label: 'Agendamentos',
      quantity: 0,
      isActive: !!selected,
      roles: ['ROLE_CLIENT']
    },
    {
      icon: Robot,
      to: '/panel/chatbot',
      label: 'ChatBot',
      isActive: !!selected,
      roles: ['ROLE_CLIENT']
    },
    {
      icon: Calls,
      to: '/panel/conversation',
      label: 'Atendimentos',
      quantity: 0,
      isActive: !!selected,
      roles: ['ROLE_CLIENT', 'CONVERSATION:MENU']
    }
  ])

  const { channels } = useSelector(
    (state: ApplicationState) => state.channelReducer
  )
  const disconnectedChannels = channels.filter(
    (item) => item.status !== 'CONNECTED'
  )
  const connectedChannels = channels.filter(
    (item) => item.status === 'CONNECTED'
  )

  function handleSignOut () {
    if (window.confirm('Tem certeza que gostaria de sair?')) {
      signOut()
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    const pendingSchedule = schedules.reduce((acc, item) => {
      if (item.status === 'PENDING') acc += 1
      return acc
    }, 0)

    setNavApp((navApp) =>
      navApp.map((item) => {
        if (item.label === 'Agendamentos') {
          item.quantity = pendingSchedule
        }

        if (item.label === 'Atendimentos') {
          item.quantity = conversations.filter(
            (item) => item.status === 'QUEUE'
          ).length
        }

        if (item.label === 'ChatBot') {
          item.quantity = bots.length
        }

        return item
      })
    )
  }, [schedules, bots, conversations])

  useEffect(() => {
    setNavApp((items) =>
      items.map((item) => {
        item.isActive = !!selected
        return item
      })
    )
    setNavApp((items) =>
      items.map((item) => {
        item.isActive = !!selected
        return item
      })
    )

    if (selected) {
      // Handle store fetches
      if (isClient) {
        getBotList(selected)(dispatch)
        getScheduleList(selected)(dispatch)
      }
      getAttendantList(selected)(dispatch)
    }
  }, [selected, isClient, dispatch])

  const picture = logged?.person?.picture || `${img['default-user']}`

  const toggleDarkMode = () => {
    const container = document.getElementById('themeMode')
    const dataTheme = container?.getAttribute('data-theme')

    if (dataTheme === 'dark') {
      container?.setAttribute('data-theme', 'light')
      localStorage.setItem('data-theme', 'light')
    } else {
      container?.setAttribute('data-theme', 'dark')
      localStorage.setItem('data-theme', 'dark')
    }
  }

  useEffect(() => {
    if (theme === 'dark') {
      setThemeMode(true)
    } else {
      setThemeMode(false)
    }

    const outsideClick = (event: MouseEvent) => {
      if (!event.target) return
      const element = event.target as HTMLElement
      const isMenu = element.closest('#user-menu')
      if (!isMenu) setInfoOpen(false)
    }
    document.addEventListener('click', outsideClick)

    return () => {
      document.removeEventListener('click', outsideClick)
    }
  }, [])

  return (
    <div className="row navbar">
      <Bars
        width={20}
        className="bar-icon pointer hidden-desk"
        onClick={onSidebarCollapsedChange}
      />
      <span
        className="row hidden-desk margin-left-16"
        style={{ width: 'auto' }}
      >
        <img className="hidden-mobile" src={img.logo} height={20} alt="" />
        <img className="hidden-desk" src={img.logoIcon} height={20} alt="" />
      </span>

      <div className="row channels hidden-mobile">
        {[...disconnectedChannels, ...connectedChannels].map((channel, i) => {
          const isConnected = channel.status === 'CONNECTED'
          const className = isConnected ? 'connected' : 'disconnected'
          const name = isConnected ? 'Conectado' : 'Desconectado'

          return (
            <NavLink
              key={i}
              className="row channel-box"
              to={`/panel/channel/${channel.id}/config`}
            >
              <div
                className={`button btn-channel ${className}`}
                title={channel.name}
              >
                <i className="fa fa-wifi" />
                <div className="grow text-left">
                  <p style={{ margin: 0 }}>{name}</p>
                </div>
              </div>
              <span>
                <b>Canal:</b> {channel.name}
              </span>
            </NavLink>
          )
        })}
      </div>

      <div className="row nav-info-icon">
        {navApp
          .filter((item) =>
            item.roles.find((str) =>
              logged?.roles?.find((role) => role === str)
            )
          )
          .map((item, i) => {
            const { isActive, icon: Icon } = item
            return (
              <NavLink
                to={item.to}
                key={i}
                className="icon-rounded row center-center"
              >
                <Icon
                  width={16}
                  height={16}
                  style={{
                    color: 'var(--water-green)',
                    opacity: isActive ? 1 : 0.4,
                    pointerEvents: isActive ? 'inherit' : 'none'
                  }}
                />
                {!!item.quantity && (
                  <div className="row icon-quantity">{item.quantity}</div>
                )}
              </NavLink>
            )
          })}
      </div>

      <div id="user-menu" className="row justify-start" onClick={() => setInfoOpen(isOpen => !isOpen)}>
        <img
          src={picture}
          width={35}
          height={35}
          className="user-pic pointer"
          alt=""
        />
        <i
          className="fa fa-angle-down pointer"
          style={{ color: 'var(--highlight)' }}
        />

        {isInfoOpen && (
          <div className="panel info-box text-overflow" ref={ref} onClick={e => {
            e.stopPropagation()
          }}>
            <strong>{logged?.person.name}</strong>
            <p className="text-overflow">{logged?.person.email}</p>
            <hr className="margin-tb-8" />
            <div className="row justify-start margin-bottom-8 margin-top-8">
              <label className="switch">
                <input
                  readOnly
                  type="checkbox"
                  checked={themeMode}
                  onChange={(event) => {
                    setThemeMode(event.target.checked)
                    toggleDarkMode()
                  }}
                />
                <span className="slider round" />
              </label>
              <strong className="margin-left-8">Tema</strong>
            </div>
            <hr className="margin-tb-8" />
            <p className="pointer" onClick={handleSignOut}>
              <i className="fa fa-power-off" /> Sair
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar
