import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Upload, { UploadRef } from 'util/modules/Upload'

import { passwordValidate } from 'util/services/Validator'
import { updateSeller, createSeller, getSeller } from '../redux/actions'
import { maskPhone, maskCPF, maskCNPJ, maskDate, formatDate } from 'util/masks'

import { ReactComponent as User } from 'assets/icons/user.svg'
import { ReactComponent as Times } from 'assets/icons/times.svg'

const initialState = {
  ...{ docType: 'CPF', docNumber: '', gender: 'O', phone: '', password: '', _password: '' },
  ...{ isActive: true, isBlocked: false, firstName: '', lastName: '', email: '', username: '', birthday: '' },
  ...{ type: 'PRE-PAID', accountLimit: 20, dueDate: '', paymentApiKey: '', paymentApiSecret: '' }
}

type Props = {
  onClose(): void
}

const SellerForm: React.FC<Props> = ({ onClose }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { id } = useParams<{ id: string }>()

  const [form, setForm] = useState(initialState)
  const [picture, setPicture] = useState<File>()
  const [fetching, setFetching] = useState(false)

  const uploadRef = useRef<UploadRef>(null)

  const handleClear = useCallback(() => {
    setForm(initialState)
    uploadRef.current?.clear()
    history.push('/panel/seller')
  }, [history, uploadRef])

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    const formData = new FormData()
    if (picture) formData.set('picture', picture)

    formData.set('type', form.type)
    formData.set('isActive', String(form.isActive ? 1 : 0))
    formData.set('isBlocked', String(form.isBlocked ? 1 : 0))
    formData.set('firstName', form.firstName)
    formData.set('lastName', form.lastName)
    formData.set('email', form.email)
    if (form.username) formData.set('username', form.username)
    if (form.password) formData.set('password', form.password)

    // non-required fields
    if (form.birthday) {
      formData.set('birthday', form.birthday.split('/').reverse().join('-'))
    }

    if (form.docType && form.docNumber) {
      formData.set('docType', form.docType)
      formData.set('docNumber', form.docNumber)
    }

    if (form.phone) formData.set('phone', form.phone)
    if (form.gender) formData.set('gender', form.gender)
    if (form.dueDate) formData.set('dueDate', form.dueDate.split('/').reverse().join('-'))
    if (form.accountLimit) formData.set('accountLimit', String(form.accountLimit))
    if (form.paymentApiKey) formData.set('paymentApiKey', String(form.paymentApiKey))
    if (form.paymentApiSecret) formData.set('paymentApiSecret', String(form.paymentApiSecret))

    setFetching(true)
    if (id) {
      await updateSeller(id, formData)(dispatch)
    } else {
      await createSeller(formData)(dispatch).then(res => {
        if (res?.status === 'success' && res?.data) {
          handleClear()
        }
      })
    }
    setFetching(false)
  }

  useEffect(() => {
    if (id) {
      getSeller(id)(dispatch).then(res => {
        const sellerUser = res?.data
        const user = res?.data?.user
        const person = res?.data?.user?.person
        if (res?.status === 'success' && sellerUser && user && person) {
          setForm(form => ({
            ...form,
            isBlocked: !!sellerUser.isBlocked,
            dueDate: form.dueDate ? formatDate(form.dueDate).split('-').reverse().join('/') : '',
            isActive: !!user.isActive,
            docType: person.docType || 'CPF',
            docNumber: person.docNumber || '',
            gender: person.gender || 'O',
            phone: person.phone || '',
            firstName: person.firstName || '',
            lastName: person.lastName || '',
            email: person.email || '',
            username: user.username || '',
            birthday: person.birthday || ''
          }))

          if (person.picture && uploadRef.current) {
            uploadRef.current.setPreviewImage(person.picture)
          }
        }
      })
    } else {
      handleClear()
    }
  }, [id, uploadRef, handleClear, dispatch])

  const validation = passwordValidate(form.password, form._password)

  return (
    <form className="form-config-container panel" onSubmit={handleSubmit}>
      <div className='row form-config'>
        <div className="row">
          <User width={20} height={25} />
          <p className="flex padding-left-8">Dados do Usuário</p>

          <span className="button-times pointer" onClick={onClose}>
            <Times width={15} height={15} />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="row" style={{ width: 179, height: 179 }}>
          <Upload
            ref={uploadRef}
            onChange={setPicture}
            placeholder="Selecionar imagem para perfil"
          />
        </div>
      </div>

      <div className="padding-24">
        <div className="row padding-left-8">
          <div className="align-end grow">
            <div className="form-control grow">
              <label>Primeiro Nome</label>
              <input
                required
                type="text"
                value={form.firstName}
                onChange={(e): void => setForm({ ...form, firstName: e.target.value })}
              />
            </div>
            <div className="form-control grow">
              <label>Sobrenome</label>
              <input
                required
                type="text"
                value={form.lastName}
                onChange={(e): void => setForm({ ...form, lastName: e.target.value })}
              />
            </div>

            <div className="form-control grow">
              <label>Ativo?</label>
              <select
                required
                value={form.isActive ? 1 : 0}
                onChange={(e): void => setForm({ ...form, isActive: parseInt(e.target.value) === 1 })}
              >
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>

            <div className="form-control grow">
              <label>Número Telefone</label>
              <input
                type="text"
                value={form.phone}
                onChange={(e): void => setForm({ ...form, phone: maskPhone(e.target.value) })}
              />
            </div>
          </div>

          <div className="align-end grow">
            <div className="form-control grow">
              <label>Tipo / Documento</label>
              <select
                value={form.docType}
                onChange={(e): void => setForm({ ...form, docType: e.target.value })}
              >
                <option>CPF</option>
                <option>CNPJ</option>
              </select>
            </div>
            <div className="form-control grow">
              <label>{form.docType}</label>
              <input
                type="text"
                value={form.docNumber}
                onChange={({ target: { value } }): void => {
                  setForm({ ...form, docNumber: form.docType === 'CPF' ? maskCPF(value) : maskCNPJ(value) })
                }}
              />
            </div>

            <div className="form-control grow">
              <label>Data Nascimento</label>
              <input
                type="text"
                value={form.birthday}
                onChange={(e): void => setForm({ ...form, birthday: maskDate(e.target.value, '/') })}
              />
            </div>

            <div className="form-control grow">
              <label>Gênero</label>
              <select
                value={form.gender}
                onChange={(e): void => setForm({ ...form, gender: e.target.value })}
              >
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
                <option value="O">Outro</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="padding-24">
        <div className="row align-start">
          <div className="grow margin-top-24">
            <h2>DADOS DO REVENDEDOR</h2>
            <div className="form-control grow">
              <label>Tipo</label>
              <select
                required
                value={form.type}
                onChange={(e): void => setForm({ ...form, type: e.target.value })}
              >
                <option value="PRE-PAID">Pré-pago</option>
                <option value="POST-PAID">Pós-pago</option>
                <option value="UNLIMITED">Ilimitado</option>
              </select>
            </div>
            <div className="form-control grow">
              <label>Bloqueado?</label>
              <select
                required
                value={form.isBlocked ? 1 : 0}
                onChange={(e): void => setForm({ ...form, isBlocked: parseInt(e.target.value) === 1 })}
              >
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>
            <div className="form-control grow">
              <label>Limite de cadastro</label>
              <input
                required
                step={1}
                type="number"
                value={form.accountLimit}
                onChange={(e): void => setForm({ ...form, accountLimit: parseInt(e.target.value) })}
              />
            </div>
            <div className="form-control grow">
              <label>Vencimento</label>
              <input
                required
                type="text"
                value={form.dueDate}
                onChange={(e): void => setForm({ ...form, dueDate: maskDate(e.target.value) })}
              />
            </div>
          </div>

          <div className="grow margin-top-24">
            <h2>DADOS DE ACESSO</h2>

            <div className="grow form-inputs align-end">
              <div className="form-control grow">
                <label>E-mail</label>
                <input
                  required
                  type="text"
                  value={form.email}
                  onChange={(e): void => setForm({ ...form, email: e.target.value })}
                />
              </div>
              <div className="form-control grow">
                <label>Nome de Usuário</label>
                <input
                  type="text"
                  value={form.username}
                  onChange={(e): void => setForm({ ...form, username: e.target.value })}
                />
              </div>
              <div className="form-control grow">
                <label>Senha</label>
                <input
                  required={!id}
                  type="password"
                  value={form.password}
                  autoComplete="new-password"
                  onChange={(e): void => setForm({ ...form, password: e.target.value })}
                />
              </div>

              <div className="form-control grow">
                <label>Confirmar Senha</label>
                <input
                  required={!id}
                  type="password"
                  value={form._password}
                  autoComplete="new-password"
                  onChange={(e): void => setForm({ ...form, _password: e.target.value })}
                />
              </div>
            </div>

            <p className="col-12 text-center">
              {validation.warnings?.[0] || <>&nbsp;</>}
            </p>

          </div>
        </div>

        <div className="row justify-end margin-top-8 form-buttons">
          <span className="secondary button margin-right-8" onClick={handleClear}>Limpar</span>
          <button
            className="primary"
            disabled={fetching || !!(!validation.isValid && !id)}
            onClick={fetching ? onClose : null as any}
          >Salvar</button>
        </div>
      </div>
    </form>
  )
}

export default SellerForm
