import React, { PropsWithChildren } from 'react'

type Props = {
  label?: string,
  pb?: boolean
}
export const FormControlNode: React.FC<PropsWithChildren<Props>> = ({ pb = false, label, children }) => {
  return (
    <div>
      <label className='px pt custom-drag-handle'>{label}</label>
      <div style={{ display: 'grid', gridTemplateColumns: '16px 1fr 16px' }}>
        <div className='custom-drag-handle'></div>
        {children }
        <div className='custom-drag-handle'></div>
      </div>
      { pb && <label className='pb custom-drag-handle' />}
    </div>
  )
}

export const ItemWithIcon: React.FC<PropsWithChildren<{ icon: string }>> = ({ icon, children }) => {
  return (
    <div style={{ display: 'grid', gridTemplateColumns: '30px 1fr' }}>
      <i
        className={`fa ${icon} custom-drag-handle`}
        style={{ margin: 0, height: '100%', display: 'flex', alignItems: 'center' }}
      />
      {children}
    </div>
  )
}
