import React from 'react'

import { Store } from 'panel/store/redux/types'
import { NavLink } from 'react-router-dom'
import { formatDate } from 'util/masks'

const AlertExpiringPlan: React.FC<{ store: Store }> = ({ store }) => {
  if (!store.plan || store.isExpired) return null

  if (store.daysToExpire === 0) {
    return (
      <div className="row alert-box warning">
        <i className="fa fa-exclamation-triangle"/>
        Seu plano vence HOJE
        <NavLink to="/panel/invoice" className="button"><i className="fa fa-plus"/> Renovar agora</NavLink>
      </div>
    )
  }

  if (store.daysToExpire <= 5 && store.daysToExpire > 0) {
    return (
      <div className="row alert-box warning">
        <i className="fa fa-exclamation-triangle"/>
        Seu plano vence em {store.daysToExpire} dias ({ formatDate(store.dueDate) })
        <NavLink to="/panel/invoice" className="button"><i className="fa fa-plus"/> Renovar agora</NavLink>
      </div>
    )
  }

  if (store.daysToExpire < 0) {
    return (
      <div className="row alert-box warning">
        <i className="fa fa-exclamation-triangle"/>
        Seu plano esta vencido
        <NavLink to="/panel/invoice" className="button"><i className="fa fa-plus"/> Renovar agora</NavLink>
      </div>
    )
  }

  return null
}

export default AlertExpiringPlan
