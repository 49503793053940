import Axios from 'axios'
import { Dispatch } from 'redux'

import { ActionResponse, Id } from 'util/types/index'
import { BotOption, BotOptionTypes } from '../type/option'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'

export function createBotOption (storeId: Id, botId: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<BotOption>> => {
    return Axios
      .post(`/store/${storeId}/bot/${botId}/option`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: BotOptionTypes.BOT_OPTION_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar a opção no bot')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateBotOption (storeId: Id, botId: Id, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<BotOption>> => {
    return Axios
      .put(`/store/${storeId}/bot/${botId}/option/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: BotOptionTypes.BOT_OPTION_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar a opção no bot')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteBotOption (storeId: Id, botId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<BotOption>> => {
    return Axios
      .delete(`/store/${storeId}/bot/${botId}/option/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: BotOptionTypes.BOT_OPTION_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover a opção no bot')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getBotOption (storeId: Id, botId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<BotOption>> => {
    return Axios
      .get(`/store/${storeId}/bot/${botId}/option/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: BotOptionTypes.BOT_OPTION_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar a opção no bot')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }

        return res
      })
  }
}

export function getBotOptionList (storeId: Id, botId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<BotOption>> => {
    return Axios
      .get(`/store/${storeId}/bot/${botId}/option`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: BotOptionTypes.BOT_OPTION_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}
