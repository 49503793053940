import { Reducer } from 'redux'
import { CommandState, CommandTypes, CommandActionTypes } from './types'

const initialState: CommandState = {
  commands: []
}

type ReducerOpts = Reducer<CommandState, CommandActionTypes>
const reducer: ReducerOpts = (state = initialState, action: CommandActionTypes): CommandState => {
  switch (action.type) {
    case CommandTypes.COMMAND_GENERATE:
      return { ...state, commands: [action.payload, ...state.commands] }
    case CommandTypes.COMMAND_DELETE:
      return { ...state, commands: state.commands.filter(item => item.id !== action.payload) }
    case CommandTypes.COMMAND_GET:
      return { ...state, command: action.payload }
    case CommandTypes.COMMAND_GET_LIST:
      return { ...state, commands: action.payload }
    default:
      return state
  }
}

export default reducer
