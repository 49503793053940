import { useDispatch } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { Api } from 'util/services/api'
import { clearAlert, createAlert } from 'util/modules/Alert/redux/actions'
import { BotAttachmentSchemaType, BotAttachmentType } from '../schema/chat-bot.attachment.schema'

export const createChatBotAttachment = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    async (form: BotAttachmentSchemaType & { url: string }) => Api.post('/bot/attachment', form),
    {
      onSuccess: async ({ statusCode, message = 'Anexo salvo' }) => {
        await queryClient.invalidateQueries(['chat-bot-attachments'])
        createAlert({ message, code: statusCode })(dispatch)
      },
      onError: (error: Error) => {
        createAlert({ message: error.message || 'Ocorreu um erro ao criar o anexo', code: 500 })(dispatch)
      },
      onSettled: () => clearAlert(3_000)(dispatch)
    }
  )
}

export const deleteChatBotAttachment = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    async (id: string) => Api.delete(`/bot/attachment/${id}`),
    {
      onSuccess: async ({ statusCode, message = 'Anexo salvo' }) => {
        await queryClient.invalidateQueries(['chat-bot-attachments'])
        createAlert({ message, code: statusCode })(dispatch)
      },
      onError: (error: Error) => {
        createAlert({ message: error.message || 'Ocorreu um erro ao remover o anexo', code: 500 })(dispatch)
      },
      onSettled: () => clearAlert(3_000)(dispatch)
    }
  )
}

export const getChatBotAttachmentList = () => {
  return useQuery<BotAttachmentDto[]>(
    ['chat-bot-attachments'],
    async () => {
      const { data } = await Api.get<BotAttachmentDto[]>('/bot/attachment')
      return data || []
    },
    {
      enabled: true,
      initialData: []
    }
  )
}

export interface BotAttachmentDto {
  id: string,
  storeId: string,

  url: string,
  type: BotAttachmentType,
  title: string,

  createdAt: string,
}
