import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { ApplicationState } from 'AppReducer'
import { getSector, updateSector, createSector } from '../redux/actions'

import { ReactComponent as Cog } from 'assets/icons/cog.svg'
import { ReactComponent as Times } from 'assets/icons/times.svg'

import TagInput from 'util/modules/Form/TagInput'
import { ModalHeader } from 'util/modules/Modal'

const initialState = { isActive: true, name: '' }

const SectorForm: React.FC<{ onClose(): void }> = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { id } = useParams<{ id: string }>()
  const [form, setForm] = useState(initialState)
  const [attendantsId, setAttendantsId] = useState<number[]>([])

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { attendants } = useSelector((state: ApplicationState) => state.attendantReducer)

  const handleClear = useCallback(() => {
    setForm(initialState)
    setAttendantsId([])
    history.push('/panel/sector')
  }, [history])

  function handleAddAttendant (id: number) {
    const isIdOnList = attendantsId.find(item => item === id)
    if (!isIdOnList) {
      setAttendantsId([...attendantsId, id])
    }
  }

  function handleDelAttendant (id: number) {
    setAttendantsId(attendantsId.filter(item => item !== id))
  }

  function handleSubmit (e: React.FormEvent): void {
    e.preventDefault()

    if (!selected) return

    if (id) {
      updateSector(selected, id, { ...form, attendantsId })(dispatch)
    } else {
      createSector(selected, { ...form, attendantsId })(dispatch).then(res => {
        if (res?.data) {
          handleClear()
          onClose()
        }
      })
    }
  }

  useEffect(() => {
    if (selected && id) {
      getSector(selected, id)(dispatch).then(res => {
        const sector = res?.data
        if (res?.status === 'success' && sector) {
          setForm({
            isActive: sector.isActive,
            name: sector.name
          })

          setAttendantsId(res.data?.sectorAttendants.map(item => item.attendant?.id || 0) || [])
        }
      })
    } else {
      handleClear()
    }
  }, [id, selected, handleClear, dispatch])

  if (!selected) return null

  return (
    <form className="panel no-padding" onSubmit={handleSubmit}>
      <ModalHeader title="Configurações de Setores" onClose={onClose} />
      <div className="panel-body">
        <div className="form-control" style={{ margin: 0 }}>
          <label>Nome do Setor</label>
          <input type="text" value={form.name} onChange={(e): void => setForm({ ...form, name: e.target.value })} />
        </div>
        <div className="form-control">
          <label>Atendentes neste setor</label>
          <TagInput
            targetPath="/panel/attendant"
            onSelect={handleAddAttendant}
            onDelete={handleDelAttendant}
            filled={attendantsId}
            items={attendants.map(({ id, user }) => ({ id: id, label: user.person?.firstName || '' }))}
          />
        </div>
        <div className="form-control">
          <label>Ativo</label>
          <div className="row justify-start">
            <label className="checkbox margin-right-16" style={{ width: 'auto' }}>
              <input
                type="checkbox"
                checked={form.isActive}
                onClick={() => setForm({ ...form, isActive: true })}
              />
              <div className="checkmark" /> Sim
            </label>
            <label className="checkbox" style={{ width: 'auto' }}>
              <input
                type="checkbox"
                checked={!form.isActive}
                onClick={() => setForm({ ...form, isActive: false })}
              />
              <div className="checkmark" /> Não
            </label>
          </div>
        </div>

        <div className="row margin-top-16 buttons">
          <span className="secondary button" onClick={handleClear}>Limpar</span>
          <button className="gradient button">Salvar</button>
        </div>
      </div>
    </form>
  )
}

export default SectorForm
