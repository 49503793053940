import React from 'react'

import './styles/InputNumber.scss'

type Props = { value: number, onChange?: (value: number) => void, disabled?: boolean }
const InputNumber: React.FC<Props> = ({ value, onChange, disabled }) => {
  function handleAdd () {
    if (onChange) {
      onChange(value + 1)
    }
  }

  function handleRemove () {
    if (onChange && value >= 1) {
      onChange(value - 1)
    }
  }

  return (
    <div className={`row input input-number ${disabled ? 'disabled' : ''}`}>
      <div className="editable">{ value }</div>
      <div className="controls">
        <i className="fa fa-angle-up" onClick={handleAdd} />
        <i className="fa fa-angle-down" onClick={handleRemove}/>
      </div>
    </div>
  )
}

export default InputNumber
