import { Id } from './types'
import NumberFormats from './data/NumberFormats.json'

export const maskDate = (date: string, divisor = '/'): string => {
  if (date) {
    return date
      .toString()
      .replace(/[\D]/g, '')
      .replace(/([\d]{0,2})([\d]{0,2})([\d]{0,4})/g, `$1${divisor}$2${divisor}$3`)
      .replace(/[\D]{1,}$/g, '')
      .slice(0, 10)
  }
  return date
}
export const maskHour = (hour: string): string => {
  if (hour) {
    return hour
      .toString()
      .replace(/[\D]/g, '')
      .replace(/([\d]{0,2})([\d]{0,2})/g, '$1:$2')
      .replace(/[\D]{1,}$/g, '')
      .slice(0, 5)
  }
  return hour
}

export const maskCNH = (cpf: string): string => {
  if (cpf) {
    return cpf
      .toString()
      .replace(/[\D]/g, '')
      .replace(/([\d]{1,11})/g, '$1')
      .replace(/[\D]{1,}$/g, '')
      .slice(0, 11)
  }
  return cpf
}

export const maskCPF = (cpf: string): string => {
  if (cpf) {
    return cpf
      .toString()
      .replace(/[\D]/g, '')
      .replace(/([\d]{0,3})([\d]{0,3})([\d]{0,3})([\d]{0,2})/g, '$1.$2.$3-$4')
      .replace(/[\D]{1,}$/g, '')
      .slice(0, 14)
  }
  return cpf
}

export const maskCNPJ = (cnpj: string): string => {
  if (cnpj) {
    return cnpj
      .toString()
      .replace(/[\D]/g, '')
      .replace(/([\d]{0,2})([\d]{0,3})([\d]{0,3})([\d]{0,4})([\d]{0,2})/g, '$1.$2.$3/$4-$5')
      .replace(/[\D]{1,}$/g, '')
      .slice(0, 18)
  }
  return cnpj
}

export const maskNumber = (str: string): string => {
  if (str) {
    return str
      .toString()
      .replace(/[\D]/g, '')
  }
  return str
}

export const maskPassport = (passport: string): string => {
  const regex = /^([A-Z]{1,2})([\d]{0,7})$/g
  if (passport) {
    return passport
      .toString()
      .replace(/^[^A-Z]|[^A-Z0-9]/g, '')
      .replace(regex, '$1$2')
      .slice(0, 9)
  }
  return ''
}

export const maskBoleto = (code: string): string => {
  if (code) {
    // eslint-disable-next-line
    const regex = /([0-9]{1,5})([0-9]{1,5})?([0-9]{1,5})?([0-9]{1,6})?([0-9]{1,5})?([0-9]{1,6})?([0-9]{1})?([0-9]{1,14})?/g
    return code
      .toString()
      .replace(/[\D]/g, '')
      .replace(regex, '$1.$2 $3.$4 $5.$6 $7 $8')
      .replace(/[\D]{1,}$/g, '')
      .slice(0, 54)
  }
  return code
}

export function formatDate (dateString?: string, placeholder = '', delimiter = '/'): string {
  if (dateString) {
    const [date] = dateString.split('T')
    const [year, month, day] = date.split('-')
    return `${day}${delimiter}${month}${delimiter}${year}`
  }
  return placeholder
}

export function formatTime (dateString?: string, placeholder = ''): string {
  if (dateString) {
    const [, time] = dateString.split('T')
    const [hour, minutes] = time?.split(':') || [undefined, undefined]
    const timeStr = time ? `${hour}:${minutes}` : ''
    return timeStr
  }
  return placeholder
}

export function formatDateTime (dateString?: string, placeholder = '', delimiter = '/'): string {
  if (dateString) {
    const date = formatDate(dateString, placeholder, delimiter)
    const [, time] = dateString.split('T')
    const [hour, minutes, seconds] = time?.split(':') || [undefined, undefined, undefined]
    const timeStr = time ? `${hour}:${minutes}:${seconds.split('.').shift()}` : ''
    return `${date} ${timeStr}`
  }

  return placeholder
}

const options: any = {
  'pt-BR': { locale: 'pt-BR', style: 'currency', currency: 'BRL' },
  'en-US': { locale: 'en-US', style: 'currency', currency: 'USD' }
}
const baseLocale = process.env.REACT_APP_BASE_CURRENCY || process.env.REACT_APP_BASE_LOCALE || 'pt-BR'
export function maskMoney (value: number, locale = baseLocale): string {
  if (!isNaN(value) && value !== null && value !== undefined) {
    const option = options[locale]
    if (option) {
      return value.toLocaleString(locale, option)
    }
  }

  return ''
}

export const maskPlate = (plate: string): string => {
  if (plate) {
    const regex = /^([a-zA-Z]{1,3})([\d][a-zA-Z\d]{0,1}[\d]{0,2})?$/g
    if (regex.test(plate.replace(/[^a-zA-Z0-9]/g, ''))) {
      return plate
        .replace(/[^a-zA-Z0-9]/g, '')
        .replace(regex, '$1-$2')
        .slice(0, 8)
    } else {
      return ''
    }
  }
  return plate
}

export const maskPhone = (phone?: string): string => {
  if (phone) {
    const number = phone
      .toString()
      .replace(/[\D]/g, '')

    if (number.length <= 12) {
      return number
        .replace(/([\d]{0,2})([\d]{0,2})([\d]{0,4})([\d]{0,4})/g, '+$1 ($2) $3-$4')
        .replace(/[\D]{1,}$/g, '')
        .slice(0, 18)
    }

    return number
      .replace(/([\d]{0,2})([\d]{0,2})([\d]{1})([\d]{0,4})([\d]{0,4})/g, '+$1 ($2) $3 $4-$5')
      .replace(/[\D]{1,}$/g, '')
      .slice(0, 20)
  }
  return phone || ''
}

export const maskLandline = (phone: string): string => {
  if (phone) {
    return phone
      .toString()
      .replace(/[\D]/g, '')
      .replace(/([\d]{0,2})([\d]{0,4})([\d]{0,4})/g, '($1) $2-$3')
      .replace(/[\D]{1,}$/g, '')
      .slice(0, 14)
  }
  return phone
}

export const maskPhoneLine = (phone?: string): string => {
  if (phone) {
    const number = phone
      .toString()
      .replace(/[\D]/g, '')

    if (number.length <= 12) {
      return number
        .replace(/([\d]{0,2})([\d]{0,2})([\d]{0,4})([\d]{0,4})/g, '+$1 ($2) $3-$4')
        .replace(/[\D]{1,}$/g, '')
        .slice(0, 18)
    }

    return number
      .replace(/([\d]{0,2})([\d]{0,2})([\d]{1})([\d]{0,4})([\d]{0,4})/g, '+$1 ($2) $4-$5')
      .replace(/[\D]{1,}$/g, '')
      .slice(0, 20)
  }
  return phone || ''
}

export const maskCEP = (cep: string): string => {
  if (cep) {
    return cep
      .toString()
      .replace(/[\D]/g, '')
      .replace(/([\d]{0,5})([\d]{0,3})/g, '$1-$2')
      .replace(/[\D]{1,}$/g, '')
      .slice(0, 9)
  }
  return cep
}

export const maskCreditCard = (cardNumber: string): string => {
  if (cardNumber) {
    return cardNumber
      .toString()
      .replace(/[\D]/g, '')
      .replace(/([\d]{0,4})([\d]{0,4})([\d]{0,4})([\d]{0,4})/g, '$1 $2 $3 $4')
      .replace(/[\D]{1,}$/g, '')
      .slice(0, 19)
  }
  return cardNumber
}

export const sentenseCase = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ')
}

export type OptionsOpts = {
  million?: string,
  thousand?: string,
  hundred: string,
  decimal?: number
}
export const inputMaskMoney = (received: number | string, prefix?: string, options?: OptionsOpts)
  : [string, number] => {
  const value = typeof received === 'number' ? received.toFixed(2) : received
  const number = parseFloat(value.replace(/[\D]/g, '')).toString().slice(0, 11)

  const s = {
    h: options?.hundred || ',',
    t: options?.thousand || '.',
    m: options?.million || '.'
  }

  const decimal = options?.decimal || 2

  const cents = { regex: new RegExp(`^([0-9]{0,${decimal}})$`), format: `0${s.h}$1` }
  const hundred = { regex: /^([\d]{1,3})([\d]{2})$/g, format: `$1${s.h}$2` }
  const thousand = { regex: /^([\d]{1,3})([\d]{3})([\d]{2})$/g, format: `$1${s.t}$2${s.h}$3` }
  const million = { regex: /^([\d]{1,3})([\d]{3})([\d]{3})([\d]{2})$/g, format: `$1${s.m}$2${s.t}$3${s.h}$4` }

  const regexList = [cents, hundred, thousand, million]

  if (options) {
    if (!options.thousand) delete regexList[2]
    if (!options.million) delete regexList[2]
  }

  const result = regexList
    .map((item) => {
      if (item.regex.test(number)) {
        const replaced = number.replace(item.regex, item.format).replace(/[\D]{1,}$/g, '')
        return replaced
      }
      return null
    })
    .filter(item => item)
    .shift()

  const prefixStr = prefix ? `${prefix.trim()} ` : ''
  const formated = result ? `${prefixStr}${result}` : ''
  const numberValue = parseFloat(`${number.toString().slice(0, -2)}.${number.toString().slice(-2)}`)
  return [formated, numberValue]
}

export const formatNumberByPattern = (received: Id, config = NumberFormats.FIAT.BRL): [string, number] => {
  // Clean non digit numbers
  let onlyNumbers: string | number = parseInt(String(received).replace(/[\D]/g, ''))
  onlyNumbers = isNaN(onlyNumbers) ? 0 : onlyNumbers
  onlyNumbers = onlyNumbers.toString().slice(0, config.max)

  // Calculate numbers of zeros to fill minimum
  let multiplier = config.min - onlyNumbers.length
  multiplier = multiplier > 0 ? multiplier : 0

  // Add zeros to fill minimum
  const value = '0'.repeat(multiplier) + onlyNumbers

  // Get replace parts of pattern
  const replaceParts = config.replace.match(/([$][0-9])/g) || []

  // Get non empty groups from inputed value and fill emepty based on replacable
  const matches = value.replace(new RegExp(config.regex), (...args) => {
    const quantity = replaceParts.length
    const parts = args.slice(1, -2).filter((item) => !!item)
    return [...Array(quantity - parts.length), ...parts].join(',')
  }).split(',')

  // Replace pattern with equivalent group matched
  let replaced = `${config.symbol} ${config.replace}`
  replaceParts.forEach((part, i) => {
    replaced = replaced.replace(part, matches[i])
  })

  // Sanitize formated and keep only valid
  const formated = replaced.replace(/(.+)(\s\D+)(.+)/g, '$1 $3')

  // Get formated and float value
  const numbers = matches.filter(item => !!item)
  const cents = numbers.pop()
  const amount = parseFloat(`${numbers.join('')}.${cents}`)

  return [formated, amount]
}
