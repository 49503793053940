import axios from 'axios'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { maskPhone, maskPhoneLine } from 'util/masks'
import { ModalHeader } from 'util/modules/Modal'
import { ApplicationState } from 'AppReducer'
import { Contact, ContactTypes } from 'panel/person/redux/types/contact'

import Modal from 'util/modules/Modal'

const initialState = { firstName: '', lastName: '', contact: '' }
const NewContact: React.FC<{
  onValidate: (contact: Partial<Contact>) => void, isModalOpen: boolean, setModalOpen: (payload: boolean) => void
}> =
  ({ onValidate, isModalOpen, setModalOpen }) => {
    const dispatch = useDispatch()

    const [form, setForm] = useState(initialState)
    const [contact, setContact] = useState<Partial<Contact>>()
    const [isFetching, setFetching] = useState(false)

    const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

    async function handleCreate () {
      const body = { ...form, contact: maskPhoneLine(form.contact) }
      setFetching(true)
      await axios
        .post(`/store/${selected}/person-contact`, body)
        .then(res => res.data)
        .then(res => {
          const contact = res.meta
          setContact(contact)
          dispatch({ type: ContactTypes.CONTACT_CREATE, payload: contact })
          setModalOpen(false)
          setFetching(false)
        }).catch((error) => {
          if (error.response.status === 400) {
            window.alert('Contato já criado anteriormente')
          }
          setFetching(false)
        })
    }

    useEffect(() => {
      setContact(undefined)
      setForm(initialState)
    }, [isModalOpen])

    useEffect(() => {
      if (contact && contact.status === 'PENDING') {
        onValidate(contact)
        setTimeout(() => {
          setModalOpen(false)
        }, 1000)
      }
    }, [contact])

    return (
      <>
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <div className="panel no-padding">
            <ModalHeader title="Novo Contato" onClose={() => setModalOpen(false)} />
            <div className="row panel">
              <div className="form-control justify-start col-6">
                <label>Nome</label>
                <input
                  required
                  type="text"
                  value={form.firstName}
                  onChange={(e) => setForm({ ...form, firstName: e.target.value })}
                />
              </div>
              <div className="form-control justify-start col-6">
                <label>Sobrenome</label>
                <input
                  required
                  type="text"
                  value={form.lastName}
                  onChange={(e) => setForm({ ...form, lastName: e.target.value })}
                />
              </div>

              <div className="form-control justify-start">
                <label>Telefone</label>
                <input
                  required
                  type="text"
                  value={form.contact}
                  onChange={(e) => setForm({ ...form, contact: maskPhone(e.target.value) })}
                />
              </div>

              <div className="row justify-end form-control">
                {
                  contact
                    ? (
                      <span className="button secondary">
                        {contact.status === 'PENDING' && <><i className="fa fa-clock" /> Aguardando Validação</>}
                        {
                          contact.status === 'INVALID' &&
                          <><i className="fa fa-times" style={{ color: 'red' }} /> Contato Inválido</>
                        }
                        {
                          contact.status === 'VALIDATED' &&
                          <><i className="fa fa-check" style={{ color: 'green' }} /> Contato Válido</>
                        }
                      </span>
                    )
                    : (
                      <span className="button gradient" onClick={() => isFetching ? null : handleCreate()}>
                        {
                          isFetching
                            ? <><i className="fa fa-spinner fa-spin" /> Criando... Aguarde.</>
                            : <><i className="fa fa-save" /> Criar Contato</>
                        }
                      </span>
                    )
                }
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }

export default NewContact
