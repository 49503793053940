import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { deleteBot } from '../redux/action/bot'
import { ApplicationState } from 'AppReducer'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import InputNumber from 'util/modules/Form/InputNumber'
import { hasPermission } from 'util/services/AuthService'

export const BotBehavior = {
  EVER: 'Executar Sempre',
  ONCE_PER_DAY: 'Executar 1x ao dia',
  ONCE_PER_CONTACT: 'Executar 1x por contato'
}

const BotList: React.FC = () => {
  const dispatch = useDispatch()

  const [deleteId, setDeleteId] = useState<number>()

  const { bots } = useSelector((state: ApplicationState) => state.botReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  async function handleRemove (id: number) {
    if (selected) {
      await deleteBot(selected, id)(dispatch)
      setDeleteId(undefined)
    }
  }

  return (
    <div className="list">
      <div className="list-header">
        <div>Ordem</div>
        <div>Ativo</div>
        <div>Nome</div>
        <div>Comportamento</div>
        <div>Auto</div>
        <div>Tentativas</div>
        <div>Opções</div>
        <div>Ações</div>
      </div>

      {
        bots.length === 0 &&
        <div className="list-row empty">
          <i className="fa fa-robot" style={{ fontSize: 100 }} />
          <div className="grid-span" style={{ height: 0 }}>Nenhum bot cadastrado</div>
        </div>
      }
      {
        bots.map((item, i) => {
          const color = item.isActive ? 'var(--water-green)' : 'var(--red)'
          const borderLeft = item.isActive ? '5px solid var(--water-green)' : '5px solid var(--red)'
          return (
            <div key={i} className="list-row" style={{ borderRadius: 0, borderLeft }}>
              <div data-content="Ordem" className="order">
                <div className="row" style={{ justifyContent: 'center' }}>
                  {item.order} <i className="fa fa-robot" style={{ color }} />
                </div>
              </div>
              <div data-content="Ativo" className='custom-option'>
                <div className="row padding-left-8">
                  <p className="small">Ativo</p>
                  <label className="switch mark">
                    <input
                      readOnly
                      type='checkbox'
                      checked={item.isActive}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </div>
              <div data-content="Nome" className="row justify-start custom-option">
                <div className="row">
                  <p className="small">Nome</p>
                  <p className="bold text-left">{item.name}</p>
                </div>
              </div>
              <div data-content="Comportamento" className="row justify-start custom-option">
                <div className="row">
                  <p className="small">Executar</p>
                  <div className="behavior">
                    <i className="fa fa-robot highlight-text" />
                    <span className="flex">{BotBehavior[item.behavior]}</span>
                  </div>
                </div>
              </div>
              <div data-content="Auto" className="row justify-start custom-option">
                <div className="row">
                  <p className="small">Auto</p>
                  <label className="switch mark">
                    <input
                      readOnly
                      type='checkbox'
                      checked={item.autoInitiation}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </div>
              <div data-content="Tentativas" className='custom-option'>
                <div className="row">
                  <p className="small">Tentativas</p>
                  <InputNumber disabled value={item.attempts} />
                </div>
              </div>
              <div data-content="Opções" className='custom-option'>
                <div className="row">
                  <p className="small">Opções</p>
                  <p style={{ height: 30 }}>
                    {item.options.length}
                  </p>
                </div>
              </div>
              <div data-content="Ações" className="row custom-option">
                <div className="row buttons-list-row">
                  <p className="small">Ações</p>
                  {
                    hasPermission('CHAT_BOT:UPDATE') &&
                    <NavLink to={`/panel/chatbot/${item.id}/edit`} className="button">
                      <i className="fas fa-edit padding-right-4" /> Editar
                    </NavLink>
                  }
                  {
                    hasPermission('CHAT_BOT:CREATE') &&
                    <NavLink to={`/panel/chatbot/${item.id}/config`} className="button button-config">
                      <i className="fas fa-cog padding-right-4" /> Configurações
                    </NavLink>
                  }
                  {
                    hasPermission('CHAT_BOT:DELETE') &&
                    <span className="button" onClick={() => setDeleteId(item.id)}>
                      <i className="fas fa-trash padding-right-4" /> Excluir
                    </span>
                  }
                </div>
              </div>
            </div>
          )
        })
      }

      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </div>
  )
}

export default BotList
