import React, { useRef } from 'react'

import './style/Audio.scss'

type Props = { acceptedMimeTypes: string[], onSelect: (file?: File) => void }
const Attachment: React.FC<Props> = ({ acceptedMimeTypes, onSelect }) => {
  const inpFile = useRef<HTMLInputElement>(null)

  function handleChange (e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target?.files?.[0]
    if (file) {
      if (acceptedMimeTypes.includes(file.type)) {
        onSelect(file)
      } else {
        alert('Formato de arquivo inválido')
      }
    } else {
      onSelect(undefined)
    }
  }

  return (
    <>
      <span
        title="Clique para escolher um arquivo"
        onClick={() => inpFile.current?.click()}
        className="secondary button margin-right-8"
        style={{ borderRadius: '50%', height: 50, width: 50 }}
      >
        <i className="fas fa-paperclip" />
      </span>
      <input type="file" ref={inpFile} style={{ display: 'none' }} onChange={handleChange} />
    </>
  )
}

export default Attachment
