import { AlertActionTypes } from 'util/modules/Alert/redux/types'
import { Attendant } from 'panel/attendant/redux/types'

/**
* Data Types
*/
export interface SectorAttendant {
  sector: Partial<Sector>,
  attendant: Partial<Attendant>,
}
export interface Sector {
  id: number,
  isActive: boolean,
  name: string,
  sectorAttendants: Partial<SectorAttendant>[],
  createdAt: string,
  updatedAt?: string
}

/**
* State Type
*/

export interface SectorState {
  readonly sector?: Sector
  readonly sectors: Sector[]
}

/**
* Action Enum Types
*/

export enum SectorTypes {
  SECTOR_RESET = 'SECTOR/RESET',
  SECTOR_CREATE = 'SECTOR/CREATE',
  SECTOR_UPDATE = 'SECTOR/UPDATE',
  SECTOR_DELETE = 'SECTOR/DELETE',
  SECTOR_GET = 'SECTOR/GET',
  SECTOR_GET_LIST = 'SECTOR/GET/LIST'
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof SectorTypes.SECTOR_RESET
}

interface CreateAction {
  type: typeof SectorTypes.SECTOR_CREATE,
  payload: Sector
}

interface UpdateAction {
  type: typeof SectorTypes.SECTOR_UPDATE,
  payload: Sector
}

interface DeleteAction {
  type: typeof SectorTypes.SECTOR_DELETE,
  payload: number
}

interface GetAction {
  type: typeof SectorTypes.SECTOR_GET,
  payload: Sector
}

interface GetListAction {
  type: typeof SectorTypes.SECTOR_GET_LIST,
  payload: Sector[]
}

export type SectorActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction
)
