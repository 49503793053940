import React from 'react'

import Select from './Select'

import './styles/TagInput.scss'

type TagInputType = {
  width?: number,
  items: { id: number, label: string }[],
  filled: number[],
  required?: boolean,
  targetPath?: string,
  placeholder?: string
  components?: { id: number, JSX: any, props: { [x: string]: any } }[],
  onSelect: (id: number) => any | Promise<any>,
  onDelete: (id: number) => any | Promise<any>
}
const TagInput: React.FC<TagInputType> = ({
  width, items, components, filled, targetPath, required, placeholder, onSelect, onDelete
}) => {
  const selected = items.filter(item => !!filled.find(id => id === item.id))
  const freeItems = items.filter((item) => !filled.find(id => id === item.id))

  return (
    <div className="tag-input" style={required ? { borderLeft: '2px solid var(--dark-gray)' } : {}}>
      {
        selected.map((item, i) => {
          const Component = components?.find(obj => obj.id === item.id)

          return (
            <span key={i} className="tag">
              {Component ? <Component.JSX {...(Component.props || {})} /> : null}
              {
                targetPath
                  ? <a target='_blank' rel="noopener noreferrer" href={`${targetPath}/${item.id}`}>{item.label}</a>
                  : item.label
              }
              <i className="fas fa-times" onClick={(): any => onDelete(item.id)} />
            </span>
          )
        })
      }
      {
        freeItems.length > 0 &&
        <div style={{ width: width || 200 }}>
          <Select
            placeholder={placeholder}
            onSelect={({ key }): void => key ? onSelect(key) : undefined}
            itens={freeItems.map(({ id, label }) => ({ key: id, value: label }))}
          />
        </div>
      }
    </div>
  )
}

export default TagInput
