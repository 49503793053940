import React, { useState } from 'react'

import ContactTab, { Tab } from './ContactTab'

import ContactData from './partials/ContactData'
import ContactConfig from './partials/ContactConfig'
import ContactContact from './partials/ContactContact'
import ContactComplementary from './partials/ContactComplementary'
import { ModalHeader } from 'util/modules/Modal'

const PersonForm: React.FC<{ isOpen: boolean, onClose(): void }> = ({ isOpen, onClose }) => {
  const [unsavedTab, setUnsavedTab] = useState<Tab>()
  const [selectedTab, setSelectedTab] = useState<Tab>('DATA')

  return (
    <div className="panel no-padding">
      <ModalHeader title="Configurações de Contatos" onClose={onClose} />
      <div className="padding-24">
        <ContactTab onSelect={setSelectedTab} active={selectedTab} unsaved={unsavedTab} />

        { isOpen && selectedTab === 'DATA' && <ContactData changeTab={setSelectedTab} onUnsaved={setUnsavedTab} /> }
        { selectedTab === 'COMPLEMENTARY' && <ContactComplementary onUnsaved={setUnsavedTab} /> }
        { selectedTab === 'CONFIG' && <ContactConfig onUnsaved={setUnsavedTab} /> }
        { selectedTab === 'CONTACT' && <ContactContact onUnsaved={setUnsavedTab} /> }
      </div>
    </div>
  )
}

export default PersonForm
