import React from 'react'

import '../styles/User.scss'

import UserForm from './components/UserForm'
import UserList from './components/UserList'
import UserExtraMenu from './components/UserExtraMenu'

const UserManager: React.FC = () => {
  return (
    <div className="row user-manager_">
      <div className="row padding-left-8">
        <div className="row" style={{ width: 400 }}>
          <h1 className="col-12 bold-center">Gestão de Usuários</h1>
          <p className="col-12 no-margin">Crie, edite, busque e remova usuários no sistema</p>
        </div>
        <div className="row grow padding-left-16">
          <UserExtraMenu />
        </div>
      </div>

      <UserForm />
      <UserList />
    </div>
  )
}

export default UserManager
