import Axios from 'axios'
import { Dispatch } from 'redux'
import { handleError, createAlert, clearAlert } from 'util/modules/Alert/redux/actions'
import { ActionResponse, Id } from 'util/types/index'
import { Conversation, ConversationTypes, Message } from './types'

export function joinConversation (storeId: number, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .post(`/store/${storeId}/conversation/${id}/join`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ConversationTypes.CONVERSATION_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao ingressar')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function leaveConversation (storeId: number, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .post(`/store/${storeId}/conversation/${id}/leave`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ConversationTypes.CONVERSATION_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao sair do atendimento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function privateConversation (storeId: number, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .post(`/store/${storeId}/conversation/${id}/private`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ConversationTypes.CONVERSATION_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao privar')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function finishConversation (storeId: number, id: Id, sendFinishMessage = true) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .post(`/store/${storeId}/conversation/${id}/finish`, { sendFinishMessage })
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ConversationTypes.CONVERSATION_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao finalizar')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function finishConversationByChannelOwner (storeId: number, id: Id, sendFinishMessage = true) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .post(`/store/${storeId}/conversation/${id}/finish-by-channel-owner`, { sendFinishMessage })
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ConversationTypes.CONVERSATION_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao finalizar')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function changeConversationTag (storeId: number, id: Id, tagId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .patch(`/store/${storeId}/conversation/${id}/tag`, { tag: tagId })
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ConversationTypes.CONVERSATION_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function removeConversationTag (storeId: number, id: Id, tagId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .delete(`/store/${storeId}/conversation/${id}/tag/${tagId}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ConversationTypes.CONVERSATION_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover a etiqueta')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function createMessage (storeId: number, id: Id, data: FormData) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    setMessageRead(storeId, id)
    return Axios
      .post(`/store/${storeId}/conversation/${id}/message`, data)
      .then(res => res.data)
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao enviar a mensagem')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }

        return res
      })
  }
}

export function createChat (storeId: number, data: Record<string, Id>) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .post(`/store/${storeId}/conversation/new`, data)
      .then(res => res.data)
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao criar a conversa')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteChatMessage (storeId: number, conId: Id, id: Id) {
  return Axios.delete(`/store/${storeId}/conversation/${conId}/message/${id}`)
}

export function retryChatMessage (storeId: number, conId: Id, id: Id) {
  return Axios.post(`/store/${storeId}/conversation/${conId}/message/${id}/retry`)
}

export function setMessageRead (storeId: number, conId: Id) {
  return Axios.post(`/store/${storeId}/conversation/${conId}/read-message`)
}

export function getPastConversationMessage (storeId: number, mainConversationId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<{ messages: Message[] }>> => {
    return Axios
      .get(`/store/${storeId}/conversation/${id}/past-messages`)
      .then(res => res.data)
      .then(res => {
        if (res.data && res.data.messages.length) {
          dispatch({
            type: ConversationTypes.CONVERSATION_PAST_MESSAGE,
            messages: res.data.messages,
            mainConversationId,
            pastConversationId: res.data.conversationId
          })
        }

        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar as mensagens')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getConversation (storeId: number, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .get(`/store/${storeId}/conversation/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ConversationTypes.CONVERSATION_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar o atendimento')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function newConversationMessage (id: Id, message: Message) {
  return (dispatch: Dispatch): void => {
    dispatch({ type: ConversationTypes.CONVERSATION_NEW_MESSAGE, id, payload: message })
  }
}

export function updateConversationMessage (conversationId: Id, message: Partial<Message>) {
  return (dispatch: Dispatch): void => {
    dispatch({ type: ConversationTypes.CONVERSATION_UPDATE_MESSAGE, conversationId, message })
  }
}

export function getConversationList (storeId: number) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation[]>> => {
    return Axios
      .get(`/store/${storeId}/conversation`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ConversationTypes.CONVERSATION_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}

export function getConversationListFiltered (storeId: number, params: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation[]>> => {
    return Axios
      .get(`/store/${storeId}/conversation/list`, { params })
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ConversationTypes.CONVERSATION_GET_LIST_FILTERED, payload: res.data || [] })
        return res
      })
  }
}
