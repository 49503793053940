import { Plan } from 'panel/plan/redux/types'
import { Store } from 'redux'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
 * Data Types
 */

export type Gateways = 'AsaasPix' | 'AsaasTicket'

export enum PaymentStatus {
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
  REFUNDED = 'REFUNDED',
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  PROCESSING = 'PROCESSING'
}

export enum PlanStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  INACTIVE = 'INACTIVE',
  FINISHED = 'FINISHED',
}

export interface Payment {
  id: number,
  invoice: Partial<Invoice>
  status: keyof typeof PaymentStatus,
  message: string,
  createdAt: string,
  updatedAt?: string
}

export interface PaymentAsaasPix extends Payment {
  dueDate: Date,
  qrCode: string,
  base64: string,
  gateway: 'AsaasPix',
  customerId: string
}

export interface PaymentAsaasTicket extends Payment {
  dueDate: Date,
  barcode: string
  gateway: 'AsaasTicket',
  customerId: string
}

export type GatewaysInterface = PaymentAsaasPix | PaymentAsaasTicket
export interface Invoice {
  id: number,
  plan?: Partial<Plan>,
  store: Partial<Store>,
  isPaid: boolean
  isActive: boolean,
  statusPlan: keyof typeof PlanStatus,
  statusPayment: keyof typeof PaymentStatus,
  payments: GatewaysInterface[],
  amount: number
  createdAt: string,
  updatedAt?: string
}

/**
 * State Type
 */

type Status = (keyof typeof PaymentStatus) | (keyof typeof PlanStatus)
export interface InvoiceState {
  readonly invoice?: Invoice
  readonly invoices: Invoice[],
  readonly labels: { [key in Status]: string }
  readonly gateways: { [key in Gateways]: string }
}

/**
 * Action Enum Types
 */

export enum InvoiceTypes {
  INVOICE_RESET = 'INVOICE/RESET',
  INVOICE_CREATE = 'INVOICE/CREATE',
  INVOICE_UPDATE = 'INVOICE/UPDATE',
  INVOICE_DELETE = 'INVOICE/DELETE',
  INVOICE_GET = 'INVOICE/GET',
  INVOICE_GET_LIST = 'INVOICE/GET/LIST'
}

/**
 * Action Types
 */

interface ResetAction {
  type: typeof InvoiceTypes.INVOICE_RESET
}

interface CreateAction {
  type: typeof InvoiceTypes.INVOICE_CREATE
  payload: Invoice
}

interface UpdateAction {
  type: typeof InvoiceTypes.INVOICE_UPDATE
  payload: Invoice
}

interface DeleteAction {
  type: typeof InvoiceTypes.INVOICE_DELETE
  payload: number
}

interface GetAction {
  type: typeof InvoiceTypes.INVOICE_GET,
  payload: Invoice
}

interface GetListAction {
  type: typeof InvoiceTypes.INVOICE_GET_LIST,
  payload: Invoice[]
}

export type InvoiceActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction
)
