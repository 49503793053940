import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { updateChannel, createChannel, getChannel } from '../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'AppReducer'

import { ModalHeader } from 'util/modules/Modal'
import TagInput from 'util/modules/Form/TagInput'
import FormButtons from 'components/FormButtons'

const initialState = { name: '' }

const ChannelForm: React.FC<{ onClose(): void }> = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { id } = useParams<{ id: string }>()
  const [form, setForm] = useState(initialState)
  const [isRefetching, setIsRefetching] = useState(false)
  const [restrictedAttendantIds, setRestrictedAttendantIds] = useState<number[]>([])

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { attendants } = useSelector((state: ApplicationState) => state.attendantReducer)

  const handleClear = useCallback(() => {
    setForm(initialState)
    history.push('/panel/channel')
  }, [history])

  function handleSubmit (e: React.FormEvent): void {
    e.preventDefault()

    if (!selected) return undefined

    setIsRefetching(true)
    if (id) {
      updateChannel(selected, id, { ...form, restrictedAttendantIds })(dispatch).then((res) => {
        if (res.data) {
          setIsRefetching(false)
        }
      })
    } else {
      createChannel(selected, { ...form, restrictedAttendantIds })(dispatch).then(res => {
        if (res?.data) {
          onClose()
          setIsRefetching(false)
        }
      })
    }
  }

  function handleRestrictAttendant (id: number) {
    const isIdOnList = restrictedAttendantIds.find(item => item === id)
    if (!isIdOnList) {
      setRestrictedAttendantIds([...restrictedAttendantIds, id])
    }
  }

  function handleDelAttendantRestriction (id: number) {
    setRestrictedAttendantIds(restrictedAttendantIds.filter(item => item !== id))
  }

  useEffect(() => {
    if (selected) {
      if (id) {
        getChannel(selected, id)(dispatch).then(res => {
          const channel = res?.data
          if (res?.status === 'success' && channel) {
            setForm({ name: channel.name })
            setRestrictedAttendantIds(channel.attendantRestrictions.map(item => item.attendant.id))
          }
        })
      } else {
        handleClear()
      }
    }
  }, [id, selected, handleClear, dispatch])

  return (
    <form className="panel form-config-container" onSubmit={handleSubmit}>
      <ModalHeader title="Configurações de Canais" onClose={onClose} />
      <div className="panel-body">
        <div className="row channel-form-inputs">
          <div className="form-control flex">
            <label>Nome do canal</label>
            <input type="text" value={form.name} onChange={(e): void => setForm({ ...form, name: e.target.value })} />
          </div>
        </div>

        <div className="form-control">
          <label>
            Atendentes com restrição de atendimento&nbsp;
            <a
              title="Saiba mais sobre atendentes com restrição de atendimento"
              href="https://ajuda.underchat.com.br/knowledge-base/limitar-atendente-em-canais"
              rel="noopener noreferrer"
              target="_blank"
            >
              Saiba mais
            </a>
          </label>
          <TagInput
            targetPath="/panel/attendant"
            onSelect={handleRestrictAttendant}
            onDelete={handleDelAttendantRestriction}
            filled={restrictedAttendantIds}
            items={attendants.map(({ id, user }) => ({ id: id, label: user.person?.firstName || '' }))}
          />
        </div>

        <div className="row justify-end buttons margin-top-16">
          <FormButtons isFetching={isRefetching} onClear={handleClear} />
        </div>
      </div>
    </form>
  )
}

export default ChannelForm
