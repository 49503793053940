import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getConversation } from '../redux/actions'
import { ApplicationState } from 'AppReducer'

// Attachment Components
import ChatLoaded from './ChatLoaded'

const Chat: React.FC = () => {
  const dispatch = useDispatch()

  const { id } = useParams<{ id: string }>()

  const [isFetching, setFetching] = useState(true)

  const { logged } = useSelector((state: ApplicationState) => state.userReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { conversation: conversationDB } = useSelector((state: ApplicationState) => state.conversationReducer)

  const conversation = conversationDB[Number(id || '0')]

  useEffect(() => {
    (async () => {
      if (selected && id) {
        setFetching(true)
        await getConversation(selected, id)(dispatch)
        setFetching(false)
      }
    })()
  }, [id, selected, dispatch])

  if (!id) {
    return (
      <div className="row center-center panel conversation-empty" style={{ border: 'none', height: '100%' }}>
        <div className="row">
          <h1 className="text-center"><i className="fa fa-comments fa-3x highlight-text" /></h1>
          <h1 className="text-center">Mantenha-se conectado</h1>
          <h3 className="text-center">As mensagem aparecerão automaticamente</h3>

          <p className="text-center margin-top-16">Selecione um atendimento para começar</p>
        </div>
      </div>
    )
  }

  if (isFetching) {
    return (
      <div className="row center-center panel conversation-empty" style={{ border: 'none', height: '95%' }}>
        <div className="row">
          <h1 className="text-center"><i className="fa fa-spinner fa-spin fa-3x highlight-text" /></h1>
          <h1 className="text-center">Carregando. Aguarde...</h1>
          <h3 className="text-center">Estamos carregando a conversa</h3>

          <p className="text-center margin-top-16">As mensagens enviadas aparecerão aqui</p>
        </div>
      </div>
    )
  }

  if (!conversation) {
    return (
      <div className="row center-center panel conversation-empty" style={{ border: 'none', height: '95%' }}>
        <div className="row">
          <h1 className="text-center"><i className="fa fa-times-circle fa-3x highlight-text" /></h1>
          <h1 className="text-center">Oops!</h1>
          <h3 className="text-center">Conversa não encontrada!</h3>

          <p className="text-center margin-top-16">A conversa pode ter sido privada ou não está mais disponível</p>
          <p className="text-center">Verifique o endereço digitado e tente novamente</p>
        </div>
      </div>
    )
  }

  if (!logged) return null

  return (
    <ChatLoaded conversation={conversation} />
  )
}

export default Chat
