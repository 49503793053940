import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { Conversation } from '../redux/types'
import { hasPermission } from 'util/services/AuthService'
import { ApplicationState } from 'AppReducer'
import { leaveConversation, privateConversation } from '../redux/actions'
import { changeConversationTag, removeConversationTag } from '../redux/actions'

import TagSelector from '../../tag/components/TagSelector'
import ContactInfo from './modals/ContactInfo'
import RedirectChat from 'panel/conversation-redirect/RedirectChat'
import FinishConversation from './modals/FinishConversation'
import ConversationAttendants from './modals/ConversationAttendants'

const ChatHeader: React.FC<{ conversation: Conversation, canInteract: boolean }> = ({ conversation, canInteract }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [dropDown, setDropDown] = useState(false)
  const [dropDownOptions, setDropDownOptions] = useState(false)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  async function handleChangeTag (tagId: number): Promise<void> {
    if (selected) {
      changeConversationTag(selected, conversation.id, tagId)(dispatch)
    }
  }

  async function handleDeleteTag (tagId: number): Promise<void> {
    if (selected) {
      removeConversationTag(selected, conversation.id, tagId)(dispatch)
    }
  }

  function handleLeave (): void {
    if (window.confirm('Tem certeza que deseja sair?')) {
      leaveConversation(conversation.store.id || 0, conversation.id)(dispatch).then(res => {
        if (res?.status === 'success') {
          history.push('/panel/conversation')
        }
      })
    }
  }

  function handlePrivate (): void {
    if (window.confirm('Tem certeza que deseja privar?')) {
      privateConversation(conversation.store.id || 0, conversation.id)(dispatch)
    }
  }

  return (
    <>
      <div className="hidden-mobile" style={{ height: '100%', width: '100%' }}>
        <div className="row header align-start" style={{ pointerEvents: canInteract ? 'all' : 'none' }}>
          {/* Client Info */}
          <ContactInfo conversation={conversation} />

          <div className="row header-center">
            <div className="flex header-info">
              <div className="row">
                <i className="fas fa-server highlight-text fa-2x" />
                <div className="flex padding-left-8" style={{ fontSize: 10 }}>
                  <p><b>Setor: </b>{conversation.sector?.name || 'BOT'}</p>
                  <p><b>Protocolo: </b>{conversation.protocol}</p>
                </div>
              </div>
            </div>

            {
              hasPermission('CONVERSATION:CHANGE_TAG') &&
              <TagSelector
                style={{ width: 158 }}
                onSelect={handleChangeTag}
                onRemove={handleDeleteTag}
                value={conversation.tag?.id}
              />
            }
            <ConversationAttendants conversation={conversation} />
          </div>
          {dropDownOptions && (
            <div className="row header-buttons-wrapper">
              <NavLink
                className="button white"
                title="Criar agendamento"
                target='_blank'
                to={`/panel/schedule?client=${conversation.client?.id}`}
              >
                <i className="fas fa-calendar-alt" />
              </NavLink>
              <RedirectChat conversation={conversation} />
              <span className="button white" onClick={handlePrivate} title="Privar Atendimento">
                <i className="fas fa-lock" />
              </span>
              {
                hasPermission('CONVERSATION:LEAVE') &&
            <span className="button white" onClick={handleLeave} title="Sair do Atendimento">
              <i className="fas fa-sign-out-alt" />
            </span>
              }
              {
                hasPermission('CONVERSATION:FINISH') &&
            <FinishConversation conversation={conversation} />
              }
            </div>
          )}

          <div className="row header-buttons">
            <NavLink
              className="button white"
              title="Criar agendamento"
              target='_blank'
              to={`/panel/schedule?client=${conversation.client?.id}`}
            >
              <i className="fas fa-calendar-alt" />
            </NavLink>
            <RedirectChat conversation={conversation} />
            <span className="button white" onClick={handlePrivate} title="Privar Atendimento">
              <i className="fas fa-lock" />
            </span>
            {
              hasPermission('CONVERSATION:LEAVE') &&
                <span className="button white" onClick={handleLeave} title="Sair do Atendimento">
                  <i className="fas fa-sign-out-alt" />
                </span>
            }
            {
              hasPermission('CONVERSATION:FINISH') &&
                <FinishConversation conversation={conversation} />
            }
          </div>

          <button
            type="button"
            className='ellipse-wrapper'
            style={{ background: 'transparent', color: '#808080' }}
            onClick={() => setDropDownOptions(!dropDownOptions)}
            title='Opções'
          >
            <i className="fa fa-ellipsis-v" />
          </button>
        </div>
      </div>
      <div className="hidden-desk" style={{ height: '100%', width: '100%' }}>
        <div className="row header align-start" style={{ pointerEvents: canInteract ? 'all' : 'none' }}>
          {/* Client Info */}
          <ContactInfo conversation={conversation} />

          <button
            type="button"
            style={{ background: 'transparent', color: '#808080' }}
            onClick={() => setDropDown(!dropDown)}
            title='Opções'
          >
            <i className="fa fa-ellipsis-v" />
          </button>

          {dropDown && (
            <div className='header-container-options'>
              <div className="header-center">
                <div className="flex header-info">
                  <div className="row">
                    <i className="fas fa-server highlight-text fa-2x" />
                    <div className="flex padding-left-8" style={{ fontSize: 10, color: '#404965' }}>
                      <p><b>Setor: </b>{conversation.sector?.name || 'BOT'}</p>
                      <p><b>Protocolo: </b>{conversation.protocol}</p>
                    </div>
                  </div>
                </div>

                <TagSelector
                  onSelect={handleChangeTag}
                  onRemove={handleDeleteTag}
                  value={conversation.tag?.id}
                />
                <ConversationAttendants conversation={conversation} />
              </div>
              <div className="row header-buttons">
                <RedirectChat conversation={conversation} />
                {
                  hasPermission('CONVERSATION:PRIVATE') &&
                  <span className="button white" onClick={handlePrivate} title="Privar Atendimento">
                    <i className="fas fa-lock" />
                  </span>
                }
                <span className="button white" onClick={handleLeave} title="Sair do Atendimento">
                  <i className="fas fa-sign-out-alt" />
                </span>
                <FinishConversation conversation={conversation} />
              </div>
            </div>
          )}
        </div>
      </div>

    </>
  )
}

export default ChatHeader
