import React from 'react'

import Modal, { ModalHeader } from 'util/modules/Modal'

import { useRouteDisclosure } from 'util/hooks'
import { AttachmentForm } from './attachment.form'
import { AttachmentList } from './attachment.list'

export const AttachmentManagement: React.FC = () => {
  const { isOpen, onClose } = useRouteDisclosure('attachment')

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="panel no-padding">
          <ModalHeader title="Gestão de Anexos" onClose={onClose} />
          <div className="padding-24">
            <AttachmentForm />
            <AttachmentList />
          </div>
        </div>
      </Modal>
    </>
  )
}
