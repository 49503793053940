/**
 * Data Types
 */
export interface Alert {
  status?: 'failed'|'success',
  isOpen?: boolean,
  code?: number,
  title?: string,
  message: string
}

/**
 * State Type
 */
export interface AlertState {
  readonly alert: Alert
}

/**
 * Action Types
 */
export enum AlertTypes {
  CREATE_ALERT = 'ALERT/CREATE_ALERT',
  CLOSE_ALERT = 'ALERT/CLOSE_ALERT',
  CLEAR_ALERT = 'ALERT/CLEAR_ALERT'
}

/**
* Action Types
*/
export interface CreateAlertAction {
  type: typeof AlertTypes.CREATE_ALERT,
  payload: Alert
}

export interface ClearAlertAction {
  type: typeof AlertTypes.CLEAR_ALERT|AlertTypes.CLOSE_ALERT
}

export type AlertActionTypes = CreateAlertAction | ClearAlertAction
