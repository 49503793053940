import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ApplicationState } from 'AppReducer'
import { Location as MapPreview } from '../preview/Location'
import { LocationPreview } from '../preview/LocationPreview'
import { createMessage } from 'panel/conversation/redux/actions'

const Location: React.FC = () => {
  const dispatch = useDispatch()
  const inpRef = useRef<HTMLInputElement>(null)

  const [isOpen, setOpen] = useState(false)
  const [opened, setOpened] = useState(0)
  const [isFetching, setFetching] = useState(false)

  const [url, setUrl] = useState('')
  const [message, setMessage] = useState('')
  const [latitude, setLatitude] = useState<number>()
  const [longitude, setLongitude] = useState<number>()

  const { id } = useParams<{ id: string }>()
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleClose () {
    setOpen(false)
    setUrl('')
    setMessage('')
    setLatitude(undefined)
    setLatitude(undefined)
    setFetching(false)
  }

  async function handleSubmit (): Promise<void> {
    if (!selected) return

    setFetching(true)

    const formData = new FormData()
    formData.set('message', message)
    formData.set('latitude', String(latitude))
    formData.set('longitude', String(longitude))

    await createMessage(selected, id, formData)(dispatch)
    handleClose()
    setFetching(false)
  }

  function parseURL (url: string) {
    const isGoogleMap = /^https:\/\/www.google.com\/maps/g.test(url)
    if (!isGoogleMap) return false

    const [, place] = url.match(/place[/]([^/]+)/) || []
    let latitude = 0
    let longitude = 0

    const regex = /!3d(-?\d+\.\d+)!4d(-?\d+\.\d+)/g

    let m
    while ((m = regex.exec(url)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }

      m.forEach((match, groupIndex) => {
        if (groupIndex === 1) latitude = Number(match)
        if (groupIndex === 2) longitude = Number(match)
      })
    }

    if (latitude && longitude) {
      setUrl(url)
      setLatitude(latitude)
      setLongitude(longitude)
      if (place) setMessage(place.replace(/[+]/g, ' '))

      return true
    }

    return false
  }

  useEffect(() => {
    document.addEventListener('paste', (event: ClipboardEvent) => {
      const { clipboardData } = event
      if (clipboardData) {
        const url = clipboardData.getData('text/plain')
        const found = parseURL(url)
        setOpen(found)
      }
    })

    return () => {
      document.removeEventListener('paste', () => null)
    }
  }, [])

  useEffect(() => {
    if (isOpen && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        if (!url) {
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          setLatitude(latitude)
          setLongitude(longitude)
        }
      }, e => console.log, {
        maximumAge: 0, timeout: Infinity, enableHighAccuracy: true
      })
    }
  }, [isOpen])

  return (
    <>
      {
        isOpen &&
        <div className="preview">
          <div className="row" style={{ flexDirection: 'column' }}>
            <i className="fa fa-times btn-close" onClick={handleClose} />

            <div className="row margin-top-16" style={{ gap: 8, width: 400 }}>
              <a
                target='__blank'
                href='https://www.google.com/maps'
                style={{ fontWeight: 'bold', color: '#069', width: '100%' }}
              >
                Abrir Google maps
              </a>
              <input
                type="text"
                value={url}
                style={{ width: 400 - 8 - 48 }}
                placeholder="Colar URL Google Maps"
                readOnly
                onPaste={(e) => parseURL(e.clipboardData.getData('text/plain'))}
              />
              <span
                style={{
                  width: 48,
                  height: 48,
                  display: 'flex',
                  cursor: 'pointer',
                  borderRadius: 5,
                  background: 'gainsboro',
                  placeItems: 'center',
                  placeContent: 'center'
                }}
                onClick={() => navigator.clipboard.readText().then(parseURL)}
              >
                <i className="fas fa-paste"></i>
              </span>
            </div>

            <div className="row margin-top-16" style={{ width: 400, borderTop: '1px solid gainsboro', paddingTop: 16 }}>
              <LocationPreview width={400} height={200} latitude={latitude} longitude={longitude} />
              <input
                type="text"
                value={message}
                style={{ width: 400, marginTop: 8 }}
                onChange={e => setMessage(e.target.value)}
                placeholder="Endereço"
              />
            </div>
            <div className="row margin-top-16" style={{ gap: 8 }}>
              <input type="text" value={latitude} style={{ width: 196 }} readOnly placeholder="Latitude" />
              <input type="text" value={longitude} style={{ width: 196 }} readOnly placeholder="Latitude" />
            </div>

            <div className="row margin-top-32">
              <button className="primary" disabled={isFetching} onClick={handleSubmit}>
                {
                  isFetching
                    ? <><i className="fa fa-spin fa-spinner" /> Enviando. Aguarde...</>
                    : <><i className="fa fa-paper-plane" /> Enviar</>
                }
              </button>
            </div>
          </div>
        </div>
      }

      <span className="secondary button location shadow" onClick={() => setOpen(true)}>
        <i className="fas fa-map" />
      </span>
    </>
  )
}

export default Location
