import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import { deleteAllContacts } from './redux/action/contact'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { getPersonList } from './redux/action/person'

const DeleteAllContacts: React.FC = () => {
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleRemove () {
    if (selected) {
      setOpen(false)
      deleteAllContacts(selected).then(() => {
        getPersonList(selected, { page: 1, limit: 10, fields: 'id,name,createdAt,updatedAt' })(dispatch)
      })
    }
  }

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="button radius remove margin-right-8"
        style={{ borderColor: 'firebrick', color: 'firebrick' }}
      >
        <i className="fa fa-trash" style={{ color: 'firebrick' }} /> Deletar Todos os Contatos
      </div>
      <DeleteConfirm isOpen={isOpen} onCancel={() => setOpen(false)} onConfirm={() => handleRemove()} />
    </>
  )
}

export default DeleteAllContacts
