import React, { useState } from 'react'

import { Contact } from 'panel/person/redux/types/contact'
import { maskPhone } from 'util/masks'
import { useEffect } from 'react'
import { deleteContact, updateContact } from 'panel/person/redux/action/contact'
import { ApplicationState } from 'AppReducer'
import { useSelector, useDispatch } from 'react-redux'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { getPerson } from 'panel/person/redux/action/person'
import { useQueryClient } from '@tanstack/react-query'

const initialState = { type: 'Phone', value: '' }
const ContactItem: React.FC<{ contact: Partial<Contact> }> = ({ contact }) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const [form, setForm] = useState(initialState)
  const [isEditing, setEditing] = useState(false)
  const [deleteId, setDeleteId] = useState<number>()

  const { person } = useSelector((state: ApplicationState) => state.personReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleSubmit (e: React.FormEvent) {
    e.preventDefault()
    if (form.type && form.value && selected && person && contact.id) {
      updateContact(selected, person.id, contact.id, form)(dispatch).then(res => {
        if (res?.status === 'success') {
          setEditing(false)
          queryClient.invalidateQueries(['contact', { number: contact.value }])
        }
      })
    }
  }

  function handleRemove (id: number) {
    if (selected && person && contact.id) {
      deleteContact(selected, person.id, id)(dispatch).then(res => {
        if (res?.status === 'success') {
          setDeleteId(undefined)
          setEditing(false)
          getPerson(selected, person.id)(dispatch)
        }
      })
      queryClient.invalidateQueries(['contact', { number: contact.value }])
    }
  }

  useEffect(() => {
    setForm({
      type: contact.type || 'Phone',
      value: contact.value ? maskPhone(contact.value) : ''
    })
  }, [contact])

  return (
    <>
      <form className="row margin-bottom-16 align-end" onSubmit={handleSubmit} style={{ padding: 8 }}>
        <span className="button secondary">
          { contact.status === 'INVALID' && <i className="fa fa-times" style={{ color: 'red' }} /> }
          { contact.status === 'VALIDATED' && <i className="fa fa-check" style={{ color: 'green' }} /> }
          { contact.status === 'PENDING' && <i className="fa fa-spinner fa-spin" /> }
        </span>
        <div className="form-control flex">
          <select
            required
            value={form.type}
            disabled={!isEditing}
            onChange={(e): void => setForm({ ...form, type: e.target.value })}
          >
            <option value="Phone">Telefone</option>
            <option>WhatsApp</option>
            <option>Telegram</option>
            <option>Comercial</option>
            <option>Pessoal</option>
          </select>
        </div>
        <div className="form-control flex">
          <input
            required
            type="text"
            value={form.value}
            disabled={!isEditing}
            onChange={(e): void => setForm({ ...form, value: maskPhone(e.target.value) })}
          />
        </div>
        <span
          className="button secondary margin-right-4"
          onClick={() => {
            setDeleteId(contact.id)
          }}
        >
          <i className="fa fa-trash" style={{ color: 'var(--secondary-text)' }} />
        </span>
        {
          isEditing
            ? (
              <button className="gradient" style={{ height: 48 }}>
                <i className="fa fa-save" />
              </button>
            )
            : (
              <span className="button highlight" onClick={() => setEditing(true)}>
                <i className="fa fa-edit" style={{ color: 'var(--highlight-text)' }} />
              </span>
            )
        }
      </form>
      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </>
  )
}

export default ContactItem
