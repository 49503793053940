import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import './styles/Alert.scss'

import { clearAlert } from './redux/actions'
import { ApplicationState } from 'AppReducer'

const Alert: React.FC = () => {
  const btnRef = useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch()
  const alert = useSelector((state: ApplicationState) => state.alertReducer.alert)

  function handleAlert (status: boolean): void {
    const alertParent = document.querySelector('.alert')
    const alertContent = alertParent ? alertParent.querySelector('.content') : null

    if (alertParent && alertContent) {
      if (status) {
        alertParent.classList.add('animated', 'fadeIn')
        alertContent.classList.add('animated', 'slideInRight')
        if (btnRef.current) {
          btnRef.current.focus()
        }
      } else if (alertParent.classList.contains('animated')) {
        alertParent.classList.add('fadeOut')
        alertContent.classList.add('slideOutRight')

        setTimeout(() => {
          alertParent.classList.remove('animated', 'fadeIn', 'fadeOut')
          alertContent.classList.remove('animated', 'slideInRight', 'slideOutRight')
        }, 500)
      }
    }
  }

  function closeAlert (): void {
    handleAlert(false)
    setTimeout(() => {
      dispatch(clearAlert())
    }, 500)
  }

  useEffect(() => {
    handleAlert(!!alert.isOpen)
  }, [alert])

  let status: 'ERROR' | 'SUCCESS' | 'PENDING' | 'WARNING' = 'PENDING'

  if (alert.code === 100) status = 'PENDING'
  if (alert.code && alert.code >= 200 && alert.code < 300) status = 'SUCCESS'
  if (alert.code && alert.code >= 300 && alert.code < 500) status = 'WARNING'
  if (alert.code && alert.code >= 500) status = 'ERROR'

  const style = {
    ERROR: { icon: <i className="icon fa fa-times-circle" />, class: 'danger' },
    SUCCESS: { icon: <i className="icon fa fa-check-circle" />, class: 'success' },
    PENDING: { icon: <i className="icon fa fa-spinner fa-spin" />, class: 'default' },
    WARNING: { icon: <i className="icon fa fa-triangle-exclamation" />, class: 'warning' }
  }[status]

  return (
    <div className="alert">
      <div className="content shadow" data-status={style.class}>
        { style.icon }
        <div className="row">
          <h2>{alert.title}</h2>
          <h4>{alert.message}</h4>
        </div>

        <i className="fa fa-times close-btn" ref={btnRef} onClick={closeAlert}></i>
      </div>
    </div>
  )
}

export default Alert
