import { Store, combineReducers, createStore, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'

// User Reducers
import { UserState } from 'panel/user/redux/types'
import { AlertState } from 'util/modules/Alert/redux/types'

import { TagState } from 'panel/tag/redux/types'
import { BotState } from 'panel/chatbot/redux/type/bot'
import { PlanState } from 'panel/plan/redux/types'
import { StoreState } from 'panel/store/redux/types'
import { GroupState } from 'panel/group/redux/types'
import { SectorState } from 'panel/sector/redux/types'
import { ConfigState } from 'panel/config/redux/types'
import { SellerState } from 'panel/seller/redux/types'
import { ClientState } from 'panel/client/redux/types'
import { PersonState } from 'panel/person/redux/types/person'
import { ReportState } from 'panel/report/redux/types'
import { CommandState } from 'panel/command/redux/types'
import { ContactState } from 'panel/person/redux/types/contact'
import { ChannelState } from 'panel/channel/redux/types'
import { ScheduleState } from 'panel/schedule/redux/types'
import { AttendantState } from 'panel/attendant/redux/types'
import { TimeTableState } from 'panel/time-table/redux/types'
import { BotOptionState } from 'panel/chatbot/redux/type/option'
import { ConversationState } from 'panel/conversation/redux/types'
import { MessageDataBaseState } from 'panel/message-database/redux/types'
import { ConversationRedirectState } from 'panel/conversation-redirect/redux/types'

import userReducer from 'panel/user/redux/reducer'
import alertReducer from './util/modules/Alert/redux/reducer'

import tagReducer from 'panel/tag/redux/reducer'
import botReducer from 'panel/chatbot/redux/reducer/bot'
import planReducer from 'panel/plan/redux/reducer'
import groupReducer from 'panel/group/redux/reducer'
import storeReducer from 'panel/store/redux/reducer'
import sectorReducer from 'panel/sector/redux/reducer'
import configReducer from 'panel/config/redux/reducer'
import sellerReducer from 'panel/seller/redux/reducer'
import personReducer from 'panel/person/redux/reducer/person'
import clientReducer from 'panel/client/redux/reducer'
import reportReducer from 'panel/report/redux/reducer'
import commandReducer from 'panel/command/redux/reducer'
import contactReducer from 'panel/person/redux/reducer/contact'
import channelReducer from 'panel/channel/redux/reducer'
import invoiceReducer from 'panel/invoice/redux/reducer'
import scheduleReducer from 'panel/schedule/redux/reducer'
import attendantReducer from 'panel/attendant/redux/reducer'
import timeTableReducer from 'panel/time-table/redux/reducer'
import botOptionReducer from 'panel/chatbot/redux/reducer/option'
import conversationReducer from 'panel/conversation/redux/reducer'
import messageDataBaseReducer from 'panel/message-database/redux/reducer'
import conversationRedirectReducer from 'panel/conversation-redirect/redux/reducer'
import { InvoiceState } from 'panel/invoice/redux/types'

// App Reducers

const reducers = combineReducers({
  alertReducer,
  userReducer,

  tagReducer,
  botReducer,
  planReducer,
  storeReducer,
  groupReducer,
  sectorReducer,
  configReducer,
  sellerReducer,
  clientReducer,
  reportReducer,
  personReducer,
  commandReducer,
  contactReducer,
  channelReducer,
  invoiceReducer,
  scheduleReducer,
  attendantReducer,
  timeTableReducer,
  botOptionReducer,
  conversationReducer,
  messageDataBaseReducer,
  conversationRedirectReducer
})

export interface ApplicationState {
  alertReducer: AlertState,
  userReducer: UserState,

  tagReducer: TagState,
  botReducer: BotState,
  planReducer: PlanState,
  storeReducer: StoreState,
  groupReducer: GroupState,
  sectorReducer: SectorState,
  configReducer: ConfigState,
  sellerReducer: SellerState,
  clientReducer: ClientState,
  reportReducer: ReportState,
  channelReducer: ChannelState,
  invoiceReducer: InvoiceState,
  personReducer: PersonState,
  commandReducer: CommandState,
  contactReducer: ContactState,
  scheduleReducer: ScheduleState,
  attendantReducer: AttendantState,
  timeTableReducer: TimeTableState,
  botOptionReducer: BotOptionState,
  conversationReducer: ConversationState,
  messageDataBaseReducer: MessageDataBaseState,
  conversationRedirectReducer: ConversationRedirectState
}

const store: Store<ApplicationState> = createStore(reducers, {}, applyMiddleware(ReduxThunk))

export default store
