import React, { useState } from 'react'

import Modal, { ModalHeader } from 'util/modules/Modal'
import TagForm from './components/TagForm'
import TagList from './components/TagList'

import './styles/Tag.scss'

const Tag: React.FC = () => {
  const [isFormOpen, setFormOpen] = useState(false)

  return (
    <>
      <Modal isOpen={isFormOpen} onClose={() => setFormOpen(false)}>
        <div className="row panel no-padding tag-container" style={{ width: 670 }}>
          <ModalHeader title="Gestão de Etiquetas" icon="tags" onClose={() => setFormOpen(false)} />
          <div className="row panel-body">
            <TagForm isOpen={isFormOpen} onClose={() => setFormOpen(false)} />
            <TagList />
          </div>
        </div>
      </Modal>
      <div
        className="row item"
        onClick={() => setFormOpen(true)}
        style={{ border: 'none', borderTop: '1px solid gainsboro' }}
      >
        <p className="bold"><i className="fa fa-plus highlight-text" /> Adicionar Nova</p>
      </div>
    </>
  )
}

export default Tag
