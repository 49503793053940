import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Modal, { ModalHeader } from 'util/modules/Modal'
import { ApplicationState } from 'AppReducer'
import { createGroup } from '../redux/actions'

const initialForm = { name: '', description: '' }
const CreateGroupModal: React.FC = () => {
  const dispatch = useDispatch()

  const [form, setForm] = useState(initialForm)
  const [isModalOpen, setModalOpen] = useState(false)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleForm (e: React.FormEvent): void {
    e.preventDefault()

    if (!selected) return undefined

    createGroup(selected, { ...form, contactIds: [] })(dispatch).then(res => {
      if (res?.status === 'success') {
        setForm(initialForm)
        setModalOpen(false)
      }
    })
  }

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <form className="row panel no-padding" style={{ width: 800 }} onSubmit={handleForm}>
          <ModalHeader title="Configurações de Grupo" onClose={() => setModalOpen(false)} />
          <div className="row panel-body">
            <div className="form-control">
              <label>Nome do Grupo</label>
              <input
                value={form.name}
                onChange={(e): void => setForm({ ...form, name: e.target.value })}
                type="text"
              />
            </div>

            <div className="form-control">
              <label>Descrição</label>
              <input
                value={form.description}
                onChange={(e): void => setForm({ ...form, description: e.target.value })}
                type="text"
              />
            </div>

            <div className="row justify-end buttons margin-top-16">
              <span className="secondary button" onClick={() => setForm(initialForm)}>Limpar</span>
              <button className="gradient margin-left-8">Salvar</button>
            </div>
          </div>
        </form>
      </Modal>
      <span className="button secondary" onClick={() => setModalOpen(true)}><i className="fa fa-plus"></i></span>
    </>
  )
}

export default CreateGroupModal
