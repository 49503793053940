import React from 'react'
import { Message } from 'panel/conversation/redux/types'

const Audio: React.FC<{ message: Partial<Message> }> = ({ message }) => {
  if (!message.attachment) return null

  return (
    <>
      <audio controls>
        <source src={message.attachment.url} />
      </audio>
    </>
  )
}

export default Audio
