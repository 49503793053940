import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Id } from 'util/types/index'
import { getBotList } from 'panel/chatbot/redux/action/bot'
import { executeCommand } from './redux/actions'
import { ApplicationState } from 'AppReducer'
import { Command, CommandParameters } from './redux/types'

import BotParameter from 'panel/chatbot/components/BotOptionTileParameter'

const CommandBotExecution: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const dispatch = useDispatch()

  const [isFetching, setFetching] = useState({ delete: false, fetching: false, run: false })
  const [parameters, setParameters] = useState<CommandParameters[]>([])

  const { command } = useSelector((state: ApplicationState) => state.commandReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  const commands: Command[] = command?.command ? JSON.parse(command.command) : []

  function handleParameter (parameter: CommandParameters | undefined, value: Id) {
    if (parameter) {
      setParameters(parameters => {
        const index = parameters.findIndex(item => item.key === parameter.key && item.type === parameter.type)

        if (index === -1) {
          return [...parameters, { ...parameter, value }]
        } else {
          return parameters.map((item, i) => {
            if (i === index) {
              item['value'] = value
            }

            return item
          })
        }
      })
    }
  }

  async function handleRun (e: React.FormEvent) {
    e.preventDefault()
    const msg = 'Tem certeza que deseja Executar? As configurações atuais serão substituídas'
    if (selected && command && window.confirm(msg)) {
      setFetching({ ...isFetching, run: true })
      await executeCommand(selected, command.id, parameters)(dispatch)
      await getBotList(selected)(dispatch)
      onClose()
      setFetching({ ...isFetching, run: false })
    }
  }

  const actionTypes: any = {
    REDIRECT_BOT: 'Redireciona BOT',
    REDIRECT_SECTOR: 'Redireciona Setor',
    FILL_PERSON_DATA: 'Preenche Informação',
    RESPONSE_MESSAGE: 'Responde Mensagem'
  }

  return (
    <div className="row panel no-padding" style={{ width: 760, height: 760, overflow: 'auto' }}>
      <h2></h2>

      <div className="panel-header" style={{ height: 80 }}>
        <div className="row">
          <p className="flex">
            <i className="fa fa-robot" style={{ margin: 0 }} /> Parâmetros da Execução
          </p>
          <span className="button-times pointer" onClick={onClose}><i className="fa fa-times"/></span>
        </div>
      </div>

      {
        !!(command && commands) &&
        <form onSubmit={handleRun} className="row panel-body">
          <p className="bold">Revise todos os campos e clique em executar</p>
          {
            commands.filter(item => item.type === 'CREATE_BOT').map((command, i) => {
              const options = commands
                .filter(item => item.type === 'CREATE_OPTION')
                .filter(item => item.reference === command.key)

              return (
                <div key={i} className="row margin-top-16">
                  <div className="row" style={{ display: 'grid', gridTemplateColumns: '150px 180px 80px 1fr' }}>
                    <div>
                      <p className="bold">Etapa</p>
                      <p>{i + 1}º Execução</p>
                    </div>
                    <div>
                      <p className="bold">Tipo</p>
                      <p>Criação de BOT</p>
                    </div>
                    <div>
                      <p className="bold">Ordem</p>
                      <p>{command.data['order']}</p>
                    </div>
                    <div>
                      <p className="bold">Nome</p>
                      <p>{command.data['name']}</p>
                    </div>
                  </div>
                  {
                    options.length > 0 &&
                    <div className="row empty bordered margin-top-8">
                      {
                        options.map((option, ii) => {
                          const actionType = option.data['actionType']
                          const parameters = option.parameters.find(item => item.type === actionType)

                          const gridTemplateColumns = '80px 80px 200px 1fr'
                          return (
                            <div
                              key={ii}
                              className="row margin-top-8"
                              style={{ display: 'grid', gridTemplateColumns, alignItems: 'start' }}
                            >
                              <div className="form-control">
                                <label>Ordem</label>
                                <input type="text" disabled value={option.data['order']} />
                              </div>
                              <div className="form-control">
                                <label>Código</label>
                                <input type="text" disabled value={option.data['code'] || '...'} />
                              </div>
                              <div className="form-control">
                                <label>Tipo</label>
                                <input
                                  disabled
                                  type="text"
                                  value={actionTypes[actionType] || 'Nenhum'}
                                />
                              </div>

                              {
                                actionType === 'REDIRECT_BOT' || actionType === 'REDIRECT_SECTOR'
                                  ? (
                                    <BotParameter
                                      required
                                      onSelect={(value) => handleParameter(parameters, value)}
                                      actionType={actionType}
                                      parameters={parameters?.value as any}
                                      botCommandList={commands.filter(item => item.type === 'CREATE_BOT')}
                                    />
                                  )
                                  : (
                                    <div className="form-control">
                                      <label>Parametro</label>
                                      <input
                                        disabled
                                        type="text"
                                        value={option.data['parameters'] || ''}
                                      />
                                    </div>
                                  )

                              }
                              <div style={{ gridColumn: '1 / 5' }} className="form-control">
                                <input type="text" disabled value={option.data['message'] || ''} />
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  }

                </div>
              )
            })
          }

          <button disabled={isFetching.run} className="primary col-12">
            {
              isFetching.run
                ? <><i className="fa fa-spinner fa-spin" /> Executando Comando. Aguarde...</>
                : <><i className="fa fa-cogs" /> Executar Comando</>
            }
          </button>
        </form>
      }
    </div>
  )
}

export default CommandBotExecution
