import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { createMessage } from 'panel/conversation/redux/actions'
import { ApplicationState } from 'AppReducer'
import { Contact as ContactDto } from 'panel/person/redux/types/contact'
import Search from 'util/modules/Pagination/components/Search'
import { maskPhone } from 'util/masks'
import { getStoreId } from 'util/hooks'

const Contact: React.FC = () => {
  const dispatch = useDispatch()

  const [isOpen, setOpen] = useState(false)
  const [isFetching, setFetching] = useState(false)
  const [filter, setFilterType] = useState('person.name')

  const [contact, setContact] = useState<ContactDto>()

  const { id } = useParams<{ id: string }>()
  const storeId = getStoreId()

  function handleClose () {
    setOpen(false)
    setContact(undefined)
    setFetching(false)
  }

  async function handleSubmit (): Promise<void> {
    if (!contact) return
    if (!selected) return
    if (!contact.person) return
    if (!contact.person.name) return

    setFetching(true)

    const formData = new FormData()
    formData.set('contact[name]', contact.person.name)
    formData.set('contact[number]', contact.value)

    await createMessage(storeId, id, formData)(dispatch)
    handleClose()
    setFetching(false)
  }

  const selected = contact ? `${contact?.person?.name} - ${maskPhone(contact?.value)}` : ''

  return (
    <>
      {
        isOpen &&
        <div className="preview">
          <div className="row" style={{ flexDirection: 'column', placeContent: 'center' }}>
            <i className="fa fa-times btn-close" onClick={handleClose} />

            <div className="row margin-top-16" style={{ gap: 8, width: 400 }}>
              <div className="row">
                <div className="col-3 form-control">
                  <label>Buscar Por</label>
                  <select value={filter} onChange={(e) => setFilterType(e.target.value)} required>
                    <option value="person.name">Nome</option>
                    <option value="value">Telefone</option>
                  </select>
                </div>
                <div className="col-9 form-control justify-start">
                  <label>Selecione o Cliente</label>
                  <Search<ContactDto>
                    required
                    url={`/store/${storeId}/contact`}
                    value={selected}
                    fields='id,value,person.name'
                    isActive={(item) => contact?.id === item.id}
                    formatter={({ value, person }) => `${person?.name} - ${maskPhone(value)}`}
                    onSelect={setContact}
                    filters={[
                      { type: 'filter', column: 'isValidated', value: 1 },
                      { type: 'filter_like', column: filter as any, match: ['start', 'end'] },
                      { type: 'filter_or', column: 'person.alias' as any, match: ['start', 'end'] }
                    ]}
                  />
                </div>
              </div>
              <button
                style={{ width: '100%' }}
                onClick={handleSubmit}
                className='gradient col-12'
                disabled={isFetching || !contact}
              >
                { isFetching ? 'Enviando...' : 'Enviar' }
              </button>
            </div>

          </div>
        </div>
      }

      <span className="secondary button contact shadow" onClick={() => setOpen(true)}>
        <i className="fas fa-address-card" />
      </span>
    </>
  )
}

export default Contact
