import Axios from 'axios'
import { Dispatch } from 'redux'

import { Plan, PlanTypes } from './types'
import { ActionResponse, Id } from 'util/types/index'
import { handleError, createAlert, clearAlert } from 'util/modules/Alert/redux/actions'

export function createPlan (data: Partial<Plan>) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Plan>> => {
    return Axios
      .post('/plan', data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: PlanTypes.PLAN_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar o plano')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updatePlan (id: Id, data: Partial<Plan>) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Plan>> => {
    return Axios
      .put(`/plan/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: PlanTypes.PLAN_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o plano')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deletePlan (id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Plan>> => {
    return Axios
      .delete(`/plan/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: PlanTypes.PLAN_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o plano')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getPlan (id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Plan>> => {
    return Axios
      .get(`/plan/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: PlanTypes.PLAN_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar por plano')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getFreePlan () {
  return async (dispatch: Dispatch): Promise<ActionResponse<Plan>> => {
    return Axios
      .get('/plan/free')
      .then(res => res.data)
      .then(res => {
        if (res.data) dispatch({ type: PlanTypes.PLAN_GET_FREE, payload: res.data })
        return res
      })
  }
}

export function getPlanList () {
  return async (dispatch: Dispatch): Promise<ActionResponse<Plan>> => {
    return Axios
      .get('/plan')
      .then(res => res.data)
      .then(res => {
        dispatch({ type: PlanTypes.PLAN_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}

export function getAvailablePlans (storeId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Plan>> => {
    return Axios
      .get(`/store/${storeId}/plan/available`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: PlanTypes.PLAN_GET_AVAILABLE, payload: res.data || [] })
        return res
      })
  }
}
