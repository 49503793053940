import { Client } from 'panel/client/redux/types'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
* Data Types
*/
export enum CommandType {
  BOT = 'BOT'
}

export interface Command {
  key: number,
  reference?: number,
  status: 'GENERATED' | 'EXECUTED',
  type: 'CREATE_BOT' | 'CREATE_OPTION',
  parameters: CommandParameters[]
  data: any,
}

export interface CommandParameters {
  key: number,
  type: 'SET_STORE' | 'SET_BOT' | 'REDIRECT_BOT' | 'REDIRECT_SECTOR' | 'RESPONSE_MESSAGE',
  value: string | number
}

export interface CommandInterface {
  id: number
  client: Partial<Client>,
  name: string,
  type: keyof typeof CommandType,
  command: string,
  createdAt: string,
  updatedAt?: string,
  deletedAt?: string
}

/**
* State Type
*/

export interface CommandState {
  readonly command?: CommandInterface
  readonly commands: CommandInterface[]
}

/**
* Action Enum Types
*/

export enum CommandTypes {
  COMMAND_GENERATE = 'COMMAND/GENERATE',
  COMMAND_DELETE = 'COMMAND/DELETE',

  COMMAND_GET = 'COMMAND/GET',
  COMMAND_GET_LIST = 'COMMAND/GET_LIST'
}

/**
* Action Types
*/

interface GenerateAction {
  type: typeof CommandTypes.COMMAND_GENERATE,
  payload: CommandInterface
}

interface DeleteAction {
  type: typeof CommandTypes.COMMAND_DELETE,
  payload: number
}

interface GetAction {
  type: typeof CommandTypes.COMMAND_GET,
  payload: CommandInterface
}

interface GetListAction {
  type: typeof CommandTypes.COMMAND_GET_LIST,
  payload: CommandInterface[]
}

export type CommandActionTypes = (
  AlertActionTypes | GenerateAction | DeleteAction | GetAction | GetListAction
)
