import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { ReactComponent as SellerUser } from 'assets/icons/owner.svg'

import { Store } from '../redux/types'
import { ApplicationState } from 'AppReducer'
import { formatDateTime, formatDate } from 'util/masks'
import { deleteStore, getSellerStoreList } from '../redux/actions'

import { img } from 'assets/online'
import Pagination from 'util/modules/Pagination'
import { getPlanList } from 'panel/plan/redux/actions'

type Filter = { name: string, isActive?: boolean, isBlocked?: boolean, isExpired?: boolean }

const StoreList: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [filter, setFilter] = useState<Filter>({ name: '' })

  const { seller: { items: stores, pagination } } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleRemove (store: Store): void {
    if (store.client.id && window.confirm('Tem certeza que deseja excluir? A ação não poderá ser desfeita')) {
      deleteStore(store.client.id, store.id)(dispatch)
    }
  }

  async function fetchStores (e?: React.FormEvent): Promise<void> {
    e?.preventDefault()

    const query = new URLSearchParams(history.location.search)
    const params: any = {
      page: query.get('page') || 1,
      limit: query.get('limit') || 20
    }

    if (filter.name) params['filter_like[name]'] = filter.name
    if (filter.isActive !== undefined) params['filter[isActive]'] = filter.isActive ? 1 : 0
    if (filter.isExpired !== undefined) params['filter[isExpired]'] = filter.isExpired ? 1 : 0
    if (filter.isBlocked !== undefined) params['filter[isBlocked]'] = filter.isBlocked ? 1 : 0

    getPlanList()(dispatch)
    getSellerStoreList(params)(dispatch)
  }

  useEffect(() => {
    fetchStores()
  }, [history.location.search, dispatch])

  return (
    <form onSubmit={fetchStores}>
      <div className="row panel align-end filter">
        <div className="form-control">
          <label>Nome</label>
          <input
            type="text"
            value={filter.name}
            onChange={(e): void => setFilter({ ...filter, name: e.target.value })}
          />
        </div>
        <div className="form-control">
          <label>Ativo</label>
          <select
            value={filter.isActive !== undefined ? (filter.isActive ? 1 : 0) : undefined}
            onChange={({ target: { value } }): void => {
              if (value !== 'Selecione') {
                setFilter({ ...filter, isActive: parseInt(value) === 1 })
              } else {
                setFilter({ ...filter, isActive: undefined })
              }
            }}
          >
            <option value={undefined}>Selecione</option>
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
        </div>
        <div className="form-control">
          <label>Bloqueado</label>
          <select
            value={filter.isBlocked !== undefined ? (filter.isBlocked ? 1 : 0) : undefined}
            onChange={({ target: { value } }): void => {
              if (value !== 'Selecione') {
                setFilter({ ...filter, isBlocked: parseInt(value) === 1 })
              } else {
                setFilter({ ...filter, isBlocked: undefined })
              }
            }}
          >
            <option value={undefined}>Selecione</option>
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
        </div>
        <div className="form-control">
          <label>Vencido</label>
          <select
            value={filter.isExpired !== undefined ? (filter.isExpired ? 1 : 0) : undefined}
            onChange={({ target: { value } }): void => {
              if (value !== 'Selecione') {
                setFilter({ ...filter, isExpired: parseInt(value) === 1 })
              } else {
                setFilter({ ...filter, isExpired: undefined })
              }
            }}
          >
            <option value={undefined}>Selecione</option>
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
        </div>
        <button className="gradient" style={{ height: 48 }}>
          <i className="fa fa-filter" />Filtrar
        </button>
      </div>

      <div className="list">
        <div className="list-header">
          <div>Id</div>
          <div>Img</div>
          <div>Nome</div>
          <div>Ativo?</div>
          <div>Bloqueado?</div>
          <div>Vencido?</div>
          <div>Vencimento</div>
          <div>Atualizado em</div>
          <div>Ações</div>
        </div>

        {
          stores.length === 0 &&
          <div className="list-row empty">
            <SellerUser width={120} height={120} style={{ padding: 24 }} />
            <div className="grid-span" style={{ height: 0 }}>Nenhum loja cadastrada</div>
          </div>
        }

        {
          stores.map((item, i) => {
            const id = item.client?.id
            const name = item.client?.user?.person?.name || ''

            return (
              <div className="list-row white" key={i}>
                <div data-content="Id">{item.id}</div>
                <div data-content="Img">
                  <img src={item.picture || img['default-img']} className="border-radius" alt="User" width={50} />
                </div>
                <div data-content="Nome">{item.name}</div>
                <div data-content="Ativo?">
                  <span className={`status ${item.isActive ? 'success' : 'danger'}`}>
                    {item.isActive ? 'Sim' : 'Não'}
                  </span>
                </div>
                <div data-content="Bloqueado?">
                  <span className={`status ${!item.isBlocked ? 'success' : 'danger'}`}>
                    {item.isBlocked ? 'Sim' : 'Não'}
                  </span>
                </div>
                <div data-content="Vencido?">
                  <span className={`status ${!item.isExpired ? 'success' : 'danger'}`}>
                    {item.isExpired ? 'Sim' : 'Não'}
                  </span>
                </div>
                <div data-content="Vencimento">{formatDate(item.dueDate)}</div>
                <div data-content="Atualizado em">{formatDateTime(item.updatedAt)}</div>
                <div data-content="Ações" className="row buttons-list-row">
                  <div className="row">
                    <NavLink to={() => {
                      const query = new URLSearchParams(window.location.search)
                      query.set('client', String(id))
                      query.set('name', name)
                      return `/panel/store/${item.id}/edit?${query.toString()}`
                    }} className="button">
                      <i className="fas fa-edit padding-right-4" /> Editar
                    </NavLink>
                    <span className="button" onClick={(): void => handleRemove(item)}>
                      <i className="fas fa-trash padding-right-4" /> Excluir
                    </span>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>

      <Pagination pagination={pagination} />
    </form>
  )
}

export default StoreList
