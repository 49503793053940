import { MessageDataBaseState, MessageDataBaseActionTypes, MessageDataBaseTypes } from './types'
import { Reducer } from 'redux'

const initialState: MessageDataBaseState = {
  messages: []
}

type ReducerOpts = Reducer<MessageDataBaseState, MessageDataBaseActionTypes>
const reducer: ReducerOpts = (state = initialState, action: MessageDataBaseActionTypes): MessageDataBaseState => {
  let index = -1
  switch (action.type) {
    case MessageDataBaseTypes.MESSAGE_RESET:
      return initialState
    case MessageDataBaseTypes.MESSAGE_CREATE:
      return {
        ...state,
        message: action.payload,
        messages: [...state.messages, action.payload]
      }
    case MessageDataBaseTypes.MESSAGE_UPDATE:
      index = state.messages.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        message: action.payload,
        messages: [
          ...state.messages.slice(0, index), action.payload, ...state.messages.slice(index + 1)]
      }
    case MessageDataBaseTypes.MESSAGE_DELETE:
      index = state.messages.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        message: undefined,
        messages: [...state.messages.slice(0, index), ...state.messages.slice(index + 1)]
      }
    case MessageDataBaseTypes.MESSAGE_GET:
      return { ...state, message: action.payload }
    case MessageDataBaseTypes.MESSAGE_GET_LIST:
      return { ...state, messages: action.payload }
    default:
      return state
  }
}

export default reducer
