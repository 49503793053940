import Axios from 'axios'
import { Dispatch } from 'redux'

import { Overview, ReportTypes } from './types'
import { ActionResponse, Id } from 'util/types/index'

export function getOverview (storeId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Overview>> => {
    return Axios
      .get(`/store/${storeId}/report/overview`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ReportTypes.REPORT_OVERVIEW, payload: res.data, storeId })
        }
        return res
      })
  }
}

export function getStatistics (storeId: Id, params?: { start: string, end: string }) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Overview>> => {
    return Axios
      .get(`/store/${storeId}/report/statistics`, { params })
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ReportTypes.REPORT_STATISTICS, payload: res.data, storeId })
        }
        return res
      })
  }
}
