import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { ReactComponent as Icon } from 'assets/icons/price-tag-money.svg'

import { cancelInvoice, getInvoiceList } from '../redux/actions'
import { ApplicationState } from 'AppReducer'
import { formatDateTime, maskMoney } from 'util/masks'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { hasPermission } from 'util/services/AuthService'

const InvoiceList: React.FC = () => {
  const dispatch = useDispatch()

  const [deleteId, setDeleteId] = useState<number>()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { invoices, labels } = useSelector((state: ApplicationState) => state.invoiceReducer)

  const canList = hasPermission('INVOICE:LIST')

  function handleRemove (id: number): void {
    if (selected) {
      cancelInvoice(selected, id)(dispatch).then(res => {
        console.log(res)
        if (res.status === 'success') {
          console.log('here')
          setDeleteId(undefined)
        }
      })
    }
  }

  useEffect(() => {
    if (selected && canList) {
      getInvoiceList(selected)(dispatch)
    }
  }, [selected, canList, dispatch])

  if (!canList) return null

  return (
    <div className="list" style={{ marginTop: 0 }}>
      <div className="list-header">
        <div>#</div>
        <div>Pagamento</div>
        <div>Plano</div>
        <div>Valor</div>
        <div>Plano</div>
        <div>Criado em</div>
        <div>Atualizado em</div>
        <div>Ações</div>
      </div>

      {
        invoices.length === 0 && (
          <div className="list-row empty">
            <Icon width={120} height={120} style={{ padding: 24 }} />
            <div className="grid-span" style={{ height: 0 }}>Nenhuma fatura encontrada</div>
          </div>
        )
      }

      {
        invoices.map((item, i) => (
          <div className="list-row" key={i}>
            <div data-content="Id" className="bold">{item.id}</div>
            <div data-content="Pagamento" className="status" data-status={item.statusPayment}>
              {labels[item.statusPayment]}
            </div>
            <div data-content="Plano" className="status" data-status={item.statusPlan}>
              {labels[item.statusPlan]}
            </div>
            <div data-content="Valor">{maskMoney(item.amount)}</div>
            <div data-content="Plano">{item.plan?.name || '...'}</div>
            <div data-content="Criado em">{formatDateTime(item.createdAt)}</div>
            <div data-content="Atualizado em">{formatDateTime(item.updatedAt)}</div>
            <div data-content="Ações" className="action">
              {
                !['REFUNDED', 'CONFIRMED'].includes(item.statusPayment)
                  ? (
                    <>
                      {
                        hasPermission('INVOICE:CHECKOUT') &&
                        <NavLink
                          className="button small gradient"
                          to={`/panel/invoice/${item.id}/open`}
                        >
                          <i className="fas fa-money-bill" /> &nbsp;Pagar
                        </NavLink>
                      }

                      {
                        item.statusPlan === 'INACTIVE' && hasPermission('INVOICE:CANCEL') &&
                        <span className="button small secondary" onClick={() => setDeleteId(item.id)}>
                          <i className="fas fa-trash-alt" /> &nbsp;Remover
                        </span>
                      }
                    </>
                  )
                  : (
                    <NavLink className="button small secondary" to={`/panel/invoice/${item.id}/open`}>
                      <i className="fas fa-eye" /> &nbsp;Abrir
                    </NavLink>
                  )
              }
            </div>
          </div>
        ))
      }

      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </div>
  )
}

export default InvoiceList
