import { deleteProfilePicture } from 'panel/user/redux/actions'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

const RemovePicture: React.FC<{ type: 'user'|'person', id?: number }> = ({ type, id }) => {
  const [isFetching, setFetching] = useState(false)

  const dispatch = useDispatch()

  async function handleRemovePicture (): Promise<void> {
    setFetching(true)
    if (type === 'user') {
      await deleteProfilePicture(id)(dispatch)
    }
    setFetching(false)
  }

  return (
    <span onClick={handleRemovePicture} className="margin-top-8" style={{ color: 'gray', cursor: 'pointer' }}>
      {
        isFetching
          ? <><i className="fa fa-spinner fa-spin"/>Removendo...</>
          : <><i className="fa fa-trash"/>Remover Imagem</>
      }

    </span>
  )
}

export default RemovePicture
