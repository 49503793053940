import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { img } from 'assets/online'
import { Message } from '../redux/types'
import { formatDateTime } from 'util/masks'
import { ApplicationState } from 'AppReducer'
import { retryChatMessage } from '../redux/actions'

import File from './attachment/preview/File'
import Audio from './attachment/preview/Audio'
import Video from './attachment/preview/Video'
import Picture from './attachment/preview/Picture'
import { hasPermission } from 'util/services/AuthService'
import { Location } from './attachment/preview/Location'
import { Contact } from './attachment/preview/Contact'

type Props = {
  message: Partial<Message>,
  handleReply?: (message: Partial<Message>) => void,
  handleDelete?: (message: Partial<Message>) => void,
}

const MessageTile: React.FC<Props> = ({ message, handleReply, handleDelete }) => {
  const [isFetching, setFetching] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  async function handleRetry (): Promise<void> {
    setFetching(true)
    if (selected && id && message.id) {
      await retryChatMessage(selected, id, message.id)
    }
    setFetching(false)
  }

  return (
    <div
      id={String(message.id)}
      title={message.status === 'DELETED' ? 'Mensagem removida' : ''}
      onDoubleClick={() => message.reference && handleReply ? handleReply(message) : null}
      className={`row message-box ${message.sender?.type === 'CLIENT' ? 'left' : 'right'}`}
      style={{ opacity: message.status === 'DELETED' ? 0.5 : 1 }}
    >
      <div className="message-tile">
        <img className="user-pic" alt="User" src={message.sender?.picture || img['default-user']} />
        <div className="flex">
          <div className="row item">
            {
              !!message.reference &&
              <span className="button btn-reply" onClick={() => handleReply ? handleReply(message) : null}>
                <i className="fa fa-reply" />
              </span>
            }

            <div className="row message-content">
              {
                !!message.referenced &&
                <a className="row reply" href={`#${message.referenced.id}`}>
                  <strong className="row justify-start">{message.referenced.name}</strong>
                  <p className="row justify-start">
                    {message.referenced.message?.replace(`*${message.referenced.name}:*`, '')}
                  </p>
                </a>
              }
              {
                message.attachment
                  ? (
                    <div className="row margin-top-8" style={{ flexFlow: 'column', alignItems: 'flex-start' }}>
                      {message.attachment.type === 'FILE' && <File message={message} />}
                      {message.attachment.type === 'AUDIO' && <Audio message={message} />}
                      {message.attachment.type === 'VIDEO' && <Video message={message} />}
                      {message.attachment.type === 'PICTURE' && <Picture message={message} />}
                      {message.attachment.type === 'LOCATION' && <Location message={message} />}
                      {message.attachment.type === 'CONTACT' && <Contact message={message} />}
                      {
                        !['LOCATION', 'CONTACT'].includes(message.attachment.type) &&
                        <p className="row justify-start"> {message.message} </p>
                      }
                    </div>
                  )
                  : (
                    <>
                      {
                        message.previousMessage && (
                          <p className="row justify-start padding-lr-8" style={{ textDecoration: 'line-through' }}>
                            {message.previousMessage}
                          </p>
                        )
                      }
                      <p
                        style={{
                          display: 'block',
                          textDecoration: message.status === 'DELETED' ? 'line-through' : 'none'
                        }}
                        className="row justify-start padding-lr-8"
                        dangerouslySetInnerHTML={{
                          __html: (message.message || '')
                            .replace(`*${message.sender?.name?.trim()}:*\n\n`, '')
                            .replace(/[*]([^*]+)[*]/g, '<b>$1</b>')
                            .replace(/\n/g, '<br />')
                            .replace(/\r/g, '<br />')
                        }}
                      />
                    </>
                  )
              }
              <div className="row message-footer">
                <span><i className="far fa-clock" />{formatDateTime(message.createdAt)}</span>
                <div title={message.errorMessage}>
                  {
                    isFetching
                      ? <i className="fa fa-spin fa-spinner" />
                      : (
                        (
                          (message.status === 'ERROR' && hasPermission('CONVERSATION:RETRY_MESSAGE')) &&
                          <i className="fa fa-times" onClick={handleRetry} />
                        ) ||
                        (
                          message.status === 'DELETED' &&
                          <i className="fa fa-trash-alt" style={{ color: 'firebrick' }} />
                        ) ||
                        (message.status === 'PENDING' && <i className="fa fa-clock" />) ||
                        (message.status === 'SENT' && <i className="fa fa-check" />) ||
                        (message.status === 'RECEIVED' && <i className="fa fa-check-double" />) ||
                        (
                          (message.status === 'SEEN' || message.status === 'PLAYED') &&
                          <i className="fa fa-check-double" style={{ color: '#0369d1' }} />
                        ) ||
                        null
                      )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <p className="sender-name">{message.sender?.name}</p>
            {
              handleDelete && hasPermission('CONVERSATION:DELETE_MESSAGE') && message.status !== 'DELETED' &&
              <span className='remove-button' onClick={() => handleDelete ? handleDelete(message) : null}>
                <i className="fa fa-trash" />
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageTile
