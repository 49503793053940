import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { useSelector } from 'react-redux'

import Modal, { ModalHeader } from 'util/modules/Modal'
import { useFormZod } from 'hooks/useFormZod'
import { ApplicationState } from 'AppReducer'
import { getChatBotConfig, saveChatBotConfig } from '../service/chat-bot.config.service'
import { ChatBotConfigSchema, ChatBotConfigSchemaType } from '../schema/chat-bot.schema'

export const ChatBotConfig: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { control, reset, handleSubmit, formState: { isValid } } = useFormZod(ChatBotConfigSchema)

  const { sectors } = useSelector((store: ApplicationState) => store.sectorReducer)

  // Inputs
  const redirectAuto = useController({ control, name: 'configSector.redirectAuto' })
  const redirectSectorId = useController({ control, name: 'configSector.redirectSectorId' })
  const redirectAutoInMinutes = useController({ control, name: 'configSector.redirectAutoInMinutes' })

  const enabled = useController({ control, name: 'enabled' })
  const redirectWrongMenu = useController({ control, name: 'configSector.redirectWrongMenu' })
  const redirectWrongMenuAttempts = useController({ control, name: 'configSector.redirectWrongMenuAttempts' })

  const redirectSectorMessage = useController({ control, name: 'redirectSectorMessage' })
  const redirectSectorMessageEnabled = useController({ control, name: 'redirectSectorMessageEnabled' })

  const redirectAttendantMessage = useController({ control, name: 'redirectAttendantMessage' })
  const redirectAttendantMessageEnabled = useController({ control, name: 'redirectAttendantMessageEnabled' })

  const { data } = getChatBotConfig()
  const { mutate } = saveChatBotConfig()

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const onSubmit = (form: ChatBotConfigSchemaType) => {
    mutate(form)
  }

  useEffect(() => {
    reset(data)
  }, [data])

  return (
    <>
      <div className="node-selector" style={{ cursor: 'pointer' }} onClick={toggleModal}>
        <i className="fa fa-cogs" /> Configurações
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(true)}>
        <div className="panel no-padding config-container">
          <ModalHeader title="Configurações do ChatBot" icon="cog" onClose={() => setIsModalOpen(false)} />
          <div className="row panel-body">
            <div className="form-control">
              <div className="row justify-start form-control">
                <label className="switch">
                  <input
                    type='checkbox'
                    checked={enabled.field.value}
                    onChange={(e) => enabled.field.onChange(e.target.checked)}
                  />
                  <span className="slider round" />
                </label>
                <div className="label padding-left-8">
                  ChatBot Habilitado?
                </div>
              </div>
              <div className="row justify-start form-control">
                <label className="switch">
                  <input
                    type='checkbox'
                    checked={redirectAuto.field.value}
                    onChange={(e) => redirectAuto.field.onChange(e.target.checked)}
                  />
                  <span className="slider round" />
                </label>
                <div className="label padding-left-8">
                  Redirecionar automaticamente?
                </div>
              </div>
              <select
                value={redirectSectorId.field.value ?? undefined}
                onChange={(e): void => redirectSectorId.field.onChange(e.target.value)}
                className='margin-top-8'
                disabled={!redirectAuto.field.value}
              >
                <option></option>
                {sectors.map((sector, i) => <option key={sector.id} value={sector.id}>{sector.name}</option>)}
              </select>

              <div className="row">
                <label>
                  Redirecionar atendimento após
                  &nbsp;<b>{ redirectAutoInMinutes.field.value } minutos</b>&nbsp;
                  sem interação
                </label>
                <input
                  min={1}
                  step={1}
                  max={60}
                  type="range"
                  value={redirectAutoInMinutes.field.value}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value)
                    redirectAutoInMinutes.field.onChange(newValue)
                  }}
                />
              </div>

              <div className="row justify-start form-control">
                <label className="switch">
                  <input
                    type='checkbox'
                    checked={redirectWrongMenu.field.value}
                    disabled={(
                      !redirectAuto.field.value ||
                      !redirectSectorId.field.value
                    )}
                    onChange={(e) => redirectWrongMenu.field.onChange(e.target.checked)}
                  />
                  <span className="slider round" />
                </label>
                <div className="label padding-left-8">
                  Redirecionar após tentativas erradas?
                </div>
              </div>
              <div className="row">
                <label>
                  Redirecionar atendimento após
                  &nbsp;<b>{ redirectWrongMenuAttempts.field.value || 5 } tentativas</b>&nbsp;
                  erradas
                </label>
                <input
                  min={1}
                  step={1}
                  max={10}
                  type="range"
                  disabled={(
                    !redirectAuto.field.value ||
                    !redirectSectorId.field.value ||
                    !redirectWrongMenu.field.value
                  )}
                  value={redirectWrongMenuAttempts.field.value}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value)
                    redirectWrongMenuAttempts.field.onChange(newValue)
                  }}
                />
              </div>
            </div>
            <div className="row form-control" style={{ gap: 8 }}>
              <div className="row justify-start form-control">
                <label className="switch">
                  <input
                    type='checkbox'
                    checked={redirectSectorMessageEnabled.field.value}
                    onChange={(e) => redirectSectorMessageEnabled.field.onChange(e.target.checked)}
                  />
                  <span className="slider round" />
                </label>
                <div className="label padding-left-8">
                Mensagem de redirecionamento setor
                </div>
              </div>
              <textarea
                rows={4}
                disabled={!redirectSectorMessageEnabled.field.value}
                value={redirectSectorMessage.field.value ?? undefined}
                onChange={(e): void => redirectSectorMessage.field.onChange(e.target.value)}
              />
            </div>
            <div className="row form-control" style={{ gap: 8 }}>
              <div className="row justify-start form-control">
                <label className="switch">
                  <input
                    type='checkbox'
                    checked={redirectAttendantMessageEnabled.field.value}
                    onChange={(e) => redirectAttendantMessageEnabled.field.onChange(e.target.checked)}
                  />
                  <span className="slider round" />
                </label>
                <div className="label padding-left-8">
                  Mensagem de redirecionamento atendente
                </div>
              </div>
              <textarea
                rows={4}
                disabled={!redirectAttendantMessageEnabled.field.value}
                value={redirectAttendantMessage.field.value || ''}
                onChange={(e): void => redirectAttendantMessage.field.onChange(e.target.value)}
              />
            </div>

            <div className="row margin-top-8 justify-end">
              <button
                disabled={!isValid}
                onClick={handleSubmit(onSubmit, console.log)}
                className="button primary"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
