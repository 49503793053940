import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { saveConfig } from 'panel/config/redux/actions'
import { ApplicationState } from 'AppReducer'

import { Config as ConfigType } from 'panel/config/redux/types'
import { useDebounce } from 'util/hooks'

const initialState = {
  showAttendantName: 'firstName' as ConfigType['showAttendantName'],
  isAttendantNameEnabled: false,

  finishStuckConversationMinutes: 5,
  shouldFinishStuckConversation: false,
  shouldSendFinishConversationMessage: false,

  useBotButtons: false,
  autoSendMessageDatabase: true,

  redirectWhenHasNoAttendant: false,
  redirectWhenHasNoAttendantSector: 0,

  sortConversation: 'createdAt' as ConfigType['sortConversation'],
  canLoadPreviousMessage: false,
  canNotifyOnChatBot: false
}
const ConfigGeneral: React.FC = () => {
  const dispatch = useDispatch()
  const debounce = useDebounce()

  const [form, setForm] = useState(initialState)
  const [finishStuckConversationMinutes, setFinishStuckConversationMinutes] = useState(5)

  const { config } = useSelector((state: ApplicationState) => state.configReducer)
  const { sectors } = useSelector((state: ApplicationState) => state.sectorReducer)

  function getMinutesDescription (_minutes: number): string {
    if (_minutes < 60) {
      return `${_minutes} Minutos`
    }

    if (_minutes >= 60 && _minutes < 60 * 24) {
      const timeInHours = Math.floor(_minutes / 60)
      const timeInMinutes = Math.floor(_minutes - (timeInHours * 60))
      return `${timeInHours} Horas e ${timeInMinutes} Minutos`
    }

    const timeInDays = Math.floor(_minutes / 60 / 24)
    const timeInHours = Math.floor((_minutes - (timeInDays * 24 * 60)) / 60)
    const timeInMinutes = Math.floor((_minutes - (timeInDays * 24 * 60)) - (timeInHours * 60))

    return `${timeInDays} ${timeInDays > 1 ? 'Dias' : 'Dia'}, ${timeInHours} Horas e ${timeInMinutes} Minutos`
  }

  useEffect(() => {
    if (config) {
      setForm({
        showAttendantName: config.showAttendantName || 'firstName',
        isAttendantNameEnabled: config.isAttendantNameEnabled,

        shouldFinishStuckConversation: config.shouldFinishStuckConversation || false,
        finishStuckConversationMinutes: config.finishStuckConversationMinutes || 5,
        shouldSendFinishConversationMessage: config.shouldSendFinishConversationMessage ?? true,

        useBotButtons: !!config.useBotButtons,
        autoSendMessageDatabase: !!config.autoSendMessageDatabase,

        redirectWhenHasNoAttendant: !!config.redirectWhenHasNoAttendant,
        redirectWhenHasNoAttendantSector: config.redirectWhenHasNoAttendantSector || 0,

        sortConversation: config.sortConversation || 'createdAt',
        canLoadPreviousMessage: config.canLoadPreviousMessage || false,
        canNotifyOnChatBot: config.canNotifyOnChatBot || false
      })

      setFinishStuckConversationMinutes(config.finishStuckConversationMinutes || 5)
    } else {
      setForm(initialState)
    }
  }, [config])

  return (
    <div className="row">
      <div className="form-control">
        <label className="bold">Assinatura do Atendente</label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={form.showAttendantName === 'name'}
            onClick={() => {
              saveConfig({ showAttendantName: 'name' })(dispatch)
            }}
            readOnly
          />
          Nome completo
        </label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={form.showAttendantName === 'firstName'}
            onClick={() => {
              saveConfig({ showAttendantName: 'firstName' })(dispatch)
            }}
            readOnly
          />
          Primeiro nome
        </label>

        <label className="switch" style={{ position: 'absolute', right: 8, top: 8 }}>
          <input
            type="checkbox"
            checked={form.isAttendantNameEnabled}
            onClick={(): void => {
              saveConfig({ isAttendantNameEnabled: !form.isAttendantNameEnabled })(dispatch)
            }}
          />
          <span className="slider round" />
        </label>
      </div>

      <div className="form-control">
        <label className="bold">Enviar automaticamente atalho de mensagem</label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={form.autoSendMessageDatabase}
            onClick={() => {
              saveConfig({ autoSendMessageDatabase: true })(dispatch)
            }}
            readOnly
          />
          Sim
        </label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={!form.autoSendMessageDatabase}
            onClick={() => {
              saveConfig({ autoSendMessageDatabase: false })(dispatch)
            }}
            readOnly
          />
          Não
        </label>
      </div>
      {/* <div className="form-control">
        <label className="bold">Usar botões no BOT</label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={form.useBotButtons}
            onClick={() => {
              saveConfig({ useBotButtons: true })(dispatch)
            }}
            readOnly
          />
          Sim
        </label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={!form.useBotButtons}
            onClick={() => {
              saveConfig({ useBotButtons: false })(dispatch)
            }}
            readOnly
          />
          Não
        </label>
      </div> */}
      <div className="form-control">
        <label className="bold">Ordenar atendimento por:</label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={form.sortConversation === 'createdAt'}
            onClick={() => {
              saveConfig({ sortConversation: 'createdAt' })(dispatch)
            }}
            readOnly
          />
          Ordem de Chegada
        </label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={form.sortConversation === 'updatedAt'}
            onClick={() => {
              saveConfig({ sortConversation: 'updatedAt' })(dispatch)
            }}
            readOnly
          />
          Por ultima mensagem
        </label>
      </div>
      <div className="form-control">
        <label className="bold">Notificar mensagem do ChatBot?</label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={form.canNotifyOnChatBot}
            onClick={() => {
              saveConfig({ canNotifyOnChatBot: true })(dispatch)
            }}
            readOnly
          />
          Sim
        </label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={!form.canNotifyOnChatBot}
            onClick={() => {
              saveConfig({ canNotifyOnChatBot: false })(dispatch)
            }}
            readOnly
          />
          Não
        </label>
      </div>
      <div className="form-control">
        <label className="bold">Enviar mensagem de finalização por padrão?</label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={form.shouldSendFinishConversationMessage}
            onClick={() => {
              saveConfig({ shouldSendFinishConversationMessage: true })(dispatch)
            }}
            readOnly
          />
          Sim
        </label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={!form.shouldSendFinishConversationMessage}
            onClick={() => {
              saveConfig({ shouldSendFinishConversationMessage: false })(dispatch)
            }}
            readOnly
          />
          Não
        </label>
      </div>

      <div className="form-control justify-start">
        <label className="bold">Carregar mensagens anteriores?</label>
        <small>Ao ativar esta opção, o atendente poderá ver todas conversas anteriores do contato</small>
        <label className="col-6">
          <input
            type="checkbox"
            checked={form.canLoadPreviousMessage}
            onClick={() => {
              saveConfig({ canLoadPreviousMessage: true })(dispatch)
            }}
            readOnly
          />
          Sim
        </label>
        <label className="col-6">
          <input
            type="checkbox"
            checked={!form.canLoadPreviousMessage}
            onClick={() => {
              saveConfig({ canLoadPreviousMessage: false })(dispatch)
            }}
            readOnly
          />
          Não
        </label>
      </div>

      <div className="form-control">
        <label className="bold">Redirecionar quando não houver atendente</label>

        <div className="row justify-start">
          {
            sectors.map((item, i) => (
              <label className="checkbox margin-right-16" style={{ width: 'auto' }} key={i}>
                <input
                  type="checkbox"
                  disabled={!form.redirectWhenHasNoAttendant}
                  checked={form.redirectWhenHasNoAttendantSector === item.id}
                  onClick={() => {
                    saveConfig({ redirectWhenHasNoAttendantSector: item.id })(dispatch)
                  }}
                />
                <div className="checkmark" /> {item.name}
              </label>
            ))
          }
        </div>

        <label className="switch" style={{ position: 'absolute', right: 8, top: 8 }}>
          <input
            type="checkbox"
            checked={form.redirectWhenHasNoAttendant}
            onClick={(): void => {
              saveConfig({ redirectWhenHasNoAttendant: !form.redirectWhenHasNoAttendant })(dispatch)
            }}
          />
          <span className="slider round" />
        </label>
      </div>

      <div className="row margin-top-16">
        <label className="bold">Atendimento</label>

        <div className="row">
          <label>
            Encerrar atendimento após
            &nbsp;<b>{ getMinutesDescription(finishStuckConversationMinutes) }</b>&nbsp;
            sem interação
          </label>
          <input
            min={5}
            step={1}
            max={60 * 24 * 3} // 3 days
            type="range"
            value={finishStuckConversationMinutes}
            onChange={(e) => {
              const newValue = parseInt(e.target.value)
              setFinishStuckConversationMinutes(newValue)

              debounce(() => {
                saveConfig({ finishStuckConversationMinutes: newValue })(dispatch)
              }, 1000)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ConfigGeneral
