import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import Tag from 'panel/tag/Tag'

type Props = {
  value?: number,
  style?: React.CSSProperties,
  onSelect: (id: number) => Promise<void> | void,
  onRemove?: (id: number) => Promise<void> | void,
}
const TagSelector: React.FC<Props> = ({ value, style, onSelect, onRemove }) => {
  const [isOpen, setOpen] = useState(false)
  const { tags } = useSelector((state: ApplicationState) => state.tagReducer)

  const selected = tags.find(item => item.id === value)
  const color = selected ? selected.color : 'gray'
  const name = selected ? selected.name : 'Etiqueta'

  return (
    <span className="tag-selector" style={style}>
      <div
        title={name}
        style={{ borderColor: color }}
        onClick={() => setOpen(!isOpen)}
        className="button white row tag-selected"
      >
        <i className="fas fa-tag" style={{ color }} />
        <p className="text-overflow">{name}</p>
        <i className={`fas ${isOpen ? 'fa-angle-up' : 'fa-angle-down'}`} />
      </div>

      {
        isOpen &&
        <div className="row tag-list">
          <div className="items shadow">
            {
              tags.map((tag, i) => (
                <div
                  key={i}
                  className="row item"
                  style={{ borderColor: tag.color }}
                  onClick={() => {
                    onSelect(tag.id)
                    setOpen(false)
                  }}
                  onMouseOver={(e: any) => {
                    if (e.target) {
                      e.target.style.color = 'white'
                      e.target.style.backgroundColor = tag.color
                    }
                  }}
                  onMouseLeave={(e: any) => {
                    if (e.target) {
                      e.target.style.color = 'inherit'
                      e.target.style.backgroundColor = 'white'
                    }
                  }}
                >
                  {tag.name}
                </div>
              ))
            }

            <Tag />
            {
              !!value && onRemove ? (
                <div
                  className="row item"
                  onClick={() => onRemove(value)}
                  style={{ border: 'none', borderTop: '1px solid gainsboro' }}
                >
                  <p className="bold"><i className="fa fa-trash highlight-text" /> Remover Atual</p>
                </div>
              ) : null
            }
          </div>
        </div>
      }
    </span>
  )
}

export default TagSelector
