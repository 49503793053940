import Axios from 'axios'
import { Dispatch } from 'redux'
import { Conversation } from 'panel/conversation/redux/types'
import { ActionResponse, Id } from 'util/types/index'
import { handleError, createAlert, clearAlert } from 'util/modules/Alert/redux/actions'
import { ConversationRedirect, ConversationRedirectTypes } from './types'

export function redirectConversation (storeId: number, id: Id, data: Record<string, Id | boolean>) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .post(`/store/${storeId}/conversation/${id}/redirect`, data)
      .then(res => res.data)
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao redirecionar a conversa')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function acceptRedirectRequest (storeId: number, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .put(`/store/${storeId}/conversation/redirect/${id}`)
      .then(res => res.data)
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o redirecionamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function rejectRedirectRequest (storeId: number, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Conversation>> => {
    return Axios
      .delete(`/store/${storeId}/conversation/redirect/${id}`)
      .then(res => res.data)
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o redirecionamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getRedirectList (storeId: number) {
  return async (dispatch: Dispatch): Promise<ActionResponse<ConversationRedirect[]>> => {
    return Axios
      .get(`/store/${storeId}/conversation/redirect`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ConversationRedirectTypes.CONVERSATION_REDIRECT_LIST, payload: res.data || [] })
        return res
      })
  }
}
