import { GroupState, GroupActionTypes, GroupTypes } from './types'
import { Reducer } from 'redux'

const initialState: GroupState = {
  groups: []
}

type ReducerOpts = Reducer<GroupState, GroupActionTypes>
const reducer: ReducerOpts = (state = initialState, action: GroupActionTypes): GroupState => {
  let index = -1
  switch (action.type) {
    case GroupTypes.GROUP_RESET:
      return initialState
    case GroupTypes.GROUP_CREATE:
      return { ...state, group: action.payload, groups: [...state.groups, action.payload] }
    case GroupTypes.GROUP_UPDATE:
      index = state.groups.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        group: action.payload,
        groups: [...state.groups.slice(0, index), action.payload, ...state.groups.slice(index + 1)]
      }
    case GroupTypes.GROUP_DELETE:
      index = state.groups.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        group: undefined,
        groups: [...state.groups.slice(0, index), ...state.groups.slice(index + 1)]
      }
    case GroupTypes.GROUP_GET:
      return { ...state, group: action.payload }
    case GroupTypes.GROUP_GET_LIST:
      return { ...state, groups: action.payload }
    default:
      return state
  }
}

export default reducer
