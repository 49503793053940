import { BotOptionState, BotOptionTypes, BotOptionActionTypes } from '../type/option'
import { Reducer } from 'redux'

const initialState: BotOptionState = {
  botOptions: []
}

type ReducerOpts = Reducer<BotOptionState, BotOptionActionTypes>
const reducer: ReducerOpts = (state = initialState, action: BotOptionActionTypes): BotOptionState => {
  let index = -1
  switch (action.type) {
    case BotOptionTypes.BOT_OPTION_RESET:
      return initialState
    case BotOptionTypes.BOT_OPTION_CREATE:
      return { ...state, botOption: action.payload, botOptions: [...state.botOptions, action.payload] }
    case BotOptionTypes.BOT_OPTION_UPDATE:
      index = state.botOptions.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        botOption: action.payload,
        botOptions: [...state.botOptions.slice(0, index), action.payload, ...state.botOptions.slice(index + 1)]
      }
    case BotOptionTypes.BOT_OPTION_DELETE:
      index = state.botOptions.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        botOption: undefined,
        botOptions: [...state.botOptions.slice(0, index), ...state.botOptions.slice(index + 1)]
      }
    case BotOptionTypes.BOT_OPTION_GET:
      return { ...state, botOption: action.payload }
    case BotOptionTypes.BOT_OPTION_GET_LIST:
      return { ...state, botOptions: action.payload }
    default:
      return state
  }
}

export default reducer
