import Axios from 'axios'
import { Dispatch } from 'redux'
import { handleError, createAlert, clearAlert } from 'util/modules/Alert/redux/actions'
import { ActionResponse, Id } from 'util/types/index'
import { Person, PersonTypes } from '../types/person'

export function createPerson (storeId: number, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Person>> => {
    return Axios
      .post(`/store/${storeId}/person`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: PersonTypes.PERSON_CREATE, payload: res.data })
        }

        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar contato')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updatePerson (storeId: number, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Person>> => {
    return Axios
      .put(`/store/${storeId}/person/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: PersonTypes.PERSON_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar contato')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deletePerson (storeId: number, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Person>> => {
    return Axios
      .delete(`/store/${storeId}/person/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: PersonTypes.PERSON_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover contato')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getPerson (storeId: number, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Person>> => {
    return Axios
      .get(`/store/${storeId}/person/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: PersonTypes.PERSON_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar por grupo')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getPersonList (storeId: number, params?: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Person>> => {
    return Axios
      .get(`/store/${storeId}/person`, { params })
      .then(res => res.data)
      .then(res => {
        dispatch({ type: PersonTypes.PERSON_GET_LIST, payload: res.data || [], pagination: res.pagination })
        return res
      })
  }
}
