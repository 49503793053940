import { PlanState, PlanActionTypes, PlanTypes } from './types'
import { Reducer } from 'redux'

const initialState: PlanState = {
  plans: [],
  available: []
}

type ReducerOpts = Reducer<PlanState, PlanActionTypes>
const reducer: ReducerOpts = (state = initialState, action: PlanActionTypes): PlanState => {
  let index = -1
  switch (action.type) {
    case PlanTypes.PLAN_RESET:
      return initialState
    case PlanTypes.PLAN_CREATE:
      return { ...state, plan: action.payload, plans: [...state.plans, action.payload] }
    case PlanTypes.PLAN_UPDATE:
      index = state.plans.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        plan: action.payload,
        plans: [...state.plans.slice(0, index), action.payload, ...state.plans.slice(index + 1)]
      }
    case PlanTypes.PLAN_DELETE:
      index = state.plans.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        plan: undefined,
        plans: [...state.plans.slice(0, index), ...state.plans.slice(index + 1)]
      }
    case PlanTypes.PLAN_GET:
      return { ...state, plan: action.payload }
    case PlanTypes.PLAN_GET_FREE:
      return { ...state, freePlan: action.payload }
    case PlanTypes.PLAN_GET_LIST:
      return { ...state, plans: action.payload }
    case PlanTypes.PLAN_GET_AVAILABLE:
      return { ...state, available: action.payload }
    default:
      return state
  }
}

export default reducer
