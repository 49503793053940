/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useRef, useEffect } from 'react'

import './styles/Modal.scss'

type ModalOpts = {
  className?: string,
  title?: string,
  isOpen: boolean,
  style?: React.CSSProperties,
  onClose?: () => void,
  closeModal?: () => void,
}

type Props = React.FC<{ title: string, subtitle?: string, icon?: string, onClose: () => void }>
export const ModalHeader: Props = ({ title, subtitle, icon = 'cog', onClose }) => {
  return (
    <div className="row panel-header">
      <div className="grow">
        <p><i className={`fa fa-${icon}`} /> {title} </p>
        {!!subtitle && <p>{subtitle}</p>}
      </div>
      <span
        className="button-times pointer"
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          onClose()
          const element = document.querySelector('body')
          element!.style.overflow = 'auto'
        }}
      >&times;</span>
    </div>
  )
}

type ModalProps = React.PropsWithChildren<ModalOpts>
const Modal: React.FC<ModalProps> = ({ children, className, title, isOpen, style, onClose, closeModal }) => {
  const modal = useRef<HTMLDivElement>(null)

  function handleModal (status: boolean): void {
    const modalParent = modal.current
    if (modalParent) {
      const modalContent = modalParent ? modalParent.querySelector('.modal-body') : null

      if (modalParent && modalContent) {
        if (status) {
          modalParent.classList.add('animated', 'fadeIn')
          modalContent.classList.add('animated', 'zoomIn')
        } else if (modalParent.classList.contains('animated')) {
          modalParent.classList.add('fadeOut')
          modalContent.classList.add('zoomOut')

          setTimeout(() => {
            modalParent.classList.remove('animated', 'fadeIn', 'fadeOut')
            modalContent.classList.remove('animated', 'zoomIn', 'zoomOut')
          }, 500)
        }
      }
    }
  }

  function closeModalByBody (event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation()
    const element = document.querySelector('body')
    element!.style.overflow = 'auto'
    const modal = event.target as HTMLDivElement
    if (modal) {
      const isModalBG = modal.classList.contains('modal-bg')
      if (isModalBG) {
        if (onClose) onClose()
        if (closeModal) closeModal()
      }
    }
  }

  useEffect(() => {
    handleModal(isOpen)
    if (isOpen) {
      const element = document.querySelector('body')
      element!.style.overflow = 'hidden'
    } else {
      const element = document.querySelector('body')
      element!.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <div ref={modal} className='modal-box' style={style}>
      <div className="modal-bg" onClick={(event): void => closeModalByBody(event)} />
      <div className={`modal-body${className ? ` ${className}` : ''}`}>
        {title && <h1>{title}</h1>}
        {children}
      </div>
    </div>
  )
}

export default Modal
