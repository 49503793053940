import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import * as z from 'zod'

export const useFormZod = <T extends z.ZodRawShape>(
  formSchema: z.ZodObject<T>,
  defaultValues?: Partial<z.infer<typeof formSchema>>
) =>
    useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      mode: 'onChange',
      defaultValues: defaultValues as any,
      shouldFocusError: true
    })
