import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import { getConversationList } from '../redux/actions'

let interval: NodeJS.Timeout
export const TitleTicker: React.FC = () => {
  const dispatch = useDispatch()
  const [tick, setTicker] = useState(new Date())

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { conversations } = useSelector((state: ApplicationState) => state.conversationReducer)

  useEffect(() => {
    const actualTitle = document.title
    const defaultTitle = 'Under Chat'

    let unReadMessages = 0
    conversations.forEach(({ id, unreadMessages: quantity }) => {
      unReadMessages += Number(quantity)
    })

    if (unReadMessages > 0 && actualTitle === defaultTitle) {
      if (unReadMessages === 1) {
        document.title = `(${unReadMessages}) Mensagem`
      } else {
        document.title = `(${unReadMessages}) Mensagens`
      }
    } else {
      document.title = defaultTitle
    }
  }, [tick])

  useEffect(() => {
    interval = setInterval(() => {
      setTicker(new Date())
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (selected) getConversationList(selected)(dispatch)
  }, [dispatch, selected])

  return null
}
