import Axios from 'axios'
import { Dispatch } from 'redux'

import { Bot, BotTypes } from '../type/bot'
import { ActionResponse, Id } from 'util/types/index'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'

export function createBot (storeId: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Bot>> => {
    return Axios
      .post(`/store/${storeId}/bot`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: BotTypes.BOT_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar o bot')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateBot (storeId: Id, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Bot>> => {
    return Axios
      .put(`/store/${storeId}/bot/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: BotTypes.BOT_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o bot')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteBot (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Bot>> => {
    return Axios
      .delete(`/store/${storeId}/bot/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: BotTypes.BOT_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o bot')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getBot (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Bot>> => {
    return Axios
      .get(`/store/${storeId}/bot/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: BotTypes.BOT_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar o bot')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getBotList (storeId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Bot>> => {
    return Axios
      .get(`/store/${storeId}/bot`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: BotTypes.BOT_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}
