import React from 'react'

import { formatDateTime } from 'util/masks'
import { BotAttachmentDict } from 'panel/chat-bot/schema/chat-bot.attachment.schema'
import { deleteChatBotAttachment, getChatBotAttachmentList } from 'panel/chat-bot/service/chat-bot.attachment.service'

export const AttachmentList: React.FC = () => {
  const { data: attachments } = getChatBotAttachmentList()
  const { mutate: handleDelete, isLoading: isDeleting } = deleteChatBotAttachment()

  const gridTemplateColumns = '200px 1fr 180px 80px'
  return (
    <div className="list" style={{ marginTop: 32 }}>
      <div className="list-header" style={{ gridTemplateColumns }}>
        <div>Tipo</div>
        <div>Titulo</div>
        <div>Criado em</div>
        <div>Ações</div>
      </div>

      {
        attachments.length === 0 &&
        <div className="list-row empty" style={{ height: 50 }}>
          <div className="grid-span" style={{ height: 0 }}>Nenhum anexo cadastrado</div>
        </div>
      }

      {
        attachments.map((attachment) => {
          return (
            <div className="list-row" key={attachment.id} style={{ gridTemplateColumns }}>
              <div data-content="Título">{ attachment.title }</div>
              <div data-content="Tipo">{ BotAttachmentDict[attachment.type] }</div>
              <div data-content="Criado em">{ formatDateTime(attachment.createdAt) }</div>
              <div data-content="Ações" className="row buttons-list-row">
                <button
                  type='button'
                  disabled={isDeleting}
                  className="button secondary flex"
                  onClick={() => handleDelete(attachment.id)}
                >
                  <i className="fas fa-trash-alt" /> &nbsp;Excluir
                </button>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
