import React, { useState } from 'react'

import { formatDate } from 'util/masks'
import Attachment from 'util/modules/Audio/Attachment'

type Props = { url: string, onSelect: (file?: File) => void }
export const AttachmentFile: React.FC<Props> = ({ url, onSelect }) => {
  const [file, setFile] = useState<File>()

  return (
    <div className="form-control">
      <label>Anexar Arquivo</label>
      <div className="row input required" style={{ height: 72 }}>
        <Attachment
          acceptedMimeTypes={['application/pdf']}
          onSelect={file => {
            onSelect(file)
            setFile(file)
          }}
        />
        {
          !!url &&
            <div className="grow padding-left-16">
              <div className="row">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="bold"
                  href={url}
                  style={{ overflow: 'hidden', height: 19, width: '100%' }}
                >
                  {file?.name || 'Selecione um arquivo'}
                </a>
                <p>
                  {file?.type} - {formatDate(file ? (new Date(file.lastModified)).toISOString() : '')}
                </p>
              </div>
            </div>
        }
      </div>
    </div>
  )
}
