import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Tab } from '../ContactTab'
import { PersonTypes } from 'panel/person/redux/types/person'
import { updatePerson } from 'panel/person/redux/action/person'
import { ApplicationState } from 'AppReducer'
import { maskCPF, maskCNPJ, maskDate } from 'util/masks'

const initialState = { maritalStatus: '', docType: 'CPF', docNumber: '', birthday: '', gender: 'O' }
const ContactComplementary: React.FC<{ onUnsaved: (tab?: Tab) => void }> = ({ onUnsaved }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [form, setForm] = useState(initialState)
  const [fetching, setFetching] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { person } = useSelector((state: ApplicationState) => state.personReducer)

  const handleClear = useCallback(() => {
    setForm(initialState)
    history.push('/panel/person')
    dispatch({ type: PersonTypes.PERSON_GET, payload: undefined })
  }, [history])

  function getSavedState () {
    if (id && person) {
      return {
        maritalStatus: person.maritalStatus || '',
        docType: person.docType || 'CPF',
        docNumber: person.docNumber || '',
        birthday: person.birthday ? person.birthday.split('-').reverse().join('/') : '',
        gender: person.gender || ''
      }
    }

    return initialState
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (!selected || !id) return undefined
    console.log(form)
    setFetching(true)
    await updatePerson(selected, id, {
      ...form,
      birthday: form.birthday ? form.birthday.split('/').reverse().join('-') : undefined
    })(dispatch)
    setFetching(false)
  }

  useEffect(() => {
    setForm(getSavedState())
  }, [person])

  // Handle Unsaved form
  useEffect(() => {
    const isUnsaved = JSON.stringify(form) !== JSON.stringify(getSavedState())
    onUnsaved(isUnsaved ? 'COMPLEMENTARY' : undefined)
  }, [form, person, initialState])

  return (
    <form className="row margin-top-16" onSubmit={handleSubmit}>
      <div className="row justify-start">
        <label>Tipo de Cadastro</label>
        <label className="checkbox margin-right-16" style={{ width: 'auto' }}>
          <input
            type="checkbox"
            checked={form.docType === 'CPF'}
            onClick={() => setForm({ ...form, docType: 'CPF' })}
          />
          <div className="checkmark" /> Pessoa Física
        </label>
        <label className="checkbox margin-right-16" style={{ width: 'auto' }}>
          <input
            type="checkbox"
            checked={form.docType === 'CNPJ'}
            onClick={() => setForm({ ...form, docType: 'CNPJ' })}
          />
          <div className="checkmark" /> Pessoa Jurídica
        </label>
      </div>

      <div className="form-control col-6">
        <label>{form.docType}</label>
        <input
          type="text"
          value={form.docNumber}
          onChange={({ target: { value } }): void => {
            setForm({ ...form, docNumber: form.docType === 'CPF' ? maskCPF(value) : maskCNPJ(value) })
          }}
        />
      </div>

      <div className="form-control col-6">
        <label>Data Nascimento</label>
        <input
          type="text"
          value={form.birthday}
          onChange={(e): void => setForm({ ...form, birthday: maskDate(e.target.value, '/') })}
        />
      </div>

      <div className="form-control col-6">
        <label>Gênero</label>
        <select value={form.gender} onChange={(e): void => setForm({ ...form, gender: e.target.value })}>
          <option value="M">Masculino</option>
          <option value="F">Feminino</option>
          <option value="O">Outro</option>
        </select>
      </div>
      <div className="form-control col-6">
        <label className="label-responsive">Estado Civil</label>
        <select
          value={form.maritalStatus || ''}
          onChange={(e): void => setForm({ ...form, maritalStatus: e.target.value })}
        >
          <option></option>
          <option value="single">Solteiro(a)</option>
          <option value="married">Casado(a)</option>
          <option value="divorced">Divorciado(a)</option>
          <option value="widower">Viúvo(a)</option>
          <option value="commonLawMarriage">União Estável</option>
        </select>
      </div>

      <div className="row margin-top-16 form-buttons">
        <span className="secondary button" onClick={handleClear}>Limpar</span>
        <button className="gradient button" disabled={fetching}>Salvar</button>
      </div>
    </form>
  )
}

export default ContactComplementary
