import React, { FormEvent, useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import './styles/SignOn.scss'

import { img } from 'assets/online'
import { register } from 'panel/user/redux/actions'
import { clearToken } from 'util/services/AuthService'
import { passwordValidate } from 'util/services/Validator'
import { maskCNPJ, maskCPF, maskPhone } from 'util/masks'

import Alert from 'util/modules/Alert'
import { getFreePlan } from 'panel/plan/redux/actions'
import { ApplicationState } from 'AppReducer'

const initialState = {
  firstName: '',
  lastName: '',
  phone: '',
  docType: 'CNPJ' as 'CNPJ' | 'CPF',
  docNumber: '',
  email: '',
  password: '',
  passwordConfirm: ''
}
const SignUp: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [form, setForm] = useState(initialState)
  const [isFetching, setFetching] = useState(false)
  const [isFetchingFreePlan, setFetchingFreePlan] = useState(false)

  const { freePlan } = useSelector((state: ApplicationState) => state.planReducer)

  async function handleForm (e: FormEvent): Promise<void> {
    e.preventDefault()
    setFetching(true)
    clearToken()
    await register(form)(dispatch).then(res => {
      if (res?.status === 'success') {
        history.push('/panel')
      }
    })
    setFetching(false)
  }

  useEffect(() => {
    (async () => {
      setFetchingFreePlan(true)
      await getFreePlan()(dispatch)
      setFetchingFreePlan(false)
    })()
  }, [dispatch])

  const validation = passwordValidate(form.password, form.passwordConfirm)

  if (!freePlan) {
    return (
      <div className="row sign-container">
        <div className="sign-content" style={{ width: 760 }}>
          <div className="row sign-title-box ">
            <img src={img.logo} alt="Logo" />
            <h2 className="text-center">Cadastre-se agora</h2>
          </div>

          {
            isFetching
              ? (
                <div className="row">
                  <h2>Carregando informações do plano. Aguarde...</h2>
                </div>
              )
              : (
                <div className="row" style={{ marginTop: 16 }}>
                  <h2 className='text-center' style={{ color: 'white ' }}>Não disponível</h2>
                  <h2 className='text-center' style={{ color: 'white ' }}>
                    Os novos cadastros estão temporariamente suspensos
                  </h2>
                </div>
              )
          }

          <p className="text-center underchat-copy-label margin-top-16 margin-bottom-8">
            Já possui conta?&nbsp;
            <NavLink style={{ color: 'var(--blue)' }} to="/sign-in" >
              Faça login
            </NavLink>
          </p>
          <p className="text-center underchat-copy-label">
            © {(new Date()).getFullYear()} UnderChat. Desenvolvido com 🤍 UnderChat
          </p>
        </div>
      </div>
    )
  }

  return (
    <>
      <Alert />
      <div className="row sign-container">
        <div className="sign-content" style={{ width: 760 }}>
          <div className="row sign-title-box ">
            <img src={img.logo} alt="Logo" />
            <h2 className="text-center">Cadastre-se agora</h2>
            <p className="text-center">
              <i className="fa fa-info-circle" />Você poderá testar por {freePlan.effectiveDays} dias, gratuitamente!
            </p>
          </div>

          <form onSubmit={handleForm} className="row">
            {/* Inputs */}
            <div className="form-control col-6">
              <label>Tipo de Cadastro</label>
              <div className="row">
                <span className='icon-widget'>
                  <i className="fas fa-user-tie" />
                </span>
                <select
                  required
                  className='flex'
                  value={form.docType}
                  style={{ flexFlow: 'row' }}
                  onChange={(e) => setForm({ ...form, docType: e.target.value as 'CNPJ', docNumber: '' })}
                >
                  <option value="CNPJ">Pessoa Jurídica</option>
                  <option value="CPF">Pessoa Física</option>
                </select>
              </div>
            </div>
            <div className="form-control col-6">
              <label>{form.docType}</label>
              <div className="row">
                <span className='icon-widget'>
                  <i className="fas fa-id-card" />
                </span>
                <input
                  required
                  type="text"
                  className="flex"
                  value={form.docNumber}
                  onChange={({ target: { value } }): void => {
                    setForm({ ...form, docNumber: form.docType === 'CPF' ? maskCPF(value) : maskCNPJ(value) })
                  }}
                />
              </div>
            </div>

            <div className="form-control col-6">
              <label>{form.docType === 'CPF' ? 'Primeiro Nome' : 'Razão Social'}</label>
              <div className="row">
                <span className='icon-widget'>
                  <i className="fas fa-user-edit" />
                </span>
                <input
                  required
                  type="text"
                  className='flex'
                  value={form.firstName}
                  onChange={(e): void => setForm({ ...form, firstName: e.target.value })}
                />
              </div>
            </div>
            <div className="form-control col-6">
              <label>{form.docType === 'CPF' ? 'Sobrenome' : 'Nome Fantasia'}</label>
              <div className="row">
                <span className='icon-widget'>
                  <i className="fas fa-user-edit" />
                </span>
                <input
                  required
                  type="text"
                  className='flex'
                  value={form.lastName}
                  onChange={(e): void => setForm({ ...form, lastName: e.target.value })}
                />
              </div>
            </div>

            <div className="form-control col-6">
              <label>E-mail</label>
              <div className="row">
                <span className='icon-widget'>
                  <i className="fas fa-envelope" />
                </span>
                <input
                  required
                  type="text"
                  className='flex'
                  value={form.email}
                  onChange={(e): void => setForm({ ...form, email: e.target.value })}
                />
              </div>
            </div>
            <div className="form-control col-6">
              <label>WhatsApp (Onde receberá os dados de Acesso)</label>
              <div className="row">
                <span className='icon-widget'>
                  <i className="fas fa-phone" />
                </span>
                <input
                  required
                  type="text"
                  className='flex'
                  value={form.phone}
                  onChange={(e): void => {
                    const onlyDigits = e.target.value.replace(/\D/g, '')
                    const startWith55 = /^55/.test(onlyDigits)
                    if (startWith55) {
                      setForm({ ...form, phone: maskPhone(e.target.value) })
                    } else {
                      setForm({ ...form, phone: maskPhone(`55${onlyDigits}`) })
                    }
                  }}
                />
              </div>
            </div>

            <div className="form-control col-6">
              <span className="row">
                <label className="flex">Senha</label>
              </span>
              <div className="row">
                <span className='icon-widget'>
                  <i className="fas fa-lock" />
                </span>
                <input
                  required
                  type="password"
                  className='flex'
                  value={form.password}
                  autoComplete="new-password"
                  onChange={(e): void => setForm({ ...form, password: e.target.value })}
                />
              </div>
            </div>
            <div className="form-control col-6">
              <span className="row">
                <label className="flex">Confirmar Senha</label>
              </span>
              <div className="row">
                <span className='icon-widget'>
                  <i className="fas fa-lock" />
                </span>
                <input
                  required
                  type="password"
                  className="flex"
                  value={form.passwordConfirm}
                  autoComplete="new-password"
                  onChange={(e): void => setForm({ ...form, passwordConfirm: e.target.value })}
                />
              </div>
            </div>

            <p className="col-12 text-center">
              {validation.warnings?.[0] || <>&nbsp;</>}
            </p>

            {/* Buttons */}
            <div className="form-control">
              <button
                className="button button-entry col-12"
                disabled={isFetching || !validation.isValid}
              >
                {isFetching ? 'Cadastrando. Aguarde...' : 'Cadastrar'}
              </button>
            </div>
          </form>
          <p className="text-center underchat-copy-label margin-top-16 margin-bottom-8">
            Já possui conta?&nbsp;
            <NavLink style={{ color: 'var(--blue)' }} to="/sign-in" >
              Faça login
            </NavLink>
          </p>
          <p className="text-center underchat-copy-label">
            © {(new Date()).getFullYear()} UnderChat. Desenvolvido com 🤍 UnderChat
          </p>
        </div>
      </div>
    </>
  )
}

export default SignUp
