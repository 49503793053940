export function handleScrollTop (): void {
  const content = document.querySelector('.layout-panel .content') as HTMLDivElement
  if (content) {
    content.scrollTo(0, 0)
  }
}

export function getDiffDays (start: Date, end: Date): number {
  const diff = end.getTime() - start.getTime()
  return Math.floor(diff / (1000 * 3600 * 24))
}

export function normalizeAccents (text: string): string {
  if (text) {
    return text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
      .replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
      .replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
      .replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
      .replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
      .replace(new RegExp('[Ç]', 'gi'), 'c')
  }
  return ''
}

export function slug (text: string, replacer = '-'): string {
  return normalizeAccents(text).toLowerCase().replace(/[^a-z0-9]/g, replacer)
}

export function objToQueryString (query: any): string {
  const decoded = Object
    .keys(query)
    .map(key => {
      if (typeof query[key] === 'object') {
        return Object.keys(query[key]).map(key2 => `${key}[${key2}]=${query[key][key2]}`)
      } else {
        return `${key}=${query[key]}`
      }
    })
    .join('&')

  return decoded
}

export function removeEmptyFromObject (obj: Record<string, any>): Record<string, any> {
  return Object
    .entries(obj)
    .reduce((acc, [k, v]) => {
      if (v !== '') {
        acc[k] = v
      }
      return acc
    }, {} as Record<string, any>)
}

export async function iterateList<T> (fn: (item: T, i: number) => Promise<void>, list: T[], index = 0): Promise<void> {
  const item = list[index]
  if (item) {
    await fn(item, index)
    await iterateList(fn, list, index + 1)
  }
}

export const sleep = (ms: number): Promise<number> => {
  return new Promise((resolve): number => setTimeout(resolve, ms))
}

export function getRandomInt (min: number, max: number): number {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}
