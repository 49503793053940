import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import { getPastConversationMessage } from '../redux/actions'

import MessageTile from './MessageTile'
import { hasPermission } from 'util/services/AuthService'

const MessageLoadPrevious: React.FC<{ conversationId: number }> = ({ conversationId }) => {
  const dispatch = useDispatch()
  const [isEnd, setEnd] = useState(false)
  const [isFetching, setFetching] = useState(false)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const conversation = useSelector((state: ApplicationState) => state.conversationReducer.conversation[conversationId])
  const pastMessages = useSelector((state: ApplicationState) => state.conversationReducer.pastMessages[conversationId])

  const pastConversations = Object.keys(pastMessages || {})
  const lastConversationId = Number(pastConversations[0] || conversationId)

  async function fetchMessage () {
    if (selected) {
      setFetching(true)
      await getPastConversationMessage(selected, conversationId, lastConversationId)(dispatch).then(res => {
        if (!res.data?.messages.length) setEnd(true)
      })
      setFetching(false)
    }
  }

  if (!conversation || !hasPermission('CONVERSATION:GET_PAST')) return null

  return (
    <>
      {
        isEnd
          ? (
            <button disabled={true} className="row hero text-center pointer">
              <p className="bold">
                Você chegou ao fim!
              </p>
            </button>
          )
          : (
            <button disabled={isFetching} className="row hero text-center pointer" onClick={fetchMessage}>
              <p className="bold" style={{ color: '#1f1f1f' }}>
                <i className={`fa fa-sync ${isFetching ? 'fa-spin' : ''}`} />
                Carregar atendimentos anteriores
              </p>
            </button>
          )
      }

      {
        pastConversations.map(conversationId => {
          const messages = pastMessages[Number(conversationId)]
          if (!messages) return null

          return messages.map((message) => (
            <MessageTile key={message.id} message={message} />
          ))
        })
      }
    </>
  )
}

export default MessageLoadPrevious
