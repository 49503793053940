import { User } from 'panel/user/redux/types'
import { SectorAttendant } from 'panel/sector/redux/types'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
* Data Types
*/
export interface Attendant {
  id: number,
  isActive: boolean,
  isOnline: boolean,
  average: number,
  attendance: number,
  user: Partial<User>,
  sectorAttendants: Partial<SectorAttendant>[],
  createdAt: string,
  updatedAt?: string,
  deletedAt?: string
}

/**
* State Type
*/

export interface AttendantState {
  readonly attendant?: Attendant
  readonly attendants: Attendant[]
}

/**
* Action Enum Types
*/

export enum AttendantTypes {
  ATTENDANT_RESET = 'ATTENDANT/RESET',
  ATTENDANT_CREATE = 'ATTENDANT/CREATE',
  ATTENDANT_UPDATE = 'ATTENDANT/UPDATE',
  ATTENDANT_DELETE = 'ATTENDANT/DELETE',
  ATTENDANT_GET = 'ATTENDANT/GET',
  ATTENDANT_GET_LIST = 'ATTENDANT/GET/LIST'
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof AttendantTypes.ATTENDANT_RESET,
}

interface CreateAction {
  type: typeof AttendantTypes.ATTENDANT_CREATE,
  payload: Attendant
}

interface UpdateAction {
  type: typeof AttendantTypes.ATTENDANT_UPDATE,
  payload: Attendant
}

interface DeleteAction {
  type: typeof AttendantTypes.ATTENDANT_DELETE,
  payload: number
}

interface GetAction {
  type: typeof AttendantTypes.ATTENDANT_GET,
  payload: Attendant
}

interface GetListAction {
  type: typeof AttendantTypes.ATTENDANT_GET_LIST,
  payload: Attendant[]
}

export type AttendantActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction
)
