import React, { useState } from 'react'

import './styles/Profile.scss'

import Modal from 'util/modules/Modal'
import ProfileForm from './components/ProfileForm'

const Profile: React.FC<{ mode?: 'SIDEBAR' | 'INFO' }> = ({ mode = 'SIDEBAR' }) => {
  const [isFormOpen, setFormOpen] = useState(false)

  return (
    <>
      <Modal isOpen={isFormOpen} onClose={() => setFormOpen(false)}>
        <ProfileForm isOpen={isFormOpen} onClose={() => setFormOpen(false)} />
      </Modal>

      {
        mode === 'SIDEBAR' &&
        <div className="flex row config-button">
          <span onClick={() => setFormOpen(true)} className='button'>
            <i className="fa fa-user">
            </i>
          </span>
        </div>
      }

      {
        mode === 'INFO' &&
        <div
          className="row padding-tb-8 pointer"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            setFormOpen(true)
          }}
        >
          <p className="flex">Perfil</p>
          <i className="fa fa-user highlight-text"/>
        </div>
      }
    </>
  )
}

export default Profile
