import { Reducer } from 'redux'

import { ConversationRedirectTypes } from './types'
import { ConversationRedirectState, ConversationRedirectActionTypes as Redirect } from './types'

const initialState: ConversationRedirectState = { redirects: [] }

type ReducerOpts = Reducer<ConversationRedirectState, Redirect>
const reducer: ReducerOpts = (state = initialState, action: Redirect): ConversationRedirectState => {
  switch (action.type) {
    case ConversationRedirectTypes.CONVERSATION_REDIRECT_LIST:
      return { ...state, redirects: action.payload }
    default:
      return state
  }
}

export default reducer
