import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { maskPhone } from 'util/masks'
import { useDebounce } from 'util/hooks'
import { ModalHeader } from 'util/modules/Modal'
import { ApplicationState } from 'AppReducer'
import { getContactStoreList } from 'panel/person/redux/action/contact'
import { createGroup, getGroup, updateGroup } from '../redux/actions'

import Pagination from 'util/modules/Pagination'
import { getPersonList } from 'panel/person/redux/action/person'

const initialState = { name: '', description: '' }
const GroupForm: React.FC<{ onClose(): void }> = ({ onClose }) => {
  const dispatch = useDispatch()
  const debounce = useDebounce()
  const history = useHistory()

  const { location: { search } } = history

  const [form, setForm] = useState(initialState)
  const [contactIds, setContactIds] = useState<number[]>([])
  const [paginationLimit, setPaginationLimit] = useState<number>(20)

  const [filter, setFilter] = useState({ key: 'person.name', value: '' })

  const { id } = useParams<{ id: string }>()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { contacts: { items: contacts, pagination } } = useSelector((state: ApplicationState) => state.contactReducer)
  const { persons } = useSelector((state: ApplicationState) => state.personReducer)
  const total = pagination?.total || 0

  const inpCheckAll = useRef<HTMLInputElement>(null)

  const handleClear = useCallback(() => {
    setContactIds([])
    setForm(initialState)
    setPaginationLimit(20)
    history.push('/panel/group')
  }, [history])

  function handleForm (): void {
    if (!selected) return undefined

    if (id) {
      updateGroup(selected, id, { ...form, contactIds })(dispatch)
    } else {
      createGroup(selected, { ...form, contactIds })(dispatch).then(res => {
        if (res?.status === 'success') {
          handleClear()
        }
      })
    }
  }

  function handleAddContact (id: number) {
    const isIdOnList = contactIds.find(item => item === id)
    if (!isIdOnList) {
      setContactIds([...contactIds, id])
    }
  }

  function handleDelContact (id: number) {
    setContactIds(contactIds.filter(item => item !== id))
  }

  function handleAll () {
    if (contacts.length === total) {
      if (contactIds.length === contacts.length) {
        setContactIds([])
      } else {
        setContactIds(contacts.map(item => item.id))
      }
    } else {
      const hasAnyContactOnList = contacts.filter(item => contactIds.includes(item.id)).length > 0
      if (hasAnyContactOnList) { // Remove actual contacts per page from id list
        setContactIds(contactIds.filter(id => !contacts.find(contact => contact.id === id)))
      } else {
        const toInclude = contacts.map(item => item.id)
        setContactIds([...contactIds, ...toInclude])
      }
    }
  }

  useEffect(() => {
    if (selected) {
      if (id) {
        getGroup(selected, id)(dispatch).then(res => {
          const group = res?.data
          if (res?.status === 'success' && group) {
            setForm({
              name: group.name,
              description: group.description
            })

            setContactIds(group.contacts?.map(item => item.id || 0) || [])
          }
        })
      } else {
        handleClear()
      }
    }
  }, [id, selected, dispatch, handleClear])

  useEffect(() => {
    if (inpCheckAll.current) {
      const indeterminate = contactIds.length > 0 && contactIds.length < total
      inpCheckAll.current.indeterminate = indeterminate
      inpCheckAll.current.checked = contactIds.length === total
    }
  }, [inpCheckAll, contactIds])

  useEffect(() => {
    if (selected) {
      const query = new URLSearchParams(search)
      getContactStoreList(selected, {
        ...Object.fromEntries(query.entries()),
        fields: 'id,value,person.name',
        limit: query.get('limit') || 20,
        page: query.get('page') || 1
      })(dispatch)

      getPersonList(selected)(dispatch)
    }
  }, [search, selected, dispatch])

  useEffect(() => {
    if (paginationLimit) {
      if (paginationLimit < contacts.length) setContactIds([])
      history.push(`?limit=${paginationLimit}`)
    }
  }, [paginationLimit])

  useEffect(() => {
    debounce(() => {
      if (filter.value) {
        history.push(`?filter_like[${filter.key}]=^${filter.value}$&limit=${paginationLimit}`)
      } else {
        history.push(`?limit=${paginationLimit}`)
      }
    }, 500)
  }, [filter])

  return (
    <div className="row panel no-padding custom-group-wrapper">
      <ModalHeader title="Configurações de Grupo" onClose={onClose} />
      <div className="row panel-body">
        <div className="form-control">
          <label>Nome do Grupo</label>
          <input
            required
            value={form.name}
            onChange={(e): void => setForm({ ...form, name: e.target.value })}
            type="text"
          />
        </div>

        <div className="form-control">
          <label>Descrição</label>
          <input
            value={form.description}
            onChange={(e): void => setForm({ ...form, description: e.target.value })}
            type="text"
          />
        </div>

        <div className="form-control margin-top-32">
          <h3 style={{ margin: '8px 0' }}>Selecione os contatos ({ contactIds.length } Selecionado)</h3>
          <select value={paginationLimit} onChange={(e) => setPaginationLimit(Number(e.target.value))}>
            {
              [20, 50, 100, 200, 500, 1000, 5000, 10000].map((number, i) => (
                <option value={number} key={i}>Exibindo { number } contatos por página</option>
              ))
            }
          </select>
          <div className="row">
            <div className="form-control col-4">
              <label htmlFor="type">Tipo</label>
              <select value={filter.key} onChange={e => setFilter({ key: e.target.value, value: '' })}>
                <option value="person.name">Nome</option>
                <option value="value">Telefone</option>
                <option value="person.alias">Apelido</option>
              </select>
            </div>
            <div className="form-control col-8">
              <label htmlFor="type">Filtro</label>
              <input
                value={filter.value}
                onChange={e => setFilter({ ...filter, value: e.target.value })}
              />
            </div>
          </div>
          <div className="list">
            <div className="list-header">
              <div>
                <input
                  readOnly
                  type="checkbox"
                  ref={inpCheckAll}
                  onClick={handleAll}
                />
              </div>
              <div className="padding-right-16">Nome</div>
              <div>Contato</div>
            </div>

            <div className="row">
              {
                contacts.map((contact, i) => {
                  const isOnList = !!contactIds.find(id => id === contact.id)
                  const findPerson = persons.items.find(person => person.contacts[0]?.id === contact.id)

                  return (
                    <div className="list-row" key={i}>
                      <div data-content="" style={{ width: 20 }}>
                        <input
                          type="checkbox"
                          checked={isOnList}
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleAddContact(contact.id)
                            } else {
                              handleDelContact(contact.id)
                            }
                          }}
                        />
                      </div>
                      <div data-content="Nome" className="flex padding-right-16">
                        {contact.person?.name} {findPerson?.alias && filter?.key === 'person.alias'
                          ? `(${findPerson.alias})`
                          : null
                        }
                      </div>
                      <div data-content="Contato" className='row'>{maskPhone(contact.value)}</div>
                    </div>
                  )
                })
              }
            </div>
            <Pagination pagination={pagination} />
          </div>
        </div>

        <div className="row justify-end buttons margin-top-16">
          <span className="secondary button" onClick={handleClear}>Limpar</span>
          <button className="gradient button" onClick={handleForm}>Salvar</button>
        </div>
      </div>
    </div>
  )
}

export default GroupForm
