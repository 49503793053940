import React, { useRef, useState } from 'react'
import { formatDate } from 'util/masks'
import Upload, { UploadRef } from 'util/modules/Upload'

type Props = { onSelect: (file?: File) => void }
export const AttachmentImage: React.FC<Props> = ({ onSelect }) => {
  const [file, setFile] = useState<File>()
  const uploadRef = useRef<UploadRef>(null)

  return (
    <div className="form-control">
      <label>Anexar Imagem</label>
      <div className="row input required" style={{ height: 72 }}>
        <div className="attachment_picture" style={{ width: 50, height: 50 }}>
          <Upload
            ref={uploadRef}
            placeholder={false} onChange={file => {
              onSelect(file)
              setFile(file)
            }}
          />
        </div>
        <div className="grow padding-left-16">
          <div className="row">
            <p className="bold" style={{ overflow: 'hidden', height: 19 }}>
              {file?.name || 'Selecione uma imagem'}
            </p>
            <p>
              {file?.type} - {formatDate(file ? (new Date(file.lastModified)).toISOString() : '')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
