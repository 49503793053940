import React from 'react'
import ReactDOM from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import './assets/scss/style/Style.scss'
import './assets/scss/Common.scss'

// Redux
import { Provider } from 'react-redux'
import { img } from 'assets/online'

// App Redux and Routers
import store from './AppReducer'
import AppRouter from './AppRouter'

// Services
import AxiosService from 'util/services/AxiosService'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { NextUIProvider } from '@nextui-org/react'
AxiosService()

export const defaultPicture = img['default-user']

export function scrollTop (): void {
  document.querySelector('.layout-panel .content')?.scrollTo(0, 0)
}

const search = new URLSearchParams(window.location.search)
const access_token = search.get('access_token')
const refresh_token = search.get('refresh_token')

if (access_token && refresh_token) {
  localStorage.setItem('@App:Token', access_token)
  localStorage.setItem('@App:RefreshToken', refresh_token)
}

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AppRouter />
      </LocalizationProvider>
    </QueryClientProvider>
  </Provider>
)

serviceWorker.unregister()
