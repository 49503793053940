import React, { useState } from 'react'
import { Message } from 'panel/conversation/redux/types'

const Picture: React.FC<{ message: Partial<Message> }> = ({ message }) => {
  const [isOpen, setOpen] = useState(false)

  if (!message.attachment) return null

  return (
    <>
      {
        isOpen &&
        <div className="preview">
          <div className="row">
            <i className="fa fa-times btn-close" onClick={() => setOpen(false)} />
            <a href={message.attachment?.url} target="_blank" rel="noreferrer" className="btn-open">
              <i className="fa fa-download"/>
            </a>

            <a title="Clique para expandir" href={message.attachment?.url} target="_blank" rel="noreferrer">
              <img src={message.attachment?.url} alt="Preview" />
            </a>
            <p>{ message.message }</p>
          </div>
        </div>
      }

      <img className="attachment-pic" src={message.attachment.thumbnail} onClick={() => setOpen(true)} />
    </>
  )
}

export default Picture
