import React from 'react'
import { useParams } from 'react-router-dom'
import { hasPermission } from 'util/services/AuthService'

type Props = { active: Tab, unsaved?: Tab, onSelect: (tab: Tab) => void }
export type Tab = 'DATA' | 'COMPLEMENTARY' | 'CONTACT' | 'CONFIG'
const ContactTab: React.FC<Props> = ({ active, unsaved, onSelect }) => {
  const { id } = useParams<{ [x: string]: string }>()

  const items: { tab: Tab, label: string }[] = [
    { tab: 'DATA', label: 'Dados' },
    { tab: 'COMPLEMENTARY', label: 'Dados Complementares' },
    { tab: 'CONFIG', label: 'Configurações' }
  ]

  if (hasPermission('PERSON:CREATE')) {
    items.push({ tab: 'CONTACT', label: 'Contatos' })
  }

  return (
    <div className="contact-tab">
      {
        items.map((item, i) => {
          const isUnsaved = (!id && i === 0) || item.tab === unsaved
          const isActive = (id || i === 0) && (!unsaved || isUnsaved)

          return (
            <span
              key={i}
              onClick={() => onSelect(item.tab)}
              className={`tab-option ${active === item.tab ? 'active' : ''}`}
              style={{ opacity: isActive ? 1 : 0.4, pointerEvents: isActive ? 'all' : 'none' }}
            >
              { item.label }
              { isUnsaved && <i className="fa fa-circle" title="Não salvo" style={{ color: 'red' }} /> }
            </span>
          )
        })
      }
    </div>
  )
}

export default ContactTab
