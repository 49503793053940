import React, { useEffect, useState } from 'react'
import { ReactComponent as Remove } from '../assets/quit.svg'

type PreviewOpts = {
  url?: string,
  type?: string,
  file?: File,
  clearFile: () => void
}
export const Preview: React.FC<PreviewOpts> = ({ file, type, url, clearFile }) => {
  const [src, setSrc] = useState<string>()

  const imageMimeTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png'
  ]

  useEffect(() => {
    if (file) {
      const isImage = !!imageMimeTypes.find(mime => mime === file.type)
      if (isImage) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (): void => {
          if (reader.result) {
            setSrc(reader.result.toString())
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [file])

  return (
    <div
      className="row upload-preview"
      style={{ backgroundImage: `url(${src || url})`, backgroundSize: type || 'cover' }}
    >
      <span className="clear-img" onClick={clearFile}>
        <Remove width={50} height={50} fill="white" />
      </span>
    </div>
  )
}

export default Preview
