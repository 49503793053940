import React from 'react'
import ReactApexChart from 'react-apexcharts'

type Props = {
  legend?: boolean,
  legendPosition?: 'left' | 'bottom',
  formatter?: (val: string) => string,
  data: { color: string, label: string, amount: number }[]
}

const DonutChart: React.FC<Props> = ({
  data, formatter = (val) => val, legend = true, legendPosition = 'left'
}) => {
  return (
    <ReactApexChart
      type='donut'
      series={data.map(item => Number(item.amount.toFixed(2)))}
      options={{
        labels: data.map(item => item.label),
        colors: data.map(item => item.color),
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true
                },
                value: { show: true, formatter }
              }
            }
          }
        },
        legend: {
          show: legend,
          position: legendPosition,
          markers: {
            width: 14,
            height: 14
          },
          fontSize: '12px',
          offsetY: 24
        }
      }}
    />
  )
}

export default DonutChart
