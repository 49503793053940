import React, { useEffect, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

export const FilterData: React.FC<{ onChange: (start: Date, end: Date) => void }> = ({ onChange }) => {
  const [form, setForm] = useState({ start: '', end: '' })

  useEffect(() => {
    const start = dayjs(form.start).toDate()
    const end = dayjs(form.end).toDate()

    if (!isNaN(start.getTime()) && !isNaN(end.getTime())) {
      start.setHours(0)
      start.setMinutes(0)
      start.setSeconds(0)

      end.setHours(23)
      end.setMinutes(59)
      end.setSeconds(59)

      console.log(start, end)
      onChange(start, end)
    }
  }, [form])

  return (
    <div className="form-control" onClick={e => e.stopPropagation()}>
      <div className="form-control grow">
        <label>Data Início</label>
        <DatePicker
          value={form.start}
          format='DD/MM/YYYY'
          onChange={start => setForm({ ...form, start: start || '' })}
        />
      </div>
      <div className="form-control grow">
        <label>Data Final</label>
        <DatePicker
          value={form.end}
          format='DD/MM/YYYY'
          onChange={end => setForm({ ...form, end: end || '' })}
        />
      </div>
    </div>
  )
}
