import React, { useState } from 'react'
import { Api } from 'util/services/api'

export const ConversationCsv: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDownload = async () => {
    setIsLoading(true)
    return Api.clientApi({
      url: `${process.env.REACT_APP_API_V2}/conversation/report`,
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      const href = URL.createObjectURL(response.data)

      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', 'relatorio-atendimentos.csv')
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    }).finally(() => setIsLoading(false))
  }

  return (
    <button
      type='button'
      disabled={isLoading}
      className="button highlight margin-left-8 radius" onClick={handleDownload}
    >
      {
        isLoading
          ? <i className="fa fa-spin fa-spinner" style={{ color: 'white' }} />
          : <i className="fa fa-download" style={{ color: 'white' }} />
      }
    </button>
  )
}
