import React, { memo } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import { NodeOptionItem, useOnChangeOption, useOnChangeValue } from './helpers/on-change-value.hook'
import { DeleteNode } from './delete-node'
import { FormControlNode } from '../form-control.node'

interface Props extends NodeProps {
  data: {
    value: string,
    options: NodeOptionItem[]
  }
}
const SendTextNode: React.FC<Props> = ({ id, data }) => {
  const onChange = useOnChangeValue(id)
  const onChangeOption = useOnChangeOption(id)

  const activation = data.options.find(item => item.key === 'activation')
  const trigger = data.options.find(item => item.key === 'trigger')

  return (
    <div className="node" style={{ width: 300 }}>
      <DeleteNode id={id} />
      <p className="custom-drag-handle pt px">
        <i className="fa fa-comment" /> Envia texto
      </p>
      <FormControlNode>
        <textarea rows={6} style={{ resize: 'none', height: 150 }} onChange={onChange} value={data.value}></textarea>
        <p style={{ gridRow: 2, gridColumn: '2/3', textAlign: 'right' }}>{data.value?.length ?? 0}/500</p>
      </FormControlNode>

      <FormControlNode label='Ativação'>
        <select value={activation?.value} onChange={e => onChangeOption('activation', e.target.value)}>
          <option value="ALL">Todos</option>
          <option value="NEW_CONTACT">Novo contato</option>
        </select>
      </FormControlNode>
      <FormControlNode label='Continuar' pb>
        <select value={trigger?.value} onChange={e => onChangeOption('trigger', e.target.value)}>
          <option value="AUTOMATIC">Automaticamente</option>
          <option value="AFTER_ANSWER">Após resposta</option>
        </select>
      </FormControlNode>

      <Handle
        id="left"
        type="target"
        position={Position.Left}
        style={{ left: -5, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="top"
        type="target"
        position={Position.Top}
        style={{ top: 3, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="bottom"
        type="source"
        position={Position.Bottom}
        style={{ bottom: 3, width: 12, height: 12, background: '#ff4081' }}
      />
      <Handle
        id="right"
        type="source"
        position={Position.Right}
        style={{ right: -5, width: 12, height: 12, background: '#ff4081' }}
      />
    </div>
  )
}

export default memo(SendTextNode)
