/* eslint-disable @typescript-eslint/no-use-before-define */
import Axios from 'axios'
import { Dispatch } from 'redux'

import { sleep } from 'util/index'
import { ActionResponse, Id } from 'util/types/index'
import { Channel, ChannelTypes } from './types'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'

export function createChannel (storeId: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Channel>> => {
    return Axios
      .post(`/store/${storeId}/channel`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ChannelTypes.CHANNEL_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar o canal')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateChannel (storeId: Id, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Channel>> => {
    return Axios
      .put(`/store/${storeId}/channel/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ChannelTypes.CHANNEL_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o canal')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function refreshChannel (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Channel>> => {
    return Axios
      .put(`/store/${storeId}/channel/${id}/refresh`)
      .then(res => res.data)
      .then(async res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ChannelTypes.CHANNEL_UPDATE, payload: res.data })
        }
        clearAlert(3000)(dispatch)
        await sleep(1000)
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o canal')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        await clearAlert(5000)(dispatch)

        return res
      })
  }
}

export function disconnectChannel (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Channel>> => {
    return Axios
      .put(`/store/${storeId}/channel/${id}/disconnect`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ChannelTypes.CHANNEL_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao desconectar o canal')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteChannel (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Channel>> => {
    return Axios
      .delete(`/store/${storeId}/channel/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ChannelTypes.CHANNEL_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o canal')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          await clearAlert(2500)(dispatch)
        }
        return res
      })
  }
}

export function getChannel (storeId: Id, id: Id, showAlert = true) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Channel>> => {
    return Axios
      .get(`/store/${storeId}/channel/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ChannelTypes.CHANNEL_GET, payload: res.data })

        if (res.meta) {
          dispatch({ type: ChannelTypes.CHANNEL_GET_CONNECTION_QR, payload: res.meta })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar o canal')
      })
      .then(async res => {
        if (res?.status !== 'success' && showAlert) {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getChannelByHash (storeId: Id, publicHash: string) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Channel>> => {
    return Axios
      .get(`/store/${storeId}/channel-by-hash/${publicHash}`)
      .then(res => res.data)
      .then(res => {
        if (res?.data) {
          dispatch({ type: ChannelTypes.CHANNEL_GET, payload: res.data })

          if (res.meta) {
            dispatch({ type: ChannelTypes.CHANNEL_GET_CONNECTION_QR, payload: res.meta })
          }
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao carregar o link')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getChannelList (storeId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Channel>> => {
    return Axios
      .get(`/store/${storeId}/channel`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ChannelTypes.CHANNEL_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}

export function startAllChannels () {
  return async (dispatch: Dispatch): Promise<ActionResponse<null>> => {
    return Axios
      .post('/channel/start-all')
      .then(res => res.data)
      .catch(error => {
        return handleError(error, 'Ocorreu um erro ao executar o comando')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function startUnconnectedChannels () {
  return async (dispatch: Dispatch): Promise<ActionResponse<null>> => {
    return Axios
      .post('/channel/start-unconnected')
      .then(res => res.data)
      .catch(error => {
        return handleError(error, 'Ocorreu um erro ao executar o comando')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}
