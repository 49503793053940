import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Handle, NodeProps, Position } from 'reactflow'

import { DeleteNode } from './delete-node'
import { ApplicationState } from 'AppReducer'
import { useOnChangeValue } from './helpers/on-change-value.hook'
import { FormControlNode, ItemWithIcon } from '../form-control.node'

interface Props extends NodeProps {
  data: {
    value: string
  }
}
const AttendantRouletteNode: React.FC<Props> = ({ id, data }) => {
  const { sectors } = useSelector((state: ApplicationState) => state.sectorReducer)

  const onChange = useOnChangeValue(id)

  return (
    <div className="node">
      <p className="custom-drag-handle pt px">
        <i className="fa fa-bullseye" /> Roleta de atendimento
        <DeleteNode id={id} />
      </p>

      <FormControlNode>
        <p className="custom-drag-handle" style={{ margin: 0 }}>
          Alterna entre atendentes sequencialmente
        </p>
      </FormControlNode>
      <FormControlNode pb>
        <select value={data.value} onChange={onChange}>
          <option value={undefined}>Todos os setores</option>
          {sectors.map((sector, i) => <option key={i} value={sector.id}>{sector.name}</option>)}
        </select>
      </FormControlNode>

      <Handle
        id="left"
        type="target"
        position={Position.Left}
        style={{ left: -5, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="top"
        type="target"
        position={Position.Top}
        style={{ top: 3, width: 12, height: 12, background: '#00ECBC' }}
      />
    </div>
  )
}

export default memo(AttendantRouletteNode)
