import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { ReactComponent as Tag } from 'assets/icons/tags.svg'

import { deleteTag, getTag } from '../redux/actions'
import { formatDateTime } from 'util/masks'
import { ApplicationState } from 'AppReducer'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { hasPermission } from 'util/services/AuthService'

const TagList: React.FC = () => {
  const dispatch = useDispatch()

  const [deleteId, setDeleteId] = useState<number>()

  const { tags } = useSelector((state: ApplicationState) => state.tagReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleFetch (id: number) {
    if (selected) {
      getTag(selected, id)(dispatch)
    }
  }

  function handleRemove (id: number): void {
    if (selected) {
      deleteTag(selected, id)(dispatch).then(res => {
        if (res.status === 'success') {
          setDeleteId(undefined)
        }
      })
    }
  }

  return (
    <div className="list border-radius">
      <div className="list-header">
        <div>Cor</div>
        <div>Nome</div>
        <div>Data</div>
        <div>Ativo</div>
        <div>Ações</div>
      </div>

      {
        tags.length === 0 && (
          <div className="list-row empty">
            <Tag width={120} height={120} style={{ padding: 24 }} />
            <div className="grid-span" style={{ height: 0 }}>Nenhuma etiqueta cadastrada</div>
          </div>
        )
      }

      {
        tags.map((tag, i) => {
          return (
            <div className="list-row" key={i}>
              <div data-content="Cor">
                <div style={{ height: 20, width: 20, backgroundColor: tag.color, borderRadius: '50%' }} />
              </div>
              <div data-content="Nome">{tag.name}</div>
              <div data-content="Data">{formatDateTime(tag.updatedAt)}</div>

              <div data-content="Ativo">
                <label className="switch">
                  <input
                    readOnly
                    type="checkbox"
                    disabled={!hasPermission('TAG:UPDATE')}
                    checked={tag.isActive}
                  />
                  <span className="slider round" />
                </label>
              </div>

              <div data-content="Ações" className="row buttons-list-row">
                <div className="row">
                  {
                    hasPermission('TAG:UPDATE') &&
                    <span className="button" onClick={() => handleFetch(tag.id)}>
                      <i className="fas fa-edit" /> Editar
                    </span>
                  }
                  {
                    hasPermission('TAG:DELETE') &&
                    <span className="button secondary" onClick={() => setDeleteId(tag.id)}>
                      <i className="fas fa-trash" style={{ margin: 0 }} /> Remover
                    </span>
                  }
                </div>
              </div>
            </div>
          )
        })
      }

      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </div>
  )
}

export default TagList
