import { Contact } from 'panel/person/redux/types/contact'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
 * Data Types
 */

export interface Group {
  id: number,
  name: string
  description: string,
  contacts: Partial<Contact>[],
  createdAt: string,
  updatedAt?: string
}

/**
 * State Type
 */

export interface GroupState {
  readonly group?: Group
  readonly groups: Group[]
}

/**
 * Action Enum Types
 */

export enum GroupTypes {
  GROUP_RESET = 'GROUP/RESET',
  GROUP_CREATE = 'GROUP/CREATE',
  GROUP_UPDATE = 'GROUP/UPDATE',
  GROUP_DELETE = 'GROUP/DELETE',
  GROUP_GET = 'GROUP/GET',
  GROUP_GET_LIST = 'GROUP/GET/LIST'
}

/**
 * Action Types
 */

interface ResetAction {
  type: typeof GroupTypes.GROUP_RESET
}

interface CreateAction {
  type: typeof GroupTypes.GROUP_CREATE
  payload: Group
}

interface UpdateAction {
  type: typeof GroupTypes.GROUP_UPDATE,
  payload: Group
}

interface DeleteAction {
  type: typeof GroupTypes.GROUP_DELETE,
  payload: number
}

interface GetAction {
  type: typeof GroupTypes.GROUP_GET,
  payload: Group
}

interface GetListAction {
  type: typeof GroupTypes.GROUP_GET_LIST,
  payload: Group[]
}

export type GroupActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction
)
