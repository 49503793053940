/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Person, User } from 'panel/user/redux/types'
import { ApplicationState } from 'AppReducer'
import { passwordValidate } from 'util/services/Validator'
import { maskCNPJ, maskCPF, maskDate, maskPhone } from 'util/masks'
import { createUser, getUser, updateUser } from 'panel/user/redux/actions'

import Upload, { UploadRef } from 'util/modules/Upload'
import RolePermissions from './RolePermissions'
import UsernameEmailVerify from './UsernameEmailVerify'
import RoleExtraPermissions from './RoleExtraPermissions'
import RemovePicture from 'panel/user/profile/components/RemovePicture'

const initialState = {
  isActive: true,
  needUpdatePassword: true,
  code: '',
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  birthday: '',
  personType: 'STANDARD',
  maritalStatus: '',
  docType: 'CPF',
  docNumber: '',
  gender: 'O',
  phone: '',
  password: '',
  _password: '',
  roles: ['ROLE_USER'],
  permissions: [] as string[]
}

const UserForm: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const uploadRef = useRef<UploadRef>(null)

  const { id } = useParams<{ id: string }>()
  const [form, setForm] = useState(initialState)
  const [picture, setPicture] = useState<File>()

  const { user } = useSelector((state: ApplicationState) => state.userReducer)

  function handleSubmit (e: React.FormEvent): void {
    e.preventDefault()

    const formData = new FormData()
    if (picture) {
      formData.set('picture', picture)
    }

    Object.entries(form).forEach(([key, value]) => {
      if (value) {
        if (key === 'birthday') {
          formData.set(key, String(value).split('/').reverse().join('-'))
        } else {
          if (
            key !== 'addresses' &&
            key !== 'contacts' &&
            key !== 'documents' &&
            key !== 'picture'
          ) {
            if (key === 'roles' && Array.isArray(value)) {
              value.map((role: string) => formData.append('roles[]', role))
            } else if (typeof value === 'boolean') {
              formData.set(key, value ? '1' : '0')
            } else if (value) {
              formData.set(key, String(value))
            }
          }
        }
      }
    })

    // Handle Password
    if (!id && !form.password) throw new Error('Senha não informada')
    if (form.password && !validation.isValid) { throw new Error(validation.warnings[0] || 'Senha Inválida') }
    if (form.password) {
      formData.set('password', form.password)
    }

    if (id) {
      updateUser(id, formData)(dispatch)
    } else {
      createUser(formData)(dispatch).then((res) => {
        if (res?.status === 'success') {
          history.push('/panel/user')
          handleClear()
        }
      })
    }
  }

  function handleClear (): void {
    if (uploadRef.current) {
      uploadRef.current.clear()
    }
    setForm(initialState)
    history.push('/panel/user')
  }

  function handleRoles (role: string): void {
    const index = form.roles.findIndex((item) => item === role)
    if (index > -1) {
      setForm((form) => ({
        ...form,
        roles: [...form.roles.slice(0, index), ...form.roles.slice(index + 1)]
      }))
    } else {
      setForm((form) => ({
        ...form,
        roles: [...form.roles, role]
      }))
    }
  }

  function handlePermissions (role: string): void {
    const index = form.permissions.findIndex((item) => item === role)
    if (index > -1) {
      setForm((form) => ({
        ...form,
        permissions: [
          ...form.permissions.slice(0, index),
          ...form.permissions.slice(index + 1)
        ]
      }))
    } else {
      setForm((form) => ({
        ...form,
        permissions: [...form.permissions, role]
      }))
    }
  }

  useEffect(() => {
    if (uploadRef.current) {
      uploadRef.current.clear()
      if (id) {
        getUser(parseInt(id))(dispatch)
      }
    }
  }, [id, uploadRef, dispatch])

  useEffect(() => {
    const person = user?.person
    if (user && person) {
      setForm({
        isActive: user.isActive,
        needUpdatePassword: user.needUpdatePassword,
        code: person.code || '',
        firstName: person.firstName || '',
        lastName: person.lastName || '',
        email: person.email || '',
        username: user.username || '',
        birthday: person.birthday ? person.birthday.split('-').reverse().join('/') : '',
        personType: person.personType || 'STANDARD',
        docType: person.docType || 'CPF',
        docNumber: person.docNumber || '',
        gender: person.gender || 'O',
        maritalStatus: person.maritalStatus || '',
        phone: person.phone || '',
        password: '',
        _password: '',
        roles: user.roles || ['ROLE_USER'],
        permissions: user.permissions || []
      })

      if (person.picture) {
        uploadRef.current?.setPreviewImage(person.picture)
      } else {
        uploadRef.current?.clear()
      }
    }
  }, [user])

  const validation = passwordValidate(form.password, form._password)

  return (
    <form onSubmit={handleSubmit} className="row align-start">
      <div className="row panel margin-top-32 mobile-responsive">
        <h2 className="col-12 margin-bottom-16">Dados Básicos</h2>
        <div className="row" style={{ width: 179, height: 190 }}>
          <div className="row" style={{ width: 179, height: 179 }}>
            <Upload
              ref={uploadRef}
              onChange={setPicture}
              placeholder="Selecionar imagem para perfil"
            />
          </div>
          {!!user?.person?.picture && <RemovePicture type="user" id={user.id} />}
        </div>
        <div className="row grow padding-left-16" style={{ marginTop: -16 }}>
          <div className="row align-end">
            <div className="form-control col-6">
              <label>Primeiro Nome</label>
              <input
                type="text"
                value={form.firstName}
                onChange={(e): void =>
                  setForm({ ...form, firstName: e.target.value })
                }
                required
              />
            </div>
            <div className="form-control col-6">
              <label>Sobrenome</label>
              <input
                type="text"
                value={form.lastName}
                onChange={(e): void =>
                  setForm({ ...form, lastName: e.target.value })
                }
                required
              />
            </div>
            <div className="form-control col-4">
              <label>E-mail</label>
              {(!user || form.email !== user?.person?.email) && (
                <UsernameEmailVerify type="email" value={form.email} />
              )}
              <input
                type="email"
                value={form.email}
                onChange={(e): void =>
                  setForm({ ...form, email: e.target.value })
                }
                required
              />
            </div>
            <div className="form-control col-2">
              <label>Código</label>
              <input
                type="text"
                value={form.code}
                onChange={(e): void =>
                  setForm({ ...form, code: e.target.value })
                }
              />
            </div>
            <div className="form-control col-3">
              <label>Ativo?</label>
              <select
                value={form.isActive ? 1 : 0}
                onChange={(e): void =>
                  setForm({ ...form, isActive: parseInt(e.target.value) === 1 })
                }
                required
              >
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>
            <div className="form-control col-3">
              <label className="label-responsive">Atualizar Senha?</label>
              <select
                value={form.needUpdatePassword ? 1 : 0}
                onChange={(e): void =>
                  setForm({
                    ...form,
                    needUpdatePassword: parseInt(e.target.value) === 1
                  })
                }
                required
              >
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="row panel margin-top-16">
        <h2 className="col-12 margin-bottom-16">Segurança</h2>
        <div className="form-control col-4">
          <label>Nome de Usuário</label>
          {(!user || form.username !== user?.username) && (
            <UsernameEmailVerify type="username" value={form.username} />
          )}
          <input
            type="text"
            value={form.username}
            placeholder="Opcional"
            autoComplete="new-password"
            onChange={(e): void =>
              setForm({ ...form, username: e.target.value })
            }
          />
        </div>
        <div className="form-control col-4">
          <label>Senha</label>
          <input
            type="password"
            value={form.password}
            autoComplete="new-password"
            onChange={(e): void =>
              setForm({ ...form, password: e.target.value })
            }
            required={!id}
          />
        </div>
        <div className="form-control col-4">
          <label>Confirmar Senha</label>
          <input
            type="password"
            value={form._password}
            autoComplete="new-password"
            onChange={(e): void =>
              setForm({ ...form, _password: e.target.value })
            }
            required={!id}
          />
        </div>
        <p className="col-12 text-center">
          {validation.warnings?.[0] || <>&nbsp;</>}
        </p>

        <RolePermissions onSelect={handleRoles} roles={form.roles} />
        <RoleExtraPermissions
          onSelect={handlePermissions}
          permissions={form.permissions}
        />
      </div>

      <div className="row panel margin-top-16 align-end">
        <h2 className="col-12 margin-bottom-16">Documentação</h2>
        <div className="form-control col-4">
          <label>Tipo de Cadastro</label>
          <select
            value={form.personType}
            onChange={(e): void =>
              setForm({ ...form, personType: e.target.value as any })
            }
          >
            <option></option>
            <option value="STANDARD">Pessoa Física</option>
            <option value="BUSINESS">Pessoa Jurídica</option>
          </select>
        </div>
        <div className="form-control col-4">
          <label>Documento Principal</label>
          <select
            value={form.docType}
            onChange={(e): void =>
              setForm({ ...form, docType: e.target.value as any })
            }
          >
            <option value="CPF">CPF</option>
            <option value="CNPJ">CNPJ</option>
            <option value="CNH">CNH</option>
            <option value="RG">RG</option>
            <option value="Passport">Passaporte</option>
          </select>
        </div>
        <div className="form-control col-4">
          <label className="label-responsive">
            Número do Documento Principal
          </label>
          <input
            type="text"
            value={form.docNumber}
            onChange={({ target: { value } }): void =>
              setForm({
                ...form,
                docNumber:
                  form.docType === 'CPF'
                    ? maskCPF(value)
                    : form.docType === 'CNPJ'
                      ? maskCNPJ(value)
                      : value
              })
            }
          />
        </div>
      </div>

      <div className="row panel margin-top-16 align-end">
        <h2 className="col-12 margin-bottom-16">Dados Complementares</h2>
        <div className="form-control col-3">
          <label>Telefone</label>
          <input
            type="text"
            value={form.phone}
            onChange={(e): void =>
              setForm({ ...form, phone: maskPhone(e.target.value) })
            }
          />
        </div>
        <div className="form-control col-3">
          <label className="label-responsive">Data de Nascimento</label>
          <input
            type="text"
            value={form.birthday}
            onChange={(e): void => setForm({ ...form, birthday: maskDate(e.target.value, '/') })}
          />
        </div>
        <div className="form-control col-3">
          <label>Gênero</label>
          <select
            value={form.gender}
            onChange={(e): void =>
              setForm({ ...form, gender: e.target.value as any })
            }
          >
            <option></option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
            <option value="O">Outro</option>
          </select>
        </div>
        <div className="form-control col-3">
          <label>Estado Civil</label>
          <select
            value={form.maritalStatus}
            onChange={(e): void =>
              setForm({ ...form, maritalStatus: e.target.value as any })
            }
          >
            <option></option>
            <option value="single">Solteiro(a)</option>
            <option value="married">Casado(a)</option>
            <option value="divorced">Divorciado(a)</option>
            <option value="widower">Viúvo(a)</option>
            <option value="commonLawMarriage">União Estável</option>
          </select>
        </div>
      </div>

      <div className="row margin-top-16 justify-end responsive-btn">
        <span
          onClick={handleClear}
          className="button secondary margin-right-8"
        /*   style={{ width: 300 }} */
        >
          Limpar
        </span>
        <button className="primary" /* style={{ width: 300 }} */>
          {id ? 'Atualizar Usuário' : 'Criar Usuário'}
        </button>
      </div>
    </form>
  )
}

export default UserForm
