import axios, { AxiosInstance } from 'axios'

import { HttpResponse } from './api.types'
import { getToken, isExpired, signOut } from './AuthService'

const createInstance = () => {
  const BASE_URL = process.env.REACT_APP_API_V2
  const client = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'X-TENANT-ID': localStorage.getItem('@App:Store') || ''
    },
    timeout: 30_000
  })

  // requests
  client.interceptors.request.use(
    async config => {
      const { token } = getToken()
      const storeId = localStorage.getItem('@App:Store')

      if (config?.headers) {
        if (token) config.headers.Authorization = `Bearer ${token}`
        if (storeId) config.headers['X-TENANT-ID'] = storeId
      }

      return config
    },
    error => {
      console.log('Axios interceptor request error', error)
      return error
    }
  )

  // responses
  client.interceptors.response.use(
    response => {
      if (response && response.data && response.data.code === 401) {
        console.log('RESPONSE 401')
        if (isExpired()) {
          signOut()
        }
      }

      return response
    },
    error => {
      if (error.response && error.response.status === 401) {
        console.log('ERROR 401')
        if (isExpired()) {
          signOut()
        }
      }
      return Promise.reject(error)
    }
  )

  return client
}

export class Api {
  static clientApi: AxiosInstance = createInstance()

  static async delete (path: string, params?: any): Promise<HttpResponse> {
    const response = await this.clientApi
      .delete(path, { params })
      .then(res => res.data)
      .catch(err => {
        const data = err?.response?.data
        const message = data?.reason || data?.message || 'Ocorreu um erro ao processar a solicitação'
        throw new Error(message, { cause: err.response?.data?.details })
      })

    return {
      data: response?.data,
      message: response?.message || 'Ocorreu um erro ao processar a solicitação',
      meta: response?.meta,
      reason: response?.reason,
      status: response?.status || 'Server Error',
      statusCode: response?.statusCode || 500
    }
  }

  static async get<Data = any, MetaData = any> (path: string, params?: any): Promise<HttpResponse<Data, MetaData>> {
    const response = await this.clientApi
      .get(path, { params })
      .then(res => res.data)
      .catch(err => {
        const data = err?.response?.data
        const message = data?.reason || data?.message || 'Ocorreu um erro ao processar a solicitação'
        throw new Error(message, { cause: err.response?.data?.details })
      })

    return {
      data: response?.data,
      message: response?.message || 'Ocorreu um erro ao processar a solicitação',
      meta: response?.meta,
      reason: response?.reason,
      status: response?.status || 'Server Error',
      statusCode: response?.statusCode || 500
    }
  }

  static getConfigs (body: any) {
    if (body instanceof FormData) {
      return {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: (formData: any) => formData
      }
    }
  }

  static async getFile<Data = any, MetaData = any> (path: string, params?: any): Promise<any> {
    const response = await this.clientApi.get(path, { params })

    return {
      data: response?.data,
      status: response?.status || 'Server Error'
    }
  }

  static async patch<Data = any, MetaData = any> (path: string, body: any = {}): Promise<HttpResponse<Data, MetaData>> {
    const response = await this.clientApi
      .patch(path, body, Api.getConfigs(body))
      .then(res => res.data)
      .catch(err => {
        const data = err?.response?.data
        const message = data?.reason || data?.message || 'Ocorreu um erro ao processar a solicitação'
        throw new Error(message, { cause: err.response?.data?.details })
      })

    return {
      data: response?.data,
      message: response?.message || 'Ocorreu um erro ao processar a solicitação',
      meta: response?.meta,
      reason: response?.reason,
      status: response?.status || 'Server Error',
      statusCode: response?.statusCode || 500
    }
  }

  static async post<Data = any, MetaData = any> (
    path: string,
    body: any = {},
    params?: any
  ): Promise<HttpResponse<Data, MetaData>> {
    const response = await this.clientApi
      .post(path, body, { ...(Api.getConfigs(body) || {}), params })
      .then(res => res.data)
      .catch(err => {
        const data = err?.response?.data
        const message = data?.reason || data?.message || 'Ocorreu um erro ao processar a solicitação'
        throw new Error(message, { cause: err.response?.data?.details })
      })

    return {
      data: response?.data,
      message: response?.message || 'Ocorreu um erro ao processar a solicitação',
      meta: response?.meta,
      reason: response?.reason,
      status: response?.status || 'Server Error',
      statusCode: response?.statusCode || 500
    }
  }

  static async put<Data = any, MetaData = any> (path: string, body: any = {}): Promise<HttpResponse<Data, MetaData>> {
    const response = await this.clientApi
      .put(path, body, Api.getConfigs(body))
      .then(res => res.data)
      .catch(err => {
        const data = err?.response?.data
        const message = data?.reason || data?.message || 'Ocorreu um erro ao processar a solicitação'
        throw new Error(message, { cause: err.response?.data?.details })
      })

    return {
      data: response?.data,
      message: response?.message || 'Ocorreu um erro ao processar a solicitação',
      meta: response?.meta,
      reason: response?.reason,
      status: response?.status || 'Server Error',
      statusCode: response?.statusCode || 500
    }
  }
}
