import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { ReactComponent as ServerSector } from 'assets/icons/server.svg'

import { deleteSector } from '../redux/actions'
import { formatDateTime } from 'util/masks'
import { ApplicationState } from 'AppReducer'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { hasPermission } from 'util/services/AuthService'

const SectorList: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteId, setDeleteId] = useState<number>()

  const { sectors } = useSelector((state: ApplicationState) => state.sectorReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleRemove (id: number): void {
    if (selected) {
      deleteSector(selected, id)(dispatch).then(res => {
        if (res.status === 'success') {
          setDeleteId(undefined)
          history.push('/panel/sector')
        }
      })
    }
  }

  return (
    <div className="list" style={{ marginTop: 0 }}>
      <div className="list-header">
        <div>Id</div>
        <div>Ativo</div>
        <div>Nome</div>
        <div>Criado</div>
        <div>Atualizado</div>
        <div>Ações</div>
      </div>

      {
        sectors.length === 0 &&
        <div className="list-row empty">
          <ServerSector width={120} height={120} style={{ padding: 24 }} />
          <div className="grid-span" style={{ height: 0 }}>Nenhum setor cadastrado</div>
        </div>
      }

      {
        sectors.map((sector, i) => {
          return (
            <div className="list-row" key={i}>
              <div data-content="Id" className="bold">#{ sector.id }</div>
              <div data-content="Ativo">
                <span className={`status ${sector.isActive ? 'success' : 'danger'}`}>
                  { sector.isActive ? 'Sim' : 'Não' }
                </span>
              </div>
              <div data-content="Nome">{ sector.name }</div>
              <div data-content="Criado">{ formatDateTime(sector.createdAt) }</div>
              <div data-content="Atualizado">{ formatDateTime(sector.updatedAt) }</div>
              <div data-content="Ações" className="row buttons-list-row">
                <div className="row">
                  {
                    hasPermission('SECTOR:UPDATE') &&
                    <NavLink to={`/panel/sector/${sector.id}/edit`} className="button">
                      <i className="fas fa-edit" /> Editar
                    </NavLink>
                  }
                  {
                    hasPermission('SECTOR:DELETE') &&
                    <span className="button secondary" onClick={() => setDeleteId(sector.id)}>
                      <i className="fas fa-trash-alt" /> &nbsp;Excluir
                    </span>
                  }
                </div>
              </div>
            </div>
          )
        })
      }

      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </div>
  )
}

export default SectorList
