import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { img } from 'assets/online'
import { getOverview } from './redux/actions'
import { ApplicationState } from 'AppReducer'

import './styles/Overview.scss'
import { ConversationCsv } from './components/conversation-csv'

const Overview: React.FC<{ storeId: number }> = ({ storeId }) => {
  const dispatch = useDispatch()

  const { overview: overviewDb } = useSelector((state: ApplicationState) => state.reportReducer)
  const overview = overviewDb[storeId]

  useEffect(() => {
    if (storeId) {
      getOverview(storeId)(dispatch)
    }
  }, [storeId, dispatch])

  if (!overview) return null

  return (
    <div className="row">
      <div className="row overview-item margin-top-16 company-info" style={{ background: 'transparent' }}>
        <h1 className="row justify-start">
          Atendimentos
          <ConversationCsv />
        </h1>
        <div className="row grid-2 margin-top-16">
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Tempo Médio Atendimento</p>
              <h1 style={{ fontWeight: 'normal' }}>
                {
                  ((): any => {
                    const { hours, minutes } = overview.time.conversation
                    return <>{ hours }<b>H</b> { minutes } <b>M</b></>
                  })()
                }
              </h1>
            </div>
            <i className="fa fa-check-double highlight-text fa-3x" />
          </div>
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Tempo Médio 1º Resposta</p>
              <h1 style={{ fontWeight: 'normal' }}>
                {
                  ((): any => {
                    const { hours, minutes } = overview.time.attendance
                    return <>{ hours }<b>H</b> { minutes } <b>M</b></>
                  })()
                }
              </h1>
            </div>
            <i className="fa fa-check-double highlight-text fa-3x" />
          </div>
        </div>

        <div className="row margin-top-16">
          <h1>Atendentes</h1>
          {
            overview.attendants.map((attendant, i) => {
              return (
                <div className="panel attendant margin-top-16" key={i}>
                  <div className='row justify-start'>
                    <img src={attendant.picture || img['default-user']} />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="margin-left-16"
                      href={`/panel/attendant/${attendant.id}`}
                    >
                      <h2 className="row justify-start">Atendente</h2>
                      <p>{attendant.name}</p>
                    </a>
                  </div>
                  <div className='row justify-start'>
                    <i className="fa fa-user btn" />
                    <div className="grow margin-left-16">
                      <h1 className="row justify-start">{attendant.attendance}</h1>
                      <p className="row justify-start">Atendimentos</p>
                    </div>
                  </div>
                  <div className='row justify-start'>
                    <i className="fa fa-layer-group btn" />

                    <div className="margin-left-16 company-info-wrapper">
                      <p style={{ fontSize: 18, marginBottom: 8 }}>Setores</p>
                      {
                        attendant.sectors.map((sector, ii) => {
                          return (
                            <a
                              key={ii}
                              target="_blank"
                              rel="noreferrer"
                              href={`/panel/sector/${sector.id}`}
                              className="sector"
                            >
                              {sector.name}
                            </a>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="row margin-top-16">
          <h1>Setores</h1>
          <div className="row margin-top-16 justify-start">
            {
              overview.sectors.map((sector, i) => {
                return (
                  <a
                    key={i}
                    target="_blank"
                    rel="noreferrer"
                    style={{ height: 150, width: 300 }}
                    href={`/panel/sector/${sector.id}`}
                    className="row panel align-start card margin-8"
                  >
                    <div className="flex">
                      <p>{sector.name}</p>
                      <h1>{sector.attendants}</h1>
                      <p>Atendentes</p>
                    </div>
                    <i className="fa fa-server highlight-text fa-3x" />
                  </a>
                )
              })
            }
          </div>
        </div>
      </div>

    </div>
  )
}

export default Overview
