import React, { useEffect, useState, FormEvent } from 'react'
import { ApplicationState } from 'AppReducer'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { getUserList } from 'panel/user/redux/actions'

import Pagination from 'util/modules/Pagination'
import { formatDateTime } from 'util/masks'
import { scrollTop } from 'index'

const List: React.FC = () => {
  const dispatch = useDispatch()
  const {
    users: { items: users, pagination }, rolePermissions
  } = useSelector((state: ApplicationState) => state.userReducer)

  const { location: { search } } = useHistory()

  const [filter, setFilter] = useState({
    name: '',
    email: '',
    username: '',
    roles: '',
    quantity: ''
  })

  function handleFilter (e: FormEvent): void {
    e.preventDefault()

    const filters: { [x: string]: string } = {}

    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        const fields = { email: 'person.email', name: 'person.name' } as any
        filters[`filter_like[${fields[key] || key}]`] = value
      }
    })

    const query = {
      page: 1,
      fields: 'id,person.name,person.email,username,roles,createdAt',
      limit: filter.quantity || process.env.REACT_APP_USER_LIST_LIMIT || 20,
      ...filters
    }

    getUserList(query)(dispatch)
  }

  useEffect(() => {
    if (search) {
      const query = Object.fromEntries(new URLSearchParams(search))
      getUserList(query)(dispatch)
    } else {
      const filter = {
        page: 1,
        limit: process.env.REACT_APP_USER_LIST_LIMIT || 20,
        fields: 'id,person.name,person.email,username,roles,createdAt'
      }
      getUserList(filter)(dispatch)
    }
  }, [search, dispatch])
  return (
    <>
      <form
        className="row panel margin-top-32 role-userlist align-end"
        style={{ padding: 16 }} onSubmit={handleFilter}>
        <div className="row grow">
          <div
            className="row role-inputs align-end"
          >
            <div className="form-control" style={{ marginTop: -8 }}>
              <label>Nome</label>
              <input
                type="text"
                value={filter.name}
                onChange={(e): void => setFilter({ ...filter, name: e.target.value })}
              />
            </div>
            <div className="form-control" style={{ marginTop: -8 }}>
              <label>E-mail</label>
              <input
                type="text"
                value={filter.email}
                onChange={(e): void => setFilter({ ...filter, email: e.target.value })}
              />
            </div>
            <div className="form-control" style={{ marginTop: -8 }}>
              <label className="label-responsive">Nome de Usuário</label>
              <input
                type="text"
                value={filter.username}
                onChange={(e): void => setFilter({ ...filter, username: e.target.value })}
              />
            </div>
            <div className="form-control" style={{ marginTop: -8 }}>
              <label>Permissão</label>
              <select
                value={filter.roles}
                onChange={(e): void => setFilter({ ...filter, roles: e.target.value })}
              >
                <option></option>
                {
                  rolePermissions
                    .sort((a, b) => {
                      return a.role.description > b.role.description
                        ? 1
                        : (
                          a.role.description < b.role.description
                            ? -1
                            : 0
                        )
                    })
                    .map((grant, i) => <option key={i} value={grant.role.name}>{ grant.role.description }</option>)
                }
              </select>
            </div>
            <div className="form-control label-responsive" style={{ marginTop: -8 }}>
              <label className="quantity">Quantidade</label>
              <select
                value={filter.quantity}
                onChange={(e): void => setFilter({ ...filter, quantity: e.target.value })}
              >
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>40</option>
                <option>50</option>
                <option>100</option>
                <option>200</option>
                <option>400</option>
                <option>800</option>
                <option>1000</option>
              </select>
            </div>
          </div>
        </div>
        <button className="button primary margin-top-24">
          <i className="fa fa-filter" style={{ margin: 0, fontSize: 20 }} />
        </button>
      </form>

      <div className="list">
        {/* Header desk */}
        <div className="list-header" style={{ gridTemplateColumns: '100px 1fr 300px 200px 180px 150px' }}>
          <div style={{ width: 50 }}>id</div>
          <div className="flex">Nome</div>
          <div className="flex">E-mail</div>
          <div className="flex">Usuário</div>
          <div className="flex">Criação</div>
          <div style={{ width: 65 }}></div>
        </div>
        {
          users.map((user, i) => (
            <div className="list-row" key={i} style={{ gridTemplateColumns: '100px 1fr 300px 200px 180px 150px' }}>
              <div style={{ width: 50 }}>{user.id}</div>
              <div className="flex role-flex">{user.person?.name}</div>
              <div className="flex role-flex">{user.person?.email}</div>
              <div className="flex role-flex">{user.username}</div>
              <div className="flex role-flex">{formatDateTime(user.createdAt)}</div>

              <Link to={`/panel/user/${user.id}`} className="button primary" onClick={scrollTop}>
                <i className="fa fa-edit no-margin"/>
              </Link>
            </div>
          ))
        }
        {
          users.length === 0 &&
          <div className="list-row"><div className="col-12 text-center">Nenhum usuário encontrado</div></div>
        }
      </div>

      <Pagination pagination={pagination} />
    </>
  )
}

export default List
