import React, { useState } from 'react'

import ProfileTab, { Tab } from './ProfileTab'

import ProfileData from './partials/ProfileData'
import ProfilePassword from './partials/ProfilePassword'
import ProfileComplementary from './partials/ProfileComplementary'
import { ModalHeader } from 'util/modules/Modal'

const ProfileForm: React.FC<{ isOpen: boolean, onClose(): void }> = ({ isOpen, onClose }) => {
  const [unsavedTab, setUnsavedTab] = useState<Tab>()
  const [selectedTab, setSelectedTab] = useState<Tab>('DATA')

  return (
    <div className="panel no-padding profile-container">
      <ModalHeader title="Configuração do Perfil" icon="cog" onClose={onClose} />
      <div className="padding-24">
        <ProfileTab onSelect={setSelectedTab} active={selectedTab} unsaved={unsavedTab} />

        { isOpen && selectedTab === 'DATA' && <ProfileData onUnsaved={setUnsavedTab} /> }
        { selectedTab === 'COMPLEMENTARY' && <ProfileComplementary onUnsaved={setUnsavedTab} /> }
        { selectedTab === 'PASSWORD' && <ProfilePassword onUnsaved={setUnsavedTab} /> }
      </div>
    </div>
  )
}

export default ProfileForm
