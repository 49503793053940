import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Tab } from '../AttendantTab'
import { ApplicationState } from 'AppReducer'
import { maskCPF, maskCNPJ, maskDate, maskPhone } from 'util/masks'
import { AttendantTypes } from 'panel/attendant/redux/types'
import { updateAttendant } from 'panel/attendant/redux/actions'

const initialState = { isActive: true, birthday: '', docType: 'CPF', docNumber: '', gender: 'O', phone: '' }
const AttendantComplementary: React.FC<{ onUnsaved: (tab?: Tab) => void }> = ({ onUnsaved }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [form, setForm] = useState(initialState)
  const [fetching, setFetching] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { attendant } = useSelector((state: ApplicationState) => state.attendantReducer)

  const handleClear = useCallback(() => {
    setForm(initialState)
    history.push('/panel/attendant')
    dispatch({ type: AttendantTypes.ATTENDANT_GET, payload: undefined })
  }, [history])

  function getSavedState () {
    const user = attendant?.user
    const person = user?.person

    if (id && user && person) {
      return {
        phone: person.phone || '',
        gender: person.gender || '',
        docType: person.docType || 'CPF',
        isActive: !!user.isActive,
        birthday: person.birthday ? person.birthday.split('-').reverse().join('/') : '',
        docNumber: person.docNumber || ''
      }
    }

    return initialState
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (!selected || !id) return undefined

    setFetching(true)
    await updateAttendant(selected, id, {
      ...form,
      birthday: form.birthday ? form.birthday.split('/').reverse().join('-') : undefined
    })(dispatch)
    setFetching(false)
  }

  useEffect(() => {
    setForm(getSavedState())
  }, [attendant])

  // Handle Unsaved form
  useEffect(() => {
    const isUnsaved = JSON.stringify(form) !== JSON.stringify(getSavedState())
    onUnsaved(isUnsaved ? 'COMPLEMENTARY' : undefined)
  }, [form, attendant, initialState])

  return (
    <form className="row margin-top-16" onSubmit={handleSubmit}>
      <div className="row">
        <div className="row col-6 justify-start">
          <label>Ativo?</label>
          <label className="checkbox margin-right-16" style={{ width: 'auto' }}>
            <input
              type="checkbox"
              checked={form.isActive}
              onClick={() => setForm({ ...form, isActive: true })}
            />
            <div className="checkmark" /> Sim
          </label>
          <label className="checkbox margin-right-16" style={{ width: 'auto' }}>
            <input
              type="checkbox"
              checked={!form.isActive}
              onClick={() => setForm({ ...form, isActive: false })}
            />
            <div className="checkmark" /> Não
          </label>
        </div>
        <div className="row col-6 justify-start">
          <label>Tipo de Cadastro</label>
          <label className="checkbox margin-right-16" style={{ width: 'auto' }}>
            <input
              type="checkbox"
              checked={form.docType === 'CPF'}
              onClick={() => setForm({ ...form, docType: 'CPF' })}
            />
            <div className="checkmark" /> Pessoa Física
          </label>
          <label className="checkbox margin-right-16" style={{ width: 'auto' }}>
            <input
              type="checkbox"
              checked={form.docType === 'CNPJ'}
              onClick={() => setForm({ ...form, docType: 'CNPJ' })}
            />
            <div className="checkmark" /> Pessoa Jurídica
          </label>
        </div>
      </div>

      <div className="form-control col-6">
        <label>{form.docType}</label>
        <input
          type="text"
          value={form.docNumber}
          onChange={({ target: { value } }): void => {
            setForm({ ...form, docNumber: form.docType === 'CPF' ? maskCPF(value) : maskCNPJ(value) })
          }}
        />
      </div>

      <div className="form-control col-6">
        <label>Data Nascimento</label>
        <input
          type="text"
          value={form.birthday}
          onChange={(e): void => setForm({ ...form, birthday: maskDate(e.target.value, '/') })}
        />
      </div>

      <div className="form-control col-6">
        <label>Gênero</label>
        <select value={form.gender} onChange={(e): void => setForm({ ...form, gender: e.target.value })}>
          <option value="M">Masculino</option>
          <option value="F">Feminino</option>
          <option value="O">Outro</option>
        </select>
      </div>
      <div className="form-control col-6">
        <label>Número Telefone</label>
        <input
          type="text"
          value={form.phone}
          onChange={(e): void => setForm({ ...form, phone: maskPhone(e.target.value) })}
        />
      </div>

      <div className="row margin-top-16 form-buttons">
        <span className="secondary button" onClick={handleClear}>Limpar</span>
        <button className="gradient button" disabled={fetching}>Salvar</button>
      </div>
    </form>
  )
}

export default AttendantComplementary
