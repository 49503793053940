import React from 'react'

import { Message } from 'panel/conversation/redux/types'
import { LocationPreview } from './LocationPreview'

export const Location: React.FC<{ message: Partial<Message> }> = ({ message }) => {
  if (!message.attachment) return null
  if (!message.attachment.latitude) return null
  if (!message.attachment.longitude) return null

  const url = new URL('https://maps.google.com')
  url.pathname = '/maps'
  url.searchParams.set('q', `${message.attachment.latitude},${message.attachment.longitude}`)
  url.searchParams.set('z', '17')
  url.searchParams.set('hl', 'pt-BR')

  return (
    <>
      <LocationPreview latitude={message.attachment.latitude} longitude={message.attachment.longitude} />
      <a
        target='__blank'
        href={url.toString()}
        className="row justify-start"
        style={{ maxWidth: 270, color: '#069', fontWeight: 'bold', marginTop: 8 }}
      >
        {message.message}
      </a>
    </>
  )
}
