import React, { useState } from 'react'
import './styles/Select.scss'

import { normalizeAccents } from 'util/index'

export type SelectItensOpts = { key: any, value: any }

type SearchOpts = {
  itens: SelectItensOpts[],
  selected?: SelectItensOpts['value'],
  required?: boolean,
  disabled?: boolean,
  placeholder?: string,
  className?: string,
  onSelect: (item: SelectItensOpts) => void
}
const Select: React.FC<SearchOpts> = ({
  selected = '', required = false, disabled = false, placeholder, className, onSelect, itens
}) => {
  const [isFocused, setFocus] = useState(false)
  const [search, setSearch] = useState('')

  function handleClick (item: SelectItensOpts): void {
    onSelect(item)
    setSearch('')
    setFocus(false)
  }

  function sanitize (str: string): string {
    return normalizeAccents(str).toLocaleLowerCase()
  }

  const list = itens.filter(item => !search || sanitize(String(item.value)).search(sanitize(search)) > -1)

  const findSelected = itens.find(item => String(item.key) === String(selected))

  return (
    <div className="row select">
      <input
        type="text"
        className={className}
        value={search || findSelected?.value || selected || ''}
        autoComplete="new-password"
        disabled={disabled}
        required={required}
        onFocus={(): void => setFocus(true)}
        placeholder={placeholder}
        onBlur={(): void => {
          setTimeout(() => {
            setFocus(false)
            if (!findSelected && !selected) {
              setSearch('')
            }
          }, 500)
        }}
        onChange={(e): void => {
          setSearch(e.target.value)
          onSelect({ key: '', value: '' })
        }}
      />
      {
        !!(list.length && isFocused) &&
        <div className="search-box">
          {
            list.map(item => {
              return (
                <div
                  key={item.key}
                  onClick={(): void => handleClick(item)}
                  className={`search-item ${selected === item.key ? 'active' : ''}`}
                >
                  {item.value}
                </div>
              )
            })
          }
        </div>
      }
      {
        !list.length && search &&
        <div className="search-box">
          <div className='search-item' >
            Nenhum item encontrado
          </div>
        </div>
      }
    </div>
  )
}

export default Select
