import { AlertState, AlertTypes, AlertActionTypes } from './types'

const initialState: AlertState = {
  alert: {
    isOpen: false,
    code: 100,
    title: '',
    message: ''
  }
}

export default (state = initialState, action: AlertActionTypes): AlertState => {
  switch (action.type) {
    case AlertTypes.CREATE_ALERT:
      return { ...state, alert: { ...action.payload, isOpen: true } }
    case AlertTypes.CLOSE_ALERT:
      return { ...state, alert: { ...state.alert, isOpen: false } }
    case AlertTypes.CLEAR_ALERT:
      return { ...state, alert: initialState.alert }
    default:
      return state
  }
}
