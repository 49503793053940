import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import './styles/ChatBot.scss'

import { getCommand } from 'panel/command/redux/actions'
import { ApplicationState } from 'AppReducer'

import Modal from 'util/modules/Modal'
import BotForm from './components/BotForm'
import BotList from './components/BotList'
import BotOptions from './components/BotOptions'
import CommandBot from 'panel/command/CommandBot'
import CommandBotExecution from 'panel/command/CommandBotExecution'
import { hasPermission } from 'util/services/AuthService'
import axios from 'axios'
import { getSellerClientList } from 'panel/store/redux/actions'

const ChatBot: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isFormOpen, setFormOpen] = useState(false)
  const [isConfigOpen, setConfigOpen] = useState(false)
  const [isCommandOpen, setCommandOpen] = useState(false)
  const [isCommandExecution, setCommandExecution] = useState(false)

  const { action } = useParams<{ [x: string]: string }>()
  const { selected, stores } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleExecution (commandId: number): void {
    if (selected && commandId) {
      getCommand(selected, commandId)(dispatch)
      setCommandExecution(true)
    }
  }

  function handleCloseModals () {
    setFormOpen(false)
    setConfigOpen(false)
    setCommandOpen(false)
    setCommandExecution(false)
    history.push('/panel/chatbot')
  }

  function handleToggleBotVersion () {
    axios.post(`/store/${selected}/toggle-bot-version`).then((res) => {
      if (res.data.status === 'success') {
        getSellerClientList()(dispatch)
      }
    })
  }

  const store = stores.find((store) => store.id === selected)
  useEffect(() => {
    if (!store) return
    if (store.useNewBotVersion) history.push('/panel/chat-bot')
  }, [store])

  useEffect(() => {
    if (action === 'edit') setFormOpen(true)
    if (action === 'config') setConfigOpen(true)
  }, [action])

  return (
    <div className="chatbot row">
      <div className="row chat-bot-options">
        <div className="row grow align-center justify-start" style={{ flexDirection: 'row' }}>
          <p style={{ fontSize: 18, width: 'auto' }}>Bots Cadastrados</p>
          <div
            style={{ width: 150 }}
            onClick={handleToggleBotVersion}
            className="button radius gradient margin-left-16"
          >
            <div className="new-badge">Beta</div>
            Usar nova versão
          </div>
        </div>
        <div className="row flex justify-end">
          {
            (
              hasPermission('CHAT_BOT:BACKUP_EXPORT') ||
              hasPermission('CHAT_BOT:BACKUP_IMPORT') ||
              hasPermission('CHAT_BOT:BACKUP_EXECUTE') ||
              hasPermission('CHAT_BOT:BACKUP_DELETE') ||
              hasPermission('CHAT_BOT:BACKUP_GET') ||
              hasPermission('CHAT_BOT:BACKUP_LIST')
            ) &&
            <span className="button white radius margin-right-8" onClick={() => setCommandOpen(true)}>
              <i className="fas fa-database" /> Backup/Recuperação
            </span>
          }
          {
            hasPermission('CHAT_BOT:CREATE') &&
            <button className="gradient radius button" onClick={() => setFormOpen(true)}>
              Cadastrar
            </button>
          }
        </div>
      </div>

      { hasPermission('CHAT_BOT:LIST') && <BotList /> }

      <Modal isOpen={isFormOpen} onClose={handleCloseModals}>
        <BotForm onClose={handleCloseModals} />
      </Modal>
      <Modal isOpen={isConfigOpen} onClose={handleCloseModals} >
        <BotOptions onClose={handleCloseModals}/>
      </Modal>

      {/* Command */}
      <Modal isOpen={isCommandOpen} onClose={handleCloseModals} >
        <CommandBot onClose={handleCloseModals} onOpenExecution={handleExecution} />
      </Modal>
      <Modal isOpen={isCommandExecution} onClose={handleCloseModals} >
        <CommandBotExecution onClose={handleCloseModals} />
      </Modal>
    </div>
  )
}

export default ChatBot
