/* eslint-disable @typescript-eslint/no-use-before-define */
import Axios from 'axios'
import { Dispatch } from 'redux'

import { Client, ClientTypes } from './types'
import { ActionResponse, Id } from 'util/types/index'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'

export function createClient (data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Client>> => {
    return Axios
      .post('/client', data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ClientTypes.CLIENT_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar o cliente')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateClient (id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Client>> => {
    return Axios
      .put(`/client/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ClientTypes.CLIENT_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o cliente')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getGlobalLib () {
  return async (dispatch: Dispatch): Promise<ActionResponse<Client>> => {
    return Axios
      .get('/client/lib')
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data?.lib) {
          dispatch({ type: ClientTypes.SET_GLOBAL_LIB, payload: res.data.lib })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o cliente')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function changeGlobalLib (lib: string) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Client>> => {
    return Axios
      .put('/client/lib', { lib })
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success') {
          getGlobalLib()(dispatch)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o cliente')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getGlobalServer () {
  return async (dispatch: Dispatch): Promise<ActionResponse<Client>> => {
    return Axios
      .get('/client/server')
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data?.server) {
          dispatch({ type: ClientTypes.SET_GLOBAL_SERVER, payload: res.data.server })
        }
        return res
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function changeGlobalServer (server: string) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Client>> => {
    return Axios
      .put('/client/server', { server })
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success') {
          getGlobalServer()(dispatch)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o cliente')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteClient (id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Client>> => {
    return Axios
      .delete(`/client/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ClientTypes.CLIENT_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o cliente')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getClientLogged () {
  return async (dispatch: Dispatch): Promise<ActionResponse<Client>> => {
    return Axios
      .get('/client/logged')
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ClientTypes.CLIENT_GET_LOGGED, payload: res.data })
        return res
      })
  }
}

export function getClient (id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Client>> => {
    return Axios
      .get(`/client/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ClientTypes.CLIENT_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar o cliente')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getClientList (params: any = {}) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Client>> => {
    return Axios
      .get('/client', { params })
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ClientTypes.CLIENT_GET_LIST, payload: res.data || [], pagination: res.pagination })
        return res
      })
  }
}
