import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import { addAttendant, delAttendant } from 'panel/sector/redux/actions'

const initialState = { isActive: true, birthday: '', docType: 'CPF', docNumber: '', gender: 'O', phone: '' }
const AttendantSector: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [form, setForm] = useState(initialState)
  const [fetching, setFetching] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { sectors } = useSelector((state: ApplicationState) => state.sectorReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { attendant } = useSelector((state: ApplicationState) => state.attendantReducer)

  if (!selected) return null
  if (!attendant) return null
  if (attendant?.id !== Number(id)) return null

  return (
    <div className="row margin-top-16">
      <p>Selecione os setores que o atendente participa</p>

      <div className="row justify-start margin-top-16">
        {
          sectors.map((sector, i) => {
            const isOnSector = attendant.sectorAttendants?.find(item => item.sector?.id === sector.id)

            if (isOnSector) {
              return (
                <span
                  key={i}
                  onClick={() => delAttendant(selected, sector.id, attendant.id)(dispatch)}
                  className="button radius highlight margin-4"
                >
                  { sector.name }
                </span>
              )
            } else {
              return (
                <span
                  key={i}
                  onClick={() => addAttendant(selected, sector.id, attendant.id)(dispatch)}
                  className="button radius secondary margin-4"
                >
                  { sector.name }
                </span>
              )
            }
          })
        }
      </div>
    </div>
  )
}

export default AttendantSector
