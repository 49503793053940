import z from 'zod'

export enum BotAttachmentType {
  IMAGE = 'IMAGE',
  FILE = 'FILE',
  AUDIO = 'AUDIO',
}

export const BotAttachmentDict = {
  [BotAttachmentType.IMAGE]: 'Imagem',
  [BotAttachmentType.FILE]: 'Arquivo',
  [BotAttachmentType.AUDIO]: 'Áudio'
}

export const BotAttachmentSchema = z.object({
  type: z.nativeEnum(BotAttachmentType).nullable(),
  title: z.string().nullable()
})

export type BotAttachmentSchemaType = z.infer<typeof BotAttachmentSchema>;
