/* eslint-disable @typescript-eslint/no-use-before-define */
import Axios from 'axios'
import { Dispatch } from 'redux'

import { Seller, SellerTypes } from './types'
import { ActionResponse, Id } from 'util/types/index'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'

export function createSeller (data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Seller>> => {
    return Axios
      .post('/seller', data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: SellerTypes.SELLER_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar o vendedor')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateSeller (id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Seller>> => {
    return Axios
      .put(`/seller/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: SellerTypes.SELLER_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o vendedor')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteSeller (id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Seller>> => {
    return Axios
      .delete(`/seller/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: SellerTypes.SELLER_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o vendedor')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getSellerLogged () {
  return async (dispatch: Dispatch): Promise<ActionResponse<Seller>> => {
    return Axios
      .get('/seller/logged')
      .then(res => res.data)
      .then(res => {
        dispatch({ type: SellerTypes.SELLER_GET_LOGGED, payload: res.data })
        return res
      })
  }
}

export function getSeller (id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Seller>> => {
    return Axios
      .get(`/seller/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: SellerTypes.SELLER_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar o vendedor')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getSellerList (params: any = {}) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Seller>> => {
    return Axios
      .get('/seller', { params })
      .then(res => res.data)
      .then(res => {
        dispatch({ type: SellerTypes.SELLER_GET_LIST, payload: res.data || [], pagination: res.pagination })
        return res
      })
  }
}
