import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import './styles/Plan.scss'

import Modal from 'util/modules/Modal'
import PlanForm from './components/PlanForm'
import PlanList from './components/PlanList'

const Plan: React.FC = () => {
  const history = useHistory()

  const [isFormOpen, setFormOpen] = useState(false)

  const { action } = useParams<{ [x: string]: string }>()

  useEffect(() => {
    if (action === 'edit') {
      setFormOpen(true)
    } else {
      setFormOpen(false)
    }
  }, [action])

  useEffect(() => {
    if (!isFormOpen) {
      history.push('/panel/plan')
    }
  }, [isFormOpen])

  return (
    <div className="row panel bordered">
      <div className="row plan-container">
        <div className="row padding-24">
          <p className="grow" style={{ fontSize: 18 }}>Planos Cadastrados</p>
          <button className="gradient radius" style={{ width: 120 }} onClick={() => setFormOpen(true)}>
            Cadastrar
          </button>
        </div>
        <PlanList />

        <Modal isOpen={isFormOpen} onClose={() => setFormOpen(false)}>
          <PlanForm onClose={() => setFormOpen(false)} />
        </Modal>
      </div>
    </div>
  )
}

export default Plan
