import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Tab } from '../ContactTab'
import { UploadRef } from 'util/modules/Upload'
import { AttendantTypes } from 'panel/attendant/redux/types'
import { ApplicationState } from 'AppReducer'
import { deleteContactPicture } from 'panel/person/redux/action/contact'
import { updatePerson, createPerson } from 'panel/person/redux/action/person'

import Upload from 'util/modules/Upload'
import TagSelector from 'panel/tag/components/TagSelector'

type Props = { onUnsaved: (tab?: Tab) => void, changeTab: (tab: Tab) => void }

const initialState = { firstName: '', lastName: '', alias: '', email: '', note: '' }
type StateType = { firstName: string, lastName: string, email: string, alias?: string, note?: string, tag?: number }
const ContactData: React.FC<Props> = ({ onUnsaved, changeTab }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [form, setForm] = useState<StateType>(initialState)
  const [picture, setPicture] = useState<File>()
  const [fetching, setFetching] = useState(false)

  const { id } = useParams<{ id: string }>()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { person } = useSelector((state: ApplicationState) => state.personReducer)

  const uploadRef = useRef<UploadRef>(null)

  const handleClear = useCallback(() => {
    if (uploadRef.current) {
      setForm(initialState)
      uploadRef.current.clear()
      history.push('/panel/person')
      dispatch({ type: AttendantTypes.ATTENDANT_GET, payload: undefined })
    }
  }, [uploadRef, history])

  function getSavedState () {
    if (id && person) {
      if (uploadRef.current) {
        if (person.picture) uploadRef.current.setPreviewImage(person.picture)
        if (!person.picture) uploadRef.current.clear()
      }

      return {
        email: person.email || '',
        alias: person.alias || '',
        firstName: person.firstName || '',
        lastName: person.lastName || '',
        note: person.note || '',
        tag: person.tag?.id
      }
    }

    return initialState
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (!selected) return undefined

    const formData = new FormData()

    if (picture) formData.set('picture', picture)

    formData.set('firstName', form.firstName)
    formData.set('lastName', form.lastName)
    formData.set('email', form.email)
    formData.set('alias', form.alias || '')
    if (form.note) formData.set('note', form.note)
    if (form.tag) formData.set('tag', form.tag?.toString())

    setFetching(true)
    if (id) {
      await updatePerson(selected, id, formData)(dispatch)
    } else {
      await createPerson(selected, formData)(dispatch).then(res => {
        if (res?.status === 'success' && res?.data) {
          history.push(`/panel/person/${res.data.id}`)
          changeTab('COMPLEMENTARY')
        }
      })
    }
    setFetching(false)
  }

  function handleRemove () {
    if (selected && id && window.confirm('Tem certeza que deseja remover a imagem?')) {
      deleteContactPicture(selected, id)(dispatch)
    }
  }

  useEffect(() => {
    setForm(getSavedState())
  }, [person])

  // Handle Unsaved form
  useEffect(() => {
    const isUnsaved = JSON.stringify(form) !== JSON.stringify(getSavedState())
    onUnsaved(isUnsaved ? 'DATA' : undefined)
  }, [form, person, initialState])

  useEffect(() => {
    return () => {
      handleClear()
    }
  }, [handleClear])

  return (
    <form className="row margin-top-16 attendant-data" onSubmit={handleSubmit}>
      <div className="row">
        <div className="row" style={{ width: 120, height: 120 }}>
          <i className="fa fa-trash upload-button pointer" onClick={handleRemove} />
          <Upload ref={uploadRef} placeholder={false} onChange={setPicture} />
        </div>
      </div>
      <div className="row align-end name-wrapper">
        <div className="form-control grow">
          <label>Primeiro Nome</label>
          <input
            required
            type="text"
            value={form.firstName}
            onChange={(e): void => setForm({ ...form, firstName: e.target.value })}
          />
        </div>
        <div className="form-control grow">
          <label>Sobrenome</label>
          <input
            required
            type="text"
            value={form.lastName}
            onChange={(e): void => setForm({ ...form, lastName: e.target.value })}
          />
        </div>
      </div>

      <div className="form-control grow">
        <label>Apelido</label>
        <input
          type="text"
          value={form.alias}
          onChange={(e): void => setForm({ ...form, alias: e.target.value })}
        />
      </div>
      <div className="form-control grow">
        <label>Etiqueta</label>
        <TagSelector
          value={form.tag}
          onSelect={(tag) => setForm({ ...form, tag })}
          onRemove={() => setForm({ ...form, tag: 0 })}
        />
      </div>

      <div className="form-control row">
        <label>E-mail</label>
        <input
          type="text"
          value={form.email}
          onChange={(e): void => setForm({ ...form, email: e.target.value })}
        />
      </div>
      <div className="row form-item margin-top32" style={{ alignItems: 'flex-start' }}>
        <label>Anotações</label>
        <textarea
          rows={4}
          value={form.note}
          onChange={(e) => setForm({ ...form, note: e.target.value })}
        />
      </div>

      <div className="row margin-top-16 form-buttons">
        <span className="secondary button" onClick={handleClear}>Limpar</span>
        <button className="gradient button" disabled={fetching}>{fetching ? 'Salvando...' : 'Salvar'}</button>
      </div>
    </form>
  )
}

export default ContactData
