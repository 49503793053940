import React, { useEffect, useState } from 'react'

import { ModalHeader } from 'util/modules/Modal'
import AttendantTab, { Tab } from './AttendantTab'

import AttendantData from './partials/AttendantData'
import AttendantSector from './partials/AttendantSector'
import AttendantPassword from './partials/AttendantPassword'
import AttendantComplementary from './partials/AttendantComplementary'
import TimeTable from 'panel/time-table/TimeTable'
import Permissions from './partials/Permissions'

const AttendantForm: React.FC<{ id: number, isOpen: boolean, onClose(): void }> = ({ id, isOpen, onClose }) => {
  const [unsavedTab, setUnsavedTab] = useState<Tab>()
  const [selectedTab, setSelectedTab] = useState<Tab>('DATA')

  useEffect(() => {
    if (!isOpen) {
      setUnsavedTab(undefined)
      setSelectedTab('DATA')
    }
  }, [isOpen])

  return (
    <div className="panel no-padding">
      <ModalHeader title="Configurações de Atendentes" onClose={onClose} />
      <div className="padding-24">
        <AttendantTab onSelect={setSelectedTab} active={selectedTab} unsaved={unsavedTab} />

        { isOpen && selectedTab === 'DATA' && <AttendantData changeTab={setSelectedTab} onUnsaved={setUnsavedTab} /> }
        { selectedTab === 'COMPLEMENTARY' && <AttendantComplementary onUnsaved={setUnsavedTab} /> }
        { selectedTab === 'SECTOR' && <AttendantSector /> }
        { selectedTab === 'PASSWORD' && <AttendantPassword onUnsaved={setUnsavedTab} /> }
        { selectedTab === 'TIME-TABLE' && <TimeTable attendantId={id} /> }
        { selectedTab === 'PERMISSIONS' && <Permissions /> }
      </div>
    </div>
  )
}

export default AttendantForm
