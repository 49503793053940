import { ApplicationState } from 'AppReducer'
import { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const useInterval = (fn: (...args: any[]) => any, delay: number|undefined, args: any[]): void => {
  useEffect(() => {
    const filledArgs = args.filter(item => item !== null && item !== undefined).length
    const canRun = args.length === filledArgs

    if (canRun && delay) {
      const id = setTimeout(fn, delay * 1000)
      return (): void => clearTimeout(id)
    }
  }, [args, delay, fn])
}

export const useDebounce = () => {
  const timeout = useRef<any>()
  return (fn: (...args: any[]) => any, delay: number): void => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(fn, delay)
  }
}

export const getStoreId = () => {
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  return selected || 0
}

export const useOutsideClick = (ref: any, callback: () => any): any => {
  const handleClick = (e: any): void => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export const useSearchParams = (field: string) => {
  const history = useHistory()
  const { location: { search } } = history

  const lastQuery = new URLSearchParams(search)

  return {
    value: lastQuery.get(field) || '',
    setValue: (value: string) => {
      const query = new URLSearchParams(search)
      query.set(field, value)
      history.push(`${window.location.pathname}?${query.toString()}`)
    },
    delValue: () => {
      const query = new URLSearchParams(search)
      query.delete(field)
      history.push(`${window.location.pathname}?${query.toString()}`)
    }
  }
}

export const useRouteDisclosure = (field: string) => {
  const history = useHistory()
  const search = window.location.search

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const query = new URLSearchParams(search)
    setIsOpen(query.get(field) === 'true')
  }, [search])

  return {
    isOpen,
    onOpen: () => {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('attachment', 'true')
      history.push(`${window.location.pathname}?${searchParams.toString()}`)
    },
    onClose: () => {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.delete('attachment')
      history.push(`${window.location.pathname}?${searchParams.toString()}`)
    }
  }
}
