import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import { exportBotToCommand, getCommandBotList, importCommand } from './redux/actions'

import CommandBotList from './components/CommandBotList'
import { hasPermission } from 'util/services/AuthService'

type Props = { onClose: () => void, onOpenExecution: (commandId: number) => void }
const CommandBot: React.FC<Props> = ({ onClose, onOpenExecution }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [mode, setMode] = useState<'CREATE'|'IMPORT'>('CREATE')
  const [file, setFile] = useState<File>()
  const [isFetching, setFetching] = useState({ generate: false, import: false })

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  const inpFileRef = useRef<HTMLInputElement>(null)

  async function handleGenerate (e: React.FormEvent) {
    e.preventDefault()
    if (selected) {
      setFetching({ ...isFetching, generate: true })
      await exportBotToCommand(selected, name)(dispatch).then(res => {
        if (res?.status === 'success') {
          setName('')
        }
      })
      setFetching({ ...isFetching, generate: false })
    }
  }

  async function handleImportCommand (e: React.FormEvent) {
    e.preventDefault()
    if (selected) {
      if (!file) {
        alert('Selecione um arquivo')
        return undefined
      }

      const formData = new FormData()
      formData.set('file', file)

      setFetching({ ...isFetching, import: true })
      await importCommand(selected, formData)(dispatch).then(res => {
        if (res?.status === 'success' && inpFileRef.current) {
          setFile(undefined)
          inpFileRef.current.value = ''
        }
      })
      setFetching({ ...isFetching, import: false })
    }
  }

  useEffect(() => {
    if (selected) {
      getCommandBotList(selected)(dispatch)
    }
  }, [selected])

  useEffect(() => {
    const canExport = hasPermission('CHAT_BOT:BACKUP_EXPORT')
    const canImport = hasPermission('CHAT_BOT:BACKUP_IMPORT')

    if (!canExport && canImport) setMode('IMPORT')
  }, [])

  return (
    <div className="row panel no-padding command">
      <div className="row panel-header" style={{ height: 70 }}>
        <div className="row">
          <p className="flex"><i className="fa fa-terminal" style={{ margin: 0 }} /> Gestão de Comandos</p>

          {
            hasPermission('CHAT_BOT:BACKUP_EXPORT') &&
            <label className="checkbox margin-right-16">
              <input
                type="checkbox"
                checked={mode === 'CREATE'}
                onClick={(): void => setMode('CREATE')}
              />
              <div className="checkmark" /> Criar
            </label>
          }
          {
            hasPermission('CHAT_BOT:BACKUP_IMPORT') &&
            <label className="checkbox" style={{ marginRight: 16 }}>
              <input
                type="checkbox"
                checked={mode === 'IMPORT'}
                onClick={(): void => setMode('IMPORT')}
              />
              <div className="checkmark" /> Importar
            </label>
          }
          <span className="button-times pointer" onClick={onClose}><i className="fa fa-times"/></span>
        </div>
      </div>

      <div className="row panel-body">
        {
          (mode === 'CREATE' && hasPermission('CHAT_BOT:BACKUP_EXPORT')) &&
          <form onSubmit={handleGenerate} className="row bordered border-radius padding-16">
            <h4 className="margin-bottom-16"><i className="fa fa-plus" style={{ margin: 0 }} /> Criar Backup</h4>
            <div className="form-control">
              <label>Nome do Backup</label>
              <input
                required
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="row justify-end">
              <button className="gradient margin-top-8" disabled={isFetching.generate}>
                {
                  isFetching.generate
                    ? <><i className="fa fa-spinner fa-spin" /> Gerando Backup. Aguarde...</>
                    : <><i className="fa fa-save" /> Gerar Backup</>
                }
              </button>
            </div>
          </form>
        }
        {
          (mode === 'IMPORT' && hasPermission('CHAT_BOT:BACKUP_IMPORT')) &&
          <form onSubmit={handleImportCommand} className="row bordered border-radius padding-16">
            <h4 className="margin-bottom-16">
              <i className="fa fa-file-upload" style={{ margin: 0 }} /> Importar Backup
            </h4>
            <div className="form-control">
              <label>Selecionar Arquivo</label>
              <input ref={inpFileRef} required type="file" onChange={(e) => setFile(e.target.files?.[0])} />
            </div>
            <div className="row justify-end">
              <button className="gradient margin-top-8" disabled={isFetching.import}>
                {
                  isFetching.import
                    ? <><i className="fa fa-spinner fa-spin" /> Gerando Backup. Aguarde...</>
                    : <><i className="fa fa-upload" /> Importar Backup</>
                }
              </button>
            </div>
          </form>
        }

        {
          hasPermission('CHAT_BOT:BACKUP_LIST') &&
          <div className="row margin-top-32">
            <h3 className="margin-bottom-16"><i className="fa fa-history" /> Meus Backups</h3>
            <CommandBotList onOpenExecution={onOpenExecution} />
          </div>
        }
      </div>
    </div>
  )
}

export default CommandBot
