import { Alert, AlertTypes, CreateAlertAction, ClearAlertAction } from './types'
import { Dispatch } from 'react'
import { AxiosError } from 'axios'

export const timeout = (delay: number): Promise<number> => {
  return new Promise((resolve): number => setTimeout(resolve, delay))
}

export const handleError = (error: AxiosError, message: string, code = 500, title?: string): Alert => {
  const data: any = error.response?.data

  return {
    status: 'failed',
    code: data?.code || code,
    title: title || 'Ocorreu um erro!',
    message: data?.message || message
  }
}

export const createAlert = (payload: Alert) => {
  return (dispatch: Dispatch<CreateAlertAction>): void => {
    const isLoading = payload.code === 100
    const hasSuccess = payload.code && payload.code >= 200 && payload.code < 400

    const title = {
      fetching: 'Carregando, aguarde...',
      success: 'Sucesso!',
      error: 'Ocorreu um erro!'
    }

    const alert = {
      ...payload,
      code: payload.code || 500,
      title: payload.title || (isLoading ? title.fetching : hasSuccess ? title.success : title.error)
    }
    return dispatch({ type: AlertTypes.CREATE_ALERT, payload: alert })
  }
}

export const clearAlert = (delay = 0) => {
  return async (dispatch: Dispatch<ClearAlertAction>): Promise<void> => {
    await timeout(delay)
    dispatch({ type: AlertTypes.CLOSE_ALERT })
    await timeout(500)
    dispatch({ type: AlertTypes.CLEAR_ALERT })
    await timeout(500)
  }
}
