import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { formatDateTime } from 'util/masks'
import { deleteAttendant } from '../redux/actions'
import { ApplicationState } from 'AppReducer'

import { ReactComponent as UserAttendants } from 'assets/icons/user-tie.svg'
import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { hasPermission } from 'util/services/AuthService'

const AttendantList: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteId, setDeleteId] = useState<number>()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { attendants } = useSelector((state: ApplicationState) => state.attendantReducer)

  function handleRemove (id: number): void {
    if (selected) {
      deleteAttendant(selected, id)(dispatch).then(res => {
        if (res.status === 'success') {
          setDeleteId(undefined)
          history.push('/panel/attendant')
        }
      })
    }
  }

  return (
    <div className="list" style={{ marginTop: 0 }}>
      <div className="list-header">
        <div>Id</div>
        <div>Nome</div>
        <div>Setor</div>
        <div>Criado em</div>
        <div>Ativo</div>
        <div>Ações</div>
      </div>

      {
        attendants.length === 0 &&
        <div className="list-row empty">
          <UserAttendants width={120} height={120} style={{ padding: 24 }} />
          <div className="grid-span" style={{ height: 0 }}>Nenhum setor cadastrado</div>
        </div>
      }

      {
        attendants.map((attendant, i) => {
          return (
            <div className="list-row" key={i}>
              <div data-content="Id" className="bold">#{attendant.id}</div>
              <div data-content="Nome">{ attendant.user.person?.firstName }</div>
              <div data-content="Setor">{ attendant.sectorAttendants?.map(item => item.sector?.name).join(', ') }</div>
              <div data-content="Criado em">{ formatDateTime(attendant.createdAt) }</div>
              <div data-content="Ativo">
                <span className={`status ${attendant.isActive ? 'success' : 'danger'}`}>
                  {attendant.isActive ? 'Sim' : 'Não'}
                </span>
              </div>
              <div data-content="Ações" className="row buttons-list-row">
                <div className="row">
                  {
                    hasPermission('ATTENDANT:UPDATE') &&
                    <NavLink to={`/panel/attendant/${attendant.id}/edit`} className="button flex">
                      <i className="fas fa-edit" /> &nbsp;Editar
                    </NavLink>
                  }
                  {
                    hasPermission('ATTENDANT:DELETE') &&
                    <span className="button secondary flex" onClick={() => setDeleteId(attendant.id)}>
                      <i className="fas fa-trash-alt" /> &nbsp;Excluir
                    </span>
                  }
                </div>
              </div>
            </div>
          )
        })
      }

      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </div>
  )
}

export default AttendantList
