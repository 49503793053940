import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'

import Modal, { ModalHeader } from 'util/modules/Modal'

import { maskPhone } from 'util/masks'
import { ContactCSV } from './redux/types/contact'
import { iterateList } from 'util/index'
import { getPersonList } from './redux/action/person'
import { ApplicationState } from 'AppReducer'
import { useDispatch, useSelector } from 'react-redux'
import CreateGroupModal from 'panel/group/components/CreateGroupModal'
import { getGroupList } from 'panel/group/redux/actions'

type Item = ContactCSV & { status: Status }
type Status = 'PENDING' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'WARNING'
const ImportContactCsv: React.FC = () => {
  const dispatch = useDispatch()

  const [groupId, setGroupId] = useState<number>(0)
  const [isFetching, setFetching] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)

  const [file, setFile] = useState<File>()
  const [items, setItems] = useState<Item[]>([])

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { groups } = useSelector((state: ApplicationState) => state.groupReducer)

  const inpFileRef = useRef<HTMLInputElement>(null)

  async function handleImport (e: React.FormEvent) {
    e.preventDefault()
    if (selected) {
      setFetching(true)
      await iterateList(async (item, i) => {
        let status: Status = 'FETCHING'
        await axios
          .post(`/store/${selected}/person-contact`, { ...item, groupId })
          .then(res => res.data)
          .then(res => {
            status = res?.data || 'ERROR'

            document.querySelector(`#contact-${i}`)?.scrollIntoView()
          })
          .catch(() => {
            status = 'ERROR'
          })

        setItems(items => ([...items.slice(0, i), { ...items[i], status }, ...items.slice(i + 1)]))
      }, items)

      await getPersonList(selected, {
        page: 1,
        limit: 10,
        fields: 'id,name,createdAt,updatedAt'
      })(dispatch)

      setItems([])
      setFile(undefined)
      setModalOpen(false)

      setFetching(false)
    }
  }

  const getIndexes = (header: string[]) => {
    // eslint-disable-next-line max-len
    const isGoogleCsv = (
      header.includes('First Name') &&
      header.includes('Middle Name') &&
      header.includes('Last Name') &&
      header.includes('Phone 1 - Value')
    )
    if (isGoogleCsv) {
      return {
        emailIndexes: [
          header.indexOf('E-mail 1 - Value')
        ],
        phoneIndexes: [
          header.indexOf('Phone 1 - Value'),
          header.indexOf('Phone 2 - Value'),
          header.indexOf('Phone 3 - Value'),
          header.indexOf('Phone 4 - Value'),
          header.indexOf('Phone 5 - Value'),
          header.indexOf('Phone 6 - Value')
        ].filter(Boolean),
        firstNameIndexes: [header.indexOf('First Name')],
        middleNameIndexes: [header.indexOf('Middle Name')],
        lastNameIndexes: [header.indexOf('Last Name')]
      }
    }

    return getDefaultIndexes(header)
  }

  const getDefaultIndexes = (header: string[]) => {
    const phoneRegex = /^Phone\s[\d]+\s[-]\sValue$|^Telefone$/g
    const emailRegex = /^E-mail\s[\d]+\s[-]\sValue$|^E-mail$/g
    const fNameRegex = /^Given Name$|^Nome$|^First Name$/g
    const lNameRegex = /^Additional\sName$|^Family\sName$|^Sobrenome$/g

    const phoneIndexes = header.map((value, i) => phoneRegex.test(value) ? i : -1).filter(i => i > -1)
    const emailIndexes = header.map((value, i) => emailRegex.test(value) ? i : -1).filter(i => i > -1)
    const lastNameIndexes = header.map((value, i) => lNameRegex.test(value) ? i : -1).filter(i => i > -1)
    const firstNameIndexes = header.map((value, i) => fNameRegex.test(value) ? i : -1).filter(i => i > -1)

    return { phoneIndexes, emailIndexes, firstNameIndexes, middleNameIndexes: [], lastNameIndexes }
  }

  useEffect(() => {
    if (file && inpFileRef.current) {
      inpFileRef.current.value = ''

      const reader = new FileReader()
      reader.onload = () => {
        if (reader.result && typeof reader.result === 'string') {
          const lines = reader.result.split('\n').filter(item => !!item).map(line => line.replace('\r', ''))

          if (lines.length > 1) {
            const header = lines[0].replace('\r', '').split(/[,]|[;]/g)
            const rows = lines.slice(1)

            const indexes = getIndexes(header)
            const { phoneIndexes, emailIndexes, firstNameIndexes, middleNameIndexes, lastNameIndexes } = indexes

            const items: Item[] = []
            rows.forEach((line, i) => {
              const parts = line.split(/[,]|[;]/g)

              const contacts = parts.filter((_, i) => phoneIndexes.includes(i))

              const sanitized = contacts
                .map(contact => {
                  if (!/^[+][\d]{2}/g.test(contact)) { // Without country code
                    const regex = /([0-9]{2})([9])?([0-9]{8})$/g
                    contact = `+55${contact.replace(/\D/g, '').match(regex)?.[0] || ''}`
                  }

                  return contact.trim().replace(/\D/g, '')
                })
                .filter(item => {
                  const regex = /([0-9]{2})([0-9]{2})([9])?([0-9]{8})$/g
                  return regex.test(item)
                })
                .filter(item => !!item)
                .reduce((acc: string[], item) => {
                  const isInside = !!acc.find(obj => obj === item)
                  if (!isInside) acc.push(item)
                  return acc
                }, [])

              sanitized.forEach(value => {
                const firstName = parts.filter((_, i) => firstNameIndexes.includes(i)).join(' ')
                const middleName = parts.filter((_, i) => middleNameIndexes.includes(i)).join(' ')
                const lastName = parts.filter((_, i) => lastNameIndexes.includes(i)).join(' ')

                items.push({
                  status: 'PENDING',
                  email: emailIndexes[0] ? parts[emailIndexes[0]] : '',
                  firstName: firstName.trim(),
                  lastName: [middleName?.trim(), lastName.trim()].filter(Boolean).join(' ').trim(),
                  contact: maskPhone(value)
                })
              })
            })

            setItems(items)
          }
        }
      }
      reader.readAsText(file)
    }
  }, [file, inpFileRef])

  useEffect(() => {
    if (!isModalOpen && inpFileRef.current) {
      setItems([])
      inpFileRef.current.value = ''
    }
  }, [isModalOpen, inpFileRef])

  useEffect(() => {
    if (selected) getGroupList(selected)(dispatch)
  }, [dispatch])

  const labels: { [key in Status]: { alias: string, icon: string } } = {
    PENDING: { alias: 'Pendente', icon: 'ellipsis-h' },
    FETCHING: { alias: 'Cadastrando', icon: 'spinner fa-spin' },
    WARNING: { alias: 'Existente', icon: 'triangle-exclamation' },
    ERROR: { alias: 'Erro', icon: 'times' },
    SUCCESS: { alias: 'Cadastrado', icon: 'check' }
  }

  const gridTemplateColumns = '150px 1fr 1fr 175px 200px 48px'

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <div className="row panel no-padding" style={{ width: 1024 }}>
          <ModalHeader title="Importar Contatos" onClose={() => setModalOpen(false)} />
          <div className="row panel-body">
            <div className="form-control">
              <label>Selecionar arquivo CSV</label>
              <input ref={inpFileRef} required type="file" onChange={(e) => setFile(e.target.files?.[0])} />
            </div>
            <div className="row align-end">
              <div className="form-control flex">
                <label>Importar para grupo</label>
                <select value={groupId} onChange={e => setGroupId(Number(e.target.value))}>
                  <option value={0}>Nenhum</option>
                  {
                    groups.map(group => <option key={group.id} value={group.id}>{ group.name}</option>)
                  }
                </select>
              </div>
              <CreateGroupModal />
            </div>

            <form className="list margin-top-32" onSubmit={handleImport}>
              <div className="list-header" style={{ display: 'grid', gridTemplateColumns }}>
                <span>Status</span>
                <span>Nome</span>
                <span>Sobrenome</span>
                <span>Telefone</span>
                <span>E-mail</span>
                <span>Ação</span>
              </div>
              {
                items.length === 0
                  ? (
                    <div className="list-row empty">
                      <i className="fa fa-address-card" style={{ fontSize: 100 }} />
                      Selecione um arquivo para continuar
                    </div>
                  )
                  : (
                    <div className="row" style={{ height: 760, overflow: 'auto' }}>
                      {
                        items.map((item, i) => {
                          const label = labels[item.status]

                          return (
                            <div
                              key={i}
                              id={`contact-${i}`}
                              className="list-row"
                              style={{ display: 'grid', gridTemplateColumns }}
                            >
                              <span data-content="Status" className="padding-right-8">
                                <span className="badge" data-status={item.status}>
                                  <i className={`fa fa-${label.icon}`} /> {label.alias}
                                </span>
                              </span>
                              <span data-content="Nome" className="padding-lr-4 text-overflow">
                                <input
                                  required
                                  type="text"
                                  value={item.firstName}
                                  onChange={(e) => setItems(items.map((item, index) => {
                                    if (index === i) item.firstName = e.target.value
                                    return item
                                  }))}
                                />
                              </span>
                              <span data-content="Sobrenome" className="padding-lr-4 text-overflow">
                                <input
                                  type="text"
                                  value={item.lastName}
                                  onChange={(e) => setItems(items.map((item, index) => {
                                    if (index === i) item.lastName = e.target.value
                                    return item
                                  }))}
                                />
                              </span>
                              <span data-content="Telefone">
                                <input
                                  required
                                  type="text"
                                  value={item.contact}
                                  onChange={(e) => setItems(items.map((item, index) => {
                                    if (index === i) item.contact = maskPhone(e.target.value)
                                    return item
                                  }))}
                                />
                              </span>
                              <span data-content="E-mail" className="padding-lr-4 text-overflow">
                                <input
                                  type="text"
                                  value={item.email}
                                  onChange={(e) => setItems(items.map((item, index) => {
                                    if (index === i) item.email = e.target.value
                                    return item
                                  }))}
                                />
                              </span>
                              <data data-content="Ação">
                                <span
                                  className="button secondary"
                                  onClick={() => setItems([...items.slice(0, i), ...items.slice(i + 1)])}
                                >
                                  <i className="fa fa-trash-alt" style={{ color: 'gray' }} />
                                </span>
                              </data>
                            </div>
                          )
                        })
                      }
                    </div>
                  )

              }

              {
                items.length > 0 &&
                <div className="row margin-top-16 justify-end">
                  <button className="gradient" disabled={isFetching} style={{ width: 200 }}>
                    <i className="fa fa-cogs" /> Importar
                  </button>
                </div>
              }
            </form>
          </div>
        </div>
      </Modal>
      <span className="button secondary radius margin-right-8" onClick={() => setModalOpen(true)}>
        <i className="fas fa-arrow-right" style={{ color: 'var(--primary)' }} /> Importar CSV
      </span>
    </>
  )
}

export default ImportContactCsv
