import React, { useState, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { AttendantTypes } from 'panel/attendant/redux/types'
import { ApplicationState } from 'AppReducer'
import { updateAttendant } from 'panel/attendant/redux/actions'

import rbac from 'assets/rbac.json'

const Permissions: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [fetching, setFetching] = useState(false)

  const [permissions, setPermissions] = useState<string[]>([])

  const { id } = useParams<{ id: string }>()

  const { logged } = useSelector((state: ApplicationState) => state.userReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { attendant } = useSelector((state: ApplicationState) => state.attendantReducer)

  const handleClear = useCallback(() => {
    setPermissions([])
    dispatch({ type: AttendantTypes.ATTENDANT_GET, payload: undefined })
    history.push('/panel/attendant')
  }, [history])

  function getInheritPermissions (role: string, argPermissions: string[] = []): string[] {
    const grants = rbac.grants.find(item => item.role === role)
    if (grants) {
      grants.permissions.forEach(grantPermissions => {
        if (!argPermissions.includes(grantPermissions)) argPermissions.push(grantPermissions)
      })

      if (grants.inherits) {
        grants.inherits.forEach(role => {
          const inheritsPermissions = getInheritPermissions(role, argPermissions)
          inheritsPermissions.forEach(inheritPermission => {
            if (!argPermissions.includes(inheritPermission)) argPermissions.push(inheritPermission)
          })
        })
      }
    }

    return argPermissions
  }

  function handleAddClientPermissions () {
    const newPermissions = [...permissions]

    const rolePermissions = getInheritPermissions('ROLE_CLIENT')
    rolePermissions.forEach(permission => {
      if (!newPermissions.includes(permission)) {
        newPermissions.push(permission)
      }
    })

    setPermissions(newPermissions)
  }

  function handleAddAll (permissionList: string[]) {
    const newPermissions = [...permissions]
    permissionList.forEach(permission => {
      if (logged) {
        const loggedUserHasThisPermission = logged.permissions.includes(permission)
        const isPermissionAlreadyDefined = permissions.includes(permission)
        if (loggedUserHasThisPermission && !isPermissionAlreadyDefined) {
          newPermissions.push(permission)
        }
      }
    })
    setPermissions(newPermissions)
  }

  function handleDelAll (permissionList: string[]) {
    const newPermissions = permissions
      .filter(permission => !permissionList.includes(permission))
    setPermissions(newPermissions)
  }

  function handlePermission (permission: string) {
    if (permissions.includes(permission)) {
      setPermissions(permissions.filter(item => item !== permission))
    } else {
      setPermissions([...permissions, permission])
    }
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (!selected || !id) return undefined

    const formData = new FormData()

    permissions.forEach((permission, i) => formData.append(`permissions[${i}]`, permission))

    setFetching(true)
    if (id) {
      await updateAttendant(selected, id, formData)(dispatch)
    }
    setFetching(false)
  }

  useEffect(() => {
    if (attendant && attendant.user) {
      if (attendant.user.permissions) {
        setPermissions(attendant.user.permissions.filter(permission => {
          return rbac.permissions.find(item => item.name === permission)
        }))
      }
    }
  }, [attendant])

  if (!logged) return null

  const isAdmin = logged.roles.includes('ROLE_ADMIN')
  const isClient = logged.roles.includes('ROLE_CLIENT')

  const exclude = ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SELLER', 'ROLE_ATTENDANT']

  return (
    <form className="row margin-top-16 attendant-data" onSubmit={handleSubmit}>
      {
        (isAdmin || isClient) &&
        <div className="row justify-end margin-bottom-8">
          <div className="span button primary" onClick={handleAddClientPermissions}>Tornar Admin</div>
        </div>
      }
      {
        rbac.roles.filter(role => !exclude.includes(role.name)).map((role, i) => {
          const _permissions = rbac.grants.find(item => item.role === role.name)?.permissions
          if (!_permissions) return null

          const permissionList = _permissions.filter(permission => logged.permissions.includes(permission))

          if (permissionList.length === 0) return null

          return (
            <div className="row padding-8 bordered border-radius margin-bottom-16" key={i}>
              <div className="row margin-bottom-12" style={{ display: 'grid', gridTemplateColumns: '1fr 200px' }}>
                <h3 style={{ display: 'flex', alignItems: 'center', textTransform: 'uppercase' }}>
                  { role.description.replace(/GESTÃO DE/gi, '') }
                </h3>

                <div className="row" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }}>
                  <span className="button secondary" onClick={() => handleAddAll(permissionList)}>Todos</span>
                  <span className="button secondary" onClick={() => handleDelAll(permissionList)}>Nenhum</span>
                </div>
              </div>

              {
                permissionList.map((item, ii) => {
                  const permission = rbac.permissions.find(obj => obj.name === item)
                  if (!permission) return null

                  const loggedUserHasThisPermission = logged.permissions.includes(item)
                  if (!loggedUserHasThisPermission) return null

                  return (
                    <label key={ii} style={{ padding: 0 }}>
                      <input
                        type="checkbox"
                        onChange={() => handlePermission(permission.name)}
                        checked={permissions.includes(permission.name)}
                      />
                      { permission.description }
                    </label>
                  )
                })
              }
            </div>
          )
        })
      }

      <div className="row margin-top-16 form-buttons">
        <span className="secondary button" onClick={handleClear}>Limpar</span>
        <button className="button gradient" disabled={fetching}>Salvar</button>
      </div>
    </form>
  )
}

export default Permissions
