import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { deleteSchedule } from '../redux/actions'
import { ApplicationState } from 'AppReducer'
import { formatDateTime, formatDate, formatTime } from 'util/masks'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { hasPermission } from 'util/services/AuthService'

const ScheduleList: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteId, setDeleteId] = useState<number>()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { schedules, status } = useSelector((state: ApplicationState) => state.scheduleReducer)

  function handleRemove (id: number): void {
    if (selected) {
      deleteSchedule(selected, id)(dispatch).then(res => {
        if (res.status === 'success') {
          setDeleteId(undefined)
          history.push('/panel/schedule')
        }
      })
    }
  }

  return (
    <div className="list">
      <div className="list-header">
        <div>Id</div>
        <div>Nome</div>
        <div>Mensagens</div>
        <div>Data</div>
        <div>Criado em</div>
        <div>Status</div>
        <div>Ações</div>
      </div>

      {
        schedules.length === 0 &&
        <div className="list-row empty">
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: 500
          }}>
            <i className="fas fa-comment-alt" style={{ fontSize: 90, padding: '24px 0' }} />
            <span className="grid-span" style={{ height: 0 }}>Nenhuma mensagem cadastrada</span>
          </div>
        </div>
      }

      {
        schedules.map((item, i) => {
          const _status = status[item.status]
          return (
            <div className="list-row" key={i}>
              <div data-content="Id" className="bold">#{item.id}</div>
              <div data-content="Nome">{item.name}</div>
              <div data-content="">
                {item.messages?.[0]?.replace(/:COMMA:/g, ',')}
              </div>
              <div data-content="Data">{formatDate(item.date)} {formatTime(item.date)}</div>
              <div data-content="Criado em">{formatDateTime(item.createdAt)}</div>
              <div data-content="Status">
                <div className="status" style={{ background: _status.bgColor, color: _status.color }}>
                  { _status.alias }
                </div>
              </div>
              <div data-content="Ações" className="row buttons-list-row">
                <div className="row">
                  <NavLink to={`/panel/schedule/${item.id}/config`} className="button">
                    <i className="fas fa-cogs" /> &nbsp;Execução
                  </NavLink>
                  {
                    hasPermission('SCHEDULE:UPDATE') &&
                    <NavLink to={`/panel/schedule/${item.id}/edit`} className="button">
                      <i className="fas fa-edit" /> &nbsp;Editar
                    </NavLink>
                  }
                  {
                    hasPermission('SCHEDULE:DELETE') &&
                    <span className="button" onClick={() => setDeleteId(item.id)}>
                      <i className="fas fa-trash-alt" /> &nbsp;Excluir
                    </span>
                  }
                </div>
              </div>
            </div>
          )
        })
      }

      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </div>
  )
}

export default ScheduleList
