import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import { changeGlobalServer, getGlobalServer } from 'panel/client/redux/actions'

export const ClientServer: React.FC = () => {
  const dispatch = useDispatch()
  const { globalServer } = useSelector((state: ApplicationState) => state.clientReducer)

  async function handleSelect (server: string) {
    if (window.confirm(`Tem certeza que gostaria de trocar o servidor padrão por: ${server}`)) {
      await changeGlobalServer(server)(dispatch)
    }
  }

  useEffect(() => {
    getGlobalServer()(dispatch)
  }, [dispatch])

  return (
    <div className="form-control margin-right-8" style={{ width: 170 }}>
      <label>Server Padrão</label>
      <select
        required
        value={globalServer}
        onChange={(e) => handleSelect(e.target.value)}
      >
        <option value="DEFAULT">Padrão</option>
        {
          [...new Array(20)].map((_, i) => {
            return <option key={i} value={`WORKER_${i + 1}`}>WORKER {i + 1}</option>
          })
        }
      </select>
    </div>
  )
}
