import React, { useState, useEffect } from 'react'

import './style/Audio.scss'

const Audio: React.FC<{ onRecord: (file: File) => void }> = ({ onRecord }) => {
  const [shouldSend, setShouldSend] = useState(false)
  const [isDeviceAvailable, setDeviceAvailable] = useState(false)

  // Stream & Record
  const [stream, setStream] = useState<MediaStream>()
  const [audioChunks, setAudioChunks] = useState<Blob[]>([])
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>()

  // Timer
  const [seconds, setSeconds] = useState(0)
  const minutes = Math.floor(seconds / 60)

  async function handleStart (): Promise<void> {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      setStream(stream)
      setMediaRecorder(new MediaRecorder(stream))

      setAudioChunks([])
    }
  }

  async function handleCancel (): Promise<void> {
    if (stream && mediaRecorder) {
      // Stop Stream
      const tracks = stream.getTracks()
      tracks.forEach(track => track.stop())

      // Stop Record
      mediaRecorder.stop()

      // Clean
      setStream(undefined)
      setMediaRecorder(undefined)

      // Timer
      setSeconds(0)

      // Prevent send
      setShouldSend(false)
    }
  }

  async function handleSend (): Promise<void> {
    handleCancel() // Stop, clean and store blob
    setShouldSend(true)
  }

  // Send file
  useEffect(() => {
    if (shouldSend && audioChunks.length) {
      const blob = new Blob(audioChunks, { type: 'audio/wav' })
      const file = new File([blob], 'audio.wav', { type: blob.type })

      onRecord(file)
    }
  }, [shouldSend, audioChunks])

  // Handle record start and stop
  useEffect(() => {
    if (stream && mediaRecorder) {
      mediaRecorder.start()

      const chunks: Blob[] = []
      const interval = setInterval(() => {
        setSeconds(seconds => (seconds + 1))
      }, 1000)

      mediaRecorder.ondataavailable = e => {
        chunks.push(e.data)

        if (mediaRecorder.state === 'inactive') {
          clearInterval(interval)
          setAudioChunks(chunks)
        }
      }
    }
  }, [stream, mediaRecorder])

  useEffect(() => {
    setDeviceAvailable(!!navigator.mediaDevices)
  }, [])

  return (
    <>
      {
        stream
          ? (
            <span
              className="secondary button margin-right-8 audio-record-button recording"
              style={{ borderRadius: 25, height: 50, width: 200 }}
            >
              <i className="fa fa-times-circle" onClick={handleCancel} />
              <span className="record-signal blinking" /><i className="fas fa-microphone" />&nbsp;
              { minutes < 10 ? `0${minutes}` : minutes}:{ seconds < 10 ? `0${seconds}` : seconds }
              <i className="fa fa-check-circle" onClick={handleSend} />
            </span>

          )
          : (
            isDeviceAvailable
              ? (
                <span
                  onClick={handleStart}
                  title="Clique para gravar um áudio"
                  className="secondary button margin-right-8 audio-record-button"
                  style={{ borderRadius: '50%', height: 50, width: 50 }}
                >
                  <i className="fas fa-microphone" />
                </span>
              )
              : (
                <span
                  onClick={() => alert('Microfone não habilitado')}
                  className="secondary button margin-right-8 audio-record-button"
                  style={{ borderRadius: '50%', height: 50, width: 50, opacity: 0.4 }}
                >
                  <i className="fas fa-microphone" />
                </span>
              )
          )
      }
    </>
  )
}

export default Audio
