import React from 'react'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import { getCSVContacts } from './redux/action/contact'
import { maskPhone } from 'util/masks'

const ExportContactCSV: React.FC = () => {
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleExport () {
    if (selected) {
      getCSVContacts(selected).then(items => {
        let csvContent = ''
        const fileName = 'Contatos.csv'

        const header = ['Nome', 'Sobrenome', 'E-mail', 'Telefone']
        const rows:string[][] = []

        items.forEach(({ firstName, lastName, email, contact }) => {
          rows.push([firstName, lastName, email, maskPhone(contact)])
        })

        csvContent += header.join(';')
        csvContent += '\n'

        rows.forEach(itens => {
          const line = itens.join(';')
          csvContent += line
          csvContent += '\n'
        })

        const universalBOM = '\uFEFF'
        const a = window.document.createElement('a')
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvContent))
        a.setAttribute('download', fileName)
        window.document.body.appendChild(a)
        a.click()
        window.document.body.removeChild(a)
      })
    }
  }

  return (
    <span className="button secondary radius margin-right-8" onClick={handleExport}>
      <i className="fas fa-arrow-down" style={{ color: 'var(--highlight)' }} /> Exportar CSV
    </span>
  )
}

export default ExportContactCSV
