import { ApplicationState } from 'AppReducer'
import { changeGlobalLib, getGlobalLib } from 'panel/client/redux/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const ClientLib: React.FC = () => {
  const dispatch = useDispatch()
  const { globalLib } = useSelector((state: ApplicationState) => state.clientReducer)

  async function handleSelect (lib: string) {
    if (window.confirm(`Tem certeza que gostaria de trocar todas as libs por: ${lib}`)) {
      await changeGlobalLib(lib)(dispatch)
    }
  }

  useEffect(() => {
    getGlobalLib()(dispatch)
  }, [dispatch])

  return (
    <div className="form-control margin-right-8" style={{ width: 170 }}>
      <label>Lib Padrão</label>
      <select
        required
        value={globalLib}
        onChange={(e) => handleSelect(e.target.value)}
      >
        <option>Baileys</option>
        <option>WhatsAppWebJs</option>
      </select>
    </div>
  )
}
