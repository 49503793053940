import { Group } from 'panel/group/redux/types'
import { Channel } from 'panel/channel/redux/types'
import { Contact } from 'panel/person/redux/types/contact'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'
import { MessageDataBase } from 'panel/message-database/redux/types'
import { Message } from 'panel/conversation/redux/types'

export type ScheduleMode = 'ALL' | 'GROUP' | 'CONTACT'
export type ScheduleStatus = 'PENDING' | 'RUNNING' | 'FINISHED' | 'PAUSED'
export type ScheduleSendStatus = 'PENDING' | 'SUCCESS' | 'ERROR'

export interface ScheduleSend {
  id: number,
  message?: Message,
  value: string,
  createdAt: string,
  updatedAt?: string
}

/**
 * Data Types
*/

export interface Schedule {
  id: number,
  mode: ScheduleMode,
  status: ScheduleStatus,
  date: string,
  name: string,
  sends: ScheduleSend[],
  messages: string[],
  groups: Partial<Group>[],
  channels: Partial<Channel>[],
  contacts: Partial<Contact>[],
  quantitySend: number,
  quantityError: number,
  quantityTotal: number,
  messageDatabase: Partial<MessageDataBase>,
  createdAt: string,
  updatedAt?: string
}

/**
 * State Type
*/

export interface ScheduleState {
  readonly schedule?: Schedule
  readonly schedules: Schedule[]
  readonly sends: ScheduleSend[]
  readonly modes: { [key in ScheduleMode]: string }
  readonly status: { [key in ScheduleStatus]: { alias: string, bgColor: string, color: string } }
}

/**
 * Action Enum Types
*/

export enum ScheduleTypes {
  SCHEDULE_RESET = 'SCHEDULE/RESET',
  SCHEDULE_CREATE = 'SCHEDULE/CREATE',
  SCHEDULE_UPDATE = 'SCHEDULE/UPDATE',
  SCHEDULE_DELETE = 'SCHEDULE/DELETE',
  SCHEDULE_GET = 'SCHEDULE/GET',
  SCHEDULE_GET_LIST = 'SCHEDULE/GET/LIST',
  SCHEDULE_GET_SENDS = 'SCHEDULE/GET/SENDS',
  SCHEDULE_UPDATE_SEND = 'SCHEDULE/UPDATE/SEND'
}

/**
 * Action Types
*/

interface ResetAction {
  type: typeof ScheduleTypes.SCHEDULE_RESET,
}

interface CreateAction {
  type: typeof ScheduleTypes.SCHEDULE_CREATE,
  payload: Schedule
}

interface UpdateAction {
  type: typeof ScheduleTypes.SCHEDULE_UPDATE,
  payload: Schedule
}

interface DeleteAction {
  type: typeof ScheduleTypes.SCHEDULE_DELETE,
  payload: number
}

interface GetAction {
  type: typeof ScheduleTypes.SCHEDULE_GET,
  payload: Schedule
}

interface GetListAction {
  type: typeof ScheduleTypes.SCHEDULE_GET_LIST,
  payload: Schedule[]
}

interface GetSendsAction {
  type: typeof ScheduleTypes.SCHEDULE_GET_SENDS,
  payload: ScheduleSend[]
}

interface UpdateSendAction {
  type: typeof ScheduleTypes.SCHEDULE_UPDATE_SEND,
  payload: ScheduleSend
}

export type ScheduleActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction |
  GetAction | GetListAction | GetSendsAction | UpdateSendAction
)
