import socketIOClient, { Socket } from 'socket.io-client'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { img } from 'assets/online'
import { ApplicationState } from 'AppReducer'
import { getChannelByHash } from 'panel/channel/redux/actions'

const HashQrCode: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { location: { search } } = history

  const [id, setId] = useState(0)
  const [isFetching, setFetching] = useState(true)

  const { channelDb, connectionQr, statusList } = useSelector((state: ApplicationState) => state.channelReducer)
  const channel = channelDb[id]

  useEffect(() => {
    let AppSocket: Socket

    if (search) {
      const query = new URLSearchParams(search)
      const publicHash = query.get('hash')
      const store = query.get('store')

      if (store && publicHash) {
        setFetching(true)
        ;(async () => {
          const response = await getChannelByHash(store, publicHash)(dispatch)
          if (response?.data?.id) {
            setId(response.data.id)
          }

          console.log(
            'Connecting socket', process.env.REACT_APP_API
          )

          AppSocket = socketIOClient(`${process.env.REACT_APP_API}`, {
            auth: [publicHash],
            extraHeaders: { public: 'channel-public-hash' }
          })
          AppSocket.on('connect', () => console.log('Socket.io is Connected'))
          AppSocket.on('connect_failed', (err) => console.log('Socket.io Connection Failed', err))
          AppSocket.on('connect_error', (err) => console.log('Socket.io Connection Error', err))
          AppSocket.on('disconnect', (err) => console.log('Socket.io is Disconnected', err))

          AppSocket.on('UpdateChannel:QrCode', () => {
            getChannelByHash(store, publicHash)(dispatch)
          })
        })()
        setFetching(false)
      }
    }

    return () => {
      console.log('Call socket to disconnect')
      AppSocket.disconnect()
    }
  }, [search, dispatch])

  if (isFetching) {
    return (
      <div className="row center-center padding-32">
        <div className="row panel text-center" style={{ width: 760 }}>
          <h1><i className="fa fa-spinner fa-spin fa-3x" style={{ color: 'gray' }} /></h1>
          <h1>Carregando Link.</h1>

          <p>Aguarde. Estamos validando o acesso</p>
        </div>
      </div>
    )
  }

  if (!channel) {
    return (
      <div className="row center-center padding-32">
        <div className="row panel text-center" style={{ width: 760 }}>
          <h1><i className="fa fa-times-circle fa-3x" style={{ color: 'red' }} /></h1>
          <h1>Link Inválido</h1>

          <p>O link informado expirou ou não é válido!</p>
        </div>
      </div>
    )
  }

  const status = statusList[channel.status || 'DISCONNECTED']

  return (
    <div className="row center-center padding-32">
      <div className="row">
        <div className="row margin-bottom-32">
          <img src={img.logo} alt="Logo" width={350} />
        </div>

        <div className="row panel no-padding" style={{ width: 760 }}>
          <div className='row panel-header'>
            <i className="fa fa-cog"/><p className="flex padding-left-8">Conexão</p>
          </div>
          <div className="panel-body">
            <div className="row hero">
              <span>
                <p className='flex'><b>Canal:</b> {channel.name}</p>
              </span>
              <div className="flex">
                <div className="row justify-end">
                  <div className="span button radius primary" style={{ backgroundColor: status.color }}>
                    <i className={`fa fa-${status.icon}`} /> {status.label}
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-start qr-code-wrapper-content">
              {
                channel.status === 'CONNECTING' &&
                <div className="row margin-top-16">
                  <div className="grow padding-left-16">
                    <p>Para conectar seu telefone:</p>
                    <ol style={{ paddingLeft: 20 }}>
                      <li>Abra o WhatsApp no seu celular</li>
                      <li>
                          Clique em <b>Menu <i className="fa fa-ellipsis-v" /></b> ou&nbsp;
                        <b>Configurações <i className="fa fa-cog" /></b>
                      </li>
                      <li>Selecione Whatsapp Web</li>
                      <li>Clique em Conectar um aparelho</li>
                      <li>Escaneie o QrCode e Pronto!</li>
                    </ol>
                  </div>
                  <div
                    className="row center-center bordered border-radius"
                    style={{ width: 264, height: 264, overflow: 'hidden' }}
                  >
                    {
                      !!connectionQr?.qrCode &&
                      <img
                        alt="WQ QRCode"
                        src={connectionQr.qrCode}
                        style={{ width: 264, height: 264 }}
                      />
                    }
                  </div>
                </div>
              }

              {
                !!connectionQr?.message &&
                <div className="row alert-box warning">
                  <i className="fa fa-exclamation-triangle"/> { connectionQr?.message }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HashQrCode
