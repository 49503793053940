import React, { FormEvent, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearToken } from 'util/services/AuthService'
import { authenticate } from 'panel/user/redux/actions'
import Alert from 'util/modules/Alert'
import './styles/SignOn.scss'
import { img } from 'assets/online'

const SignIn: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [isFetching, setFetching] = useState(false)
  const [form, setForm] = useState({ username: '', password: '' })

  async function handleForm (e: FormEvent): Promise<void> {
    e.preventDefault()
    setFetching(true)
    clearToken()
    await authenticate(form)(dispatch).then(res => {
      if (res?.status === 'success' && res?.data?.token) {
        const query = new URLSearchParams(history.location.search)
        history.push(query.get('to') || '/panel')
      }
    })
    setFetching(false)
  }

  return (
    <>
      <Alert />
      <div className="row sign-container">
        <div className="sign-content">
          <div className="row sign-title-box ">
            <img src={img.logo} alt="Logo" />
            <h2 className="text-center">Faça login para acessar o painel</h2>
            <p className="text-center"><i className="fa fa-shield-alt" />Ambiente Seguro</p>
          </div>

          <form onSubmit={handleForm} className="row">
            {/* Inputs */}
            <div className="form-control">
              <label>E-mail</label>
              <div className="row">
                <span className='icon-widget'>
                  <i className="fas fa-envelope" />
                </span>
                <input
                  className='flex'
                  type="text"
                  value={form.username}
                  onChange={(e): void => setForm({ ...form, username: e.target.value })}
                  required={true}
                />
              </div>
            </div>
            <div className="form-control">
              <span className="row">
                <label className="flex">Senha</label>
                <NavLink to="/recovery" className="recovery-label">Esqueceu a senha?</NavLink>
              </span>
              <div className="row">
                <span className='icon-widget'>
                  <i className="fas fa-lock" />
                </span>
                <input
                  className='flex'
                  type="password"
                  value={form.password}
                  onChange={(e): void => setForm({ ...form, password: e.target.value })}
                  required={true}
                />
              </div>
            </div>

            {/* Buttons */}
            <div className="form-control">
              <button className="button button-entry col-12" disabled={isFetching}>
                {isFetching ? 'Fazendo login. Aguarde...' : 'Entrar'}
              </button>
            </div>
          </form>
          <p className="text-center underchat-copy-label margin-top-16 margin-bottom-8">
            Não tem uma conta?&nbsp;
            <NavLink style={{ color: 'var(--blue)' }} to="/sign-up">
              Registre-se agora
            </NavLink>
          </p>
          <p className="text-center underchat-copy-label">
            © {(new Date()).getFullYear()} UnderChat. Desenvolvido com 🤍 UnderChat
          </p>
        </div>
      </div>
    </>
  )
}

export default SignIn
