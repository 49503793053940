import React, { memo, useEffect } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'

import { DeleteNode } from './delete-node'
import { AttachmentManagement } from '../attachment/attachment.management'
import { NodeOptionItem, useOnChangeOption, useOnChangeValue } from './helpers/on-change-value.hook'
import { useRouteDisclosure, useSearchParams } from 'util/hooks'
import { getChatBotAttachmentList } from 'panel/chat-bot/service/chat-bot.attachment.service'
import { FormControlNode } from '../form-control.node'

interface Props extends NodeProps {
  data: {
    value: string,
    options: NodeOptionItem[]
  }
}
const SendAttachmentNode: React.FC<Props> = ({ id, data }) => {
  const onChangeOption = useOnChangeOption(id)

  const { setValue, delValue } = useSearchParams('attachmentType')
  const { onOpen } = useRouteDisclosure('attachment')
  const { data: attachments } = getChatBotAttachmentList()

  const type = data.options.find(item => item.key === 'type')
  const attachmentId = data.options.find(item => item.key === 'attachmentId')

  useEffect(() => {
    if (type && type.value) setValue(type.value)
    else delValue()
  }, [type])

  return (
    <div className="node" style={{ width: 300 }}>
      <DeleteNode id={id} />
      <p className="custom-drag-handle pt px">
        <i className="fa fa-paperclip" /> Envia anexo
      </p>
      <FormControlNode label='Tipo'>
        <select
          value={type?.value}
          onChange={e => {
            onChangeOption('type', e.target.value)
            onChangeOption('attachmentId', '')
          }}
        >
          <option>Selecione</option>
          <option value="IMAGE">Imagem</option>
          <option value="AUDIO">Áudio</option>
          <option value="FILE">Arquivo</option>
        </select>
      </FormControlNode>

      <FormControlNode label='Arquivo' pb>
        <div className='row' style={{ display: 'grid', gap: '8px', gridTemplateColumns: '1fr 48px' }}>
          <select value={attachmentId?.value} onChange={e => {
            onChangeOption('attachmentId', e.target.value)
          }}>
            <option value="">Selecione um arquivo</option>
            {
              attachments.map(attachment => (
                <option key={attachment.id} value={attachment.id}>{attachment.title}</option>
              ))
            }
          </select>
          <button onClick={onOpen} type="button" className='button' style={{ width: 48 }}>
            <i className="fa fa-plus" style={{ fontSize: 14 }}></i>
          </button>
        </div>
      </FormControlNode>

      <Handle
        id="left"
        type="target"
        position={Position.Left}
        style={{ left: -5, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="top"
        type="target"
        position={Position.Top}
        style={{ top: 3, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="bottom"
        type="source"
        position={Position.Bottom}
        style={{ bottom: 3, width: 12, height: 12, background: '#ff4081' }}
      />
      <Handle
        id="right"
        type="source"
        position={Position.Right}
        style={{ right: -5, width: 12, height: 12, background: '#ff4081' }}
      />
    </div>
  )
}

export default memo(SendAttachmentNode)
