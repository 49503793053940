import axios from 'axios'
import { isExpired, getToken, signOut } from './AuthService'

export default (): void => {
  let url = process.env.REACT_APP_API || ''
  if (!/(http|https)[:][/][/]/g.test(url)) url = `https://${url}`

  axios.defaults.baseURL = url
  axios.defaults.headers.post['Content-Type'] = 'application/json'

  // Intercept Request
  axios.interceptors.request.use(
    config => {
      const { token } = getToken()
      if (config?.headers && token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    },
    error => {
      return error
    }
  )

  // Intercept Response
  axios.interceptors.response.use(
    response => {
      if (response && response.data && response.data.code === 401) {
        console.log('RESPONSE 401')
        if (isExpired()) {
          signOut()
        }
      }

      return response
    },
    error => {
      if (error.response && error.response.status === 401) {
        console.log('ERROR 401')
        if (isExpired()) {
          signOut()
        }
      }
      return Promise.reject(error)
    }
  )
}
