/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import './styles/Conversation.scss'

import { hasPermission } from 'util/services/AuthService'
import { ApplicationState } from 'AppReducer'
import { normalizeAccents } from 'util/index'
import { Conversation as ConversationTypes } from './redux/types'
import { FilterCurrentConversations, FilterDetailed } from './components/partials/FilterCurrentConversations'

import Chat from './components/Chat'
import Modal from 'util/modules/Modal'
import ConversationTile from './components/ConversationTile'
import SearchConversation from './components/modals/SearchConversation'
import ConversationButtons from './components/ConversationButtons'
import RedirectRequests from 'panel/conversation-redirect/RedirectRequests'
import FinishConversationBatch from './components/modals/FinishConversationBatch'

const Conversation: React.FC = () => {
  const history = useHistory()
  const [filter, setFilter] = useState('')
  const [status, setStatus] = useState<ConversationTypes['status']>()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [filterDetailed, setFilterDetailed] = useState<FilterDetailed>()

  const { logged } = useSelector((state: ApplicationState) => state.userReducer)
  const { config } = useSelector((state: ApplicationState) => state.configReducer)
  const { conversations } = useSelector((state: ApplicationState) => state.conversationReducer)

  const isClient = !!logged?.roles.includes('ROLE_CLIENT')

  function s (string: string): string {
    return normalizeAccents(string).toLocaleLowerCase().trim()
  }

  function handleFilter (conversation: Partial<ConversationTypes>): boolean {
    if (!filter) return true

    const name = conversation.client?.person?.name
    const alias = conversation.client?.person?.alias
    const value = conversation.client?.value

    const findName = name && s(name).search(s(filter)) > -1
    const findAlias = alias && s(alias).search(s(filter)) > -1
    const findValue = value && value.search(filter) > -1

    return !!findName || !!findValue || !!findAlias
  }

  const list = conversations
    .filter(item => {
      if (filterDetailed && filterDetailed.value) {
        if (filterDetailed.key === 'date') {
          const createdAt = new Date(item.createdAt)
          return createdAt >= filterDetailed.value.start && createdAt <= filterDetailed.value.end
        }
        if (filterDetailed.key === 'attendant') {
          return item.attendants.some(item => item.id === filterDetailed.value)
        }
        if (filterDetailed.key === 'channel') return item.channel.id === filterDetailed.value
        if (filterDetailed.key === 'sector') return item.sector?.id === filterDetailed.value
        if (filterDetailed.key === 'tag') return item.tag?.id === filterDetailed.value
      }
      return item
    })
    .filter(item => !status || item.status === status)
    .filter(handleFilter)

  useEffect(() => {
    return () => {
      document.title = 'Under Chat'
    }
  }, [])

  if (config?.sortConversation === 'createdAt') {
    list.sort((a, b) => (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime())
  } else {
    list.sort((a, b) => (new Date(b.tick)).getTime() - (new Date(a.tick)).getTime())
  }

  const onClose = () => {
    setModalOpen(false)
    history.push('/panel/conversation')
    const element = document.querySelector('body')
    element!.style.overflow = 'auto'
  }

  return (
    <div className="row conversation">
      <div className="row conversation-sidebar">
        <ConversationButtons onStatusChange={setStatus} />

        {/* Filter */}
        <div className="row padding-tb-16" style={{ display: 'grid', gap: 6, gridTemplateColumns: '48px 48px 1fr' }}>
          <RedirectRequests />
          <SearchConversation />
          <div className="row" style={{ position: 'relative' }}>
            <input
              type="text"
              value={filter}
              placeholder="Buscar Atendimento"
              onChange={(e) => setFilter(e.target.value)}
              style={{ paddingRight: 46 }}
            />

            <FilterCurrentConversations onFilter={setFilterDetailed} />
          </div>
        </div>

        {!!(isClient && list.length > 0) && <FinishConversationBatch />}

        <div className="row sidebar-content margin-top-16">
          {
            hasPermission('CONVERSATION:GET_LIST') &&
            list.map((item) => {
              return <ConversationTile key={Math.random()} conversation={item} onClickOpen={() => setModalOpen(true)} />
            })
          }
          {
            list.length === 0 &&
            <div className="row panel">
              <h1 className="text-center"><i className="fa fa-envelope-open-text" /></h1>
              Nenhum atendimento encontrado
            </div>
          }
        </div>
      </div>
      <div className="hidden-mobile">
        <Chat />
      </div>

      <div className="hidden-desk">
        <Modal
          isOpen={modalOpen}
          onClose={onClose}
        >
          <div className='row justify-end'>
            <div className="mobile-close-button" onClick={onClose}><i className="fa fa-times"/></div>
          </div>
          <Chat />
        </Modal>
      </div>
    </div>
  )
}

export default Conversation
