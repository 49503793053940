import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { deleteChannel } from '../redux/actions'
import { formatDateTime, maskPhone } from 'util/masks'
import { ApplicationState } from 'AppReducer'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'

import { ReactComponent as Plug } from 'assets/icons/plug.svg'
import { hasPermission } from 'util/services/AuthService'

const ChannelsList: React.FC = () => {
  const [deleteId, setDeleteId] = useState<number>()

  const dispatch = useDispatch()
  const history = useHistory()

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { channels } = useSelector((state: ApplicationState) => state.channelReducer)

  function handleRemove (id: number): void {
    if (selected) {
      deleteChannel(selected, id)(dispatch).then(res => {
        if (res?.status === 'success') {
          setDeleteId(undefined)
          history.push('/panel/channel')
        }
      })
    }
  }

  if (!selected) return null

  return (
    <div className="list">
      <div className="list-header">
        <div>Id</div>
        <div>Conectado</div>
        <div>Nome</div>
        <div>Contato</div>
        <div>Criado</div>
        <div>Atualizado</div>
        <div>Ações</div>
      </div>

      {
        channels.length === 0 &&
        <div className="list-row empty">
          <Plug width={120} height={120} style={{ padding: 24 }} />
          <div className="grid-span" style={{ height: 0 }}>Nenhum canal cadastrado</div>
        </div>
      }

      {
        channels.map((channel, i) => {
          return (
            <div className="list-row" key={i}>
              <div data-content="Id" className="bold">#{channel.id}</div>
              <div data-content="Conectado">
                <div className={`status ${channel.status === 'CONNECTED' ? 'success' : 'danger'}`}>
                  { channel.status === 'CONNECTED' ? 'SIM' : 'NÃO' }
                </div>
              </div>
              <div data-content="Nome">{channel.name}</div>
              <div data-content="Contato">{maskPhone(channel.value)}</div>
              <div data-content="Criado">{formatDateTime(channel.createdAt)}</div>
              <div data-content="Atualizado">{formatDateTime(channel.updatedAt)}</div>
              <div data-content="Ações" className="row buttons-list-row">
                <div className="row">
                  <NavLink to={`/panel/channel/${channel.id}/config`} className="button">
                    <i className="fas fa-cog" /> &nbsp;Configurações
                  </NavLink>
                  {
                    hasPermission('CHANNEL:UPDATE') &&
                    <NavLink to={`/panel/channel/${channel.id}/edit`} className="button">
                      <i className="fas fa-edit" /> &nbsp;Editar
                    </NavLink>
                  }
                  {
                    hasPermission('CHANNEL:DELETE') &&
                    <span className="button" onClick={() => setDeleteId(channel.id)}>
                      <i className="fas fa-trash-alt" /> &nbsp;Excluir
                    </span>
                  }
                </div>
              </div>
            </div>
          )
        })
      }

      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </div>
  )
}

export default ChannelsList
