import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import EmojiPicker from 'emoji-picker-react'

import { ApplicationState } from 'AppReducer'
import { Conversation, Message } from '../redux/types'
import { createMessage, deleteChatMessage, joinConversation } from '../redux/actions'

import ChatHeader from './ChatHeader'
import MessageTile from './MessageTile'

// Attachment Components
import File from './attachment/buttons/File'
import Audio from './attachment/buttons/Audio'
import Video from './attachment/buttons/Video'
import Picture from './attachment/buttons/Picture'
import Shortcuts from './partials/Shortcuts'
import Reply from './partials/Reply'
import MessageLoadPrevious from './MessageLoadPrevious'
import FileAudio from './attachment/buttons/FileAudio'
import { hasPermission } from 'util/services/AuthService'
import Location from './attachment/buttons/Location'
import Contact from './attachment/buttons/Contact'

const ChatLoaded: React.FC<{ conversation: Conversation }> = ({ conversation }) => {
  const dispatch = useDispatch()

  const { id } = useParams<{ id: string }>()

  const [message, setMessage] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [isFetching, setFetching] = useState(false)
  const [showEmoji, setShowEmoji] = useState(false)
  const [replyMessage, setReplyMessage] = useState<Partial<Message>>()

  const { logged } = useSelector((state: ApplicationState) => state.userReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  const refMessages = useRef<HTMLDivElement>(null)
  const refTextArea = useRef<HTMLTextAreaElement>(null)

  async function handleJoin (): Promise<void> {
    if (selected && id) {
      setFetching(true)
      await joinConversation(selected, id)(dispatch)
      setFetching(false)
    }
  }

  async function sendMessage (e?: React.FormEvent) {
    e?.preventDefault()
    if (selected && message) {
      const formData = new FormData()
      if (message) formData.set('message', message)
      if (replyMessage && replyMessage.reference) formData.set('referenced', replyMessage.reference)

      setIsSending(true)
      await createMessage(selected, id, formData)(dispatch).then(res => {
        if (res?.status === 'success') {
          setMessage('')
          setReplyMessage(undefined)
        }
      })
      setIsSending(false)
    }
  }

  function scrollToBottom () {
    if (refMessages.current) {
      const element = refMessages.current
      const position = element.scrollHeight

      element.scrollTo(0, position)
    }
  }

  function handleEmoji (emoji: string) {
    if (refTextArea.current) {
      const pos = refTextArea.current.selectionStart
      setMessage(message.slice(0, pos) + emoji + message.slice(pos + 1))
    }
  }

  function handleDeleteMessage (message: Partial<Message>) {
    if (selected && message.id && window.confirm('Tem certeza que deseja remover?')) {
      deleteChatMessage(selected, conversation.id, message.id)
    }
  }

  useEffect(() => {
    if (refTextArea.current && !isSending) {
      refTextArea.current.focus()
    }
  }, [refTextArea, replyMessage, isSending])

  // Scroll down every time conversation update
  useEffect(() => {
    if (conversation && refMessages.current) {
      scrollToBottom()
    }
  }, [conversation, refMessages.current])

  const isClient = !!logged?.permissions.includes('CONVERSATION:VIEW_IN_BOT')

  const isAttendantInConversation = !!conversation.attendants.find(item => item.user?.id === logged?.id)
  const pointerEvents = isClient || (isAttendantInConversation || conversation.status === 'FINISHED') ? 'all' : 'none'

  const blockConversation = !isAttendantInConversation
  const blur = !isClient && blockConversation ? 'blur(5px)' : 'unset'

  return (
    <>
      {
        blockConversation &&
        <div className={`row attendant-join-box ${isClient ? 'admin' : ''}`}>
          <div className="row">
            <div className="row">
              <h1>Conversa Automatizada</h1>
              <p>Para interagir com a conversa é necessário ingressar</p>
            </div>
            {
              hasPermission('CONVERSATION:JOIN') &&
              <button className="gradient margin-top-16" disabled={isFetching} onClick={handleJoin}>
                {
                  isFetching
                    ? <><i className="fa fa-spin fa-spinner" /> Entrando. Aguarde...</>
                    : <><i className="fa fa-user-plus" /> Atender</>
                }
              </button>
            }
          </div>
        </div>
      }
      <div className="row conversation-content" style={{ filter: blur }} >
        <ChatHeader conversation={conversation} canInteract={!blockConversation} />

        <div className="row messages" ref={refMessages} style={{ pointerEvents }}>
          <MessageLoadPrevious conversationId={conversation.id} />
          {
            conversation.messages.map((message) => (
              <MessageTile
                key={message.id}
                message={message}
                handleReply={setReplyMessage}
                handleDelete={message.sender?.type !== 'CLIENT' ? handleDeleteMessage : undefined}
              />
            ))
          }
        </div>

        <form className="row footer" onSubmit={sendMessage}>
          <Reply message={replyMessage} close={() => setReplyMessage(undefined)} />
          <Shortcuts message={message} onSelect={setMessage} onClear={() => setMessage('')} />

          <textarea
            className="flex"
            value={message}
            ref={refTextArea}
            disabled={isSending}
            style={{ border: 0 }}
            placeholder="Escreva sua mensagem aqui..."
            onChange={(e) => {
              setMessage(e.target.value)
              const lines = Math.min(4, e.target.value.split('\n').length)
              const lineHeight = 27.5

              if (refTextArea.current) {
                refTextArea.current.style.height = `${lineHeight * lines}px`
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                sendMessage()

                if (refTextArea.current) {
                  refTextArea.current.style.height = '55px'
                }
              }
            }}
          />

          {
            showEmoji && // Emoji Button
              <div className="emoji-box" style={{ left: 16 }}>
                <EmojiPicker onEmojiClick={(e, { emoji }) => handleEmoji(emoji)} />
              </div>
          }
          <span className="button secondary emoji" onClick={() => setShowEmoji(!showEmoji)}>
            <i className="far fa-grin-alt" />
          </span>

          <div className="attachments">
            <File />
            <FileAudio />
            <Video />
            <Picture />
            <Location />
            <Contact />
            <span className="button secondary shadow main">
              <i className="fas fa-paperclip" />
            </span>
          </div>

          <Audio />
          <button className="primary button send" disabled={isSending || !hasPermission('CONVERSATION:SEND_MESSAGE')}>
            <i className="fas fa-paper-plane" />
          </button>
        </form>

      </div>
    </>
  )
}

export default ChatLoaded
