import React, { useState } from 'react'
import { Message } from 'panel/conversation/redux/types'

import { img } from 'assets/online'

const Video: React.FC<{ message: Partial<Message> }> = ({ message }) => {
  const [isOpen, setOpen] = useState(false)

  if (!message.attachment) return null

  return (
    <>
      {
        isOpen &&
        <div className="preview">
          <div className="row">
            <i className="fa fa-times btn-close" onClick={() => setOpen(false)} />
            <a href={message.attachment?.url} target="_blank" rel="noreferrer" className="btn-open">
              <i className="fa fa-download" />
            </a>

            <video height={600} controls autoPlay>
              <source src={message.attachment.url} />
            </video>
            <p>{message.message}</p>
          </div>
        </div>
      }

      <div className="video-attachment" onClick={() => setOpen(true)}>
        <img src={img['pay-btn']} alt="Play Button" />
        <video width={300}>
          <source src={message.attachment.url} />
        </video>
      </div>
    </>
  )
}

export default Video
