import { PersonState, PersonActionTypes, PersonTypes } from '../types/person'
import { Reducer } from 'redux'

const initialState: PersonState = {
  persons: { items: [] }
}

type ReducerOpts = Reducer<PersonState, PersonActionTypes>
const reducer: ReducerOpts = (state = initialState, action: PersonActionTypes): PersonState => {
  let index = -1
  switch (action.type) {
    case PersonTypes.PERSON_RESET:
      return initialState
    case PersonTypes.PERSON_CREATE:
      return {
        ...state,
        person: action.payload,
        persons: {
          pagination: state.persons.pagination,
          items: [...state.persons.items, action.payload]
        }
      }
    case PersonTypes.PERSON_UPDATE:
      index = state.persons.items.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        person: action.payload,
        persons: {
          pagination: state.persons.pagination,
          items: [...state.persons.items.slice(0, index), action.payload, ...state.persons.items.slice(index + 1)]
        }
      }
    case PersonTypes.PERSON_DELETE:
      index = state.persons.items.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        persons: {
          pagination: state.persons.pagination,
          items: [...state.persons.items.slice(0, index), ...state.persons.items.slice(index + 1)]
        }
      }
    case PersonTypes.PERSON_GET:
      return { ...state, person: action.payload }
    case PersonTypes.PERSON_GET_LIST:
      return { ...state, persons: { items: action.payload, pagination: action.pagination } }
    case PersonTypes.PERSON_UPDATE_CONTACT:
      return {
        ...state,
        person: state.person
          ? ({
            ...state.person,
            contacts: state.person.contacts.map((contact) => {
              if (contact.id === action.payload.id) {
                contact['status'] = action.payload.status
              }
              return { ...contact }
            })
          })
          : undefined,
        persons: {
          pagination: state.persons.pagination,
          items: state.persons.items.map(person => {
            person.contacts.forEach((contact, index) => {
              if (contact.id === action.payload.id) {
                person.contacts[index]['status'] = action.payload.status
              }
            })
            return { ...person }
          })
        }
      }
    default:
      return state
  }
}

export default reducer
