import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import { validateAllContacts } from './redux/action/contact'

const ValidateContacts: React.FC = () => {
  const dispatch = useDispatch()
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleValidate () {
    if (selected) {
      validateAllContacts(selected)(dispatch)
    }
  }

  return (
    <span className="button secondary radius margin-right-8" onClick={handleValidate}>
      <i className="fas fa-paper-plane" style={{ color: 'var(--highlight)' }} /> Validar Pendentes
    </span>
  )
}

export default ValidateContacts
