/* eslint-disable max-len */
import React from 'react'

import { img } from 'assets/online'
import { ReactComponent as LoaderIcon } from 'assets/icons/loader.svg'

const Loader: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="row center-center">
      <div className="row text-center">
        <img src={img.logo} width={400} alt="Logo" />
        <h2 className="margin-top-16">{message}</h2>

        <LoaderIcon />
      </div>
    </div>
  )
}

export default Loader
