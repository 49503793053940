/* eslint-disable @typescript-eslint/no-use-before-define */
import Axios from 'axios'
import { Dispatch } from 'redux'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'
import { ActionResponse, Id } from 'util/types/index'
import { TimeTable, TimeTableTypes } from './types'

export function createTimeTable (storeId: Id, attendantId: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<TimeTable>> => {
    return Axios
      .post(`/store/${storeId}/attendant/${attendantId}/time-table`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: TimeTableTypes.TIMETABLE_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar a configuração de horário')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateTimeTable (storeId: Id, attendantId: Id, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<TimeTable>> => {
    return Axios
      .put(`/store/${storeId}/attendant/${attendantId}/time-table/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: TimeTableTypes.TIMETABLE_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar a configuração de horário')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteTimeTable (storeId: Id, attendantId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<TimeTable>> => {
    return Axios
      .delete(`/store/${storeId}/attendant/${attendantId}/time-table/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: TimeTableTypes.TIMETABLE_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover a configuração de horário')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getTimeTableList (storeId: Id, attendantId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<TimeTable>> => {
    return Axios
      .get(`/store/${storeId}/attendant/${attendantId}/time-table`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: TimeTableTypes.TIMETABLE_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}
