import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'

const UserExtraMenu: React.FC = () => {
  const { user } = useSelector((state: ApplicationState) => state.userReducer)

  if (!user) {
    return null
  }

  return (
    <div className="row justify-end">
      <NavLink to={`/panel/person/${user.person}/contact`} className="button secondary">
        Contatos
      </NavLink>
    </div>
  )
}

export default UserExtraMenu
