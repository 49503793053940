import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BotOption } from '../redux/type/option'
import { formatDate } from 'util/masks'
import { ApplicationState } from 'AppReducer'
import { deleteBotOption, updateBotOption } from '../redux/action/option'

import Audio from 'util/modules/Audio/Audio'
import Parameter from './BotOptionTileParameter'
import Attachment from 'util/modules/Audio/Attachment'
import Upload, { UploadRef } from 'util/modules/Upload'
import InputNumber from 'util/modules/Form/InputNumber'

const initialState = {
  order: 1,
  isActive: true,
  type: 'MESSAGE',
  mode: 'AUTO',
  code: '',
  name: '',
  message: '',
  actionType: '',
  parameters: ''
}
const BotOptionTile: React.FC<{ optionId: number }> = ({ optionId }) => {
  const dispatch = useDispatch()
  const [form, setForm] = useState(initialState)
  const [file, setFile] = useState<File>()
  const [attachmentUrl, setAttachmentUrl] = useState('')

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { botOptions } = useSelector((state: ApplicationState) => state.botOptionReducer)

  const option = botOptions.find(item => item.id === optionId)

  const uploadRef = useRef<UploadRef>(null)

  function handleUpdate (e: React.FormEvent): void {
    e.preventDefault()
    if (option && selected) {
      const botId = option.bot?.id || 0

      const formData = new FormData()

      formData.set('order', String(form.order))
      formData.set('isActive', (form.isActive ? 1 : 0).toString())
      formData.set('type', form.type)
      formData.set('mode', form.mode)
      formData.set('code', form.code)
      formData.set('name', form.name)
      formData.set('message', form.message)
      formData.set('actionType', form.actionType)
      formData.set('parameters', form.parameters)

      if (file) formData.set('attachment', file)

      if (!form.message && !file) {
        alert('Necessário enviar texto ou anexo')
      } else {
        updateBotOption(selected, botId, option.id, formData)(dispatch).then(res => {
          if (res.data && res.status === 'success') {
            handleFillState(res.data)
          }
        })
      }
    }
  }

  function handleRemove (): void {
    if (option && selected && window.confirm('Tem certeza que deseja remover a opção?')) {
      deleteBotOption(selected, option.bot?.id || 0, option.id)(dispatch)
    }
  }

  function handleSelectAttachment (file?: File) {
    if (file) {
      setFile(file)
      const fReader = new FileReader()

      fReader.onload = (e) => {
        setAttachmentUrl(e.target?.result?.toString() || '')
      }
      fReader.readAsDataURL(file)
    } else {
      setFile(undefined)
      setAttachmentUrl('')
    }
  }

  function handleFillState (option?: BotOption): void {
    if (option) {
      setForm({
        isActive: option.isActive,
        order: option.order,
        mode: option.mode,
        type: option.type || 'MESSAGE',
        code: option.code || '',
        name: option.name || '',
        message: option.message || '',
        actionType: option.actionType || '',
        parameters: option.parameters || ''
      })

      if (option.type !== 'MESSAGE' && option.message) {
        setFile(undefined)
        setAttachmentUrl(option.message)
        uploadRef.current?.setPreviewImage(option.message)
      }
    }
  }

  useEffect(() => {
    handleFillState(option)
  }, [option, uploadRef])

  if (!option) return null

  return (
    <form className="row bot-option" onSubmit={handleUpdate}>

      <div className="row">
        <div className="row col-6 justify-start">
          <label>
            <label className="switch">
              <input
                readOnly
                type='checkbox'
                checked={form.isActive}
              />
              <span className="slider round" />
            </label>&nbsp;&nbsp;Ativo
          </label>
        </div>
        <div className="row col-6 justify-start">
          <label>
            <label className="switch">
              <input
                readOnly
                type='checkbox'
                checked={form.isActive}
              />
              <span className="slider round" />
            </label>&nbsp;&nbsp;Automático
          </label>
        </div>

        <div className="row align-end">
          <div className="row">
            <div className="form-control col-6">
              <label>Nome</label>
              <input
                type="text"
                value={form.name}
                onChange={(e): void => setForm({ ...form, name: e.target.value })}
              />
            </div>
            <div className="row col-6">
              <div className="form-control col-5">
                <label>Modo</label>
                <select
                  required
                  value={form.mode}
                  disabled={form.type !== 'MESSAGE'}
                  onChange={(e): void => setForm({ ...form, mode: e.target.value })}
                >
                  <option value='AUTO'>Automático</option>
                  <option value='SINGLE'>Resposta Única</option>
                  <option value='MULTIPLE'>Resposta Múltipla</option>
                </select>
              </div>
              <div className="form-control col-4">
                <label>Ordem</label>
                <InputNumber value={form.order} onChange={(order) => setForm({ ...form, order })} />
              </div>
              <div className="form-control col-3">
                <label>Código</label>
                <input
                  type="text"
                  value={form.code}
                  onChange={(e): void => setForm({ ...form, code: e.target.value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-control">
            <label>Forma de Envio</label>
            <select
              required
              value={form.type}
              onChange={(e): void => {
                const type = e.target.value
                if (type === 'MESSAGE') {
                  setForm({ ...form, type, message: '' })
                } else {
                  setForm({ ...form, type, message: '', mode: 'AUTO' })
                }
                setFile(undefined)
                setAttachmentUrl('')
              }}
            >
              <option value='MESSAGE'>Mensagem</option>
              <option value='ATTACHMENT_AUDIO'>Anexar Áudio</option>
              <option value='ATTACHMENT_FILE'>Anexar Arquivo</option>
              <option value='ATTACHMENT_PICTURE'>Anexar Imagem</option>
            </select>
          </div>
          {
            form.type === 'MESSAGE' && (
              <div className="form-control">
                <label>Mensagem</label>
                <textarea
                  rows={4}
                  required
                  value={form.message}
                  onChange={(e): void => setForm({ ...form, message: e.target.value })}
                />
              </div>
            )
          }
          {
            form.type === 'ATTACHMENT_AUDIO' && (
              <div className="form-control">
                <label>Anexar Áudio</label>
                <div className="row input required" style={{ height: 72 }}>
                  <Audio onRecord={handleSelectAttachment} />
                  <Attachment
                    acceptedMimeTypes={['audio/ogg', 'audio/wav', 'audio/mp3', 'audio/mp4', 'audio/mpeg']}
                    onSelect={handleSelectAttachment}
                  />
                  {
                    !!attachmentUrl &&
                    <audio controls style={{ flexGrow: 1 }}>
                      <source src={attachmentUrl} />
                    </audio>
                  }
                </div>
              </div>
            )
          }
          {
            form.type === 'ATTACHMENT_FILE' && (
              <div className="form-control">
                <label>Anexar Arquivo</label>
                <div className="row input required" style={{ height: 72 }}>
                  <Attachment
                    acceptedMimeTypes={['application/pdf']}
                    onSelect={handleSelectAttachment}
                  />
                  {
                    !!attachmentUrl &&
                    <div className="grow padding-left-16">
                      <div className="row">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="bold"
                          href={attachmentUrl}
                          style={{ overflow: 'hidden', height: 19, width: '100%' }}
                        >
                          {file?.name || option?.message?.split('/').pop()}
                        </a>
                        <p>
                          {file?.type || `image/${option?.message?.split('.').pop() || 'jpeg'}`}
                          &nbsp;-&nbsp;
                          {formatDate(file ? (new Date(file.lastModified)).toISOString() : option?.updatedAt)}
                        </p>
                      </div>
                    </div>
                  }
                </div>
              </div>
            )
          }

          <div
            className="form-control"
            style={{ display: form.type === 'ATTACHMENT_PICTURE' ? 'inherit' : 'none' }}
          >
            <label>Anexar Imagem</label>
            <div className="row input required" style={{ height: 72 }}>
              <div className="attachment_picture" style={{ width: 50, height: 50 }}>
                <Upload ref={uploadRef} placeholder={false} onChange={handleSelectAttachment} />
              </div>
              <div className="grow padding-left-16">
                <div className="row">
                  <p className="bold" style={{ overflow: 'hidden', height: 19 }}>
                    {file?.name || option?.message?.split('/').pop()}
                  </p>
                  <p>
                    {file?.type || `image/${option?.message?.split('.').pop() || 'jpeg'}`}
                      &nbsp;-&nbsp;
                    {formatDate(file ? (new Date(file.lastModified)).toISOString() : option?.updatedAt)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="form-control">
            <label>Tipo</label>
            <select
              required
              value={form.actionType}
              onChange={(e): void => {
                if (e.target.value !== 'Nenhum') {
                  setForm({ ...form, actionType: e.target.value, parameters: '' })
                } else {
                  setForm({ ...form, actionType: '' })
                }
              }}
            >
              <option>Nenhum</option>
              <option value="FILL_PERSON_DATA">Preenche Informação</option>
              <option value="FINISH_CONVERSATION">Finaliza Atendimento</option>
              <option value="REDIRECT_BOT">Redireciona BOT</option>
              <option value="RESPONSE_MESSAGE">Responde Mensagem</option>
              <option value="REDIRECT_SECTOR">Redireciona Setor</option>
              <option value="REQUEST_REVIEW">Solicita Avaliação</option>
            </select>
          </div>

          <Parameter
            parameters={option.parameters}
            actionType={form.actionType as 'REDIRECT_BOT'}
            onSelect={(parameters): void => setForm({ ...form, parameters })}
          />

          <div className="row form-control justify-end margin-top-16">
            <span className="button secondary margin-right-8" style={{ width: 200 }} onClick={handleRemove}>
              Apagar
            </span>
            <button className="primary button" style={{ width: 200 }}>Salvar</button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default BotOptionTile
