import { BotState, BotTypes, BotActionTypes, Bot } from '../type/bot'
import { Reducer } from 'redux'

const initialState: BotState = {
  bots: []
}

function sortBot (bots: Bot[]): Bot[] {
  bots.sort((a, b) => a.order - b.order)
  return bots
}

type ReducerOpts = Reducer<BotState, BotActionTypes>
const reducer: ReducerOpts = (state = initialState, action: BotActionTypes): BotState => {
  let index = -1
  switch (action.type) {
    case BotTypes.BOT_RESET:
      return initialState
    case BotTypes.BOT_CREATE:
      return { ...state, bot: action.payload, bots: sortBot([...state.bots, action.payload]) }
    case BotTypes.BOT_UPDATE:
      index = state.bots.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        bot: action.payload,
        bots: sortBot([...state.bots.slice(0, index), action.payload, ...state.bots.slice(index + 1)])
      }
    case BotTypes.BOT_DELETE:
      index = state.bots.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        bot: undefined,
        bots: sortBot([...state.bots.slice(0, index), ...state.bots.slice(index + 1)])
      }
    case BotTypes.BOT_GET:
      return { ...state, bot: action.payload }
    case BotTypes.BOT_GET_LIST:
      return { ...state, bots: action.payload }
    default:
      return state
  }
}

export default reducer
