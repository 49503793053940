import { StoreState, StoreTypes, StoreActionTypes } from './types'
import { Reducer } from 'redux'

const initialState: StoreState = {
  stores: [],
  seller: { items: [] }
}

type ReducerOpts = Reducer<StoreState, StoreActionTypes>
const reducer: ReducerOpts = (state = initialState, action: StoreActionTypes): StoreState => {
  let index = -1
  switch (action.type) {
    case StoreTypes.STORE_RESET:
      return initialState
    case StoreTypes.STORE_CREATE:
      return { ...state, store: action.payload, stores: [...state.stores, action.payload] }
    case StoreTypes.STORE_UPDATE:
      index = state.stores.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        store: action.payload,
        stores: [...state.stores.slice(0, index), action.payload, ...state.stores.slice(index + 1)]
      }
    case StoreTypes.STORE_DELETE:
      return {
        ...state,
        store: undefined,
        stores: state.stores.filter(item => item.id !== action.payload)
      }
    case StoreTypes.STORE_GET:
      return { ...state, store: action.payload }
    case StoreTypes.STORE_GET_LIST:
      return { ...state, stores: action.payload }
    case StoreTypes.STORE_GET_SELLER_LIST:
      return { ...state, seller: { items: action.payload, pagination: action.pagination } }
    case StoreTypes.STORE_GET_CLIENT_LIST:
      return { ...state, stores: action.payload }
    case StoreTypes.STORE_SELECT:
      return { ...state, selected: action.payload }
    default:
      return state
  }
}

export default reducer
