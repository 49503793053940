import React, { memo } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import { DeleteNode } from './delete-node'

interface Props extends NodeProps {}
const FinishConversationNode: React.FC<Props> = ({ id }) => {
  return (
    <div className="node">
      <DeleteNode id={id} />
      <p className='custom-drag-handle px py row'>
        <i className="fa fa-times" /> Finaliza Atendimento
      </p>

      <Handle
        id="left"
        type="target"
        position={Position.Left}
        style={{ left: -5, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="top"
        type="target"
        position={Position.Top}
        style={{ top: 3, width: 12, height: 12, background: '#00ECBC' }}
      />
    </div>
  )
}

export default memo(FinishConversationNode)
