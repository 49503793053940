import { SellerState, SellerTypes, SellerActionTypes } from './types'
import { Reducer } from 'redux'

const initialState: SellerState = {
  sellers: []
}

type ReducerOpts = Reducer<SellerState, SellerActionTypes>
const reducer: ReducerOpts = (state = initialState, action: SellerActionTypes): SellerState => {
  let index = -1
  switch (action.type) {
    case SellerTypes.SELLER_RESET:
      return initialState
    case SellerTypes.SELLER_CREATE:
      return { ...state, seller: action.payload, sellers: [...state.sellers, action.payload] }
    case SellerTypes.SELLER_UPDATE:
      index = state.sellers.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        seller: action.payload,
        sellers: [...state.sellers.slice(0, index), action.payload, ...state.sellers.slice(index + 1)]
      }
    case SellerTypes.SELLER_DELETE:
      index = state.sellers.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        seller: undefined,
        sellers: [...state.sellers.slice(0, index), ...state.sellers.slice(index + 1)]
      }
    case SellerTypes.SELLER_GET:
      return { ...state, seller: action.payload }
    case SellerTypes.SELLER_GET_LOGGED:
      return { ...state, logged: action.payload }
    case SellerTypes.SELLER_GET_LIST:
      return { ...state, sellers: action.payload, pagination: action.pagination }
    default:
      return state
  }
}

export default reducer
