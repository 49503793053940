import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
 * Data Types
*/

export interface MessageDataBase {
  id: number,
  shortcut: string,
  message: string,
  attachmentType?: 'FILE'|'AUDIO'|'PICTURE',
  attachmentUrl?: string,
  createdAt: string,
  updatedAt?: string
}

/**
 * State Type
*/

export interface MessageDataBaseState {
  readonly message?: MessageDataBase
  readonly messages: MessageDataBase[]
}

/**
 * Action Enum Types
*/

export enum MessageDataBaseTypes {
  MESSAGE_RESET = 'MESSAGE/RESET',
  MESSAGE_CREATE = 'MESSAGE/CREATE',
  MESSAGE_UPDATE = 'MESSAGE/UPDATE',
  MESSAGE_DELETE = 'MESSAGE/DELETE',
  MESSAGE_GET = 'MESSAGE/GET',
  MESSAGE_GET_LIST = 'MESSAGE/GET/LIST'
}

/**
 * Action Types
*/

interface ResetAction {
  type: typeof MessageDataBaseTypes.MESSAGE_RESET
}

interface CreateAction {
  type: typeof MessageDataBaseTypes.MESSAGE_CREATE,
  payload: MessageDataBase
}

interface UpdateAction {
  type: typeof MessageDataBaseTypes.MESSAGE_UPDATE,
  payload: MessageDataBase
}

interface DeleteAction {
  type: typeof MessageDataBaseTypes.MESSAGE_DELETE,
  payload: number
}

interface GetAction {
  type: typeof MessageDataBaseTypes.MESSAGE_GET,
  payload: MessageDataBase
}

interface GetListAction {
  type: typeof MessageDataBaseTypes.MESSAGE_GET_LIST,
  payload: MessageDataBase[]
}
export type MessageDataBaseActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction
)
