import React, { useEffect, useState } from 'react'

import { FilterTag } from './filters/FilterTag'
import { FilterData } from './filters/FilterData'

import '../../styles/FilterCurrentConversation.scss'
import { FilterChannel } from './filters/FilterChannel'
import { FilterAttendant } from './filters/FilterAttendant'
import { FilterSector } from './filters/FilterSector'

export type FilterDetailed = (
  { key: 'date', value: { start: Date, end: Date } } |
  { key: 'tag', value: number } |
  { key: 'sector', value: number } |
  { key: 'channel', value: number } |
  { key: 'attendant', value: number }
)

export const FilterCurrentConversations: React.FC<{ onFilter: (filter?: FilterDetailed) => void }> = ({ onFilter }) => {
  const [isOpen, setOpen] = useState(false)
  const [filter, setFilter] = useState<FilterDetailed>()
  const [selected, setSelected] = useState<string>()

  const availableFilters = [
    { icon: 'calendar-alt', key: 'date', value: 'Data' },
    { icon: 'tags', key: 'tag', value: 'Etiqueta' },
    { icon: 'plug', key: 'channel', value: 'Canal' },
    { icon: 'user-tie', key: 'attendant', value: 'Atendente' },
    { icon: 'server', key: 'sector', value: 'Setor' }
  ]

  useEffect(() => {
    setFilter(undefined)
  }, [isOpen, selected])

  useEffect(() => {
    document.addEventListener('click', (e) => {
      const target = e.target as HTMLDivElement
      const isBox = target.closest('.filter-current-conversation')
      const isButton = target.closest('.filter-current-conversation-btn')

      if (!isBox && !isButton) setOpen(false)
    })

    return () => document.removeEventListener('click', () => null)
  }, [])

  return (
    <>
      <span
        title="Filtrar mensagens"
        style={{ width: 48, position: 'absolute', right: 0 }}
        onClick={() => setOpen(!isOpen)}
        className="button secondary filter-current-conversation-btn"
      >
        <i className="fas fa-filter"></i>
      </span>
      {
        isOpen &&
        <div className="row justify-start filter-current-conversation shadow">
          {
            availableFilters.map(item => {
              const isOpened = selected === item.key
              return (
                <div
                  key={item.key}
                  className="row button secondary"
                  onClick={() => setSelected(isOpened ? undefined : item.key)}
                >
                  <p className="row" style={{ justifyContent: 'space-between' }} >
                    <span><i className={`fa fa-${item.icon}`}></i> {item.value}</span>
                    {
                      isOpened
                        ? <i className="fa fa-angle-up" />
                        : <i className="fa fa-angle-down" />
                    }
                  </p>
                  {
                    isOpened &&
                    (
                      (
                        item.key === 'date' &&
                        <FilterData onChange={(start, end) => setFilter({ key: 'date', value: { start, end } })} />
                      ) ||
                      (
                        item.key === 'tag' &&
                        <FilterTag onChange={value => setFilter({ key: 'tag', value })} />
                      ) ||
                      (
                        item.key === 'channel' &&
                        <FilterChannel onChange={value => setFilter({ key: 'channel', value })} />
                      ) ||
                      (
                        item.key === 'attendant' &&
                        <FilterAttendant onChange={value => setFilter({ key: 'attendant', value })} />
                      ) ||
                      (
                        item.key === 'sector' &&
                        <FilterSector onChange={value => setFilter({ key: 'sector', value })} />
                      )
                    )
                  }
                </div>
              )
            })
          }

          <button
            className="gradient col-12"
            onClick={e => {
              e.preventDefault()
              onFilter(filter)
            }}
            style={{ justifyContent: 'center' }}
          >
            Filtrar
          </button>
          <span
            className="button secondary col-12 margin-top-8"
            onClick={e => {
              e.preventDefault()
              setSelected(undefined)
              onFilter(undefined)
            }}
            style={{ justifyContent: 'center' }}
          >
            Limpar
          </span>
        </div>
      }
    </>
  )
}
