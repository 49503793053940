import { getId } from 'panel/chat-bot/service/chat-bot.service'
import { ChangeEvent } from 'react'
import { useReactFlow, useStoreApi } from 'reactflow'

export type NodeOptionItem = {
  id: string;
  key: string;
  value: string;
}

export const useOnChangeValue = (nodeId: string) => {
  const { setNodes } = useReactFlow()
  const store = useStoreApi()

  const onChange = (evt: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { nodeInternals } = store.getState()
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            value: evt.target.value?.slice(0, 500) || ''
          }
        }

        return node
      })
    )
  }

  return onChange
}

export const useOnChangeLabel = (nodeId: string) => {
  const { setNodes } = useReactFlow()
  const store = useStoreApi()

  const onChange = (evt: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { nodeInternals } = store.getState()
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            label: evt.target.value
          }
        }

        return node
      })
    )
  }

  return onChange
}

export const useAddOption = (nodeId: string) => {
  const { setNodes } = useReactFlow()
  const store = useStoreApi()

  const handleAddOption = (option: { key: string, value: string }) => {
    const { nodeInternals } = store.getState()

    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            options: [
              ...node.data.options,
              {
                id: getId(),
                ...option
              }
            ]
          }
        }

        return node
      })
    )
  }

  return handleAddOption
}

export const useSetOptions = (nodeId: string) => {
  const { setNodes } = useReactFlow()
  const store = useStoreApi()
  return (fn: (actualItems: NodeOptionItem[]) => NodeOptionItem[]) => {
    const { nodeInternals } = store.getState()
    const node = nodeInternals.get(nodeId)
    const prevItems = node?.data.options || []

    const result = fn(prevItems)

    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            options: result
          }
        }

        return node
      })
    )
  }
}

export const useRemoveOption = (nodeId: string, key: string) => {
  const { setNodes } = useReactFlow()
  const store = useStoreApi()

  const handleRemove = () => {
    const { nodeInternals } = store.getState()
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            options: node.data.options
              .filter((item: { key: string, value: string }) => item.key !== key)
              .map((item: { key: string, value: string }, i: number) => {
                if (item.key === key) {
                  return {
                    ...item,
                    key: String(i + 1)
                  }
                }

                return item
              })
          }
        }

        return node
      })
    )
  }

  return handleRemove
}

export const useOnChangeOption = (nodeId: string) => {
  const { setNodes } = useReactFlow()
  const store = useStoreApi()

  const onChange = (key: string, value: string) => {
    const { nodeInternals } = store.getState()
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            options: node.data.options.map((item: { key: string, value: string }) => {
              if (item.key === key) {
                return {
                  ...item,
                  value
                }
              }

              return item
            })
          }
        }

        return node
      })
    )
  }

  return onChange
}
