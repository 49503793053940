import { useDispatch } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { Api } from 'util/services/api'
import { ChatBotConfigSchemaType } from '../schema/chat-bot.schema'
import { clearAlert, createAlert } from 'util/modules/Alert/redux/actions'

export const saveChatBotConfig = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    async (form: ChatBotConfigSchemaType) => Api.put('/bot/config', form),
    {
      onSuccess: async ({ statusCode, message = 'Bot Salvo' }) => {
        await queryClient.invalidateQueries(['chat-bot-config'])
        createAlert({ message, code: statusCode })(dispatch)
      },
      onError: (error: Error) => {
        createAlert({ message: error.message || 'Ocorreu um erro ao salvar as configurações', code: 500 })(dispatch)
      },
      onSettled: () => clearAlert(3_000)(dispatch)
    }
  )
}

export const getChatBotConfig = () => {
  return useQuery<GetBotConfigResponse>(
    ['chat-bot-config'],
    async () => {
      const { data } = await Api.get<GetBotConfigResponse>('/bot/config')
      return data
    },
    {
      enabled: true,
      initialData: {
        id: '',
        storeId: '',
        enabled: true,

        configSector: {
          redirectAuto: false,
          redirectAutoInMinutes: 0,

          redirectWrongMenu: true,
          redirectWrongMenuAttempts: 5
        }
      }
    }
  )
}

type GetBotConfigResponse = {
  id: string,
  storeId: string,

  enabled: true,

  configSector: {
    redirectAuto: boolean
    redirectMessage?: string
    redirectSectorId?: string
    redirectAutoInMinutes: number,

    redirectWrongMenu: boolean,
    redirectWrongMenuAttempts: number
  },
  redirectAttendantMessage?: string,
}
