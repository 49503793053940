import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import '../styles/Sidebar.scss'

import { img } from 'assets/online'
import { signOut } from 'util/services/AuthService'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'AppReducer'

import Menu from './Menu'
import Config from 'panel/config/Config'
import Profile from 'panel/profile/Profile'
import RedirectRequests from 'panel/conversation-redirect/RedirectRequests'

type Props = { sideBarCollapsed: boolean, onSidebarCollapsedChange(): void }
const Sidebar: React.FC<Props> = ({ onSidebarCollapsedChange, sideBarCollapsed }) => {
  const [isInfoOpen, setInfoOpen] = useState<boolean>(false)
  const { logged } = useSelector((state: ApplicationState) => state.userReducer)

  function handleSignOut () {
    if (window.confirm('Tem certeza que gostaria de sair?')) {
      signOut()
    }
  }

  const picture = logged?.person?.picture || `${img['default-user']}`

  return (
    <div>
      <span className={`hidden-desk ${sideBarCollapsed ? 'sidebar-bg' : ''}`} onClick={onSidebarCollapsedChange} />
      <div className={`sidebar ${sideBarCollapsed ? 'open' : ''}`} >
        <NavLink className="row logo" to="/panel">
          <img src={img.logoIcon} className="img-logo-icon" alt="Logo" />
          <img src={img.logo} className="img-logo" alt="Logo" />
        </NavLink>
        <span className="angle-btn hidden-mobile" onClick={onSidebarCollapsedChange}>
          <i className="fas fa-angle-right" />
          <i className="fas fa-angle-left" />
        </span>
        <div className="row menu-content">
          <Menu />
        </div>

        <div className="row sidebar-footer">
          <Profile />
          <div className="flex row config-button">
            <RedirectRequests style={{ background: 'none', color: 'inherit' }} />
          </div>
          <div className="flex row config-button">
            <Config />
          </div>
        </div>

        <div className="row profile-config">
          <Config mode="COG" />

          <div className="row profile-config-btn" onClick={(e: any) => {
            const isFromButton = !!e.target.closest('.button')
            if (isFromButton) setInfoOpen(!isInfoOpen)
          }}>
            <div className="button">
              <img src={picture} width={40} height={40} alt="Profile" />
            </div>

            {
              isInfoOpen &&
              <div className="row panel info-box">
                <Profile mode="INFO" />
                <Config mode="INFO" />
                <hr className="margin-tb-8" />
                <div className="row padding-tb-8 pointer" onClick={handleSignOut}>
                  <p className="flex">Sair</p>
                  <i className="fa fa-power-off highlight-text"/>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
