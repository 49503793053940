import React from 'react'
import { ReactComponent as Upload } from '../assets/upload.svg'

export const Placeholder: React.FC<{ onClick: any, placeholder?: string|boolean }> = ({ onClick, placeholder }) => {
  return (
    <div onClick={onClick} className="row placeholder">
      <div className="placeholder-content">
        <Upload width={50} height={50} />
        <p className="col-12 text-center no-margin">
          { placeholder !== false ? (placeholder || 'Clique para Selecionar') : '' }
        </p>
      </div>
    </div>
  )
}

export default Placeholder
