import { AlertActionTypes } from 'util/modules/Alert/redux/types'
import { Bot } from './bot'

/**
* Data Types
*/

export type BotActionType = (
  'FILL_PERSON_DATA' | 'REQUEST_REVIEW' | 'REDIRECT_BOT' | 'REDIRECT_ATTENDANT' |
  'REDIRECT_SECTOR' | 'FINISH_CONVERSATION' | 'RESPONSE_MESSAGE'
)
export interface BotOption {
  id: number,
  bot: Partial<Bot>,
  isActive: boolean,
  order: number,
  type: 'MESSAGE'|'ATTACHMENT_AUDIO' | 'ATTACHMENT_PICTURE',
  mode: 'SINGLE' | 'MULTIPLE' | 'AUTO',
  code?: string,
  name?: string,
  message: string,
  actionType?: BotActionType,
  parameters?: string,
  createdAt: string,
  updatedAt?: string
}

/**
* State Type
*/

export interface BotOptionState {
  readonly botOption?: BotOption
  readonly botOptions: BotOption[]
}

/**
* Action Enum Types
*/

export enum BotOptionTypes {
  BOT_OPTION_RESET = 'BOT/OPTION/RESET',
  BOT_OPTION_CREATE = 'BOT/OPTION/CREATE',
  BOT_OPTION_UPDATE = 'BOT/OPTION/UPDATE',
  BOT_OPTION_DELETE = 'BOT/OPTION/DELETE',
  BOT_OPTION_GET = 'BOT/OPTION/GET',
  BOT_OPTION_GET_LIST = 'BOT/OPTION/GET/LIST'
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof BotOptionTypes.BOT_OPTION_RESET,
}

interface CreateAction {
  type: typeof BotOptionTypes.BOT_OPTION_CREATE,
  payload: BotOption
}

interface UpdateAction {
  type: typeof BotOptionTypes.BOT_OPTION_UPDATE,
  payload: BotOption
}

interface DeleteAction {
  type: typeof BotOptionTypes.BOT_OPTION_DELETE,
  payload: number
}

interface GetAction {
  type: typeof BotOptionTypes.BOT_OPTION_GET,
  payload: BotOption
}

interface GetListAction {
  type: typeof BotOptionTypes.BOT_OPTION_GET_LIST,
  payload: BotOption[]
}

export type BotOptionActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction
)
