import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { maskDate } from 'util/masks'
import { getStatistics } from './redux/actions'
import { ApplicationState } from 'AppReducer'

import DonutChart from 'panel/dashboard/components/DonutChart'

import './styles/Overview.scss'

const Statistic: React.FC = () => {
  const dispatch = useDispatch()

  const [query, setQuery] = useState({ start: '', end: '' })

  const { selected: storeId = 0 } = useSelector((state: ApplicationState) => state.storeReducer)

  const { statistics: statisticsDb } = useSelector((state: ApplicationState) => state.reportReducer)
  const statistics = statisticsDb[storeId]

  function handleFilter (e: React.FormEvent) {
    e.preventDefault()

    if (storeId && query.start && query.end) {
      getStatistics(storeId, {
        start: query.start.split('/').reverse().join('-'),
        end: query.end.split('/').reverse().join('-')
      })(dispatch)
    }
  }

  useEffect(() => {
    if (storeId) {
      getStatistics(storeId)(dispatch)
    }
  }, [storeId, dispatch])

  if (!statistics) return null

  return (
    <div className="row statistic-container">
      <div className="grow">
        <div className="row statistic-date">
          <h2>Estatísticas</h2>
          <form onSubmit={handleFilter}>
            <div className="form-control col-5">
              <label>Data Início</label>
              <input
                type="text"
                value={query.start}
                onChange={(e): void => setQuery({ ...query, start: maskDate(e.target.value) })}
                required
              />
            </div>
            <div className="form-control col-5">
              <label>Data Fim</label>
              <input
                type="text"
                value={query.end}
                onChange={(e): void => setQuery({ ...query, end: maskDate(e.target.value) })}
                required
              />
            </div>
            <button className="primary" style={{ height: 48 }}>
              <i className="fa fa-filter" />
            </button>
          </form></div>
      </div>

      <div className="row grid-2 margin-top-16" style={{ alignItems: 'stretch' }}>
        <div className="row" style={{ alignItems: 'stretch' }}>
          <div className="row panel" style={{ height: '100%' }}>
            <div className="row padding-top-8 chart-content" style={{ width: 470 }}>
              <h2 className="row justify-start" style={{ position: 'absolute', left: 16, top: 8 }}>Resumo</h2>
              <DonutChart
                legendPosition="left"
                data={[
                  { color: '#01A098', label: 'Atendimento', amount: statistics.conversations.running },
                  { color: '#F7970C', label: 'Pendentes', amount: statistics.conversations.queue },
                  { color: '#FC3886', label: 'Bot', amount: statistics.conversations.idle },
                  { color: '#5338FC', label: 'Finalizado', amount: statistics.conversations.finished }
                ]}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row panel">
            <div>
              <div className="row justify-start text-center">
                <h2 className="row justify-start">Total</h2>
                <h1 className="bold margin-top-32" style={{ fontSize: 70 }}>
                  {statistics.conversations.total}
                </h1>
                <p>Atendimentos</p>
              </div>
            </div>
            <div className="row padding-top-8 chart-content">
              <DonutChart
                legend={false}
                data={[
                  {
                    color: '#01A098',
                    label: 'Atendimento',
                    amount: (
                      statistics.conversations.running + statistics.conversations.queue + statistics.conversations.idle
                    )
                  },
                  { color: '#e0e0e0', label: 'Total', amount: statistics.conversations.total }
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row grid-4 margin-top-16">
        <div className="row panel align-start card" style={{ height: 150 }}>
          <div className="flex">
            <p>Atendimento</p>
            <h1>{statistics.conversations.running}</h1>
          </div>
          <i className="fa fa-users highlight-text fa-3x" />
        </div>
        <div className="row panel align-start card" style={{ height: 150 }}>
          <div className="flex">
            <p>Pendentes</p>
            <h1>{statistics.conversations.queue}</h1>
          </div>
          <i className="fa fa-history highlight-text fa-3x" />
        </div>
        <div className="row panel align-start card" style={{ height: 150 }}>
          <div className="flex">
            <p>Bot</p>
            <h1>{statistics.conversations.idle}</h1>
          </div>
          <i className="fa fa-robot highlight-text fa-3x" />
        </div>
        <div className="row panel align-start card" style={{ height: 150 }}>
          <div className="flex">
            <p>Finalizado</p>
            <h1>{statistics.conversations.finished}</h1>
          </div>
          <i className="fa fa-check-double highlight-text fa-3x" />
        </div>
      </div>
      <div className="row margin-top-16">
        <h1>Estatísticas</h1>
        <div className="row grid-4 margin-top-16">
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Atendentes</p>
              <h1>{statistics.quantities.attendants}</h1>
            </div>
            <i className="fa fa-users highlight-text fa-3x" />
          </div>
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Canais</p>
              <h1>{statistics.quantities.channels}</h1>
            </div>
            <i className="fa fa-network-wired highlight-text fa-3x" />
          </div>
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Contatos</p>
              <h1>{statistics.quantities.contacts}</h1>
            </div>
            <i className="fa fa-address-book highlight-text fa-3x" />
          </div>
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Grupos</p>
              <h1>{statistics.quantities.groups}</h1>
            </div>
            <i className="fa fa-users highlight-text fa-3x" />
          </div>
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Mensagens</p>
              <h1>{statistics.quantities.messages}</h1>
            </div>
            <i className="fa fa-envelope highlight-text fa-3x" />
          </div>
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Base de Mensagem</p>
              <h1>{statistics.quantities.messageDatabase}</h1>
            </div>
            <i className="fa fa-database highlight-text fa-3x" />
          </div>
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Etiquetas</p>
              <h1>{statistics.quantities.tags}</h1>
            </div>
            <i className="fa fa-tag highlight-text fa-3x" />
          </div>
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Agendados Enviados</p>
              <h1>{statistics.quantities.schedulesPending} / {statistics.quantities.schedulesSend}</h1>
            </div>
            <i className="fa fa-history highlight-text fa-3x" />
          </div>
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Setores</p>
              <h1>{statistics.quantities.sectors}</h1>
            </div>
            <i className="fa fa-layer-group highlight-text fa-3x" />
          </div>
          <div className="row panel align-start card" style={{ height: 150 }}>
            <div className="flex">
              <p>Bots</p>
              <h1>{statistics.quantities.bots}</h1>
            </div>
            <i className="fa fa-robot highlight-text fa-3x" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Statistic
