import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './styles/ConversationRedirect.scss'

import { Tab } from './components/RedirectTab'
import { getSocket } from 'util/services/SocketService'
import { getRedirectList } from './redux/actions'
import { ApplicationState } from 'AppReducer'

import Modal, { ModalHeader } from 'util/modules/Modal'
import Pending from './components/Pending'
import RedirectTab from './components/RedirectTab'
import Accepted from './components/Accepted'
import Rejected from './components/Rejected'

import { ReactComponent as RedirectIcon } from 'assets/icons/MdiAccountSwitch.svg'

const RedirectRequests: React.FC<{ style?: React.CSSProperties }> = ({ style = {} }) => {
  const dispatch = useDispatch()

  const [selectedTab, setSelectedTab] = useState<Tab>('PENDING')
  const [isModalOpen, setModalOpen] = useState(false)

  const { logged } = useSelector((state: ApplicationState) => state.userReducer)
  const { redirects } = useSelector((state: ApplicationState) => state.conversationRedirectReducer)
  const { attendants } = useSelector((state: ApplicationState) => state.attendantReducer)
  const pending = redirects.filter(item => item.to?.user?.id === logged?.id).filter(item => item.status === 'PENDING')

  const selected = useSelector((state: ApplicationState) => state.storeReducer.selected)
  const isAttendant = !!attendants.find(item => item.user?.id === logged?.id)

  // Handle Socket
  useEffect(() => {
    const socket = getSocket()

    if (socket && selected && logged) {
      socket.on(`@Store:${selected}:User:${logged.id}:Redirect`, () => {
        getRedirectList(selected)(dispatch).then(res => {
          const requests = res.data
          if (requests?.length) {
            setModalOpen(true)
          }
        })
      })
    }

    return () => {
      if (logged && socket && selected) {
        socket.off(`@Store:${selected}:User:${logged.id}:Redirect`)
      }
    }
  }, [selected, logged])

  useEffect(() => {
    if (logged && selected && isAttendant) {
      getRedirectList(selected)(dispatch).then(res => {
        const requests = res.data
        if (requests?.filter(item => item.status === 'PENDING' && item.to?.user?.id === logged?.id).length) {
          setModalOpen(true)
        }
      })
    }
  }, [selected, isAttendant, logged])

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={() => setModalOpen(false)}>
        <div className="row panel no-padding redirect-requests" style={{ width: 900 }}>
          <ModalHeader title="Redirecionamentos" icon="paper-plane" onClose={() => setModalOpen(false)} />
          <div className="row panel-body">
            <RedirectTab onSelect={setSelectedTab} active={selectedTab} />
            { selectedTab === 'PENDING' && <Pending onClose={() => setModalOpen(false)} /> }
            { selectedTab === 'ACCEPTED' && <Accepted /> }
            { selectedTab === 'REJECTED' && <Rejected /> }
          </div>
        </div>
      </Modal>
      <span
        title='Redirecionamentos'
        className="button white"
        onClick={() => setModalOpen(true)}
        style={{ padding: 0, ...style }}
      >
        <RedirectIcon width={22} />
        {
          pending.length > 0 &&
          <span
            className="row quantity small"
            style={{
              top: 6,
              right: 12,
              height: 17,
              width: 17,
              minHeight: 'unset',
              minWidth: 'unset',
              fontSize: 10,
              lineHeight: 1
            }}
          >
            {pending.length}
          </span>
        }
      </span>
    </>
  )
}

export default RedirectRequests
