import Axios from 'axios'
import { Dispatch } from 'redux'

import { ActionResponse, Id } from 'util/types/index'
import { CommandTypes, CommandInterface } from './types'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'

export function exportBotToCommand (storeId: Id, name: string) {
  return async (dispatch: Dispatch): Promise<ActionResponse<CommandInterface>> => {
    return Axios
      .post(`/store/${storeId}/command/bot/export`, { name })
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: CommandTypes.COMMAND_GENERATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao gerar o comando')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        return res
      })
  }
}

export function importCommand (storeId: Id, body: FormData) {
  return async (dispatch: Dispatch): Promise<ActionResponse<CommandInterface>> => {
    return Axios
      .post(`/store/${storeId}/command/import`, body)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: CommandTypes.COMMAND_GENERATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao importar o comando')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        return res
      })
  }
}

export function deleteCommand (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<number>> => {
    return Axios
      .delete(`/store/${storeId}/command/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: CommandTypes.COMMAND_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o comando')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
        }
        return res
      })
  }
}

export function executeCommand (storeId: Id, id: Id, body: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<number>> => {
    return Axios
      .post(`/store/${storeId}/command/${id}`, body)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: CommandTypes.COMMAND_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao executar o comando')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        return res
      })
  }
}

export function getCommand (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<CommandInterface>> => {
    return Axios
      .get(`/store/${storeId}/command/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: CommandTypes.COMMAND_GET, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar o comando')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getCommandBotList (storeId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<CommandInterface[]>> => {
    return Axios
      .get(`/store/${storeId}/command/bot`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: CommandTypes.COMMAND_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}
