import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import './styles/Channel.scss'

import { ApplicationState } from 'AppReducer'

import Modal from 'util/modules/Modal'
import ChannelForm from './components/ChannelForm'
import ChannelList from './components/ChannelList'
import ChannelConfig from './components/ChannelConfig'

import { startAllChannels, startUnconnectedChannels } from './redux/actions'
import { hasPermission } from 'util/services/AuthService'
import { ClientLib } from './components/ClientLib'

const Channels: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [isFormOpen, setFormOpen] = useState(false)
  const [isConfigOpen, setConfigOpen] = useState(false)

  const { action } = useParams<{ [x: string]: string }>()

  const { channels } = useSelector((state: ApplicationState) => state.channelReducer)
  const connectingId = channels.find(item => item.status === 'CONNECTING')?.id

  const { logged } = useSelector((state: ApplicationState) => state.userReducer)
  const isAdmin = !!logged?.roles.includes('ROLE_ADMIN')

  async function handleStartAll () {
    if (window.confirm('Tem certeza que deseja executar esta ação?')) {
      await startAllChannels()(dispatch)
    }
  }

  async function handleStartUnconnected () {
    if (window.confirm('Tem certeza que deseja executar esta ação?')) {
      await startUnconnectedChannels()(dispatch)
    }
  }

  function handleCloseModals () {
    setFormOpen(false)
    setConfigOpen(false)
    history.push('/panel/channel')
  }

  useEffect(() => {
    if (action === 'edit') {
      setFormOpen(true)
    } else if (action === 'config') {
      setConfigOpen(true)
    } else {
      setFormOpen(false)
      setConfigOpen(false)
    }
  }, [action])

  const openWhenIsConnecting = useCallback(() => {
    if (connectingId) {
      history.push(`/panel/channel/${connectingId}/config`)
    }
  }, [connectingId])

  useEffect(() => {
    openWhenIsConnecting()
    return () => {
      setFormOpen(false)
      setConfigOpen(false)
    }
  }, [])

  return (
    <div className="row panel bordered">
      <div className="row channels-container">
        <div className="row padding-24">
          <p className="grow" style={{ fontSize: 18 }}>Canais Cadastrados</p>

          <div className='row channel-header-buttons'>
            {
              !!isAdmin && (
                <>
                  <ClientLib />
                  <span className="button secondary radius margin-right-8" onClick={handleStartUnconnected}>
                    Iniciar Pendentes
                  </span>
                  <span className="button secondary radius margin-right-8" onClick={handleStartAll}>
                    Iniciar Todos
                  </span>
                </>
              )
            }

            {
              hasPermission('CHANNEL:CREATE') &&
              <button className="gradient radius" style={{ width: 120, height: 48 }} onClick={() => setFormOpen(true)}>
                Cadastrar
              </button>
            }
          </div>
        </div>
        {hasPermission('CHANNEL:LIST') && <ChannelList />}

        <Modal isOpen={isConfigOpen} onClose={handleCloseModals}>
          <ChannelConfig />
        </Modal>
        <Modal isOpen={isFormOpen} onClose={handleCloseModals}>
          <ChannelForm onClose={handleCloseModals} />
        </Modal>
      </div>
    </div>
  )
}

export default Channels
