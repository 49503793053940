import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { ApplicationState } from 'AppReducer'
import { createTag, getTag, updateTag } from '../redux/actions'
import { useRef } from 'react'
import { TagTypes } from '../redux/types'

const initialState = { isActive: true, name: '', color: '#00F088' }

const TagForm: React.FC<{ isOpen: boolean, onClose(): void }> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [form, setForm] = useState(initialState)

  const { tag } = useSelector((state: ApplicationState) => state.tagReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  const handleClear = useCallback(() => {
    dispatch({ type: TagTypes.TAG_GET, payload: undefined })
  }, [history])

  function handleSubmit (e: React.FormEvent): void {
    e.preventDefault()

    if (!selected) return undefined

    if (tag) {
      updateTag(selected, tag.id, form)(dispatch)
    } else {
      createTag(selected, form)(dispatch).then((res) => {
        if (res?.data) {
          handleClear()
        }
      })
    }
  }

  useEffect(() => {
    if (tag) {
      setForm({
        isActive: tag.isActive,
        name: tag.name,
        color: tag.color
      })
    } else {
      setForm(initialState)
    }
  }, [tag, isOpen])

  useEffect(() => {
    if (!isOpen) {
      dispatch({ type: TagTypes.TAG_GET, payload: undefined })
    }
  }, [isOpen])

  const colorRef = useRef<HTMLInputElement>(null)

  return (
    <form className="row" onSubmit={handleSubmit}>
      <div className="row form-content align-end" style={{ marginTop: -8 }}>
        <div className="form-control" style={{ width: 48, paddingRight: 0 }}>
          <label>Nome</label>
          <span
            className="button secondary"
            onClick={() => setForm({ ...form, isActive: !form.isActive })}
            style={{ color: 'white', background: form.isActive ? 'var(--highlight)' : 'red', width: 48, height: 48 }}
          >
            <i className="fa fa-tags" style={{ margin: 0, fontSize: 18 }} />
          </span>
        </div>

        <div className="form-control flex" style={{ padding: 0 }}>
          <input
            required
            type="text"
            value={form.name}
            style={{ borderRadius: 0, borderLeft: 'none' }}
            onChange={(e): void => {
              setForm({ ...form, name: e.target.value })

              if (!e.target.value) {
                handleClear()
              }
            }}
          />
        </div>

        <div className="form-control" style={{ width: 48, paddingLeft: 0 }}>
          <label>Cor</label>
          <input
            type="color"
            ref={colorRef}
            value={form.color}
            style={{ display: 'none' }}
            onChange={(e): void => setForm({ ...form, color: e.target.value })}
          />

          <span
            className="button secondary"
            onClick={() => colorRef.current?.click()}
            style={{ color: 'white', background: form.color, width: 48, height: 48 }}
          >
            <i className="fa fa-eye-dropper" style={{ margin: 0, fontSize: 18 }} />
          </span>
        </div>

        <div className="form-control" style={{ width: 56 }}>
          <button className="gradient button" style={{ width: 48 }}><i className="fa fa-plus"/></button>
        </div>
      </div>
    </form>
  )
}

export default TagForm
