import { ClientState, ClientTypes, ClientActionTypes } from './types'
import { Reducer } from 'redux'

const initialState: ClientState = {
  clients: { items: [] },
  globalLib: 'Baileys',
  globalServer: 'DEFAULT'
}

type ReducerOpts = Reducer<ClientState, ClientActionTypes>
const reducer: ReducerOpts = (state = initialState, action: ClientActionTypes): ClientState => {
  switch (action.type) {
    case ClientTypes.CLIENT_RESET:
      return initialState
    case ClientTypes.CLIENT_CREATE:
      return {
        ...state,
        client: action.payload,
        clients: { ...state.clients, items: [...state.clients.items, action.payload] }
      }
    case ClientTypes.CLIENT_UPDATE:
      return {
        ...state,
        client: action.payload,
        clients: {
          ...state.clients,
          items: state.clients.items.map(obj => obj.id === action.payload.id ? action.payload : obj)
        }
      }
    case ClientTypes.CLIENT_DELETE:
      return {
        ...state,
        client: undefined,
        clients: {
          ...state.clients,
          items: state.clients.items.filter(obj => obj.id !== action.payload)
        }
      }
    case ClientTypes.SET_GLOBAL_LIB:
      return {
        ...state,
        globalLib: action.payload
      }
    case ClientTypes.SET_GLOBAL_SERVER:
      return {
        ...state,
        globalServer: action.payload
      }
    case ClientTypes.CLIENT_GET:
      return { ...state, client: action.payload }
    case ClientTypes.CLIENT_GET_LIST:
      return { ...state, clients: { items: action.payload, pagination: action.pagination } }
    default:
      return state
  }
}

export default reducer
