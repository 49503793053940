import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Message } from 'panel/conversation/redux/types'
import { maskPhone } from 'util/masks'
import { createChat } from 'panel/conversation/redux/actions'
import { getStoreId } from 'util/hooks'
import { defaultPicture } from 'index'
import { ApplicationState } from 'AppReducer'
import { createContact, fetchContact } from 'panel/person/person.service'

export const Contact: React.FC<{ message: Partial<Message> }> = ({ message }) => {
  const history = useHistory()
  const storeId = getStoreId()
  const dispatch = useDispatch()

  const { sectors } = useSelector((store: ApplicationState) => store.sectorReducer)
  const { channels } = useSelector((store: ApplicationState) => store.channelReducer)
  const { conversations } = useSelector((store: ApplicationState) => store.conversationReducer)

  const { data, isLoading } = fetchContact(message.attachment?.number)
  const { mutate: handleCreateContact, isLoading: isCreating } = createContact()

  if (isLoading) return null
  if (!message.attachment) return null
  if (!message.attachment.name) return null
  if (!message.attachment.number) return null

  const { name, number } = message.attachment

  function handleCreate () {
    handleCreateContact({ name, number })
  }

  async function handleStartConversation () {
    if (!data) return null
    if (!sectors.length) return null
    if (!channels.length) return null

    const alreadyStarted = conversations.find(con => con.clientValue === number)
    if (alreadyStarted) return history.push(`/panel/conversation/${alreadyStarted.id}`)

    const form = { contact: data.id, channel: channels[0].id, sector: sectors[0].id }

    return createChat(storeId, form)(dispatch).then(res => {
      if (res?.status === 'success' && res.data) {
        history.push(`/panel/conversation/${res.data.id}`)
      }
    })
  }

  const status = {
    PENDING: 'Aguardando validação',
    VALIDATED: 'Iniciar atendimento',
    INVALID: 'Número inválido'
  }[data?.status || 'PENDING']

  return (
    <div className="row" style={{ background: 'white', padding: 16, borderRadius: 5, width: 300 }}>
      <div className="row" style={{ borderBottom: '1px solid gainsboro', paddingBottom: 16 }}>
        <img
          width={40}
          height={40}
          style={{ borderRadius: 20 }}
          src={data?.person.picture || defaultPicture}
        />
        <div className="grow padding-left-16">
          <b>{name}</b>
          <p>{maskPhone(number)}</p>
        </div>
      </div>
      {
        data
          ? (
            <button
              className="row"
              onClick={handleStartConversation}
              disabled={data?.status !== 'VALIDATED'}
              style={{ background: 'transparent', height: 30 }}
            >
              { status }
            </button>
          ) : (
            <button
              disabled={isLoading}
              onClick={handleCreate}
              className="row"
              style={{ background: 'transparent', height: 30 }}
            >
              {isCreating ? 'Cadastrando...' : 'Cadastrar contato'}
            </button>
          )
      }
    </div>
  )
}
