import { TimeTableState, TimeTableTypes, TimeTableActionTypes } from './types'
import { Reducer } from 'redux'

const initialState: TimeTableState = {
  timeTable: []
}

type ReducerOpts = Reducer<TimeTableState, TimeTableActionTypes>
const reducer: ReducerOpts = (state = initialState, action: TimeTableActionTypes): TimeTableState => {
  let index = -1
  switch (action.type) {
    case TimeTableTypes.TIMETABLE_RESET:
      return initialState
    case TimeTableTypes.TIMETABLE_CREATE:
      return { ...state, timeTable: [...state.timeTable, action.payload] }
    case TimeTableTypes.TIMETABLE_UPDATE:
      index = state.timeTable.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        timeTable: [...state.timeTable.slice(0, index), action.payload, ...state.timeTable.slice(index + 1)]
      }
    case TimeTableTypes.TIMETABLE_DELETE:
      index = state.timeTable.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        timeTable: [...state.timeTable.slice(0, index), ...state.timeTable.slice(index + 1)]
      }
    case TimeTableTypes.TIMETABLE_GET_LIST:
      return { ...state, timeTable: action.payload }
    default:
      return state
  }
}

export default reducer
