import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import '../sign-on/styles/SignOn.scss'

import { img } from 'assets/online'
import { passwordValidate } from 'util/services/Validator'
import { recoveryPassword, recoveryPre, recoveryVerify } from 'panel/user/redux/actions'

const initialFormPre = { email: '' }

const Recovery: React.FC = () => {
  const dispatch = useDispatch()

  // Forms
  const [code, setCode] = useState('')
  const [step, setStep] = useState<'START' | 'PRE' | 'SEND' | 'PASSWORD' | 'FINISH'>('START')
  const [formPre, setFormPre] = useState(initialFormPre)
  const [formPass, setFormPass] = useState({ newPassword: '', confirmNewPassword: '' })
  const [isFetching, setFetching] = useState(false)

  const validation = passwordValidate(formPass.newPassword, formPass.confirmNewPassword)

  async function handlePre (e: React.FormEvent): Promise<void> {
    e.preventDefault()
    setFetching(true)
    await recoveryPre(formPre)(dispatch).then(res => {
      if (res?.status === 'success' && res?.data) {
        if (res.data.isSend) {
          setStep('SEND')
        } else {
          setStep('PRE')
        }
      }
    })
    setFetching(false)
  }

  async function handleVerify (e: React.FormEvent): Promise<void> {
    e.preventDefault()
    if (code) {
      setFetching(true)
      await recoveryVerify({ code })(dispatch).then(res => {
        if (res?.status === 'success') {
          setStep('PASSWORD')
        }
      })
      setFetching(false)
    } else {
      alert('Código não informado')
    }
  }

  async function handlePassword (e: React.FormEvent): Promise<void> {
    e.preventDefault()
    const newPassword = formPass.newPassword

    if (code && newPassword) {
      if (newPassword && !validation.isValid) {
        alert(validation.warnings[0] || 'Senha Inválida')
      } else {
        setFetching(true)
        await recoveryPassword({ code, newPassword })(dispatch).then(res => {
          if (res?.status === 'success') {
            setStep('FINISH')
          }
        })
        setFetching(false)
      }
    } else {
      alert('Formulário inválido')
    }
  }

  return (
    <div className="row sign-container">
      <div className="sign-content">
        <div className="row sign-title-box">
          <img src={img.logo} alt="Logo" />
        </div>
        <div className="row side-right">
          <div className="row justify-start align-start">
            {
              step === 'START' &&
              <form onSubmit={handlePre}>
                <h2 className="text-center">Recupere sua conta</h2>
                <div className="form-control">
                  <label>Informe seu e-mail</label>
                  <input
                    style={{ borderRadius: 5 }}
                    type="text"
                    value={formPre.email}
                    onChange={(e): void => {
                      setFormPre({ ...formPre, email: e.target.value })
                    }}
                    required={true}
                  />
                </div>
                <div className="form-control">
                  <button className="button button-entry col-12 margin-top-16" disabled={isFetching}>
                    Avançar <i className="fa fa-angle-right" style={{ marginBottom: -2 }} />
                  </button>
                </div>
              </form>
            }

            {
              step === 'SEND' &&
              <form onSubmit={handleVerify}>
                <h2>E-mail</h2>
                <p>Código enviado para:&nbsp;<b>{formPre.email}</b></p>

                <div className="form-control">
                  <label>Informe o Código Recebido</label>
                  <input
                    type="text"
                    value={code}
                    onChange={(e): void => { setCode(e.target.value.toUpperCase()) }}
                  />
                </div>

                <button className="primary col-12 margin-top-8">
                  Validar Código <i className="fa fa-angle-right" style={{ marginBottom: -2 }} />
                </button>
              </form>
            }
            {
              step === 'PASSWORD' &&
              <form onSubmit={handlePassword}>
                <h2>E-mail</h2>
                <p>Código Validado! Crie uma nova senha</p>

                <div className="form-control">
                  <label>Nova Senha</label>
                  <input
                    type="password"
                    value={formPass.newPassword}
                    onChange={(e): void => { setFormPass({ ...formPass, newPassword: e.target.value }) }}
                  />
                </div>
                <div className="form-control">
                  <label>Confirme a Nova Senha</label>
                  <input
                    type="password"
                    value={formPass.confirmNewPassword}
                    onChange={(e): void => { setFormPass({ ...formPass, confirmNewPassword: e.target.value }) }}
                  />
                </div>

                <p className="col-12 text-center">
                  {validation.warnings?.[0] || <>&nbsp;</>}
                </p>

                <button className="primary button-entry col-12 margin-top-8">
                  Criar nova senha
                </button>
              </form>
            }
            {
              step === 'FINISH' &&
              <form onSubmit={handlePassword}>

                <h1 className="text-center" style={{ color: 'green' }}><i className="fa fa-check-circle fa-4x" /></h1>
                <h2 className="text-center">Sua senha foi atualizada!</h2>
                <p className="text-center">Utilize a senha que criou para entrar</p>

                <NavLink to="/sign-in" className="button button-entry col-12 margin-top-16">
                  Voltar ao Login
                </NavLink>
              </form>
            }
          </div>

          {/* Buttons */}
          <NavLink to="/sign-in" className="button underchat-copy-label col-12 margin-top-16">
            Já possui conta? Fazer login.
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default Recovery
