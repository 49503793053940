import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import './styles/Sector.scss'

import Modal from 'util/modules/Modal'
import SectorForm from './components/SectorForm'
import SectorList from './components/SectorList'
import { hasPermission } from 'util/services/AuthService'

const Sector: React.FC = () => {
  const history = useHistory()

  const [isFormOpen, setFormOpen] = useState(false)

  const { action } = useParams<{ [x: string]: string }>()

  function handleCloseForm () {
    setFormOpen(false)
    history.push('/panel/sector')
  }

  useEffect(() => {
    if (action === 'edit') setFormOpen(true)
  }, [action])

  return (
    <div className="row panel bordered">
      <div className="row sectors-container">
        <div className="row padding-24">
          <p className="grow" style={{ fontSize: 18 }}>Setores Cadastrados</p>
          {
            hasPermission('SECTOR:CREATE') &&
            <button className="gradient radius" onClick={() => setFormOpen(true)}>
              Cadastrar
            </button>
          }
        </div>
        { hasPermission('SECTOR:LIST') && <SectorList /> }
        <Modal isOpen={isFormOpen} onClose={handleCloseForm}>
          <SectorForm onClose={handleCloseForm} />
        </Modal>
      </div>
    </div>
  )
}

export default Sector
