import { Reducer } from 'redux'

import { ReportState, ReportActionTypes, ReportTypes } from './types'

const initialState: ReportState = {
  overview: {},
  statistics: {}
}

type ReducerOpts = Reducer<ReportState, ReportActionTypes>
const reducer: ReducerOpts = (state = initialState, action: ReportActionTypes): ReportState => {
  switch (action.type) {
    case ReportTypes.REPORT_OVERVIEW:
      return { ...state, overview: { ...state.overview, [action.storeId]: action.payload } }
    case ReportTypes.REPORT_STATISTICS:
      return { ...state, statistics: { ...state.statistics, [action.storeId]: action.payload } }
    default:
      return state
  }
}

export default reducer
