import React from 'react'

type Props = {
  latitude?: number,
  longitude?: number,
  width?: number,
  height?: number,
}
export const LocationPreview: React.FC<Props> = ({ latitude, longitude, width = 270, height = 200 }) => {
  if (!latitude || !longitude) return null

  const preview = new URL('https://maps.googleapis.com')
  preview.pathname = '/maps/api/staticmap'
  preview.searchParams.set('zoom', '15')
  preview.searchParams.set('size', `${width}x${height}`)
  preview.searchParams.set('scale', '1')
  preview.searchParams.set('language', 'pt-BR')
  preview.searchParams.set('markers', `color:Red|${latitude},${longitude}`)
  preview.searchParams.set('key', process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '')

  const url = new URL('https://maps.google.com')
  url.pathname = '/maps'
  url.searchParams.set('q', `${latitude},${longitude}`)
  url.searchParams.set('z', '17')
  url.searchParams.set('hl', 'pt-BR')

  return (
    <a href={url.toString()} target='__blank'>
      <img src={preview.toString()} style={{ width, height }}/>
    </a>
  )
}
