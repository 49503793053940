import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
* Data Types
*/
export interface Tag {
  id: number,
  isActive: boolean,
  name: string,
  color: string,
  createdAt: string,
  updatedAt?: string
}

/**
* State Type
*/

export interface TagState {
  readonly tag?: Tag
  readonly tags: Tag[]
}

/**
* Action Enum Types
*/

export enum TagTypes {
  TAG_RESET = 'TAG/RESET',
  TAG_CREATE = 'TAG/CREATE',
  TAG_UPDATE = 'TAG/UPDATE',
  TAG_DELETE = 'TAG/DELETE',
  TAG_GET = 'TAG/GET',
  TAG_GET_LIST = 'TAG/GET/LIST'
}

/**
* Action Types
*/

interface ResetAction {
  type: typeof TagTypes.TAG_RESET
}

interface CreateAction {
  type: typeof TagTypes.TAG_CREATE,
  payload: Tag
}

interface UpdateAction {
  type: typeof TagTypes.TAG_UPDATE,
  payload: Tag
}

interface DeleteAction {
  type: typeof TagTypes.TAG_DELETE,
  payload: number
}

interface GetAction {
  type: typeof TagTypes.TAG_GET,
  payload: Tag
}

interface GetListAction {
  type: typeof TagTypes.TAG_GET_LIST,
  payload: Tag[]
}

export type TagActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction
)
