import { getToken } from './AuthService'

type Credentials = { apiKey?: string, token?: string }

let socket: WebSocket
let credentials: Credentials = {}
let reconnectingAt: number | null

const url = new URL(process.env.REACT_APP_SOCKET || '')
const callbacks: { [event: string]: any } = {}
const pendingMessages: string[] = []

const checkSocketConnectionSeconds = 5
const maxReconnectingTimeInSeconds = 10

export const getSocket = () => {
  return AppSocket
}

const AppSocket = {
  on: (event: string, callback: (message: any) => void) => {
    callbacks[event] = (payload: any) => {
      try {
        if (typeof payload.data === 'string') {
          const data = JSON.parse(payload.data)
          if (data.event === event) {
            callback(data.message)
          }
        } else if (typeof payload.data === 'object') {
          if (payload.event === event) {
            callback(payload.message)
          }
        }
      } catch (error) {
        console.error('[Websocket] Error parsing message', payload, error)
      }
    }
  },
  off: (event: string) => {
    if (!socket) return
    if (!callbacks[event]) return
    callbacks[event] = undefined
    socket?.removeEventListener('message', callbacks[event])
  },
  to: (room: string) => {
    return {
      emit: (event: string, message?: string | number | Record<string, any>) => {
        const payload = JSON.stringify({ room, event, message: message || '' })
        if (!socket || socket.readyState !== socket.OPEN) {
          if (event !== 'ping') pendingMessages.push(payload)
          return
        }
        socket.send(payload)
      }
    }
  }
}

const sendPendingMessage = () => {
  if (!socket) return
  if (!pendingMessages.length) return
  if (socket.readyState !== socket.OPEN) return

  try {
    const message = pendingMessages.shift()
    if (message) {
      socket.send(message)
      sendPendingMessage()
    }
  } catch (e) {
    console.error('sendPendingMessage', e)
  }
}

export const initSocket = (receivedCredentials: Credentials): void => {
  if (socket && socket.readyState === socket.CONNECTING) return

  credentials = receivedCredentials

  if (credentials.token) url.searchParams.set('token', credentials.token)
  if (credentials.apiKey) url.searchParams.set('apiKey', credentials.apiKey)

  console.log('Connecting socket', url.origin)
  reconnectingAt = new Date().getTime()

  socket = new WebSocket(url)

  socket.onopen = () => {
    console.log('Socket connected')
    reconnectingAt = null
    const subscribedEvents = Object.keys(callbacks)

    subscribedEvents.forEach(event => {
      socket.removeEventListener('message', callbacks[event])
      socket.addEventListener('message', callbacks[event])
    })

    AppSocket.on('ping', () => {
      if (!socket) return
      if (socket.readyState !== socket.OPEN) return
      socket.send(JSON.stringify({ event: 'pong', message: { time: new Date().getTime() } }))
    })

    setTimeout(() => sendPendingMessage(), 10 * 1000)
  }

  socket.onclose = (event) => {
    console.error('Socket disconnected', event.reason)
  }

  socket.onerror = (event) => {
    console.error('Socket error', event)
  }
}

setInterval(() => {
  if (!socket) return

  const status = {
    open: socket.readyState === socket.OPEN,
    closed: socket.readyState === socket.CLOSED,
    closing: socket.readyState === socket.CLOSING,
    connecting: socket.readyState === socket.CONNECTING
  }

  if (status.open) return
  if (!status.connecting) {
    console.log('Socket desconectado, reiniciando')
    const { token } = getToken()
    return initSocket(token ? { token } : credentials)
  }
  if (status.connecting) {
    const now = new Date().getTime()
    const diffInSeconds = (now - (reconnectingAt || 0)) / 1000
    if (diffInSeconds >= maxReconnectingTimeInSeconds) {
      console.log('Socket atingiu tempo máximo de reconexão, reiniciando')
      socket.close()
      const { token } = getToken()
      return initSocket(token ? { token } : credentials)
    }
  }

  // console.log('Socket Reconnection', status)
}, checkSocketConnectionSeconds * 1000)
