import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { formatDateTime, maskMoney } from 'util/masks'
import { ApplicationState } from 'AppReducer'
import { deletePlan, getPlanList } from '../redux/actions'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'

const PlanList: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [deleteId, setDeleteId] = useState<number>()

  const { plans } = useSelector((state: ApplicationState) => state.planReducer)

  function handleRemove (id: number): void {
    deletePlan(id)(dispatch).then(res => {
      if (res.status === 'success') {
        setDeleteId(undefined)
        history.push('/panel/plan')
      }
    })
  }

  useEffect(() => {
    getPlanList()(dispatch)
  }, [dispatch])

  return (
    <div className="list" style={{ marginTop: 0 }}>
      <div className="list-header">
        <div>#</div>
        <div>Nome</div>
        <div>Preço</div>
        <div>Vigência</div>
        <div>Tolerância</div>
        <div>Canais</div>
        <div>Atendentes</div>
        <div>Criado em</div>
        <div>Atualizado em</div>
        <div>Ações</div>
      </div>

      {
        plans.length === 0 && (
          <div className="list-row empty">
            <i className='fas fa-grip-horizontal fa-4x' />
            <p style={{ height: 0 }}>Nenhum plano cadastrado</p>
          </div>
        )
      }

      {
        plans.map((item, i) => (
          <div className="list-row" key={i}>
            <div data-content="Id" className="bold">{item.order}</div>
            <div data-content="Nome">{item.name}</div>
            <div data-content="Preço">{maskMoney(item.price)}</div>
            <div data-content="Vigência">{item.effectiveDays} dias</div>
            <div data-content="Tolerância">{item.toleranceDays} dias</div>
            <div data-content="Canais">{item.limitChannel}</div>
            <div data-content="Atendentes">{item.limitAttendants}</div>
            <div data-content="Criado em">{formatDateTime(item.createdAt)}</div>
            <div data-content="Atualizado em">{formatDateTime(item.updatedAt)}</div>
            <div data-content="Ações" className="row buttons-list-row">
              <div className="row">
                <NavLink to={`/panel/plan/${item.id}/edit`} className="button">
                  <i className="fas fa-edit" /> &nbsp;Editar
                </NavLink>
                <span className="button secondary" onClick={() => setDeleteId(item.id)}>
                  <i className="fas fa-trash-alt" /> &nbsp;Excluir
                </span>
              </div>
            </div>
          </div>
        ))
      }

      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </div>
  )
}

export default PlanList
