import React from 'react'
import { NavLink } from 'react-router-dom'

import { Conversation } from '../redux/types'
import { defaultPicture } from 'index'
import { formatDateTime, maskPhone } from 'util/masks'

type Props = { conversation: Partial<Conversation>, onClickOpen?: () => void }
const ConversationTile: React.FC<Props> = ({ conversation, onClickOpen }) => {
  const { tag, channel } = conversation

  const handleClick = () => {
    if (onClickOpen) onClickOpen()
  }

  const sector = conversation.sector?.name
  const attendantNames = conversation.attendants?.map(item => item.user?.person?.firstName) || []

  const email = conversation.client?.person?.email
  const phone = maskPhone(conversation.client?.value || conversation.clientValue)

  const alias = conversation.client?.person?.alias

  return (
    <>
      <NavLink className="row conversation-tile" to={`/panel/conversation/${conversation.id}`} onClick={handleClick}>
        <div className="row tile-header">
          <div className="grow padding-right-8">
            <div className="row justify-start" style={{ overflow: 'auto' }}>
              {
                !!tag &&
                <span className="tag text-overflow" title={tag.name} style={{ background: tag.color }}>{tag.name}</span>
              }
              {
                !!channel &&
                <span className="channel text-overflow" title={channel.name}>{channel.name}</span>
              }
            </div>
          </div>
          <span className="tile-date" style={{ fontSize: 10 }}>
            { formatDateTime(conversation.createdAt) }
          </span>
        </div>
        <div className="row tile-body">
          <img
            src={conversation.client?.person?.picture || defaultPicture}
            alt="User"
            width={50}
            height={50}
            className='img-circle'
          />
          <div className="row grow justify-start margin-left-8">
            <p className="bold">
              {conversation.client?.person?.name || 'Removido'}
              { alias ? ` (${alias})` : '' }
            </p>
            <p className="row justify-start" style={{ fontSize: 12 }}>
              {phone}
              <i
                title={`Copiar ${phone}`}
                style={{ margin: '0 8px' }}
                className="far fa-copy highlight-text"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigator.clipboard.writeText(phone)
                }}
              />
              {
                !!email &&
                <i
                  title={`Copiar ${email}`}
                  style={{ margin: 0 }}
                  className="fa fa-envelope highlight-text"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigator.clipboard.writeText(email)
                  }}
                />
              }
            </p>
          </div>

          {
            Number(conversation.unreadMessages) > 0 &&
            <span className="counter">{conversation.unreadMessages}</span>
          }
        </div>
        {
          (!!attendantNames.length || !!sector) &&
          <div className="row tile-footer" title={attendantNames.join(', ')}>
            { sector ? <><i className="fa fa-server"/>{ sector }</> : null }
            { attendantNames.length ? <><i className="fa fa-user-tie"/> { attendantNames.join(', ') }</> : null}
          </div>
        }
      </NavLink>
    </>
  )
}

export default ConversationTile
