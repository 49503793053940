import { SectorState, SectorTypes, SectorActionTypes } from './types'
import { Reducer } from 'redux'

const initialState: SectorState = {
  sectors: []
}

type ReducerOpts = Reducer<SectorState, SectorActionTypes>
const reducer: ReducerOpts = (state = initialState, action: SectorActionTypes): SectorState => {
  let index = -1
  switch (action.type) {
    case SectorTypes.SECTOR_RESET:
      return initialState
    case SectorTypes.SECTOR_CREATE:
      return { ...state, sector: action.payload, sectors: [...state.sectors, action.payload] }
    case SectorTypes.SECTOR_UPDATE:
      index = state.sectors.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        sector: action.payload,
        sectors: [...state.sectors.slice(0, index), action.payload, ...state.sectors.slice(index + 1)]
      }
    case SectorTypes.SECTOR_DELETE:
      index = state.sectors.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        sector: undefined,
        sectors: [...state.sectors.slice(0, index), ...state.sectors.slice(index + 1)]
      }
    case SectorTypes.SECTOR_GET:
      return { ...state, sector: action.payload }
    case SectorTypes.SECTOR_GET_LIST:
      return { ...state, sectors: action.payload }
    default:
      return state
  }
}

export default reducer
