import Axios from 'axios'
import { Dispatch } from 'redux'
import { handleError, createAlert, clearAlert } from 'util/modules/Alert/redux/actions'
import { ActionResponse, Id } from 'util/types/index'
import { MessageDataBase, MessageDataBaseTypes } from './types'

export function createMessage (storeId: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<MessageDataBase>> => {
    return Axios
      .post(`/store/${storeId}/message-database`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: MessageDataBaseTypes.MESSAGE_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar a mensagem')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateMessage (storeId: Id, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<MessageDataBase>> => {
    return Axios
      .put(`/store/${storeId}/message-database/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: MessageDataBaseTypes.MESSAGE_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro au atualizar a mensagem')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteMessage (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<MessageDataBase>> => {
    return Axios
      .delete(`/store/${storeId}/message-database/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: MessageDataBaseTypes.MESSAGE_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover a mensagem')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getMessage (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<MessageDataBase>> => {
    return Axios
      .get(`/store/${storeId}/message-database/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: MessageDataBaseTypes.MESSAGE_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar a mensagem')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getMessageList (storeId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<MessageDataBase>> => {
    return Axios
      .get(`/store/${storeId}/message-database`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: MessageDataBaseTypes.MESSAGE_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}
