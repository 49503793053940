import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import { getGroupList } from 'panel/group/redux/actions'
import { ScheduleMode } from '../redux/types'
import { ApplicationState } from 'AppReducer'
import { getContactStoreList } from 'panel/person/redux/action/contact'
import { createSchedule, updateSchedule } from '../redux/actions'
import { maskDate, maskHour, formatDate, formatTime } from 'util/masks'

import TagInput from 'util/modules/Form/TagInput'
import { ModalHeader } from 'util/modules/Modal'
import FormButtons from 'components/FormButtons'

const initialForm = {
  name: '',
  date: '',
  time: '',
  messages: [''],
  groups: [] as number[],
  channels: [] as number[],
  contacts: [] as number[],
  mode: 'ALL' as ScheduleMode
}

const ScheduleForm: React.FC<{ onClose (): void }> = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { location: { search } } = history

  const { id } = useParams<{ id: string }>()

  const [isFetching, setIsFetching] = useState(false)
  const [form, setForm] = useState(initialForm)
  const { groups } = useSelector((state: ApplicationState) => state.groupReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { channels } = useSelector((state: ApplicationState) => state.channelReducer)
  const { schedule } = useSelector((state: ApplicationState) => state.scheduleReducer)
  const { contacts: { items: contacts } } = useSelector((state: ApplicationState) => state.contactReducer)

  const handleClear = useCallback(() => {
    setForm(initialForm)
    history.push('/panel/schedule')
  }, [history])

  function handleForm (e: React.FormEvent): void {
    e.preventDefault()

    if (!selected) return undefined

    const body = { ...form, date: form.date.split('/').reverse().join('-') }

    setIsFetching(true)
    if (id) {
      updateSchedule(selected, id, body)(dispatch)
    } else {
      createSchedule(selected, body)(dispatch).then(res => {
        if (res?.status === 'success') {
          onClose()
          handleClear()
        }
      })
    }
    setIsFetching(false)
  }

  useEffect(() => {
    if (schedule) {
      setForm({
        name: schedule.name,
        date: formatDate(schedule.date),
        time: formatTime(schedule.date),
        messages: schedule.messages,
        groups: schedule.groups.map(item => item.id || 0),
        channels: schedule.channels.map(item => item.id || 0),
        contacts: schedule.contacts.map(item => item.id || 0),
        mode: schedule.mode
      })
    } else {
      const query = new URLSearchParams(search)
      if (query.get('client')) {
        setForm({
          ...initialForm,
          mode: 'CONTACT',
          contacts: [Number(query.get('client'))]
        })
      } else {
        setForm(initialForm)
      }
    }
  }, [schedule])

  useEffect(() => {
    if (channels.length === 1) {
      setForm(state => ({ ...state, channels: [channels[0].id] }))
    }
  }, [channels])

  useEffect(() => {
    if (selected) {
      getGroupList(selected)(dispatch)
      getContactStoreList(selected, { fields: 'id,status,value,person.name', 'filter[status]': 'VALIDATED' })(dispatch)
    }
  }, [selected, dispatch])

  return (
    <form className="row panel no-padding" onSubmit={handleForm}>
      <ModalHeader title="Configurações de Agendamento" onClose={onClose} />
      <div className="row panel-body">
        <div className="row">
          <div className="form-control col-6">
            <label>Nome</label>
            <input
              required
              type="text"
              value={form.name}
              onChange={(e): void => setForm({ ...form, name: e.target.value })}
            />
          </div>
          <div className="form-control col-3">
            <label>Data</label>
            <input
              required
              type="text"
              value={form.date}
              onChange={(e): void => setForm({ ...form, date: maskDate(e.target.value) })}
            />
          </div>

          <div className="form-control col-3">
            <label>Horário</label>
            <input
              required
              type="text"
              value={form.time}
              onChange={(e): void => setForm({ ...form, time: maskHour(e.target.value) })}
            />
          </div>
        </div>

        <div className="row form-control">
          <label>Mensagem</label>
          {
            form.messages.map((message, i) => {
              return (
                <div className={`row align-start ${i > 0 ? 'margin-top-8' : ''}`} key={i}>
                  <div className="grow">
                    <textarea
                      rows={4}
                      required
                      value={message}
                      onChange={(e): void => setForm({
                        ...form,
                        messages: form.messages.map((item, key) => {
                          if (key === i) {
                            return e.target.value
                          }
                          return item
                        })
                      })}
                    />
                  </div>
                  <span
                    style={{ width: 'auto' }}
                    className="button secondary margin-left-8"
                    onClick={() => {
                      if (i > 0) {
                        setForm({ ...form, messages: form.messages.filter((_, key) => key !== i) })
                      }
                    }}
                  >
                    <i className="fa fa-trash" style={{ color: 'gray' }} />
                  </span>
                </div>
              )
            })
          }
        </div>
        <div className="row form-control">
          <div
            style={{ borderRadius: 5, width: 'auto' }}
            className="button bordered col-12"
            onClick={() => setForm({ ...form, messages: [...form.messages, ''] })}
          >
            <i className="fa fa-plus" /> Adicionar mensagem alternativa
          </div>
        </div>

        <div className="row form-control">
          <label>Selecione os canais que dispararam as mensagens</label>
          <TagInput
            required
            filled={form.channels}
            onSelect={(id) => setForm({ ...form, channels: [...form.channels, id] })}
            onDelete={(id) => setForm({ ...form, channels: form.channels.filter(obj => obj !== id) })}
            items={channels.map(({ id, name }) => ({ id, label: name }))}
          />
        </div>

        <div className="row">
          <div className="form-control">
            <label>Modo de envio - Apenas Contatos Validados</label>

            {/* Send to all */}
            <div className="row">
              <label className="row justify-start">
                <label className="switch">
                  <input
                    type='checkbox'
                    checked={form.mode === 'ALL'}
                    value={String(form.mode)}
                    onChange={(e): void => setForm({ ...form, mode: e.target.checked && 'ALL' as any })}
                  />
                  <span className="slider round" />
                </label> &nbsp; Enviar para todos
              </label>
            </div>

            {/* Send to selected groups */}
            <div className="row">
              <label className="row justify-start">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={form.mode === 'GROUP'}
                    value={form.mode}
                    onChange={(e): void => setForm({ ...form, mode: e.target.checked && 'GROUP' as any })}
                  />
                  <span className="slider round" />
                </label> &nbsp; Grupos
              </label>

              {
                form.mode === 'GROUP' &&
                <div className="row margin-bottom-8">
                  <label className="bold">Selecione os grupos:</label>
                  <TagInput
                    required
                    filled={form.groups}
                    onSelect={(id) => setForm({ ...form, groups: [...form.groups, id] })}
                    onDelete={(id) => setForm({ ...form, groups: form.groups.filter(obj => obj !== id) })}
                    items={groups.map(({ id, name }) => ({ id, label: name }))}
                  />
                </div>
              }
            </div>

            {/* Send to selected contacts */}
            <div className="row">
              <label className="row justify-start">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={form.mode === 'CONTACT'}
                    value={form.mode}
                    onChange={(e): void => setForm({ ...form, mode: e.target.checked && 'CONTACT' as any })}
                  />
                  <span className="slider round" />
                </label> &nbsp; Contatos
              </label>
            </div>

            {
              form.mode === 'CONTACT' &&
              <div className="row">
                <label className="bold">Selecione os contatos:</label>
                <TagInput
                  required
                  filled={form.contacts}
                  onSelect={(id) => setForm({ ...form, contacts: [...form.contacts, id] })}
                  onDelete={(id) => setForm({ ...form, contacts: form.contacts.filter(obj => obj !== id) })}
                  items={contacts.map(({ id, value, person }) => ({ id, label: `${person?.name} ${value}` }))}
                />
              </div>
            }
          </div>
        </div>

        <div className="row buttons padding-bottom-16 padding-top-16 gap-10">
          <FormButtons isFetching={isFetching} onClear={handleClear} />
        </div>
      </div>
    </form>
  )
}

export default ScheduleForm
