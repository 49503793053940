import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { ApplicationState } from 'AppReducer'
import { removeEmptyFromObject } from 'util/index'
import { createBot, getBot, updateBot } from '../redux/action/bot'
import { ModalHeader } from 'util/modules/Modal'

const initialState = {
  ...{ isActive: true, autoInitiation: false, runInBackground: false, skipOldContact: true },
  ...{ order: 0, name: '', behavior: '', attempts: 3, invalidMessage: '', description: '' }
}

export const BotBehavior = [
  { name: 'EVER', alias: 'Executar Sempre' },
  { name: 'ONCE_PER_DAY', alias: 'Executar 1x ao dia' },
  { name: 'ONCE_PER_CONTACT', alias: 'Executar 1x por contato' }
]

type Props = {
  onClose(): void
}

const BotForm: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { id } = useParams<{ [x: string]: string }>()
  const [form, setForm] = useState(initialState)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  const handleClear = useCallback(() => {
    setForm(initialState)
    history.push('/panel/chatbot')
  }, [history])

  function handleSubmit (e: React.FormEvent): void {
    e.preventDefault()

    if (!selected) return undefined

    if (id) {
      updateBot(selected, id, removeEmptyFromObject(form))(dispatch)
    } else {
      createBot(selected, removeEmptyFromObject(form))(dispatch).then(res => {
        if (res?.data) {
          handleClear()
        }
      })
    }
  }

  useEffect(() => {
    if (selected) {
      if (id) {
        getBot(selected, id)(dispatch).then(res => {
          const item = res?.data
          if (res?.status === 'success' && item) {
            setForm({
              isActive: item.isActive || initialState.isActive,
              autoInitiation: item.autoInitiation || initialState.autoInitiation,
              runInBackground: item.runInBackground || initialState.runInBackground,
              skipOldContact: item.skipOldContact || initialState.skipOldContact,
              order: item.order || initialState.order,
              name: item.name || initialState.name,
              behavior: item.behavior || initialState.behavior,
              attempts: item.attempts || initialState.attempts,
              invalidMessage: item.invalidMessage || initialState.invalidMessage,
              description: item.description || initialState.description
            })
          }
        })
      } else {
        handleClear()
      }
    }
  }, [id, handleClear, dispatch])

  return (
    <form className="row panel no-padding" onSubmit={handleSubmit}>
      <ModalHeader title="Editar Bot" icon="cog" onClose={onClose} />
      <div className="panel-body">
        <div className="row align-end">
          <div className="form-control grow">
            <label>Nome</label>
            <input
              required
              type="text"
              value={form.name}
              onChange={(e): void => setForm({ ...form, name: e.target.value })}
            />
          </div>

          <div className="form-control col-3">
            <label>Ordem de Execução</label>
            <input
              required
              type="number"
              value={form.order}
              onChange={(e): void => setForm({ ...form, order: parseInt(e.target.value) })}
            />
          </div>

          <div className="col-3">
            <label>Nº de Tentativas</label>
            <input
              required
              type="number"
              value={form.attempts}
              onChange={(e): void => setForm({ ...form, attempts: parseInt(e.target.value) })}
            />
          </div>

        </div>

        <div className="row align-end">
          <div className="form-control row">
            <label>Mensagem inválida</label>
            <textarea
              rows={6}
              required
              value={form.invalidMessage}
              style={{ resize: 'none' }}
              onChange={(e): void => setForm({ ...form, invalidMessage: e.target.value })}
            />
          </div>
        </div>

        <div className="form-control">
          <label>Descrição do BOT</label>
          <textarea
            rows={6}
            required
            value={form.description}
            style={{ resize: 'none' }}
            onChange={(e): void => setForm({ ...form, description: e.target.value })}
          />
        </div>

        <div className="row align-start margin-top-8">
          <div className="col-6">
            <div className="row justify-start form-control">
              <label className="switch">
                <input
                  type='checkbox'
                  checked={form.isActive}
                  onChange={(e) => setForm({ ...form, isActive: e.target.checked })}
                />
                <span className="slider round" />
              </label>
              <div className="label padding-left-8">Ativo?</div>
            </div>

            <div className="row justify-start form-control">
              <label className="switch">
                <input
                  type='checkbox'
                  checked={form.autoInitiation}
                  onChange={(e) => setForm({ ...form, autoInitiation: e.target.checked })}
                />
                <span className="slider round" />
              </label>
              <div className="label padding-left-8">Automático?</div>
            </div>

            <div className="row justify-start form-control">
              <label className="switch">
                <input
                  type='checkbox'
                  checked={form.runInBackground}
                  onChange={(e) => setForm({ ...form, runInBackground: e.target.checked })}
                />
                <span className="slider round" />
              </label>
              <div className="label padding-left-8">Executar em segundo plano?</div>
            </div>
          </div>

          <div className="col-6">
            {
              BotBehavior.map((behavior, i) => {
                return (
                  <label key={i} className="checkbox margin-right-16">
                    <input
                      type="checkbox"
                      checked={form.behavior === behavior.name}
                      onClick={(): void => setForm({ ...form, behavior: behavior.name })}
                    />
                    <div className="checkmark" /> { behavior.alias }
                  </label>
                )
              })
            }
          </div>

          {
            form.behavior === 'ONCE_PER_CONTACT' &&
            <div className="row form-control justify-start">
              <label>Forma de execução por contato</label>

              <label className="checkbox margin-right-16">
                <input
                  readOnly
                  type="checkbox"
                  checked={form.skipOldContact}
                  onClick={(e): void => setForm({ ...form, skipOldContact: true })}
                />
                <div className="checkmark" /> Apenas Novos Contatos
              </label>
              <label className="checkbox margin-right-16">
                <input
                  readOnly
                  type="checkbox"
                  checked={!form.skipOldContact}
                  onClick={(e): void => setForm({ ...form, skipOldContact: false })}
                />
                <div className="checkmark" /> Todos os Contatos
              </label>
            </div>
          }
        </div>
        <div className="row margin-top-16 panel-body-button justify-end">
          <span className="secondary button" onClick={handleClear}>Limpar</span>
          <button className="gradient button">Salvar</button>
        </div>
      </div>
    </form>
  )
}

export default BotForm
