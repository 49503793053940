import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'

import { ReactComponent as UserAttendants } from 'assets/icons/user-tie.svg'
import { ReactComponent as OldMessagesIcon } from 'assets/icons/FoundationArchive.svg'

import { Contact } from 'panel/person/redux/types/contact'
import { Conversation } from 'panel/conversation/redux/types'
import { ApplicationState } from 'AppReducer'
import { getConversationListFiltered } from 'panel/conversation/redux/actions'
import { maskPhone, maskNumber, maskDate, formatDateTime } from 'util/masks'

import Modal, { ModalHeader } from 'util/modules/Modal'
import Search from 'util/modules/Pagination/components/Search'
import Select from 'util/modules/Form/Select'
import ChatHeader from '../ChatHeader'
import MessageTile from '../MessageTile'

const initialState = {
  protocol: '',
  start: '',
  end: '',
  client: 0,
  channel: 0,
  sector: 0,
  attendant: 0
}

const SearchConversation: React.FC = () => {
  const dispatch = useDispatch()

  const [form, setForm] = useState(initialState)
  const [isModalOpen, setModalOpen] = useState(false)
  const [conversation, setConversation] = useState<Conversation>()

  // Store contact name
  const [contact, setContact] = useState('')

  const { sectors } = useSelector(
    (state: ApplicationState) => state.sectorReducer
  )
  const { selected } = useSelector(
    (state: ApplicationState) => state.storeReducer
  )
  const { channels } = useSelector(
    (state: ApplicationState) => state.channelReducer
  )
  const { attendants } = useSelector(
    (state: ApplicationState) => state.attendantReducer
  )
  const {
    persons: { items: contacts }
  } = useSelector((state: ApplicationState) => state.personReducer)
  const { conversationsFiltered } = useSelector(
    (state: ApplicationState) => state.conversationReducer
  )

  function onClose () {
    setModalOpen(false)
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (selected) {
      const query: any = {}

      if (form.client) query['filter[client.id]'] = form.client
      if (form.sector) query['filter[sector.id]'] = form.sector
      if (form.channel) query['filter[channel.id]'] = form.channel
      if (form.protocol) query['filter[protocol]'] = form.protocol
      if (form.attendant) query['filter[attendants.id]'] = form.attendant

      if (form.end) query['end'] = form.end.split('/').reverse().join('-')
      if (form.start) {
        query['start'] = form.start.split('/').reverse().join('-')
      }

      if (Object.keys(query).length > 0) {
        getConversationListFiltered(selected, query)(dispatch)
      } else {
        alert('Nenhum campo informado')
      }
    }
  }

  async function handleOpen (id: number) {
    if (selected) {
      return axios
        .get(`/store/${selected}/conversation/${id}`)
        .then((res) => res.data)
        .then((res) => {
          if (res.status === 'success' && res.data) {
            setConversation(res.data)
          }
        })
    }
  }

  return (
    <>
      <Modal isOpen={isModalOpen} className="row" closeModal={onClose}>
        <form
          className="panel no-padding search-conversation"
          onSubmit={handleSubmit}
        >
          <div className="panel-header">
            <div className="row">
              <div className="grow">
                <p className="flex">
                  <i className="fa fa-search" style={{ margin: 0 }} /> Buscar
                  Atendimento
                </p>
              </div>
              <span className="button-times pointer" onClick={onClose}>
                <i className="fa fa-times" />
              </span>
            </div>
          </div>

          <div className="row panel-body">
            <div className="row align-end filters">
              <div className="form-control grow">
                <label>Protocolo</label>
                <input
                  type="text"
                  value={form.protocol}
                  onChange={(e) =>
                    setForm({ ...form, protocol: maskNumber(e.target.value) })
                  }
                />
              </div>
              <div className="form-control grow">
                <label>Data Início</label>
                <input
                  type="text"
                  value={form.start}
                  onChange={(e): void =>
                    setForm({ ...form, start: maskDate(e.target.value, '/') })
                  }
                />
              </div>
              <div className="form-control grow">
                <label>Data Final</label>
                <input
                  type="text"
                  value={form.end}
                  onChange={(e): void =>
                    setForm({ ...form, end: maskDate(e.target.value, '/') })
                  }
                />
              </div>
              <div className="form-control grow">
                <label>Cliente</label>
                <Search<Contact>
                  url={`/store/${selected}/contact`}
                  value={contact}
                  fields="id,value,person.name"
                  isActive={(item) => form.client === item.id}
                  formatter={({ value, person }) => person?.name || ''}
                  onSelect={({ id, value, person }): void => {
                    setForm({ ...form, client: id })
                    setContact(`${person?.name} - ${maskPhone(value)}`)
                  }}
                  filters={[
                    { type: 'filter', column: 'isValidated', value: 1 },
                    {
                      type: 'filter_like',
                      column: 'person.name' as any,
                      match: ['start', 'end']
                    },
                    {
                      type: 'filter_or',
                      column: 'person.alias' as any,
                      match: ['start', 'end']
                    }
                  ]}
                />
              </div>
              <div className="form-control grow">
                <label>Atendente</label>
                <Select
                  selected={form.attendant}
                  onSelect={({ key }) => setForm({ ...form, attendant: key })}
                  itens={attendants.map(({ id, user }) => ({
                    key: id,
                    value: user?.person?.name
                  }))}
                />
              </div>
              <div className="form-control grow">
                <label>Canal</label>
                <select
                  value={form.channel}
                  onChange={(e) =>
                    setForm({ ...form, channel: Number(e.target.value) })
                  }
                >
                  <option></option>
                  {channels.map((channel, i) => (
                    <option key={i} value={channel.id}>
                      {channel.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-control grow">
                <label>Setor</label>
                <select
                  value={form.sector}
                  onChange={(e) =>
                    setForm({ ...form, sector: Number(e.target.value) })
                  }
                >
                  <option></option>
                  {sectors.map((sector, i) => (
                    <option key={i} value={sector.id}>
                      {sector.name}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="btn-filter margin-left-8"
                style={{ height: 50 }}
              >
                <i className="fa fa-filter" />
                Filtrar
              </button>
            </div>

            <div className="row margin-top-32">
              <h4>
                <i className="fa fa-history" /> Resultado da busca
              </h4>
            </div>

            <div className="list">
              <div className="list-header">
                <div>Protocolo</div>
                <div>Cliente</div>
                <div>Setor</div>
                <div>Canal</div>
                <div>Data</div>
                <div />
              </div>

              {conversationsFiltered.length === 0 && (
                <div className="list-row empty">
                  <div className="search-icon-attendant">
                    <UserAttendants
                      width={120}
                      height={120}
                      style={{ padding: 24 }}
                    />
                    <div className="grid-span">
                      Nenhum atendimento encontrado
                    </div>
                  </div>
                </div>
              )}
              {conversationsFiltered.map((con, i) => {
                return (
                  <div className="list-row" key={i}>
                    <div data-content="Protocolo">{con.protocol} </div>
                    <div data-content="Cliente">
                      {con.client?.person?.name || 'Removido'}{' '}
                    </div>
                    <div data-content="Setor">
                      {con.sector?.name || 'Removido'}{' '}
                    </div>
                    <div data-content="Canal">
                      {con.channel?.name || 'Removido'}{' '}
                    </div>
                    <div data-content="Data">
                      {formatDateTime(con.createdAt)}{' '}
                    </div>
                    <span
                      className="button secondary"
                      onClick={() => handleOpen(con.id)}
                    >
                      <i className="fa fa-folder-open" />
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </form>
      </Modal>
      <span
        title="Mensagens arquivadas"
        style={{ padding: 0 }}
        className="button white"
        onClick={(): void => setModalOpen(true)}
      >
        <OldMessagesIcon width={20} />
      </span>

      <Modal isOpen={!!conversation} className="row" closeModal={onClose}>
        <div className="row panel no-padding">
          <ModalHeader
            title="Histórico de Atendimento"
            onClose={() => setConversation(undefined)}
          />
          {!!conversation && (
            <div className="row conversation-content">
              <ChatHeader conversation={conversation} canInteract={false} />

              <div className="row messages">
                {conversation.messages.map((message, i) => (
                  <MessageTile
                    key={i}
                    message={message}
                    handleReply={() => null}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default SearchConversation
