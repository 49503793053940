import Axios from 'axios'
import { useDispatch } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { Contact } from './redux/types/contact'
import { getStoreId } from 'util/hooks'
import { ActionResponse } from 'util/types/index'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'

export const fetchContact = (number?: string) => {
  const storeId = getStoreId()

  return useQuery(
    ['contact', { number }],
    async () => {
      const { data } = await Axios.get<ActionResponse<Contact[]>>(`/store/${storeId}/contact`, {
        params: {
          page: 1,
          limit: 1,
          fields: 'id,isValidated,status,value,person.name,person.picture',
          'filter[value]': number
        }
      })

      return data?.data?.[0]
    },
    {
      retry: false,
      enabled: Boolean(storeId && number),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  )
}

export const createContact = () => {
  const storeId = getStoreId()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation(
    async (form: CreateContact) => {
      const response = await Axios
        .post(`/store/${storeId}/person-contact`, {
          contact: form.number,
          firstName: form.name
        })
        .then(res => res.data)
        .then(async res => {
          if (res?.status === 'success') {
            await queryClient.invalidateQueries(['contact', { number: form.number }])
          }
          return res
        })
        .catch((error) => {
          return handleError(error, 'Ocorreu um erro ao cadastrar o contato')
        })
        .then(async res => {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)

          return res
        })
    }
  )
}

type CreateContact = {
  name: string,
  number: string
}
