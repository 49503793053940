import React from 'react'

import { Store } from 'panel/store/redux/types'
import { NavLink } from 'react-router-dom'

const AlertEmptyPlan: React.FC<{ store: Store }> = ({ store }) => {
  if (store.plan) return null

  return (
    <div className="row alert-box danger">
      <i className="fa fa-times"/>
      Você não possui nenhum plano ativo
      <NavLink to="/panel/invoice" className="button"><i className="fa fa-plus"/> Contratar Agora</NavLink>
    </div>
  )
}

export default AlertEmptyPlan
