import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { getBot } from '../redux/action/bot'
import { ModalHeader } from 'util/modules/Modal'
import { getBotOptionList, createBotOption } from '../redux/action/option'
import { ApplicationState } from 'AppReducer'

import BotOptionTile from './BotOptionTile'

const BotOptions: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { id } = useParams<{ [x: string]: string }>()

  const { bot } = useSelector((state: ApplicationState) => state.botReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { botOptions } = useSelector((state: ApplicationState) => state.botOptionReducer)

  function handleAddOption (): void {
    if (selected && id) {
      createBotOption(selected, id, {})(dispatch)
    }
  }

  useEffect(() => {
    if (selected && id) {
      getBot(selected, id)(dispatch)
      getBotOptionList(selected, id)(dispatch)
    }
  }, [selected, id, dispatch])

  if (!bot) return null

  return (
    <div className="row panel no-padding">
      <ModalHeader title="Configurações do Bot" icon="cog" onClose={onClose} />

      <span
        onClick={handleAddOption}
        style={{ position: 'absolute', top: 8, right: 52 }}
        className="button primary margin-right-16"
      >
        <i className="fa fa-plus"/> Adicionar Opção
      </span>

      <div className="row panel-body" style={{ maxHeight: 760, overflow: 'auto' }}>
        { botOptions.length === 0 && <div className="row bot-option">Nenhuma opção cadastrada</div> }
        { botOptions.map((item, i) => <BotOptionTile key={i} optionId={item.id} />) }
      </div>
    </div>
  )
}

export default BotOptions
