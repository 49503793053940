import { AlertActionTypes } from 'util/modules/Alert/redux/types'

export type ChannelType = '1' | '2' | '3' | '4' | '5'
export type ChannelStatus = 'NOT_CONNECTED' | 'DISCONNECTED' | 'CONNECTING' | 'CONNECTED' | 'IDLE'

/**
* Data Types
*/
export interface Channel {
  id: number,
  type: ChannelType,
  status: ChannelStatus,
  attendantRestrictions: Array<{ id: number, channel: { id: number }, attendant: { id: number } }>,
  name: string,
  value: string,
  createdAt: string,
  updatedAt?: string
}

export interface ChannelConnectionQr {
  qrCode: string,
  status: ChannelStatus,
  message?: string
  publicHash: string
}

/**
* State Type
*/

export interface ChannelState {
  readonly channelDb: { [id: number]: Channel }
  readonly connectionQr?: ChannelConnectionQr
  readonly channels: Channel[]
  readonly statusList: { [key in ChannelStatus]: { label: string, color: string, icon: string } }
}

/**
* Action Enum Types
*/

export enum ChannelTypes {
  CHANNEL_RESET = 'CHANNEL/RESET',
  CHANNEL_CREATE = 'CHANNEL/CREATE',
  CHANNEL_UPDATE = 'CHANNEL/UPDATE',
  CHANNEL_DELETE = 'CHANNEL/DELETE',
  CHANNEL_GET = 'CHANNEL/GET',
  CHANNEL_GET_LIST = 'CHANNEL/GET/LIST',

  CHANNEL_GET_CONNECTION_QR = 'CHANNEL/GET/CONNECTION_QR'
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof ChannelTypes.CHANNEL_RESET,
}

interface CreateAction {
  type: typeof ChannelTypes.CHANNEL_CREATE,
  payload: Channel
}

interface UpdateAction {
  type: typeof ChannelTypes.CHANNEL_UPDATE,
  payload: Channel
}

interface DeleteAction {
  type: typeof ChannelTypes.CHANNEL_DELETE,
  payload: number
}

interface GetAction {
  type: typeof ChannelTypes.CHANNEL_GET,
  payload: Channel
}

interface GetListAction {
  type: typeof ChannelTypes.CHANNEL_GET_LIST,
  payload: Channel[]
}
interface GetConnectionQrAction {
  type: typeof ChannelTypes.CHANNEL_GET_CONNECTION_QR,
  payload: ChannelConnectionQr
}

export type ChannelActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction |
  GetConnectionQrAction
)
