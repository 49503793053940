import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Command } from 'panel/command/redux/types'
import { BotActionType } from '../redux/type/option'
import { ApplicationState } from 'AppReducer'

type BotParameterType = {
  actionType: BotActionType,
  parameters?: string,
  botCommandList?: Command[],
  required?: boolean,
  onSelect: (value: string) => void
}
const BotParameter: React.FC<BotParameterType> = ({ actionType, parameters, botCommandList, required, onSelect }) => {
  const [value, setValue] = useState('')
  const { bots } = useSelector((state: ApplicationState) => state.botReducer)
  const { sectors } = useSelector((state: ApplicationState) => state.sectorReducer)

  useEffect(() => {
    if (parameters !== undefined && parameters !== null) {
      setValue(parameters)
      onSelect(parameters)
    }
  }, [parameters])

  if (actionType === 'REDIRECT_BOT') {
    return (
      <div className="row justify-start form-control">
        <label>Selecione o BOT</label>

        {
          botCommandList
            ? (
              <select
                value={value}
                required={required}
                onChange={({ target: { value } }): void => { setValue(value); onSelect(value) }}
              >
                <option></option>
                {
                  botCommandList.map((item, i) => (
                    <option key={i} value={item.key}>[{item.data['order']}] {item.data['name']}</option>
                  ))
                }
              </select>
            )
            : (
              bots.map((item, i) => (
                <label className="checkbox margin-right-16" style={{ width: 'auto' }} key={i}>
                  <input
                    type="checkbox"
                    checked={value === String(item.id)}
                    onClick={() => { setValue(String(item.id)); onSelect(String(item.id)) }}
                  />
                  <div className="checkmark" /> {item.name}
                </label>
              ))
            )
        }
      </div>
    )
  }

  if (actionType === 'REDIRECT_SECTOR') {
    return (
      <div className="row justify-start form-control">
        <label>Selecione o Setor</label>
        {
          sectors.map((item, i) => (
            <label className="checkbox margin-right-16" style={{ width: 'auto' }} key={i}>
              <input
                type="checkbox"
                checked={value === String(item.id)}
                onClick={() => { setValue(String(item.id)); onSelect(String(item.id)) }}
              />
              <div className="checkmark" /> {item.name}
            </label>
          ))
        }
      </div>
    )
  }

  if (actionType === 'FILL_PERSON_DATA') {
    return (
      <div className="row form-control justify-start">
        <label>Selecione o campo</label>

        {
          [
            { key: 'firstName', label: 'Nome' },
            { key: 'lastName', label: 'Sobrenome' },
            { key: 'email', label: 'E-mail' },
            { key: 'cpf', label: 'CPF' },
            { key: 'cnpj', label: 'CNPJ' },
            { key: 'personal', label: 'Personalizado' }
          ].map((item, i) => (
            <label className="checkbox margin-right-16" style={{ width: 'auto' }} key={i}>
              <input
                type="checkbox"
                checked={value === item.key}
                onClick={() => { setValue(item.key); onSelect(item.key) }}
              />
              <div className="checkmark" /> {item.label}
            </label>
          ))
        }
      </div>
    )
  }

  if (actionType === 'RESPONSE_MESSAGE') {
    return (
      <div className="form-control">
        <label>Mensagem Resposta</label>
        <textarea
          rows={4}
          value={value}
          onChange={({ target: { value } }): void => { setValue(value); onSelect(value) }}
        />
      </div>
    )
  }

  return (
    <div className="form-control">
      <label>Parâmetro</label>
      <input type="text" disabled />
    </div>
  )
}

export default BotParameter
