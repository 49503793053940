import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import 'panel/user/styles/RolePermissions.scss'

import { ApplicationState } from 'AppReducer'
import { getRolePermissions } from 'panel/user/redux/actions'
import { RoleGrant } from 'panel/user/redux/types'

type RoleItemOpts = {
  onSelect: (role: string) => void,
  isActive: string,
  rolePermissions: RoleGrant[],
  grant: RoleGrant
}

const RoleItem: React.FC<RoleItemOpts> = ({ isActive, rolePermissions, grant, onSelect }) => {
  const [isOpen, setOpen] = useState(false)
  const inherits = getInherits(grant.inherits.map(item => item.name))

  function handleOpen (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void {
    e.stopPropagation()
    setOpen(!isOpen)
  }

  function getInherits (inherits: string[]): string[] {
    const roles: string[] = [...inherits]

    inherits.forEach(inherit => {
      const grant = rolePermissions.find(item => item.role.name === inherit)
      if (grant?.inherits) {
        grant.inherits.forEach(p => roles.push(p.name))

        const roleInherits = getInherits(grant.inherits.map(item => item.name))
        roleInherits.forEach(role => roles.push(role))
      }
    })

    const unique = new Set(roles) as unknown as string[]
    return [...unique]
  }

  return (
    <div className={`row role mob-12 ${isActive}`} key={grant.id} onClick={(): void => onSelect(grant.role.name)}>
      <span className="role-name mob-10">{ grant.role.name }</span>
      <h3>{ grant.role.description }</h3>
      <span className="button role-open-btn mob-2" onClick={handleOpen}>
        <i className="fa fa-caret-down"></i>
      </span>

      {
        isOpen &&
        <div className="row margin-top-16">
          <p className="no-margin">Permissões Atribuidas</p>
          <div className="row justify-start">
            {
              grant.permissions.map((permission, i) => {
                return (
                  <div className="row role-permission mob-12" key={i}>
                    <p className="bold">{ permission.description }</p>
                    <span className="role-name role-name-inside">{ permission.name }</span>
                  </div>
                )
              })
            }
          </div>

          {
            inherits.length > 0 &&
            <div className="row margin-top-16">
              <p className="no-margin">Permissões Herdadas</p>
              <div className="row justify-start mob-12">
                {
                  inherits.map((key, i) => {
                    const inherit = rolePermissions.find(grant => grant.role.name === key)
                    if (!inherit) return null

                    return (
                      <div className="row justify-start" key={i}>
                        <div className="row role-permission inherit" key={i}>
                          <p className="bold">{ inherit.role.description }</p>
                          <span className="role-name mob-12 ">{ inherit.role.name }</span>
                        </div>
                        <div className="row flex padding-left-8">
                          <div style={{ display: 'grid', width: '100%' }}>
                            <div className="inherit-box">
                              {

                                inherit.permissions
                                  .map((permission, i) => {
                                    return (
                                      <div className="row role-permission" key={i}>
                                        <p className="bold">{ permission.description }</p>
                                        <span className="role-name">{ permission.name }</span>
                                      </div>
                                    )
                                  })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}

const RolePermissions: React.FC<{ onSelect: (role: string) => void, roles: string[] }> = ({ onSelect, roles }) => {
  const dispatch = useDispatch()

  const { rolePermissions } = useSelector((state: ApplicationState) => state.userReducer)

  useEffect(() => {
    getRolePermissions()(dispatch)
  }, [dispatch])

  return (
    <div className="row mob-12">
      <h2 className="col-12 mob-12 permissions-breakword">Permissões do usuário</h2>
      <p className="col-12 mob-12 permissions-breakword">
        Adicione grupos de permissão. Um grupo contém várias permissoes e pode herdar de outro grupo
      </p>

      {
        rolePermissions.length === 0 &&
        <div className="row">
          Nenhuma permissão Encontrada
        </div>
      }
      {
        rolePermissions.map(grant => {
          const isActive = roles.find(role => role === grant.role.name) ? 'active' : ''

          return (
            <RoleItem
              isActive={isActive}
              rolePermissions={rolePermissions}
              onSelect={onSelect}
              grant={grant}
              key={grant.id}
            />
          )
        })
      }
    </div>
  )
}

export default RolePermissions
