/* eslint-disable @typescript-eslint/no-use-before-define */
import Axios from 'axios'
import { Dispatch } from 'redux'

import { ActionResponse, Id } from 'util/types/index'
import { Config, ConfigTypes } from './types'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'

export function saveConfig (data: Partial<Config>) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Config>> => {
    return Axios
      .put('/config', data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ConfigTypes.CONFIG_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao salvar a configuração')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getConfigs () {
  return async (dispatch: Dispatch): Promise<ActionResponse<Config>> => {
    return Axios
      .get('/config')
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ConfigTypes.CONFIG_GET, payload: res.data })
        return res
      })
  }
}
