import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import 'panel/user/styles/RolePermissions.scss'

import { ApplicationState } from 'AppReducer'

type RoleExtraPermissionsOpts = {
  onSelect: (role: string) => void,
  permissions: string[]
}
const RoleExtraPermissions: React.FC<RoleExtraPermissionsOpts> = ({ onSelect, permissions }) => {
  const [form, setForm] = useState({ role: '', permission: '' })
  const { rolePermissions } = useSelector((state: ApplicationState) => state.userReducer)

  const selectedPermissions = rolePermissions.find(grant => grant.role.name === form.role)?.permissions

  function handleClick (): void {
    if (form.permission) {
      onSelect(form.permission)
    }
  }

  return (
    <div className="row role-extra-permissions">
      <div className="row align-end role-mobile">
        <div className="row grow role-header">
          <h2 className="col-12">Permissões Extras</h2>
          <p className="col-12">
            Adicione  específicas de um grupo a este usuário
          </p>
        </div>
        <div className="row role-inputs" style={{ width: 'auto' }}>
          <div className="form-control col-6">
            <label>Grupo de Permissão</label>
            <select value={form.role} onChange={(e): void => setForm({ role: e.target.value, permission: '' })}>
              <option></option>
              {
                rolePermissions.map(grant => (
                  <option key={grant.id} value={grant.role.name}>
                    {grant.role.description}
                  </option>
                ))
              }
            </select>
          </div>
          <div className="form-control col-6">
            <label>Permissão</label>
            <select value={form.permission} onChange={(e): void => setForm({ ...form, permission: e.target.value })}>
              <option></option>
              {
                selectedPermissions?.map((permission, i) => (
                  <option key={i} value={permission.name}>
                    {permission.description}
                  </option>
                ))
              }
            </select>
          </div>
        </div>
        <span className="button primary" onClick={handleClick}>
          <i className="fa fa-plus no-margin" />
        </span>
      </div>

      {
        rolePermissions.length === 0 &&
        <div className="row">
          Nenhuma permissão Encontrada
        </div>
      }
      {
        rolePermissions.map(grant => {
          return (
            <div className="row" key={grant.id}></div>
          )
        })
      }

      {/* Selected Permissions */}
      <div className={`row role ${permissions.length > 0 ? 'active' : ''}`}>

        {
          permissions.length === 0 &&
          <div className="row">
            Nenhuma permissão extra definida
          </div>
        }

        {
          permissions.map((role, i) => {
            const permission = rolePermissions.map(g => g.permissions.find(p => p.name === role)).find(item => item)
            if (!permission) return null

            return (
              <div className="row role-permission" key={i}>
                <div className="remove-bg" onClick={(): void => onSelect(permission.name)}>
                  <i className="fa fa-times" />
                </div>
                <p className="bold">{permission.description}</p>
                <span className="role-name mob-6">{permission.name}</span>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default RoleExtraPermissions
