import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { ReactComponent as Layer } from 'assets/icons/layer.svg'

import { formatDateTime } from 'util/masks'
import { ApplicationState } from 'AppReducer'
import { deleteGroup, getGroupList } from '../redux/actions'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import { hasPermission } from 'util/services/AuthService'

const GroupList: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [deleteId, setDeleteId] = useState<number>()

  const { groups } = useSelector((state: ApplicationState) => state.groupReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleRemove (id: number): void {
    if (selected) {
      deleteGroup(selected, id)(dispatch).then(res => {
        if (res.status === 'success') {
          setDeleteId(undefined)
          history.push('/panel/group')
        }
      })
    }
  }

  useEffect(() => {
    if (selected) {
      getGroupList(selected)(dispatch)
    }
  }, [selected, dispatch])

  return (
    <div className="list" style={{ marginTop: 0 }}>
      <div className="list-header">
        <div>Id</div>
        <div>Nome</div>
        <div>Descrição</div>
        <div>Criado em</div>
        <div>Atualizado em</div>
        <div>Ações</div>
      </div>

      {
        groups.length === 0 && (
          <div className="list-row empty">
            <Layer width={120} height={120} style={{ padding: 24 }} />
            <div className="grid-span" style={{ height: 0 }}>Nenhum grupo cadastrado</div>
          </div>
        )
      }

      {
        groups.map((item, i) => (
          <div className="list-row" key={i}>
            <div data-content="Id" className="bold">#{item.id}</div>
            <div data-content="Nome">{item.name}</div>
            <div data-content="Descrição">{item.description}</div>
            <div data-content="Criado em">{formatDateTime(item.createdAt)}</div>
            <div data-content="Atualizado em">{formatDateTime(item.updatedAt)}</div>
            <div data-content="Ações" className="row buttons-list-row">
              <div className="row">
                {
                  hasPermission('GROUP:UPDATE') &&
                  <NavLink to={`/panel/group/${item.id}/edit`} className="button">
                    <i className="fas fa-edit" /> &nbsp;Editar
                  </NavLink>
                }

                {
                  hasPermission('GROUP:DELETE') &&
                  <span className="button secondary" onClick={() => setDeleteId(item.id)}>
                    <i className="fas fa-trash-alt" /> &nbsp;Excluir
                  </span>
                }
              </div>
            </div>
          </div>
        ))
      }

      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </div>
  )
}

export default GroupList
