import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import PanelLayout from 'panel/_root/Layout'

import SignIn from 'panel/user/sign-on/SignIn'
import Recovery from 'panel/user/recovery/Recovery'
import HashQrCode from 'public/channel/HashQrCode'
import SignUp from 'panel/user/sign-on/SignUp'

const AppRouter: React.FC = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" render={(): any => <Redirect to="/panel" />} />
          <Route path="/panel" component={PanelLayout }/>
          <Route path="/channel/qr-code" component={HashQrCode }/>

          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/sign-up" component={SignUp} />
          <Route exact path="/recovery" component={Recovery} />
        </Switch>
      </Router>
    </>
  )
}

export default AppRouter
