/* eslint-disable @typescript-eslint/no-use-before-define */
import Axios from 'axios'
import { Dispatch, Store } from 'redux'

import { storeToken } from 'util/services/AuthService'
import { ActionResponse, Id } from 'util/types/index'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'
import { AuthData, AuthRefreshData, AuthResponse, Person, Recovery, UpdatePassword, User, UserTypes } from './types'
import { Client } from 'panel/client/redux/types'

export function authenticate (data: AuthData) {
  return async (dispatch: Dispatch): Promise<ActionResponse<AuthResponse>> => {
    return Axios
      .post('/user/auth', data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          const { user, token, refreshToken } = res.data
          dispatch({ type: UserTypes.USER_AUTH, payload: user, token, refreshToken })

          storeToken(token, refreshToken)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao realizar o login')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function authenticateRefresh (data: AuthRefreshData) {
  return async (dispatch: Dispatch): Promise<ActionResponse<AuthResponse>> => {
    return Axios
      .post('/user/auth/refresh', data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          const { user, token, refreshToken } = res.data
          dispatch({ type: UserTypes.USER_AUTH, payload: user, token, refreshToken })

          storeToken(token, refreshToken)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o token')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function authenticateLikeAdmin (userId: number) {
  return async (dispatch: Dispatch): Promise<ActionResponse<AuthResponse>> => {
    return Axios
      .post(`/auth/like/${userId}/admin`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          const { user, token, refreshToken } = res.data
          dispatch({ type: UserTypes.USER_AUTH, payload: user, token, refreshToken })

          storeToken(token, refreshToken)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao realizar o login')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function authenticateLikeSeller (userId: number) {
  return async (dispatch: Dispatch): Promise<ActionResponse<AuthResponse>> => {
    return Axios
      .post(`/auth/like/${userId}/seller`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          const { user, token, refreshToken } = res.data
          dispatch({ type: UserTypes.USER_AUTH, payload: user, token, refreshToken })

          storeToken(token, refreshToken)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao realizar o login')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function register (data: Partial<Client & User & Person & Store>) {
  return async (dispatch: Dispatch): Promise<ActionResponse<AuthResponse>> => {
    return Axios
      .post('/sign-up', data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          const { user, token, refreshToken } = res.data
          dispatch({ type: UserTypes.USER_AUTH, payload: user, token, refreshToken })

          storeToken(token, refreshToken)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao realizar o cadastro')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function createUser (data: FormData) {
  return async (dispatch: Dispatch): Promise<ActionResponse<User>> => {
    createAlert({ code: 100, title: 'Criando Usuário', message: 'Aguarde...' })(dispatch)
    return Axios
      .post('/user', data)
      .then(res => res.data)
      .then(res => {
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar o usuário')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateUser (id: Id, data: FormData) {
  return async (dispatch: Dispatch): Promise<ActionResponse<User>> => {
    createAlert({ code: 100, title: 'Atualizando Usuário', message: 'Aguarde...' })(dispatch)
    return Axios
      .put(`/user/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success') {
          getUser(id)(dispatch)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o usuário')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateProfile (data: FormData) {
  return async (dispatch: Dispatch): Promise<ActionResponse<User>> => {
    // createAlert({ code: 100, title: 'Atualizando Perfil', message: 'Aguarde...' })(dispatch)
    return Axios
      .patch('/user/profile', data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res?.data) {
          getLogged()(dispatch)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o perfil')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updatePassword (id: Id, data: UpdatePassword) {
  return async (dispatch: Dispatch): Promise<ActionResponse<User>> => {
    // createAlert({ code: 100, title: 'Atualizando Senha', message: 'Aguarde...' })(dispatch)
    return Axios
      .patch(`/user/${id}/password`, data)
      .then(res => res.data)
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar a senha')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteProfilePicture (id?: number) {
  return async (dispatch: Dispatch): Promise<ActionResponse<User>> => {
    return Axios
      .delete(id ? `/user/${id}/picture` : '/user/profile/picture')
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success') {
          getLogged()(dispatch)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o perfil')
      })
      .then(async res => {
        if (res?.status === 'failed') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }

        return res
      })
  }
}

export function getLogged () {
  return async (dispatch: Dispatch): Promise<ActionResponse<User>> => {
    return Axios
      .get('/user/logged')
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: UserTypes.USER_GET_LOGGED, payload: res.data })
        }
        return res
      })
  }
}

export function getUser (id: Id|string) {
  return async (dispatch: Dispatch): Promise<ActionResponse<User>> => {
    return Axios
      .get(`/user/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: UserTypes.USER_GET, payload: res.data })
        }
        return res
      })
  }
}

export function getUserByPerson (personId: number|string) {
  return async (dispatch: Dispatch): Promise<ActionResponse<User[]>> => {
    return Axios
      .get(`/user/person/${personId}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: UserTypes.USER_GET, payload: res.data })
        }
        return res
      })
  }
}

export function getUserList (params: Record<string, any>) {
  return async (dispatch: Dispatch): Promise<ActionResponse<User[]>> => {
    return Axios
      .get('/user', { params })
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: UserTypes.USER_GET_LIST, payload: res.data, pagination: res.pagination })
        }
        return res
      })
  }
}

export function getRolePermissions () {
  return async (dispatch: Dispatch): Promise<ActionResponse<User>> => {
    return Axios
      .get('/user/permissions')
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: UserTypes.USER_GET_PERMISSIONS, payload: res.data })
        }
        return res
      })
  }
}

export async function isEmailAvailable (email: string): Promise<boolean> {
  return Axios
    .post('/user/email', { email })
    .then(res => res.data)
    .then(res => {
      return res?.status === 'success'
    })
    .catch(() => false)
}

export async function isUsernameAvailable (username: string): Promise<boolean> {
  return Axios
    .post('/user/username', { username })
    .then(res => res.data)
    .then(res => {
      return res?.status === 'success'
    })
    .catch(() => false)
}

export function recoveryPre (data: { email: string }) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Recovery>> => {
    return Axios
      .post('/user/recovery/pre', data)
      .then(res => res.data)
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao solicitar a recuperação de senha')
      })
      .then(async res => {
        if (res?.code === 500) {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }

        return res
      })
  }
}

export function recoveryRequest (data: { type: string, value: string }) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Recovery>> => {
    return Axios
      .post('/user/recovery', data)
      .then(res => res.data)
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao solicitar a recuperação de senha')
      })
      .then(async res => {
        if (res?.code === 500) {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }

        return res
      })
  }
}

export function recoveryVerify (data: { code: string }) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Recovery>> => {
    return Axios
      .post('/user/recovery/verify', data)
      .then(res => res.data)
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao solicitar ao verificar o código')
      })
      .then(async res => {
        if (res?.code === 500) {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }

        return res
      })
  }
}

export function recoveryPassword (data: { code: string, newPassword: string }) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Recovery>> => {
    return Axios
      .put('/user/recovery/password', data)
      .then(res => res.data)
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar a senha')
      })
      .then(async res => {
        if (res?.code === 500) {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }

        return res
      })
  }
}
