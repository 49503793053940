import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Tab } from '../ContactTab'
import { maskPhone, maskPhoneLine } from 'util/masks'
import { ApplicationState } from 'AppReducer'

import ContactItem from './ContactItem'
import { createContact } from 'panel/person/redux/action/contact'
import { getPerson } from 'panel/person/redux/action/person'

const initialState = { type: 'Phone', value: '' }
const ContactContact: React.FC<{ onUnsaved: (tab?: Tab) => void }> = ({ onUnsaved }) => {
  const dispatch = useDispatch()

  const [form, setForm] = useState(initialState)
  const [fetching, setFetching] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { person } = useSelector((state: ApplicationState) => state.personReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  const handleClear = useCallback(() => {
    setForm(initialState)
  }, [])

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (!selected) return undefined

    const body = { ...form, value: maskPhoneLine(form.value) }
    setFetching(true)
    await createContact(selected, id, body)(dispatch).then(res => {
      if (person && res?.status === 'success') {
        setForm(initialState)
        getPerson(selected, person.id)(dispatch)
      }
    })
    setFetching(false)
  }

  // Handle Unsaved form
  useEffect(() => {
    const isUnsaved = JSON.stringify(form) !== JSON.stringify(initialState)
    onUnsaved(isUnsaved ? 'CONTACT' : undefined)
  }, [form, person, initialState])

  if (!person) return null

  return (
    <div className="row">
      <form className="row align-end" onSubmit={handleSubmit}>
        <div className="form-control flex">
          <label>Tipo de Contato</label>
          <select
            value={form.type}
            onChange={(e): void => setForm({ ...form, type: e.target.value })}
            required
          >
            <option value="Phone">Telefone</option>
            <option>WhatsApp</option>
            <option>Telegram</option>
            <option>Comercial</option>
            <option>Pessoal</option>
          </select>
        </div>
        <div className="form-control flex">
          <label>Número</label>
          <input
            type="text"
            value={form.value}
            onChange={(e): void => setForm({ ...form, value: maskPhone(e.target.value) })}
            required
          />
        </div>
        <div className="form-buttons row" style={{ width: 250 }}>
          <span className="secondary button flex" onClick={handleClear}>Limpar</span>
          <button className="gradient flex" style={{ height: 48 }} disabled={fetching}>
            <i className="fa fa-save" /> Salvar
          </button>
        </div>
      </form>

      <div className="row margin-top-32">
        <h3><i className="fa fa-history"/> Contatos Cadastrado</h3>
        { person.contacts.length === 0 && <p className="hero">Nenhum contato cadastrado</p> }
        { person.contacts.map((contact, i) => <ContactItem contact={contact} key={i} />) }
      </div>
    </div>
  )
}

export default ContactContact
