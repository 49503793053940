import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import './styles/Config.scss'

import { ApplicationState } from 'AppReducer'

import Modal, { ModalHeader } from 'util/modules/Modal'
import ConfigTab, { Tab } from './components/ConfigTab'
import ConfigMessage from './components/partials/ConfigMessage'
import ConfigGeneral from './components/partials/ConfigGeneral'

const Config: React.FC<{ mode?: 'COGS' | 'COG' | 'INFO' }> = ({ mode = 'COGS' }) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState<Tab>('MESSAGE')

  const { logged } = useSelector((state: ApplicationState) => state.userReducer)

  const hasPermission = !!logged?.permissions?.includes('CONFIG:UPDATE')
  if (!hasPermission) {
    return (
      <div className="button config-btn" style={{ opacity: 0.4 }}>
        <i className="fa fa-cogs"/>
      </div>
    )
  }

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={() => setModalOpen(false)}>
        <div className="panel no-padding config-container">
          <ModalHeader title="Configurações do Sistema" icon="cog" onClose={() => setModalOpen(false)} />
          <div className="row panel-body">
            <ConfigTab onSelect={setSelectedTab} active={selectedTab} />
            { selectedTab === 'MESSAGE' && <ConfigMessage /> }
            { selectedTab === 'GENERAL' && <ConfigGeneral /> }
          </div>
        </div>
      </Modal>
      {
        mode === 'COGS' &&
        <div className="button" onClick={() => setModalOpen(true)}>
          <i className="fa fa-cogs"/>
        </div>
      }
      {
        mode === 'COG' &&
        <div className="button config-btn" onClick={() => setModalOpen(true)}>
          <i className="fa fa-cog"/>
        </div>
      }
      {
        mode === 'INFO' &&
        <div
          className="row padding-tb-8 pointer"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setModalOpen(true)
          }}
        >
          <p className="flex">Configuração</p>
          <i className="fa fa-cog highlight-text"/>
        </div>
      }
    </>
  )
}

export default Config
