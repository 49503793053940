import React, { useState, useEffect } from 'react'

import { Id } from 'util/types/index'
import { maskHour } from 'util/masks'
import { ApplicationState } from 'AppReducer'
import { useDispatch, useSelector } from 'react-redux'
import { createTimeTable, getTimeTableList, deleteTimeTable } from './redux/actions'

type GroupedType = { day: number, times: { id: number, start: string, end: string }[] }

const initialForm = { day: (new Date()).getDay(), start: '', end: '' }
const TimeTable: React.FC<{ attendantId?: Id }> = ({ attendantId }) => {
  const dispatch = useDispatch()

  const [form, setForm] = useState(initialForm)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { timeTable } = useSelector((state: ApplicationState) => state.timeTableReducer)

  function handleSubmit (e: React.FormEvent): void {
    e.preventDefault()
    if (selected && attendantId) {
      createTimeTable(selected, attendantId, form)(dispatch).then(res => {
        if (res?.status === 'success') {
          setForm(initialForm)
        }
      })
    }
  }

  function handleRemove (id: number): void {
    if (selected && attendantId) {
      deleteTimeTable(selected, attendantId, id)(dispatch)
    }
  }

  useEffect(() => {
    if (selected && attendantId) {
      getTimeTableList(selected, attendantId)(dispatch)
    }
  }, [selected, attendantId, dispatch])

  const grouped = timeTable.reduce((acc: GroupedType[], item) => {
    const time = { id: item.id, start: item.start, end: item.end }
    const index = acc.findIndex(obj => obj.day === item.day)
    if (index > -1) {
      acc[index].times.push(time)
    }
    return acc
  }, [...Array(7)].map((_, i) => ({ day: i, times: [] })))

  const btnStyle = { width: 30, height: 30, background: 'rgba(0,0,0,0.2)' }
  const days = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado']

  if (!attendantId) return null

  return (
    <div className="row">
      <form className="row align-end margin-top-16" onSubmit={handleSubmit}>
        <div className="form-control flex">
          <label>Dia da Semana</label>
          <select
            value={form.day}
            onChange={(e): void => setForm({ ...form, day: parseInt(e.target.value) })}
            required
          >
            { days.map((day, i) => <option key={i} value={i}>{ day }</option>) }
          </select>
        </div>
        <div className="form-control flex">
          <label>Horário de Início</label>
          <span
            className="span button secondary"
            style={{ position: 'absolute', width: 48, height: 48, right: 4, bottom: 0 }}
          >
            <i className="far fa-clock" style={{ margin: 0, fontSize: 20 }} />
          </span>
          <input
            type="text"
            value={form.start}
            onChange={(e): void => setForm({ ...form, start: maskHour(e.target.value) })}
            required
          />
        </div>
        <div className="form-control flex">
          <label>Horário de Saída</label>
          <span
            className="span button secondary"
            style={{ position: 'absolute', width: 48, height: 48, right: 4, bottom: 0 }}
          >
            <i className="far fa-clock" style={{ margin: 0, fontSize: 20 }} />
          </span>
          <input
            type="text"
            value={form.end}
            onChange={(e): void => setForm({ ...form, end: maskHour(e.target.value) })}
            required
          />
        </div>

        <button className="highlight">Adicionar</button>
      </form>

      {
        grouped.filter(week => week.times.length).length === 0 &&
        <div className="row hero margin-top-16">
          <p>Nenhum horário cadastrado</p>
        </div>
      }
      {
        grouped.filter(week => week.times.length).map(week => {
          return (
            <div className="row margin-top-16 panel" key={week.day} style={{ background: '#efefef', padding: 8 }}>
              <h3 className="margin-left-8">{days[week.day]}</h3>

              <div className="row justify-start margin-top-16">
                {
                  week.times.map((time, i) => {
                    return (
                      <div className="button highlight margin-8" key={i}>
                        Das&nbsp;<b>{time.start}</b>&nbsp;Até&nbsp;<b>{time.end}</b>

                        <span
                          style={btnStyle}
                          className="button margin-left-8"
                          onClick={(): void => handleRemove(time.id)}
                        >
                          <i className="fa fa-trash" style={{ margin: 0, color: 'white' }} />
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default TimeTable
