import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import { Seller } from '../redux/types'
import { formatDateTime, formatDate } from 'util/masks'
import { ApplicationState } from 'AppReducer'
import { authenticateLikeAdmin } from 'panel/user/redux/actions'
import { deleteSeller, getSellerList } from '../redux/actions'

import { ReactComponent as SellerUser } from 'assets/icons/owner.svg'

import { img } from 'assets/online'

import Pagination from 'util/modules/Pagination'
import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'

const SellerList: React.FC = () => {
  const [deleteId, setDeleteId] = useState<number>()

  const dispatch = useDispatch()
  const history = useHistory()

  const [filter, setFilter] = useState({ isActive: true, isBlocked: false, isExpired: false, name: '', email: '' })
  const { sellers, pagination } = useSelector((state: ApplicationState) => state.sellerReducer)

  function handleRemove (id: number): void {
    deleteSeller(id)(dispatch).then(res => {
      if (res.status === 'success') {
        setDeleteId(undefined)
        history.push('/panel/seller')
      }
    })
  }

  function handleAuthenticate (seller: Seller): void {
    if (seller.user.id) {
      authenticateLikeAdmin(seller.user.id)(dispatch).then(res => {
        if (res?.status === 'success') {
          history.push('/panel')
        }
      })
    }
  }

  async function fetchSellers (e?: React.FormEvent): Promise<void> {
    e?.preventDefault()

    const query = new URLSearchParams(history.location.search)
    const params: any = {
      page: query.get('page') || 1,
      limit: query.get('limit') || 20,
      'filter[isActive]': filter.isActive ? 1 : 0,
      'filter[isBlocked]': filter.isBlocked ? 1 : 0,
      'filter[isExpired]': filter.isExpired ? 1 : 0
    }

    if (filter.name) params['filter_like[p.name]'] = filter.name
    if (filter.email) params['filter_like[p.email]'] = filter.email

    getSellerList(params)(dispatch)
  }

  useEffect(() => {
    fetchSellers()
  }, [history.location.search, dispatch])

  return (
    <form onSubmit={fetchSellers}>
      <div className="row panel shadow seller-head" >
        <div className="row align-end filter">
          <div className="form-control">
            <label>Nome</label>
            <input
              type="text"
              value={filter.name}
              onChange={(e): void => setFilter({ ...filter, name: e.target.value })}
            />
          </div>
          <div className="form-control">
            <label>E-mail</label>
            <input
              type="text"
              value={filter.email}
              onChange={(e): void => setFilter({ ...filter, email: e.target.value })}
            />
          </div>
          <div className="form-control">
            <label>Ativo</label>
            <select
              value={filter.isActive ? 1 : 0}
              onChange={(e): void => setFilter({ ...filter, isActive: parseInt(e.target.value) === 1 })}
            >
              <option value={1}>Sim</option>
              <option value={0}>Não</option>
            </select>
          </div>
          <div className="form-control">
            <label>Bloqueado</label>
            <select
              value={filter.isBlocked ? 1 : 0}
              onChange={(e): void => setFilter({ ...filter, isBlocked: parseInt(e.target.value) === 1 })}
            >
              <option value={1}>Sim</option>
              <option value={0}>Não</option>
            </select>
          </div>
          <div className="form-control">
            <label>Vencido</label>
            <select
              value={filter.isExpired ? 1 : 0}
              onChange={(e): void => setFilter({ ...filter, isExpired: parseInt(e.target.value) === 1 })}
            >
              <option value={1}>Sim</option>
              <option value={0}>Não</option>
            </select>
          </div>
          <button className="gradient button"><i className="fa fa-filter" />Filtrar</button>
        </div>
      </div>

      <div className="list">
        <div className="list-header">
          <div>Id</div>
          <div>Img</div>
          <div>Nome</div>
          <div>Ativo</div>
          <div>Bloqueado</div>
          <div>Vencido</div>
          <div>Vencimento</div>
          <div>Atualizado em</div>
          <div>Ações</div>
        </div>

        {
          sellers.length === 0 &&
          <div className="list-row empty">
            <SellerUser width={120} height={120} style={{ padding: 24 }} />
            <div className="grid-span" style={{ height: 0 }}>Nenhum revendedor cadastrado</div>
          </div>
        }

        {
          sellers.map((item, i) => {
            const color = { 1: 'var(--green)', 0: 'var(--red)' }
            const picture = item.user?.person?.picture || img['default-img']
            const background = { 1: 'var(--green-alpha)', 0: 'var(--red-alpha)' }

            // Color index
            const isActive = item.isActive ? 1 : 0
            const isBlocked = !item.isBlocked ? 1 : 0
            const isExpired = !item.isExpired ? 1 : 0

            return (
              <div className="list-row" key={i}>
                <div data-content="Id">{item.id}</div>
                <div data-content="Img">
                  <img src={picture} className="border-radius" alt="User" width={48} />
                </div>
                <div data-content="Nome">{item.user?.person?.name}</div>
                <div data-content="Status">
                  <span
                    className="status"
                    style={{ background: background[isActive], color: color[isActive] }}
                  >
                    {item.isActive ? 'Sim' : 'Não'}
                  </span>
                </div>
                <div data-content="Ativo">
                  <span
                    className="status"
                    style={{ background: background[isBlocked], color: color[isBlocked] }}
                  >
                    {item.isBlocked ? 'Sim' : 'Não'}
                  </span>
                </div>
                <div data-content="Expirado">
                  <span
                    className="status"
                    style={{ background: background[isExpired], color: color[isExpired] }}
                  >
                    {item.isExpired ? 'Sim' : 'Não'}
                  </span>
                </div>
                <div data-content="Criado em">
                  {formatDate(item.dueDate, 'Não configurado')}
                </div>
                <div data-content="Atualizado em">{formatDateTime(item.updatedAt)}</div>
                <div data-content="Ações" className="row buttons-list-row">
                  <div className="row">
                    <span
                      className="button"
                      onClick={(): void => handleAuthenticate(item)}>
                      <i className="fas fa-key" />
                    </span>
                    <NavLink to={`/panel/seller/${item.id}/edit`} className="button">
                      <i className="fas fa-edit" />
                    </NavLink>
                    <span className="button" onClick={() => setDeleteId(item.id)}>
                      <i className="fas fa-trash" />
                    </span>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>

      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />

      <Pagination pagination={pagination} />
    </form>
  )
}

export default SellerList
