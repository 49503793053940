import { Api } from './api'

type PreAssignResponse = {
  fields: Record<string, string>
  location: string
  url: string
}

type AssignedOutput = {
  form: FormData
  location: string
  url: string
}

const getPreAssignUrl = async (url: string, file: File, permission = 'public-read'): Promise<AssignedOutput> => {
  const { data } = await Api.post<PreAssignResponse>(url, {
    mimeType: file.type
  })
  if (!data) throw new Error('Ocorreu um erro ao realizar a requisição')

  const form = new FormData()

  for (const [key, value] of Object.entries(data.fields)) {
    form.append(key, value)
  }

  form.append('acl', permission)
  form.append('Content-Type', file.type)
  form.append('tagging', '<Tagging><TagSet><Tag><Key>keep</Key><Value>false</Value></Tag></TagSet></Tagging>')
  form.append('file', file)

  return {
    form,
    location: data.location,
    url: data.url
  }
}

export const uploadFile = async (path: string, file: File, permission?: 'private' | 'public-read'): Promise<string> => {
  const { form, location, url } = await getPreAssignUrl(path, file, permission)

  const res = await fetch(url, { body: form, method: 'POST' })
  if (res.ok) return location

  throw new Error('Ocorreu um erro ao realizar o upload')
}
