import { Group } from 'panel/group/redux/types'
import { PaginationOpts } from 'util/types/index'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'
import { Person } from './person'

export type ContactValidationStatus = 'PENDING' | 'VALIDATED' | 'INVALID'

/**
* Data Types
*/

export interface ContactCSV {
  firstName: string,
  lastName: string,
  contact: string,
  email: string
}

export interface Contact {
  id: number,
  type: string,
  value: string,
  subValue: string,
  status: ContactValidationStatus,
  person: Partial<Person>,
  isValidated: boolean,
  groups: Partial<Group>[],
  createdAt: string,
  updatedAt?: string
}

/**
* State Type
*/

export interface ContactState {
  readonly contact?: Contact
  readonly contacts: { items: Contact[], pagination?: PaginationOpts }
}

/**
* Action Data
*/

export interface ContactData {
  type: string,
  value: string
}

/**
* Action Enum Types
*/

export enum ContactTypes {
  CONTACT_RESET = 'CONTACT/RESET',
  CONTACT_CREATE = 'CONTACT/CREATE',
  CONTACT_UPDATE = 'CONTACT/UPDATE',
  CONTACT_DELETE = 'CONTACT/DELETE',
  CONTACT_GET = 'CONTACT/GET',
  CONTACT_GET_LIST = 'CONTACT/GET/LIST',
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof ContactTypes.CONTACT_RESET
}

interface CreateAction {
  type: typeof ContactTypes.CONTACT_CREATE,
  payload: Contact
}

interface UpdateAction {
  type: typeof ContactTypes.CONTACT_UPDATE,
  payload: Contact
}

interface DeleteAction {
  type: typeof ContactTypes.CONTACT_DELETE,
  payload: number
}

interface GetAction {
  type: typeof ContactTypes.CONTACT_GET,
  payload: Contact
}

interface GetListAction {
  type: typeof ContactTypes.CONTACT_GET_LIST,
  payload: Contact[],
  pagination: PaginationOpts
}

export type ContactActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction
)
