import Axios from 'axios'
import { Dispatch } from 'redux'
import { ActionResponse, Id } from 'util/types/index'
import { Contact, ContactCSV, ContactData, ContactTypes } from '../types/contact'
import { clearAlert, createAlert, handleError } from 'util/modules/Alert/redux/actions'
import { PersonTypes } from '../types/person'

export function createContact (storeId: Id, personId: Id, data: ContactData) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Contact>> => {
    return Axios
      .post(`/store/${storeId}/person/${personId}/contact`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res?.data) {
          dispatch({ type: ContactTypes.CONTACT_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar o contato')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateContact (storeId: Id, personId: Id, id:Id, data: ContactData) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Contact>> => {
    return Axios
      .put(`/store/${storeId}/person/${personId}/contact/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res?.data) {
          dispatch({ type: ContactTypes.CONTACT_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o contato')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteContactPicture (storeId: Id, personId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Contact>> => {
    return Axios
      .delete(`/store/${storeId}/person/${personId}/picture`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res?.data) {
          dispatch({ type: PersonTypes.PERSON_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover a imagem do contato')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteContact (storeId: Id, personId: Id, id:Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Contact>> => {
    return Axios
      .delete(`/store/${storeId}/person/${personId}/contact/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res?.data) {
          dispatch({ type: ContactTypes.CONTACT_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o contato')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getContact (storeId: Id, personId:Id, id:Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Contact>> => {
    return Axios
      .get(`/store/${storeId}/person/${personId}/contact/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ContactTypes.CONTACT_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar o contato')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }

        return res
      })
  }
}

export function getContactList (storeId: Id, personId: Id, params?: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Contact[]>> => {
    return Axios
      .get(`/store/${storeId}/person/${personId}/contact`, { params })
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ContactTypes.CONTACT_GET_LIST, payload: res.data || [], pagination: res.pagination })
        return res
      })
  }
}

export function getContactStoreList (storeId: Id, params?: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Contact[]>> => {
    return Axios
      .get(`/store/${storeId}/contact`, { params })
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ContactTypes.CONTACT_GET_LIST, payload: res.data || [], pagination: res.pagination })
        return res
      })
  }
}

export async function getCSVContacts (storeId: Id): Promise<ContactCSV[]> {
  return Axios
    .get(`/store/${storeId}/contact-csv`)
    .then(res => res.data)
    .then(res => {
      return res?.data || []
    })
}

export async function deleteAllContacts (storeId: Id): Promise<ContactCSV[]> {
  return Axios
    .delete(`/store/${storeId}/contact`)
    .then(res => res.data)
}

export function validateAllContacts (storeId: Id) {
  return async (dispatch: Dispatch): Promise<void> => {
    Axios
      .post(`/store/${storeId}/contact/validate`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ContactTypes.CONTACT_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao validar os contatos')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        await clearAlert(3000)(dispatch)

        return res
      })
  }
}
