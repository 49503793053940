import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { maskPhone } from 'util/masks'
import { iterateList } from 'util/index'
import { ModalHeader } from 'util/modules/Modal'
import { defaultPicture } from 'index'
import { ApplicationState } from 'AppReducer'
import { finishConversationByChannelOwner } from 'panel/conversation/redux/actions'

import Modal from 'util/modules/Modal'
import { Conversation } from 'panel/conversation/redux/types'
import { useEffect } from 'react'

const FinishConversationBatch: React.FC = () => {
  const dispatch = useDispatch()

  const [ids, setIds] = useState<number[]>([])
  const [isRunning, setRunning] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [filterStatus, setFilterStatus] = useState<Conversation['status'][]>([])

  const inpCheckAll = useRef<HTMLInputElement>(null)

  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { conversations } = useSelector((state: ApplicationState) => state.conversationReducer)
  const filtered = conversations.filter(item => filterStatus.includes(item.status))

  function handleAll () {
    if (filtered.length === ids.length) {
      setIds([])
    } else {
      setIds(filtered.map(item => item.id))
    }
  }

  function handleAdd (id: number) {
    const isIdOnList = ids.find(item => item === id)
    if (!isIdOnList) {
      setIds([...ids, id])
    }
  }

  function handleDel (id: number) {
    setIds(ids.filter(item => item !== id))
  }

  function handleAddStatus (status: Conversation['status']) {
    if (filterStatus.includes(status)) {
      setFilterStatus(filterStatus.filter(item => item !== status))
    } else {
      setFilterStatus([...filterStatus, status])
    }
  }

  async function handleRun () {
    if (selected && window.confirm('Tem certeza que deseja finalizar os atendimentos selecionado?')) {
      setRunning(true)
      await iterateList(async (id) => {
        await finishConversationByChannelOwner(selected, id, false)(dispatch)
      }, ids)
      setRunning(false)
      setIds([])
    }
  }

  useEffect(() => {
    setIds([])
  }, [filterStatus])

  useEffect(() => {
    if (inpCheckAll.current) {
      inpCheckAll.current.indeterminate = ids.length > 0 && ids.length < filtered.length
    }
  }, [inpCheckAll, ids])

  const gridTemplateColumns = '50px 50px 1fr 150px'

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <div className="row panel no-padding finish-chat" style={{ width: 760 }}>
          <ModalHeader title="Finalizar Vários Atendimentos" onClose={() => setModalOpen(false)} />
          <div className="panel-body">
            <div className="form-control">
              {/* Conversation Filter */}
              <div className="row justify-start margin-bottom-16">
                <div className="row form-control" style={{ width: 'auto' }}>
                  <label className="switch">
                    <input
                      type='checkbox'
                      checked={filterStatus.length === 3}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFilterStatus(['QUEUE', 'RUNNING', 'IDLE'])
                        } else {
                          setFilterStatus([])
                        }
                      }}
                    />
                    <span className="slider round" />
                  </label>
                  <div className="label padding-left-8">Todos ({ conversations.length })</div>
                </div>
                <div className="row form-control" style={{ width: 'auto' }}>
                  <label className="switch">
                    <input
                      type='checkbox'
                      checked={filterStatus.includes('QUEUE')}
                      onChange={() => handleAddStatus('QUEUE')}
                    />
                    <span className="slider round" />
                  </label>
                  <div className="label padding-left-8">
                    Pendentes ({ conversations.filter(c => c.status === 'QUEUE').length })
                  </div>
                </div>
                <div className="row form-control" style={{ width: 'auto' }}>
                  <label className="switch">
                    <input
                      type='checkbox'
                      checked={filterStatus.includes('RUNNING')}
                      onChange={() => handleAddStatus('RUNNING')}
                    />
                    <span className="slider round" />
                  </label>
                  <div className="label padding-left-8">
                    Em Atendimento  ({ conversations.filter(c => c.status === 'RUNNING').length })
                  </div>
                </div>
                <div className="row form-control" style={{ width: 'auto' }}>
                  <label className="switch">
                    <input
                      type='checkbox'
                      checked={filterStatus.includes('IDLE')}
                      onChange={() => handleAddStatus('IDLE')}
                    />
                    <span className="slider round" />
                  </label>
                  <div className="label padding-left-8">
                    BOT  ({ conversations.filter(c => c.status === 'IDLE').length })
                  </div>
                </div>
              </div>

              <div className="list" style={{ marginTop: 0 }}>
                <div className="list-header" style={{ display: 'grid', gridTemplateColumns }}>
                  <div>
                    <input
                      readOnly
                      type="checkbox"
                      ref={inpCheckAll}
                      onClick={handleAll}
                    />
                  </div>
                  <div className="padding-right-16">Img</div>
                  <div className="padding-left-16">Cliente</div>
                  <div>Contato</div>
                </div>

                <div className="row" style={{ height: 400, overflow: 'auto' }}>
                  {
                    filtered.length === 0 && <div className="row hero">Nenhum atendimento encontrado</div>
                  }
                  {
                    filtered.map((item, i) => {
                      const isOnList = !!ids.find(id => id === item.id)
                      return (
                        <div className="list-row" style={{ display: 'grid', gridTemplateColumns }} key={i}>
                          <div style={{ width: 20 }}>
                            <input
                              type="checkbox"
                              checked={isOnList}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  handleAdd(item.id)
                                } else {
                                  handleDel(item.id)
                                }
                              }}
                            />
                          </div>
                          <div data-content="Imagem">
                            <img
                              src={item.client?.person?.picture || defaultPicture}
                              alt="User"
                              width={42}
                              height={42}
                              className='img-circle margin-right-16'
                            />
                          </div>
                          <div data-content="Cliente" className="flex padding-lr-16">
                            {item.client?.person?.name}
                          </div>
                          <div data-content="Contato" style={{ width: 150 }}>{maskPhone(item.clientValue)}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>

            <button className="gradient col-12 margin-top-16" disabled={!ids.length} onClick={handleRun}>
              {
                isRunning
                  ? (
                    <>
                      <i className="fa fa-spinner fa-spin" style={{ color: 'white' }} />
                      Finalizando {ids.length} {ids.length === 1 ? 'Atendimento' : 'Atendimentos'}. Aguarde...
                    </>
                  )
                  : (
                    <>
                      <i className="fa fa-cog" style={{ color: 'white' }} />
                      {
                        ids.length
                          ? `Finalizar ${ids.length} ${ids.length === 1 ? 'Atendimento' : 'Atendimentos'}`
                          : 'Selecione Para Finalizar'
                      }
                    </>
                  )
              }
            </button>
          </div>
        </div>
      </Modal>
      <span className="button white col-12 small" onClick={() => setModalOpen(true)}>
        <i className="fa fa-times"/> Finalizar Vários Atendimentos
      </span>
    </>
  )
}

export default FinishConversationBatch
