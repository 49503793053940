import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { ReactComponent as People } from 'assets/icons/people.svg'

import { img } from 'assets/online'
import { Client } from '../redux/types'
import { formatDateTime } from 'util/masks'
import { ApplicationState } from 'AppReducer'
import { deleteClient, getClientList } from '../redux/actions'
import { authenticateLikeSeller } from 'panel/user/redux/actions'

import DeleteConfirm from 'util/modules/Confirm/DeleteConfirm'
import Pagination from 'util/modules/Pagination'

const ClientList: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [filter, setFilter] = useState({ isActive: true, isBlocked: false, name: '', email: '' })
  const [deleteId, setDeleteId] = useState<number>()

  const { clients: { items, pagination } } = useSelector((state: ApplicationState) => state.clientReducer)

  function handleRemove (id: number): void {
    deleteClient(id)(dispatch).then((res) => {
      if (res.status === 'success') {
        setDeleteId(undefined)
        history.push('/panel/client')
      }
    })
  }

  function handleAuthenticate (client: Client): void {
    if (client.user.id) {
      authenticateLikeSeller(client.user.id)(dispatch).then(res => {
        if (res?.status === 'success') {
          history.push('/panel')
        }
      })
    }
  }
  async function fetchClients (e?: React.FormEvent): Promise<void> {
    e?.preventDefault()

    const query = new URLSearchParams(history.location.search)
    const params: any = {
      page: query.get('page') || 1,
      limit: query.get('limit') || 20,
      'filter[isActive]': filter.isActive ? 1 : 0,
      'filter[isBlocked]': filter.isBlocked ? 1 : 0
    }

    if (filter.name) params['filter_like[p.name]'] = filter.name
    if (filter.email) params['filter_like[p.email]'] = filter.email

    getClientList(params)(dispatch)
  }

  useEffect(() => {
    fetchClients()
  }, [history.location.search, dispatch])

  return (
    <form onSubmit={fetchClients}>
      <div className="row panel align-end filter">
        <div className="form-control">
          <label>Nome</label>
          <input
            type="text"
            value={filter.name}
            onChange={(e): void => setFilter({ ...filter, name: e.target.value })} />
        </div>
        <div className="form-control">
          <label>E-mail</label>
          <input
            type="text"
            value={filter.email}
            onChange={(e): void => setFilter({ ...filter, email: e.target.value })} />
        </div>
        <div className="form-control">
          <label>Ativo</label>
          <select
            value={filter.isActive ? 1 : 0}
            onChange={(e): void => setFilter({ ...filter, isActive: parseInt(e.target.value) === 1 })}
          >
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
        </div>
        <div className="form-control">
          <label>Bloqueado</label>
          <select
            value={filter.isBlocked ? 1 : 0}
            onChange={(e): void => setFilter({ ...filter, isBlocked: parseInt(e.target.value) === 1 })}
          >
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
        </div>
        <button className="gradient button"><i className="fa fa-filter" />Filtrar</button>
      </div>

      <div className="list">
        <div className="list-header">
          <div>Id</div>
          <div>Img</div>
          <div>Nome</div>
          <div>Ativo?</div>
          <div>Bloqueado?</div>
          <div>Criado em</div>
          <div>Atualizado em</div>
          <div>Ações</div>
        </div>

        {
          items.length === 0 &&
          <div className="list-row empty">
            <People width={120} height={120} style={{ padding: 24 }} />
            <div className="grid-span" style={{ height: 0 }}>Nenhum cliente cadastrado</div>
          </div>
        }

        {
          items.map((item, i) => (
            <div className="list-row" key={i}>
              <div data-content="Id">{item.id}</div>
              <div data-content="Img">
                <img
                  src={item.user?.person?.picture || img['default-img']}
                  className="border-radius" alt="User" width={50}
                />
              </div>
              <div data-content="Nome">{item.user?.person?.name}</div>
              <div data-content="Ativo?">
                <span className={`status ${item.isActive ? 'success' : 'danger'}`}>
                  {item.isActive ? 'Sim' : 'Não'}
                </span>
              </div>
              <div data-content="Bloqueado?">
                <span className={`status ${item.isBlocked ? 'success' : 'danger'}`}>
                  {item.isBlocked ? 'Sim' : 'Não'}
                </span>
              </div>
              <div data-content="Criado em">{formatDateTime(item.createdAt)}</div>
              <div data-content="Vencimento">{formatDateTime(item.updatedAt)}</div>
              <div data-content="Ações" className="row buttons-list-row">
                <div className="row">
                  <button className="button" onClick={(): void => handleAuthenticate(item)}>
                    <i className="fas fa-key" />
                  </button>
                  <NavLink to={`/panel/client/${item.id}/edit`} className="button">
                    <i className="fas fa-edit padding-right-4" /> Editar
                  </NavLink>
                  <span className="button" onClick={() => setDeleteId(item.id)}>
                    <i className="fas fa-trash padding-right-4" /> Excluir
                  </span>
                </div>
              </div>
            </div>
          ))
        }
      </div>
      <Pagination pagination={pagination} />
      <DeleteConfirm id={deleteId} onCancel={() => setDeleteId(undefined)} onConfirm={(id) => handleRemove(id)} />
    </form>
  )
}

export default ClientList
