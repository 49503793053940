/* eslint-disable @typescript-eslint/ban-ts-comment */
type Validation = {
  strength: number,
  isValid: boolean,
  warnings: string[],
  info: string
}

type Configs = {
  uppercase: number,
  number: number,
  specialChar?: number,
  minLength: number,
  requiredStrength: number
}

const {
  REACT_APP_USER_PWD_UPPERCASE: uppercase,
  REACT_APP_USER_PWD_NUMBER: number,
  REACT_APP_USER_PWD_LENGTH: length,
  REACT_APP_USER_PWD_STRENGTH: strength
} = process.env

const defaultConfig: Configs = {
  uppercase: parseInt(uppercase || '1'),
  number: parseInt(number || '1'),
  minLength: parseInt(length || '6'),
  requiredStrength: parseInt(strength || '60')
}

export function passwordValidate (password = '', _password = '', configs = defaultConfig): Validation {
  // Validation config
  const validationConfigs: {[x: string]: { match: any, weight: number, warning: string }} = {
    uppercase: {
      match: password.match(/[A-Z]/g),
      weight: 10,
      warning: `A senha precisa conter pelo ao menos ${configs.uppercase} letra(s) maiúscula(s)`
    },
    number: {
      match: password.match(/[0-9]/g),
      weight: 8,
      warning: `A senha precisa conter pelo ao menos ${configs.number} número(s)`
    },
    specialChar: {
      match: password.match(/[^0-9a-zA-Z]/g),
      weight: 15,
      warning: `A senha precisa conter pelo ao menos ${configs.specialChar} caractere(s) especiais`
    },
    minLength: {
      match: password.match(/./g),
      weight: 2,
      warning: `A senha precisa conter pelo ao menos ${configs.minLength} caracteres`
    },
    isEqual: {
      match: password === _password,
      weight: 1,
      warning: 'Senha informada não iguais'
    }
  }

  const validation: Validation = {
    strength: 0,
    isValid: false,
    warnings: [],
    info: ''
  }

  if (password) {
    // Loop all itens to validate
    Object.keys(configs).forEach(key => {
      if (key !== 'requiredStrength') {
        const v = validationConfigs[key]
        // @ts-ignore
        const config = configs[key]
        if (v && config) {
          if (v.match && v.match.length >= config) {
            validation.strength += v.weight * v.match.length
          } else {
            validation.warnings.push(v.warning)
          }
        }
        return true
      }
    })

    // Calibrate strength
    const maxStrength = 100
    validation.strength = validation.strength > maxStrength ? maxStrength : validation.strength
    validation.strength = Math.trunc((validation.strength / maxStrength) * 100)

    // Check strength
    const hasStrength = validation.strength >= configs.requiredStrength
    if (!hasStrength) {
      // const req = configs.requiredStrength
      // const act = Math.trunc(validation.strength)
      validation.warnings.push('Senha fraca, utilize uma senha mais forte')
    }

    // Set Info
    validation.isValid = !validation.warnings.length
    validation.info = !validation.isValid ? 'Ruim' : hasStrength &&
    validation.strength < 90 ? 'Boa' : 'Excelente'

    // Check is Equal
    if (!validationConfigs.isEqual.match && _password) {
      validation.warnings.push(validationConfigs.isEqual.warning)
    }
  }

  return validation
}
