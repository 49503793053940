/* eslint-disable react/display-name */
import React, { useState, useImperativeHandle, ForwardRefRenderFunction, useCallback } from 'react'
import Preview from './components/Preview'
import Placeholder from './components/Placeholder'

import './styles/Upload.scss'

type UploadProps = {
  ref?: any,
  url?: string,
  type?: 'cover'|'contain',
  disabled?: boolean,
  placeholder?: string|boolean,
  onChange: (file: File | undefined) => void
}

export interface UploadRef {
  clear: () => void,
  setPreviewImage: (url: string) => void
}

type UploadOpts = ForwardRefRenderFunction<UploadRef, UploadProps>

const Upload: UploadOpts = ({ onChange, placeholder, type, disabled = false }, ref) => {
  const [file, setFile] = useState<File>()
  const [url, setUrl] = useState<string>()
  const inputFile = React.createRef<HTMLInputElement>()

  function handleFile (event: React.ChangeEvent<HTMLInputElement>): void {
    const file = event.target.files?.[0]
    if (file && inputFile.current) {
      setFile(file)
      onChange(file)

      inputFile.current.value = ''
    }
  }

  function handleClick (): void {
    if (inputFile.current) {
      inputFile.current.click()
    }
  }

  const handleClear = useCallback(() => {
    setFile(undefined)
    setUrl(undefined)
    onChange(undefined)
  }, [onChange])

  useImperativeHandle(ref, () => ({
    clear: (): void => {
      handleClear()
    },
    setPreviewImage: (url: string): void => {
      setFile(undefined)
      setUrl(url)
    }
  }))

  const showPlaceholder = !file && !url
  const showByFileOrUrl = file || url

  return (
    <div className="upload" style={{ pointerEvents: disabled ? 'none' : 'inherit' }}>
      { showPlaceholder && <Placeholder onClick={handleClick} placeholder={placeholder} /> }
      { showByFileOrUrl && <Preview type={type} file={file} url={url} clearFile={handleClear} /> }

      <input ref={inputFile} onChange={handleFile} type="file" style={{ display: 'none' }} />
    </div>
  )
}

export default React.forwardRef(Upload)
