/* eslint-disable no-useless-escape */
import React from 'react'
import mime from 'mime'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import { MessageDataBase } from 'panel/message-database/redux/types'
import { createMessage } from 'panel/conversation/redux/actions'
import { useParams } from 'react-router-dom'

type Props = { message: string, onSelect: (message: string) => void, onClear: () => void }
const Shortcuts: React.FC<Props> = ({ message, onSelect, onClear }) => {
  const dispatch = useDispatch()
  const firstCharIsSlash = message && message[0] === '/'

  const { id } = useParams<{ id: string }>()
  const { config } = useSelector((state: ApplicationState) => state.configReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { messages } = useSelector((state: ApplicationState) => state.messageDataBaseReducer)

  const filtered = messages.filter(item => (
    firstCharIsSlash &&
    item.shortcut.toLowerCase().search(message.toLocaleLowerCase()) > -1
  ))

  async function handleSelect (item: MessageDataBase) {
    if (!selected) return
    onClear()

    const formData = new FormData()
    formData.set('message', item.message)

    if (item.attachmentUrl) {
      const fileName = item.attachmentUrl.split('/').pop() || ''
      const file = await fetch(item.attachmentUrl)
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
          const type = mime.getType(fileName) || ''
          return new File([blob], fileName || '', { type })
        })

      formData.set('file', file)
      formData.set('fileOrigin', 'recorded')
      await createMessage(selected, id, formData)(dispatch)
    } else {
      if (config?.autoSendMessageDatabase) {
        await createMessage(selected, id, formData)(dispatch)
      } else {
        onSelect(item.message)
      }
    }
  }

  const isOpen = message && filtered.length > 0

  if (!isOpen) return null

  return (
    <div className="row shortcut simple-table" style={{ maxHeight: 190, overflow: 'auto' }}>
      {
        filtered.map((item, i) => {
          return (
            <div className="row item" style={{ padding: 12 }} key={i} onClick={() => handleSelect(item)}>
              <div className="bold" style={{ color: '#76838F', width: 150 }}>{ item.shortcut }</div>
              <div className="flex padding-left-8" style={{ fontWeight: 600 }}>
                { item.message }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default Shortcuts
