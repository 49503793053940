import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Upload, { UploadRef } from 'util/modules/Upload'

import { passwordValidate } from 'util/services/Validator'
import { updateClient, createClient, getClient } from '../redux/actions'
import { maskPhone, maskCPF, maskCNPJ, maskDate } from 'util/masks'
import Select from 'util/modules/Form/Select'

import { ReactComponent as User } from 'assets/icons/user.svg'
import { ReactComponent as Times } from 'assets/icons/times.svg'
import { ApplicationState } from 'AppReducer'
import FormButtons from 'components/FormButtons'

type Props = {
  isModalOpen?: boolean
  onClose(): void
}

const initialState = {
  ...{ lib: 'Baileys', server: 'DEFAULT' },
  ...{ isActive: true, isBlocked: false, personType: 'STANDARD' as 'STANDARD' | 'BUSINESS' },
  ...{ zip: '', country: 'Brasil', state: '', city: '', street: '', number: '', district: '', complement: '' },
  ...{ docType: 'CPF', docNumber: '', gender: 'O', phone: '', password: '', _password: '' },
  ...{ firstName: '', lastName: '', email: '', username: '', birthday: '' }
}

const ClientForm: React.FC<Props> = ({ onClose, isModalOpen }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { id } = useParams<{ [x: string]: string }>()

  const [form, setForm] = useState(initialState)
  const [picture, setPicture] = useState<File>()
  const [fetching, setFetching] = useState(false)

  const [states, setStates] = useState<{ short: string, name: string }[]>([])
  const [cities, setCities] = useState<{ name: string }[]>([])

  const { globalServer, globalLib } = useSelector((state: ApplicationState) => state.clientReducer)

  const uploadRef = useRef<UploadRef>(null)

  const handleClear = useCallback(() => {
    setForm(initialState)
    uploadRef.current?.clear()
    history.push('/panel/client')
  }, [history])

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    const formData = new FormData()
    if (picture) formData.set('picture', picture)

    formData.set('isActive', String(form.isActive ? 1 : 0))
    formData.set('isBlocked', String(form.isBlocked ? 1 : 0))
    formData.set('lib', form.lib)
    formData.set('server', form.server)
    formData.set('firstName', form.firstName)
    formData.set('lastName', form.lastName)
    formData.set('email', form.email)
    formData.set('username', form.username)

    // Address Field
    formData.set('zip', form.zip)
    formData.set('country', form.country)
    formData.set('state', form.state)
    formData.set('city', form.city)
    formData.set('street', form.street)
    formData.set('number', form.number)
    formData.set('district', form.district)
    formData.set('complement', form.complement)

    // non-required fields
    if (form.birthday) {
      formData.set('birthday', form.birthday.split('/').reverse().join('-'))
    }

    if (form.docType && form.docNumber) {
      formData.set('docType', form.docType)
      formData.set('docNumber', form.docNumber)
    }

    if (form.phone) formData.set('phone', form.phone)
    if (form.gender) formData.set('gender', form.gender)
    if (form.password) formData.set('password', form.password)

    setFetching(true)
    if (id) {
      await updateClient(id, formData)(dispatch)
    } else {
      await createClient(formData)(dispatch).then(res => {
        if (res?.status === 'success' && res?.data) {
          handleClear()
        }
      })
    }
    setFetching(false)
  }

  function fetchStates (): void {
    fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(res => res.json())
      .then(res => {
        if (res && Array.isArray(res)) {
          setStates(res.map(item => ({ short: item.sigla, name: item.nome })))
        }
      })
  }

  function fetchCities (short: string): void {
    if (short) {
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${short}/municipios`)
        .then(res => res.json())
        .then(res => {
          if (res && Array.isArray(res)) {
            setCities(res.map(item => ({ name: item.nome })))
          }
        })
    }
  }

  useEffect(() => {
    if (id) {
      getClient(id)(dispatch).then(res => {
        const client = res?.data
        const user = res?.data?.user
        const person = res?.data?.user?.person
        if (res?.status === 'success' && client && user && person) {
          setForm(form => ({
            ...form,
            lib: client.lib || 'Baileys',
            isBlocked: !!client.isBlocked,
            isActive: !!client.isActive,
            server: client.server,
            personType: person.personType || 'STANDARD',
            docType: person.docType || 'CPF',
            docNumber: person.docNumber || '',
            gender: person.gender || 'O',
            phone: person.phone || '',
            firstName: person.firstName || '',
            lastName: person.lastName || '',
            email: person.email || '',
            username: user.username || '',
            birthday: person.birthday || '',
            zip: client.zip || '',
            country: client.country || '',
            state: client.state || '',
            city: client.city || '',
            street: client.street || '',
            number: client.number || '',
            district: client.district || '',
            complement: client.complement || ''
          }))

          if (person.picture && uploadRef.current) {
            uploadRef.current.setPreviewImage(person.picture)
          }
        }
      })
    } else {
      handleClear()
    }

    fetchStates()
  }, [id, handleClear, dispatch])

  useEffect(() => {
    if (globalLib && globalLib && !id) {
      setForm({ ...form, server: globalServer, lib: globalLib })
    }
  }, [globalLib, globalServer, id])

  useEffect(() => {
    if (!isModalOpen) {
      setForm(initialState)
    }
  }, [isModalOpen])

  const validation = passwordValidate(form.password, form._password)

  return (
    <form className="form-config-container panel" onSubmit={handleSubmit}>
      <div className='row form-config'>
        <div className="row">
          <User width={20} height={25} />
          <p className="flex padding-left-8" style={{ fontSize: 21 }}>Dados do Usuário</p>

          <span className="button-times pointer" onClick={onClose}>
            <Times width={15} height={15} />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="row margin-24" style={{ width: 179, height: 179 }}>
          <Upload
            ref={uploadRef}
            onChange={setPicture}
            placeholder="Selecionar imagem para perfil"
          />
        </div>
      </div>
      <div className="padding-24">
        <div className="row">
          <div className="form-control grow">
            <label>{form.personType === 'STANDARD' ? 'Primeiro Nome' : 'Razão Social'}</label>
            <input
              required
              type="text"
              value={form.firstName}
              onChange={(e): void => setForm({ ...form, firstName: e.target.value })}
            />
          </div>
          <div className="form-control grow">
            <label>{form.personType === 'STANDARD' ? 'Sobrenome' : 'Nome Fantasia'}</label>
            <input
              type="text"
              value={form.lastName}
              onChange={(e): void => setForm({ ...form, lastName: e.target.value })}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-control grow">
            <label>Tipo de Cadastro</label>
            <select
              required
              value={form.personType}
              onChange={(e): void => {
                setForm({
                  ...form,
                  personType: e.target.value as 'STANDARD',
                  docType: e.target.value === 'STANDARD' ? 'CPF' : 'CNPJ'
                })
              }}
            >
              <option value="STANDARD">Pessoa Física</option>
              <option value="BUSINESS">Pessoa Jurídica</option>
            </select>
          </div>
          <div className="form-control grow">
            <label>{form.docType}</label>
            <input
              required
              type="text"
              value={form.docNumber}
              onChange={({ target: { value } }): void => {
                setForm({ ...form, docNumber: form.docType === 'CPF' ? maskCPF(value) : maskCNPJ(value) })
              }}
            />
          </div>
        </div>
        <div className="row align-end form-inputs">
          <div className="form-control grow">
            <label>Número Telefone</label>
            <input
              type="text"
              value={form.phone}
              onChange={(e): void => setForm({ ...form, phone: maskPhone(e.target.value) })}
            />
          </div>

          <div className="form-control grow">
            <label>Data Nascimento</label>
            <input
              type="text"
              value={form.birthday}
              onChange={(e): void => setForm({ ...form, birthday: maskDate(e.target.value, '/') })}
            />
          </div>

          <div className="form-control grow">
            <label>Gênero</label>
            <select
              value={form.gender}
              onChange={(e): void => setForm({ ...form, gender: e.target.value })}
            >
              <option value="M">Masculino</option>
              <option value="F">Feminino</option>
              <option value="O">Outro</option>
            </select>
          </div>
        </div>
      </div>

      <div className="padding-24">
        <div className="row">
          <h2>ENDEREÇO</h2>

          <div className="form-control col-2">
            <label>CEP</label>
            <input
              type="text"
              value={form.zip}
              onChange={(e): void => setForm({ ...form, zip: e.target.value })}
            />
          </div>

          <div className="form-control col-5">
            <label>Estado</label>
            <Select
              selected={form.state}
              onSelect={({ key }): void => {
                fetchCities(key)
                setForm({ ...form, state: key, city: '' })
              }}
              itens={states.map(({ short, name }) => ({ key: short, value: name }))}
            />
          </div>

          <div className="form-control col-5">
            <label>Cidade</label>
            <Select
              selected={form.city}
              onSelect={({ key }): void => {
                setForm({ ...form, city: key })
              }}
              itens={cities.map(({ name }) => ({ key: name, value: name }))}
            />
          </div>

          <div className="form-control col-4">
            <label>Logradouro</label>
            <input
              type="text"
              value={form.street}
              onChange={(e): void => setForm({ ...form, street: e.target.value })}
            />
          </div>

          <div className="form-control col-2">
            <label>Número</label>
            <input
              type="text"
              value={form.number}
              onChange={(e): void => setForm({ ...form, number: e.target.value })}
            />
          </div>

          <div className="form-control col-3">
            <label>Bairro</label>
            <input
              type="text"
              value={form.district}
              onChange={(e): void => setForm({ ...form, district: e.target.value })}
            />
          </div>

          <div className="form-control col-3">
            <label>Complemento</label>
            <input
              type="text"
              value={form.complement}
              onChange={(e): void => setForm({ ...form, complement: e.target.value })}
            />
          </div>
        </div>

        <div className="padding-top-16 padding-bottom-16">
          <h2>DADOS DE ACESSO</h2>

          <div className="row">
            <div className="form-control grow">
              <label>E-mail</label>
              <input
                required
                type="text"
                value={form.email}
                onChange={(e): void => setForm({ ...form, email: e.target.value })}
              />
            </div>
            <div className="form-control grow">
              <label>Nome de Usuário</label>
              <input
                type="text"
                value={form.username}
                onChange={(e): void => setForm({ ...form, username: e.target.value })}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-control grow">
              <label>Senha</label>
              <input
                required={!id}
                type="password"
                value={form.password}
                autoComplete="new-password"
                onChange={(e): void => setForm({ ...form, password: e.target.value })}
              />
            </div>

            <div className="form-control grow">
              <label>Confirmar Senha</label>
              <input
                required={!id}
                type="password"
                value={form._password}
                autoComplete="new-password"
                onChange={(e): void => setForm({ ...form, _password: e.target.value })}
              />
            </div>
          </div>

          <p className="col-12 text-center">
            {validation.warnings?.[0] || <>&nbsp;</>}
          </p>

        </div>

        <div className="padding-bottom-16">
          <h2>CONFIGURAÇÕES</h2>

          <div className="row">
            <div className="form-control col-3">
              <label>Servidor</label>
              <select
                required
                value={form.server}
                onChange={(e): void => setForm({ ...form, server: e.target.value })}
              >
                <option value="DEFAULT">Padrão</option>
                {
                  [...new Array(20)].map((_, i) => {
                    return <option key={i} value={`WORKER_${i + 1}`}>WORKER {i + 1}</option>
                  })
                }
              </select>
            </div>
            <div className="form-control col-3">
              <label>Lib Mensagem</label>
              <select
                required
                value={form.lib}
                onChange={(e): void => setForm({ ...form, lib: e.target.value })}
              >
                <option>Baileys</option>
                <option>WhatsAppWebJs</option>
              </select>
            </div>
            <div className="form-control col-3">
              <label>Ativo?</label>
              <select
                required
                value={form.isActive ? 1 : 0}
                onChange={(e): void => setForm({ ...form, isActive: parseInt(e.target.value) === 1 })}
              >
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>

            <div className="form-control col-3">
              <label>Bloqueado?</label>
              <select
                required
                value={form.isBlocked ? 1 : 0}
                onChange={(e): void => setForm({ ...form, isBlocked: parseInt(e.target.value) === 1 })}
              >
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-end padding-24 gap-10">
        <FormButtons
          isFetching={fetching}
          disabled={fetching || !!(!validation.isValid && !id)}
          onClear={handleClear}
        />
      </div>
    </form>
  )
}

export default ClientForm
