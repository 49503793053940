import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Tab } from '../ProfileTab'
import { updatePassword } from 'panel/user/redux/actions'
import { ApplicationState } from 'AppReducer'
import { passwordValidate } from 'util/services/Validator'

type Props = { onUnsaved: (tab?: Tab) => void }

const initialState = { oldPassword: '', newPassword: '', _password: '' }
const ProfilePassword: React.FC<Props> = ({ onUnsaved }) => {
  const dispatch = useDispatch()

  const [form, setForm] = useState(initialState)
  const [fetching, setFetching] = useState(false)

  const { logged } = useSelector((state: ApplicationState) => state.userReducer)

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (!logged) return undefined

    setFetching(true)
    await updatePassword(logged.id, form)(dispatch).then(res => {
      if (res?.status === 'success') {
        setForm(initialState)
      }
    })
    setFetching(false)
  }

  // Handle Unsaved form
  useEffect(() => {
    const isUnsaved = JSON.stringify(form) !== JSON.stringify(initialState)
    onUnsaved(isUnsaved ? 'PASSWORD' : undefined)
  }, [form, initialState])

  const validation = passwordValidate(form.newPassword, form._password)

  return (
    <form className="row margin-top-16 attendant-data" onSubmit={handleSubmit}>
      <div className="row password-wrapper">
        <div className="form-control">
          <label>Senha Anterior</label>
          <input
            required={true}
            type="password"
            value={form.oldPassword}
            autoComplete="new-password"
            onChange={(e): void => setForm({ ...form, oldPassword: e.target.value })}
          />
        </div>
        <div className="form-control">
          <label>Nova Senha</label>
          <input
            required={true}
            type="password"
            value={form.newPassword}
            autoComplete="new-password"
            onChange={(e): void => setForm({ ...form, newPassword: e.target.value })}
          />
        </div>

        <div className="form-control">
          <label>Confirmar Nova Senha</label>
          <input
            required={true}
            type="password"
            value={form._password}
            autoComplete="new-password"
            onChange={(e): void => setForm({ ...form, _password: e.target.value })}
          />
        </div>

        <p className="col-12 text-center">{validation.warnings?.[0] || <>&nbsp;</>}</p>
      </div>

      <div className="row margin-top-16 form-buttons">
        <span className="secondary button" onClick={() => setForm(initialState)}>Limpar</span>
        <button className="gradient button" disabled={fetching}>Salvar</button>
      </div>
    </form>
  )
}

export default ProfilePassword
