import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as Store } from 'assets/icons/store.svg'
import { ReactComponent as Times } from 'assets/icons/times.svg'

import { UploadRef } from 'util/modules/Upload'
import { ApplicationState } from 'AppReducer'
import { maskCPF, maskCNPJ, maskDate, formatDate, maskMoney } from 'util/masks'
import { updateStore, createStore, getStore, getSellerStoreList } from '../redux/actions'

import { Client } from 'panel/client/redux/types'
import { StoreTypes } from '../redux/types'
import { getPlanList } from 'panel/plan/redux/actions'

import Upload from 'util/modules/Upload'
import Search from 'util/modules/Pagination/components/Search'
import FormButtons from 'components/FormButtons'

const initialState = {
  ...{ name: '', plan: 0, dueDate: '' },
  ...{ isActive: true, isBlocked: false, docType: 'CNPJ', docNumber: '' }
}

const StoreForm: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const uploadRef = useRef<UploadRef>(null)

  const { id } = useParams<{ [x: string]: string }>()
  const { search } = history.location

  const [form, setForm] = useState(initialState)
  const [client, setClient] = useState({ id: 0, name: '' })
  const [picture, setPicture] = useState<File>()
  const [fetching, setFetching] = useState(false)

  const { plans } = useSelector((state: ApplicationState) => state.planReducer)

  const handleClear = useCallback(() => {
    setForm(initialState)
    setClient({ id: 0, name: '' })

    uploadRef.current?.clear()
    history.push('/panel/store')

    dispatch({ type: StoreTypes.STORE_GET, payload: undefined })
  }, [uploadRef, history])

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    const formData = new FormData()
    if (picture) formData.set('picture', picture)

    formData.set('isActive', String(form.isActive ? 1 : 0))
    formData.set('isBlocked', String(form.isBlocked ? 1 : 0))
    formData.set('name', form.name)

    if (form.docType && form.docNumber) {
      formData.set('docType', form.docType)
      formData.set('docNumber', form.docNumber)
    }

    if (form.dueDate) formData.set('dueDate', form.dueDate.split('/').reverse().join('-'))
    if (form.plan) formData.set('plan', String(form.plan))

    setFetching(true)
    if (client.id && id) {
      await updateStore(client.id, id, formData)(dispatch)
    } else {
      await createStore(client.id, formData)(dispatch).then(res => {
        if (res?.status === 'success' && res?.data) {
          handleClear()
          onClose()
        }
      })
    }
    await getSellerStoreList({ page: 1, limit: 20 })(dispatch)
    setFetching(false)
  }

  useEffect(() => {
    if (search && id) {
      const clientParam = Object.fromEntries(new URLSearchParams(search))

      if (clientParam && clientParam.client) {
        setClient({ id: parseInt(clientParam.client), name: clientParam.name })
        getStore(clientParam.client, id)(dispatch).then(res => {
          const store = res?.data
          if (res?.status === 'success' && store) {
            setForm(form => ({
              ...form,
              isBlocked: !!store.isBlocked,
              isActive: !!store.isActive,
              docType: store.docType || 'CNPJ',
              docNumber: store.docNumber || '',
              name: store.name,
              dueDate: store.dueDate ? formatDate(store.dueDate).split('-').reverse().join('/') : '',
              plan: store.plan && store.plan.id ? store.plan.id : 0
            }))

            if (store.picture && uploadRef.current) {
              uploadRef.current.setPreviewImage(store.picture)
            }
          }
        })
      } else {
        handleClear()
      }
    }
  }, [search, id, uploadRef, handleClear, dispatch])

  useEffect(() => {
    if (!isOpen) handleClear()
  }, [isOpen])

  useEffect(() => {
    getPlanList()(dispatch)
  }, [dispatch])

  return (
    <form className="form-config-container panel" onSubmit={handleSubmit}>
      <div className='row form-config'>
        <div className="row">
          <Store width={20} height={25} />
          <p className="flex padding-left-8">Dados da Loja</p>

          <span className="button-times pointer" onClick={onClose}>
            <Times width={15} height={15} />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="row" style={{ width: 179, height: 179 }}>
          <Upload
            ref={uploadRef}
            onChange={setPicture}
            placeholder="Selecionar imagem para perfil"
          />
        </div>
      </div>

      <div className="padding-24">
        <div className="row">
          <div className="form-control grow">
            <label>Nome</label>
            <input
              required
              type="text"
              value={form.name}
              onChange={(e): void => setForm({ ...form, name: e.target.value })}
            />
          </div>
          <div className="form-control col-3">
            <label>Proprietário</label>

            <Search<Client & { ['p.name']: string }>
              required
              url="/client"
              filters={[{ type: 'filter_like', column: 'p.name', match: ['start', 'end'] }]}
              fields='id,user.id,p.name'
              value={client.name}
              isActive={(item) => client.id === item.id}
              formatter={({ user: { person } }) => person?.name || ''}
              onSelect={({ id, user }): void => setClient({ id, name: user.person?.name || '' })}
            />
          </div>

          <div className="row align-end">
            <div className="form-control grow">
              <label>Ativo?</label>
              <select
                required
                value={form.isActive ? 1 : 0}
                onChange={(e): void => setForm({ ...form, isActive: parseInt(e.target.value) === 1 })}
              >
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>

            <div className="form-control grow">
              <label>Bloqueado?</label>
              <select
                required
                value={form.isBlocked ? 1 : 0}
                onChange={(e): void => setForm({ ...form, isBlocked: parseInt(e.target.value) === 1 })}
              >
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>
          </div>

          <div className="row align-end">
            <div className="form-control grow">
              <label>Tipo / Documento</label>
              <select
                value={form.docType}
                onChange={(e): void => setForm({ ...form, docType: e.target.value })}
              >
                <option>CPF</option>
                <option>CNPJ</option>
              </select>
            </div>
            <div className="form-control grow">
              <label>{form.docType}</label>
              <input
                type="text"
                value={form.docNumber}
                onChange={({ target: { value } }): void => {
                  setForm({ ...form, docNumber: form.docType === 'CPF' ? maskCPF(value) : maskCNPJ(value) })
                }}
              />
            </div>
          </div>

          <div className="row align-end">
            <div className="form-control grow">
              <label>Plano</label>
              <select
                value={form.plan}
                onChange={(e): void => setForm({ ...form, plan: parseInt(e.target.value) })}
              >
                <option></option>
                {
                  plans.filter(item => item.isActive).map((plan, i) => {
                    return (
                      <option key={i} value={plan.id}>{plan.name} - {maskMoney(plan.price)}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className="form-control grow">
              <label>Dia de vencimento</label>
              <input
                type="text"
                value={form.dueDate}
                onChange={(e): void => setForm({ ...form, dueDate: maskDate(e.target.value) })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row justify-end padding-24 gap-10'>
        <FormButtons onClear={handleClear} isFetching={fetching} />
      </div>
    </form>
  )
}

export default StoreForm
