import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Tab } from '../ContactTab'
import { PersonTypes } from 'panel/person/redux/types/person'
import { updatePerson } from 'panel/person/redux/action/person'
import { ApplicationState } from 'AppReducer'
import { ContactConfigPreferredAttendant } from './ContactConfig.preferred-attendant'
import { Api } from 'util/services/api'

const initialState = { ignoreAutomation: false, ignoreConversation: false }
const ContactConfig: React.FC<{ onUnsaved: (tab?: Tab) => void }> = ({ onUnsaved }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [form, setForm] = useState(initialState)
  const [preferredAttendantId, setPreferredAttendantId] = useState<number>()

  const [fetching, setFetching] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { person } = useSelector((state: ApplicationState) => state.personReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  const handleClear = useCallback(() => {
    setForm(initialState)
    history.push('/panel/person')
    dispatch({ type: PersonTypes.PERSON_GET, payload: undefined })
  }, [history])

  function getSavedState () {
    if (id && person) {
      return {
        ignoreAutomation: !!person.ignoreAutomation,
        ignoreConversation: !!person.ignoreConversation
      }
    }

    return initialState
  }

  async function handleSubmit (e: React.FormEvent): Promise<void> {
    e.preventDefault()

    if (!selected || !id) return undefined

    setFetching(true)
    // TODO: refactor to react-query service
    await Api.put('/bot/config/preferred-attendant', {
      personId: id,
      attendantId: preferredAttendantId
    })
    await updatePerson(selected, id, form)(dispatch)
    setFetching(false)
  }

  useEffect(() => {
    setForm(getSavedState())
  }, [person])

  // Handle Unsaved form
  useEffect(() => {
    const isUnsaved = JSON.stringify(form) !== JSON.stringify(getSavedState())
    onUnsaved(isUnsaved ? 'CONFIG' : undefined)
  }, [form, person, initialState])

  return (
    <form className="row margin-top-16" onSubmit={handleSubmit}>
      <div className="justify-start form-control">
        <label>Ignorar Atendimento?</label>
        <label className="switch">
          <input
            type='checkbox'
            checked={form.ignoreConversation ? 1 : 0 as any}
            value={Number(form.ignoreConversation)}
            onChange={(e): void => setForm({ ...form, ignoreConversation: e.target.checked })}
          />
          <span className="slider round" />
        </label>
      </div>

      <div className="justify-start form-control">
        <label>Ignorar Automação?</label>
        <label className="switch">
          <input
            type='checkbox'
            disabled={form.ignoreConversation}
            checked={form.ignoreAutomation ? 1 : 0 as any}
            value={Number(form.ignoreAutomation)}
            onChange={(e): void => setForm({ ...form, ignoreAutomation: e.target.checked })}
          />
          <span className="slider round" />
        </label>
      </div>

      <ContactConfigPreferredAttendant
        personId={person?.id}
        onSelect={setPreferredAttendantId}
        preferredAttendantId={person?.preferred_attendant_id}
      />

      <div className="row margin-top-16 form-buttons">
        <span className="secondary button" onClick={handleClear}>Limpar</span>
        <button className="gradient button" disabled={fetching}>Salvar</button>
      </div>
    </form>
  )
}

export default ContactConfig
