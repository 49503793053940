import { TagState, TagActionTypes, TagTypes } from './types'
import { Reducer } from 'redux'

const initialState: TagState = {
  tags: []
}

type ReducerOpts = Reducer<TagState, TagActionTypes>
const reducer: ReducerOpts = (state = initialState, action: TagActionTypes): TagState => {
  let index = -1
  switch (action.type) {
    case TagTypes.TAG_RESET:
      return initialState
    case TagTypes.TAG_CREATE:
      return { ...state, tag: action.payload, tags: [...state.tags, action.payload] }
    case TagTypes.TAG_UPDATE:
      index = state.tags.findIndex((item) => item.id === action.payload.id)
      return {
        ...state,
        tag: action.payload,
        tags: [...state.tags.slice(0, index), action.payload, ...state.tags.slice(index + 1)]
      }
    case TagTypes.TAG_DELETE:
      index = state.tags.findIndex((item) => item.id === action.payload)
      return {
        ...state,
        tag: undefined,
        tags: [...state.tags.slice(0, index), ...state.tags.slice(index + 1)]
      }
    case TagTypes.TAG_GET:
      return { ...state, tag: action.payload }
    case TagTypes.TAG_GET_LIST:
      return { ...state, tags: action.payload }
    default:
      return state
  }
}

export default reducer
