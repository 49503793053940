import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
* Data Types
*/
export interface TimeTable {
  id: number,
  day: number,
  start: string,
  end: string,
  createdAt: string,
  updatedAt?: string
}

/**
* State Type
*/

export interface TimeTableState {
  readonly timeTable: TimeTable[]
}

/**
* Action Enum Types
*/

export enum TimeTableTypes {
  TIMETABLE_RESET = 'TIMETABLE/RESET',
  TIMETABLE_CREATE = 'TIMETABLE/CREATE',
  TIMETABLE_UPDATE = 'TIMETABLE/UPDATE',
  TIMETABLE_DELETE = 'TIMETABLE/DELETE',
  TIMETABLE_GET = 'TIMETABLE/GET',
  TIMETABLE_GET_LIST = 'TIMETABLE/GET/LIST'
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof TimeTableTypes.TIMETABLE_RESET,
}

interface CreateAction {
  type: typeof TimeTableTypes.TIMETABLE_CREATE,
  payload: TimeTable
}

interface UpdateAction {
  type: typeof TimeTableTypes.TIMETABLE_UPDATE,
  payload: TimeTable
}

interface DeleteAction {
  type: typeof TimeTableTypes.TIMETABLE_DELETE,
  payload: number
}

interface GetAction {
  type: typeof TimeTableTypes.TIMETABLE_GET,
  payload: TimeTable
}

interface GetListAction {
  type: typeof TimeTableTypes.TIMETABLE_GET_LIST,
  payload: TimeTable[]
}

export type TimeTableActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction | GetAction | GetListAction
)
