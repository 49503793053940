import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from 'AppReducer'

import { disconnectChannel } from '../redux/actions'
import ReconnectButton from './ReconnectButton'

import { ReactComponent as Cog } from 'assets/icons/cog.svg'

import '../styles/Channel.scss'
import { hasPermission } from 'util/services/AuthService'
import { useParams } from 'react-router-dom'

const ChannelConfig: React.FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()

  const { channelDb, connectionQr, statusList } = useSelector((state: ApplicationState) => state.channelReducer)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  const channel = channelDb[Number(id)]
  if (!channel || !selected) return null

  const status = connectionQr?.status || 'CONNECTING'
  const qrCode = connectionQr?.qrCode
  const message = connectionQr?.message
  const publicHash = connectionQr?.publicHash

  const path = process.env.REACT_APP_DOMAIN || 'painel.underchat.com.br'
  const protocol = path.search('localhost') > -1 ? 'http' : 'https'

  const isConnecting = status === 'CONNECTING'
  const publicHashUrl = `${protocol}://${path}/channel/qr-code?hash=${publicHash}&store=${selected}`

  const statusInfo = statusList[status]

  return (
    <div className="row panel no-padding">
      <div className='row panel-header'>
        <Cog width={20} height={25} /> <p className="flex padding-left-8" style={{ fontSize: 20 }}>Conexão</p>
      </div>
      <div className="panel-body">
        <div className="row hero">
          <span>
            <p className='flex'><b>Canal:</b> {channel.name}</p>
          </span>
          <div className="flex">
            <div className="row justify-end">
              <div className="span button radius primary" style={{ backgroundColor: statusInfo.color, width: 'auto' }}>
                <i className={`fa fa-${statusInfo.icon}`} /> {statusInfo.label}
              </div>

              {hasPermission('CHANNEL:REFRESH') && <ReconnectButton channel={channel} />}
              {
                hasPermission('CHANNEL:DISCONNECT') &&
                <div
                  className="span button radius secondary"
                  onClick={() => disconnectChannel(selected, channel.id)(dispatch)}
                  style={{ width: 'auto' }}
                >
                  <i className="fa fa-times" /> Desconectar
                </div>
              }
            </div>
          </div>
        </div>

        <div className="row justify-start qr-code-wrapper-content">
          {
            isConnecting &&
            <div className="row margin-top-16">
              <div className="grow padding-left-16">
                <p>Para conectar seu telefone:</p>
                <ol style={{ paddingLeft: 20 }}>
                  <li>Abra o WhatsApp no seu celular</li>
                  <li>
                    Clique em <b>Menu <i className="fa fa-ellipsis-v" /></b> ou&nbsp;
                    <b>Configurações <i className="fa fa-cog" /></b>
                  </li>
                  <li>Selecione Whatsapp Web</li>
                  <li>Clique em Conectar um aparelho</li>
                  <li>Escaneie o QrCode e Pronto!</li>
                </ol>
              </div>
              <div className="row center-center" style={{ width: 264, height: 264, overflow: 'hidden' }}>
                {
                  qrCode
                    ? (
                      <img
                        alt="WQ QRCode"
                        src={qrCode}
                        style={{ width: 264, height: 264 }}
                      />
                    )
                    : (
                      <div className="row center-center bordered border-radius" style={{ width: 264, height: 264 }}>
                        <div className="row text-center">
                          <i className="fa fa-spinner fa-spin fa-4x" />
                          <h2 className="margin-top-16">Solicitando QRCode.</h2>
                          <p>Por favor, aguarde...</p>
                        </div>
                      </div>
                    )
                }
              </div>
            </div>
          }

          {
            !!message &&
            <div className="row alert-box warning">
              <i className="fa fa-exclamation-triangle" /> {message}
            </div>
          }

          {
            !!publicHash && isConnecting &&
            <div className="row hero margin-top-16">
              <h2 className="margin-bottom-16">Link para conexão externa</h2>
              <div className="row" style={{ display: 'grid', gridTemplateColumns: '48px 1fr 48px', gap: 8 }}>
                <a
                  style={{ width: 'auto' }}
                  target="_blank"
                  rel="noreferrer"
                  className="button gradient"
                  href={publicHashUrl}
                >
                  <i className="fa fa-hand-point-up" />
                </a>
                <input type="text" readOnly value={publicHashUrl} />
                <span
                  style={{ width: 'auto' }}
                  className="button white"
                  onClick={async () => {
                    navigator.clipboard.writeText(publicHashUrl).then(() => alert('Copiado!'))
                  }}
                >
                  <i className="fa fa-copy" />
                </span>
              </div>

              <p>Atenção: Link público e temporário, não compartilhe com pessoas sem autorização</p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ChannelConfig
