import { Client } from 'panel/client/redux/types'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
* Data Types
*/
export interface Config {
  id: number,
  client: Partial<Client>,
  showAttendantName: 'firstName' | 'name',
  conversationMessageJoin: string,
  conversationMessageFinish: string,
  conversationMessageRedirect: string

  isAttendantNameEnabled: boolean,
  isConversationMessageJoinEnabled: boolean,
  isConversationMessageRedirectEnabled: boolean,

  shouldFinishStuckConversation: boolean,
  finishStuckConversationMinutes: number,
  finishStuckConversationMessage: string,
  shouldSendFinishConversationMessage: boolean,

  useBotButtons: boolean,
  autoSendMessageDatabase: boolean,

  redirectWhenHasNoAttendant: boolean,
  redirectWhenHasNoAttendantSector: number,

  sortConversation: 'createdAt' | 'updatedAt',
  canLoadPreviousMessage: boolean,
  canNotifyOnChatBot: boolean,

  checkAttendant: boolean,
  checkAttendantMessage: string

  createdAt: string,
  updatedAt?: string
}

/**
* State Type
*/

export interface ConfigState {
  readonly config?: Config
}

/**
* Action Enum Types
*/

export enum ConfigTypes {
  CONFIG_GET = 'CONFIG/GET',
  CONFIG_UPDATE = 'CONFIG/UPDATE'
}

/**
* Action Types
*/

interface UpdateAction {
  type: typeof ConfigTypes.CONFIG_UPDATE,
  payload: Config
}

interface GetAction {
  type: typeof ConfigTypes.CONFIG_GET,
  payload: Config
}

export type ConfigActionTypes = (
  AlertActionTypes | UpdateAction | GetAction
)
