import { User } from 'panel/user/redux/types'
import { PaginationOpts } from 'util/types/index'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'

/**
* Data Types
*/
export interface Seller {
  id: number,
  type: 'PRE-PAID' | 'POST-PAID' | 'UNLIMITED',
  isActive: boolean,
  isBlocked: boolean,
  isExpired: boolean,
  accountLimit: number,
  dueDate: string,
  paymentApiKey: string,
  paymentApiSecret: string,
  user: Partial<User>,
  createdAt: string,
  updatedAt: string
}

/**
* State Type
*/

export interface SellerState {
  readonly seller?: Seller
  readonly logged?: Seller
  readonly sellers: Seller[]
  readonly pagination?: PaginationOpts
}

/**
* Action Enum Types
*/

export enum SellerTypes {
  SELLER_RESET = 'SELLER/RESET',
  SELLER_CREATE = 'SELLER/CREATE',
  SELLER_UPDATE = 'SELLER/UPDATE',
  SELLER_DELETE = 'SELLER/DELETE',
  SELLER_GET = 'SELLER/GET',
  SELLER_GET_LIST = 'SELLER/GET/LIST',
  SELLER_GET_LOGGED = 'SELLER/GET/LOGGED',
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof SellerTypes.SELLER_RESET
}

interface CreateAction {
  type: typeof SellerTypes.SELLER_CREATE,
  payload: Seller
}

interface UpdateAction {
  type: typeof SellerTypes.SELLER_UPDATE,
  payload: Seller
}

interface DeleteAction {
  type: typeof SellerTypes.SELLER_DELETE,
  payload: number
}

interface GetAction {
  type: typeof SellerTypes.SELLER_GET,
  payload: Seller
}

interface GetLoggedAction {
  type: typeof SellerTypes.SELLER_GET_LOGGED,
  payload: Seller
}

interface GetListAction {
  type: typeof SellerTypes.SELLER_GET_LIST,
  payload: Seller[],
  pagination: PaginationOpts
}

export type SellerActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | DeleteAction |
  GetAction | GetLoggedAction | GetListAction
)
