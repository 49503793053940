import React, { useState } from 'react'

import Modal, { ModalHeader } from 'util/modules/Modal'
import TagForm from './components/TagForm'
import TagList from './components/TagList'

import './styles/Tag.scss'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'AppReducer'
import { useEffect } from 'react'
import { hasPermission } from 'util/services/AuthService'

const TagPage: React.FC = () => {
  const [isFormOpen, setFormOpen] = useState(false)

  const { tag } = useSelector((state: ApplicationState) => state.tagReducer)

  useEffect(() => {
    if (tag) {
      setFormOpen(true)
    }
  }, [tag])

  return (
    <div className="row panel bordered">
      <div className="row tag-container">
        <div className="row padding-24">
          <p className="grow" style={{ fontSize: 18 }}>Etiquetas Cadastrados</p>
          {
            hasPermission('TAG:CREATE') &&
            <button className="gradient radius" onClick={() => setFormOpen(true)}>
              Cadastrar
            </button>
          }
        </div>
        { hasPermission('TAG:LIST') && <TagList /> }
        <Modal isOpen={isFormOpen} onClose={() => setFormOpen(false)}>
          <div className="row panel no-padding tag-container">
            <ModalHeader title="Gestão de Etiquetas" icon="tags" onClose={() => setFormOpen(false)} />
            <div className="row panel-body">
              <TagForm isOpen={isFormOpen} onClose={() => setFormOpen(false)} />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default TagPage
