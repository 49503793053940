import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ApplicationState } from 'AppReducer'
import { createMessage } from 'panel/conversation/redux/actions'

import Audio from 'util/modules/Audio/Audio'

const AudioAttachment: React.FC = () => {
  const dispatch = useDispatch()

  const { id } = useParams<{ id: string }>()
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  async function handleSubmit (file: File): Promise<void> {
    if (id && selected) {
      const formData = new FormData()
      formData.set('file', file)
      formData.set('fileOrigin', 'recorded')

      await createMessage(selected, id, formData)(dispatch)
    }
  }

  return (
    <Audio onRecord={handleSubmit} />
  )
}

export default AudioAttachment
