import React, { useCallback, useEffect, useState } from 'react'
import { isEmailAvailable, isUsernameAvailable } from 'panel/user/redux/actions'
import { useDebounce } from 'util/hooks'

// eslint-disable-next-line max-len
const emailRegex = /^[a-zA-Z0-9.\-_+]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g

const UsernameEmailVerify: React.FC<{ type: 'email'|'username', value?: string }> = ({ type, value }) => {
  const [status, setStatus] = useState<'PENDING'|'FECHING'|'AVAILABLE'|'UNAVAILABLE'|'INVALID'>('PENDING')

  const debouce = useDebounce()

  const verifyStatus = useCallback(() => {
    if (value) setStatus('FECHING')
    debouce(() => {
      if (value) {
        if (type === 'email') {
          isEmailAvailable(value).then((res) => {
            if (emailRegex.test(value)) {
              setStatus(res ? 'AVAILABLE' : 'UNAVAILABLE')
            } else {
              setStatus('INVALID')
            }
          })
        }

        if (type === 'username') {
          isUsernameAvailable(value).then((res) => setStatus(res ? 'AVAILABLE' : 'UNAVAILABLE'))
        }
      }
    }, 1000)
    // eslint-disable-next-line
  }, [type, value])

  useEffect(() => {
    setStatus('PENDING')
    verifyStatus()
  }, [verifyStatus])

  const icons = {
    PENDING: { icon: <i className="fa fa-ellipsis-h"/>, color: 'gray', label: '' },
    FECHING: { icon: <i className="fa fa-spinner fa-spin"/>, color: 'gray', label: 'Verificando' },
    AVAILABLE: { icon: <i className="fa fa-check"/>, color: 'green', label: 'Disponível' },
    UNAVAILABLE: { icon: <i className="fa fa-times"/>, color: 'red', label: 'Indisponível' },
    INVALID: { icon: <i className="fa fa-times"/>, color: 'red', label: 'Inválido' }
  }

  const item = icons[status]
  const Icon = () => item.icon

  return (
    <div className="row" style={{ width: 'auto', position: 'absolute', top: 8, right: 8, color: item.color }}>
      <Icon /> { item.label }
    </div>
  )
}

export default UsernameEmailVerify
