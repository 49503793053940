import React from 'react'
import { useReactFlow } from 'reactflow'

export const DeleteNode: React.FC<{ id: string }> = ({ id }) => {
  const { setNodes } = useReactFlow()

  const handleDelete = () => {
    setNodes((nodes) => nodes.filter(node => node.id !== id))
  }

  return (
    <div className="del-node-btn" onClick={handleDelete}>
      <i className="fa fa-times" style={{ fontSize: 12 }} />
    </div>
  )
}
