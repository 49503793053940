import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ApplicationState } from 'AppReducer'
import { createMessage } from 'panel/conversation/redux/actions'

import mimeTypes from '../mime-types.json'

const FileAudio: React.FC = () => {
  const dispatch = useDispatch()
  const inpRef = useRef<HTMLInputElement>(null)

  const [uri, setUri] = useState('')
  const [name, setName] = useState('')
  const [file, setFile] = useState<File>()
  const [message, setMessage] = useState('')
  const [isFetching, setFetching] = useState(false)

  const [sendType, setSendType] = useState<'AUDIO'|'FILE'>('AUDIO')

  const { id } = useParams<{ id: string }>()
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleClose () {
    setUri('')
    setName('')
    setFile(undefined)
    setMessage('')
    setFetching(false)
  }

  function handleFile (file: File) {
    const isAudio = mimeTypes.audio.includes(file.type)
    if (isAudio) {
      setName(file.name)
      setFile(file)
    }

    if (inpRef.current) inpRef.current.value = ''
  }

  function handleChange (e: React.ChangeEvent<HTMLInputElement>): void {
    const file = e.target.files?.[0]
    if (file && inpRef.current) {
      handleFile(file)
    }
  }

  async function handleSubmit (): Promise<void> {
    if (name && file && id && selected) {
      const formData = new FormData()
      formData.set('sendType', sendType)
      formData.set('file', file)

      setFetching(true)
      await createMessage(selected, id, formData)(dispatch).then(res => {
        if (res?.status === 'success') {
          handleClose()
        }
      })
      setFetching(false)
    }
  }

  useEffect(() => {
    document.addEventListener('paste', (event: ClipboardEvent) => {
      const { clipboardData } = event
      if (clipboardData) {
        const file = clipboardData.files[0]
        if (file) handleFile(file)
      }
    })

    return () => {
      document.removeEventListener('paste', () => null)
    }
  }, [])

  return (
    <>
      {
        !!file &&
        <div className="preview">
          <div className="row">
            <i className="fa fa-times btn-close" onClick={handleClose} />

            <h1 className="text-center text-primary"><i className="fa fa-file fa-3x" /></h1>
            <p>{name}</p>

            <div className="row margin-top-16" style={{ width: 300 }}>
              <h4 className="text-center">Como gostaria de enviar?</h4>
              <div className="row" style={{ flexFlow: 'row' }}>
                <label>
                  <label className="switch">
                    <input
                      type='checkbox'
                      checked={sendType === 'AUDIO'}
                      onChange={() => setSendType('AUDIO')}
                    />
                    <span className="slider round" />
                  </label>&nbsp;&nbsp;Áudio
                </label>
                <label>
                  <label className="switch">
                    <input
                      type='checkbox'
                      checked={sendType === 'FILE'}
                      onChange={() => setSendType('FILE')}
                    />
                    <span className="slider round" />
                  </label>&nbsp;&nbsp;Arquivo
                </label>
              </div>
            </div>

            <div className="row margin-top-32">
              <button className="primary" disabled={isFetching} onClick={handleSubmit}>
                {
                  isFetching
                    ? <><i className="fa fa-spin fa-spinner" /> Enviando. Aguarde...</>
                    : <><i className="fa fa-paper-plane" /> Enviar Arquivo</>
                }
              </button>
            </div>
          </div>
        </div>
      }
      <input
        type="file"
        ref={inpRef}
        accept="audio/*"
        style={{ display: 'none' }}
        onChange={(e) => handleChange(e)}
      />
      <span className="secondary button audio shadow" onClick={() => inpRef.current?.click()}>
        <i className="fas fa-music" />
      </span>
    </>
  )
}

export default FileAudio
