import React from 'react'

interface FormButtonsProps {
  disabled?: boolean
  isFetching: boolean
  onClear: () => void
}

const FormButtons: React.FC<FormButtonsProps> = ({ isFetching, onClear, disabled }) => {
  return (
    <>
      <span className="secondary button" onClick={onClear}>Limpar</span>
      <button className="gradient button" type='submit' disabled={isFetching || disabled}>
        {
          isFetching
            ? <div> <i className="fa fa-spin fa-spinner" /> Salvando...</div>
            : <div><i className="fas fa-save" /> Salvar</div>
        }
      </button>
    </>
  )
}

export default FormButtons
