import { User } from 'panel/user/redux/types'
import { Seller } from 'panel/seller/redux/types'
import { AlertActionTypes } from 'util/modules/Alert/redux/types'
import { PaginationOpts } from 'util/types/index'

/**
* Data Types
*/
export interface Client {
  id: number,
  isActive: boolean,
  isBlocked: boolean,
  lib: string,
  server: string,
  zip: string,
  country: string,
  state: string,
  city: string,
  street: string,
  number: string,
  district: string,
  complement: string,
  user: Partial<User>,
  seller: Partial<Seller>,
  createdAt: string,
  updatedAt?: string
}

/**
* State Type
*/

export interface ClientState {
  readonly client?: Client
  readonly logged?: Client
  readonly clients: { items: Client[], pagination?: PaginationOpts }
  readonly globalLib: string
  readonly globalServer: string
}

/**
* Action Enum Types
*/

export enum ClientTypes {
  CLIENT_RESET = 'CLIENT/RESET',
  CLIENT_CREATE = 'CLIENT/CREATE',
  CLIENT_UPDATE = 'CLIENT/UPDATE',
  CLIENT_DELETE = 'CLIENT/DELETE',
  CLIENT_GET = 'CLIENT/GET',
  CLIENT_GET_LOGGED = 'CLIENT/GET/LOGGED',
  CLIENT_GET_LIST = 'CLIENT/GET/LIST',
  SET_GLOBAL_LIB = 'CLIENT/SET/LIB',
  SET_GLOBAL_SERVER = 'CLIENT/SET/SERVER'
}

/**
* Action Types
*/
interface ResetAction {
  type: typeof ClientTypes.CLIENT_RESET,
}

interface CreateAction {
  type: typeof ClientTypes.CLIENT_CREATE,
  payload: Client
}

interface UpdateAction {
  type: typeof ClientTypes.CLIENT_UPDATE,
  payload: Client
}

interface DeleteAction {
  type: typeof ClientTypes.CLIENT_DELETE,
  payload: number
}

interface GetAction {
  type: typeof ClientTypes.CLIENT_GET,
  payload: Client
}

interface GetListAction {
  type: typeof ClientTypes.CLIENT_GET_LIST,
  payload: Client[]
  pagination: PaginationOpts
}

interface GetLoggedAction {
  type: typeof ClientTypes.CLIENT_GET_LOGGED,
  payload: Client
}

interface SetGlobalLibAction {
  type: typeof ClientTypes.SET_GLOBAL_LIB,
  payload: string
}

interface SetGlobalServerAction {
  type: typeof ClientTypes.SET_GLOBAL_SERVER,
  payload: string
}

export type ClientActionTypes = (
  AlertActionTypes | ResetAction | CreateAction | UpdateAction | SetGlobalServerAction |
  DeleteAction | GetAction | GetListAction | GetLoggedAction | SetGlobalLibAction
)
