import React, { useState, useEffect } from 'react'
import Modal from 'util/modules/Modal'

import ClientForm from './components/ClientForm'
import ClientList from './components/ClientList'

import './styles/Client.scss'
import { useHistory, useParams } from 'react-router-dom'
import { ClientServer } from './components/ClientServer'

const Client: React.FC = () => {
  const history = useHistory()

  const [isFormOpen, setFormOpen] = useState(false)

  const { action } = useParams<{ [x: string]: string }>()

  useEffect(() => {
    if (action === 'edit') setFormOpen(true)
  }, [action])

  useEffect(() => {
    if (!isFormOpen) {
      history.push('/panel/client')
    }
  }, [isFormOpen])

  return (
    <div className="global-style-container">
      <div className="row client-manage">
        <div className="row custom-header-client">
          <p style={{ fontSize: 18 }}>Clientes Cadastrados</p>
          <div className="row margin-bottom-16 justify-end align-end">
            <ClientServer />
            <div>
              <button className="gradient radius" onClick={() => setFormOpen(true)}>
                Cadastrar
              </button>
            </div>
          </div>
        </div>
        <ClientList />
        <Modal isOpen={isFormOpen} onClose={() => setFormOpen(false)}>
          <ClientForm onClose={() => setFormOpen(false)} isModalOpen={isFormOpen} />
        </Modal>
      </div>
    </div>
  )
}

export default Client
