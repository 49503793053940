import update from 'immutability-helper'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Handle, NodeProps, Position, useReactFlow } from 'reactflow'

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import { NodeOptionItem, useAddOption, useRemoveOption, useSetOptions } from './helpers/on-change-value.hook'
import { useOnChangeLabel, useOnChangeOption, useOnChangeValue } from './helpers/on-change-value.hook'
import { Droppable } from '../drag-and-drop/droppable'
import { DeleteNode } from './delete-node'
import { FormControlNode, ItemWithIcon } from '../form-control.node'

interface Props extends NodeProps {
  data: {
    label: string,
    value: string,
    options: NodeOptionItem[]
  }
}
const MenuNode: React.FC<Props> = ({ id, data }) => {
  const { getNode } = useReactFlow()

  const onAddOption = useAddOption(id)
  const onSetOptions = useSetOptions(id)
  const onChangeValue = useOnChangeValue(id)
  const onChangeLabel = useOnChangeLabel(id)

  const handleAddOption = () => {
    const node = getNode(id)
    if (!node) return

    onAddOption({
      key: String(node.data.options.length + 1),
      value: ''
    })
  }

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    onSetOptions((prevOptions) => {
      const result = update(prevOptions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevOptions[dragIndex]]
        ]
      })

      return JSON.parse(
        JSON.stringify(
          result.map((item, i) => ({ ...item, key: String(i + 1) }))
        )
      )
    })
  }, [])

  return (
    <div className="node" style={{ width: 300 }}>
      <DeleteNode id={id} />
      <FormControlNode pb>
        <ItemWithIcon icon="fa-comments">
          <input
            value={data.label}
            onChange={onChangeLabel}
            placeholder='Defina o título do menu'
          />
        </ItemWithIcon>
      </FormControlNode>

      <FormControlNode>
        <textarea
          rows={4}
          style={{ resize: 'none' }}
          onChange={onChangeValue}
          value={data.value}
          placeholder='Informe a mensagem a ser enviada'
        ></textarea>
        <p style={{ gridRow: 2, gridColumn: '2/3', textAlign: 'right' }}>{data.value?.length ?? 0}/500</p>
      </FormControlNode>
      <div className='px custom-drag-handle'>
        <button className="node-button" onClick={handleAddOption}>
          Adicionar Opção
        </button>
      </div>

      <DndProviderWrapper>
        <div
          className="row options px"
          data-rbd-drag-handle-draggable-id="gibberish"
          data-rbd-drag-handle-context-id='gibberish'
        >
          {
            data.options.map((item, i) => (
              <div key={item.key} style={{ position: 'relative', width: '100%' }}>
                <Droppable index={i} moveCard={moveCard}>
                  <MenuOption nodeId={id} value={item.value} handleId={item.key} />
                </Droppable>
                <Handle
                  id={item.key}
                  type="source"
                  position={Position.Right}
                  style={{ right: -5, width: 12, height: 12, background: '#ff4081' }}
                />
              </div>
            ))
          }
        </div>
      </DndProviderWrapper>

      <Handle
        id="left"
        type="target"
        position={Position.Left}
        style={{ left: -5, width: 12, height: 12, background: '#00ECBC' }}
      />

      <Handle
        id="top"
        type="target"
        position={Position.Top}
        style={{ top: 3, width: 12, height: 12, background: '#00ECBC' }}
      />
    </div>
  )
}

type MenuOptionProps = {
  value: string,
  nodeId: string,
  handleId: string,
}
const MenuOption: React.FC<MenuOptionProps> = ({ value, handleId, nodeId }) => {
  const onRemoveOption = useRemoveOption(nodeId, handleId)
  const onChangeOption = useOnChangeOption(nodeId)

  return (
    <div className="option-item" key={handleId}>
      <div className='option-key'>
        <div className="remove-option-item" onClick={onRemoveOption}>
          <i className="fa fa-times"></i>
        </div>
        <input type="text" value={handleId} disabled />
      </div>
      <input
        type="text"
        value={value}
        placeholder='Digite uma opção'
        onChange={evt => onChangeOption(handleId, evt.target.value)}
      />
    </div>
  )
}

const DndProviderWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const context = useRef(null)
  const dndArea = context.current
  const html5Options = useMemo(() => ({ rootElement: dndArea }), [dndArea])

  const [isContextLoaded, setContextLoaded] = useState(false)

  useEffect(() => {
    setContextLoaded(!!context.current)
  }, [context])

  return (
    <div ref={context} style={{ display: 'flex' }}>
      {isContextLoaded && (
        <DndProvider backend={HTML5Backend} options={html5Options}>
          {children}
        </DndProvider>
      )}
    </div>
  )
}

export default memo(MenuNode)
