import React, { useEffect, useRef } from 'react'

import './style/DeleteConfirm.scss'

import Modal, { ModalHeader } from 'util/modules/Modal'

type Props = { id?: number, isOpen?: boolean, onCancel (): void, onConfirm (id: number): void }
const DeleteConfirm: React.FC<Props> = ({ id, isOpen, onCancel, onConfirm }) => {
  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if ((id || isOpen) && buttonRef.current) {
      buttonRef.current.focus()
    }
  }, [id, isOpen, buttonRef])

  if (!id && !isOpen) return null

  return (
    <Modal isOpen={true} onClose={onCancel}>
      <div className="row panel no-padding" style={{ width: 500, margin: '0 auto' }}>
        <ModalHeader title="Confirmação" icon="info-circle" onClose={onCancel} />

        <div className="row panel-body">
          <h1>
            <i
              className="fa fa-trash"
              style={{ background: 'red', color: 'white', padding: 24, borderRadius: '50%' }}
            />
          </h1>

          <p className='row text-center margin-tb-24'>
            Tem certeza que deseja excluir? Esta ação não poderá ser desfeita
          </p>

          <div className="row">
            <span className="secondary button" style={{ width: 150 }} onClick={onCancel}> Cancelar </span>
            <button
              ref={buttonRef}
              style={{ width: 150 }}
              onClick={() => onConfirm(id || 0)}
              className="gradient button margin-left-8"
            >
              Excluir
            </button>
          </div>
        </div>

      </div>
    </Modal>
  )
}

export default DeleteConfirm
