import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Tab } from '../ProfileTab'
import { ApplicationState } from 'AppReducer'
import { maskCPF, maskCNPJ, maskDate, maskPhone } from 'util/masks'
import { AttendantTypes } from 'panel/attendant/redux/types'
import { updateAttendant } from 'panel/attendant/redux/actions'

const ProfileComplementary: React.FC<{ onUnsaved: (tab?: Tab) => void }> = () => {
  const { logged } = useSelector((state: ApplicationState) => state.userReducer)

  if (!logged) return null

  return (
    <div className="row margin-top-16 complementary-data">
      <div className="row">
        <div className="row col-6 justify-start">
          <label>Ativo?</label>
          <label className="checkbox margin-right-16" style={{ width: 'auto' }}>
            <input type="checkbox" checked={logged.isActive} />
            <div className="checkmark" /> Sim
          </label>
          <label className="checkbox margin-right-16" style={{ width: 'auto' }}>
            <input type="checkbox" checked={!logged.isActive} />
            <div className="checkmark" /> Não
          </label>
        </div>
        <div className="row col-6 justify-start">
          <label>Tipo de Cadastro</label>
          <label className="checkbox margin-right-16" style={{ width: 'auto' }}>
            <input type="checkbox" checked={!logged.person.docType || logged.person.docType === 'CPF'} />
            <div className="checkmark" /> Pessoa Física
          </label>
          <label className="checkbox margin-right-16" style={{ width: 'auto' }}>
            <input type="checkbox" checked={logged.person.docType === 'CNPJ'} />
            <div className="checkmark" /> Pessoa Jurídica
          </label>
        </div>
      </div>

      <div className="form-control col-6">
        <label>{logged.person.docType || 'CPF'}</label>
        <input type="text" value={logged.person.docNumber} disabled />
      </div>

      <div className="form-control col-6">
        <label>Data Nascimento</label>
        <input type="text" value={logged.person.birthday} disabled/>
      </div>

      <div className="form-control col-6">
        <label>Gênero</label>
        <select value={logged.person.gender} disabled>
          <option value="M">Masculino</option>
          <option value="F">Feminino</option>
          <option value="O">Outro</option>
        </select>
      </div>
      <div className="form-control col-6">
        <label>Número Telefone</label>
        <input type="text" value={logged.person.phone} disabled />
      </div>

      <div className="row margin-top-16 form-buttons">
        <span className="secondary button">Limpar</span>
        <button className="gradient button" disabled={true}>Salvar</button>
      </div>
    </div>
  )
}

export default ProfileComplementary
