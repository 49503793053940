import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { PaymentAsaasPix } from '../redux/types'
import { ApplicationState } from 'AppReducer'
import { cancelInvoicePayment } from '../redux/actions'

const AsaasPix: React.FC<{ payment: PaymentAsaasPix }> = ({ payment }) => {
  const dispatch = useDispatch()

  const { id } = useParams<{ [x: string]: string }>()
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  const [isCancelling, setCancelling] = useState(false)

  async function handleCancel () {
    if (!id || !selected) return undefined
    if (window.confirm('Tem certeza que deseja cancelar este pagamento? Será necessário gerar outro')) {
      setCancelling(true)
      await cancelInvoicePayment(selected, id, payment.id)(dispatch)
      setCancelling(false)
    }
  }

  return (
    <div className="row payment-asaas-pix">
      <img src={`${process.env.PUBLIC_URL}/img/pix.png`} className="logo" alt="Pix" />
      <img src={payment.base64} className="qrcode" alt="Pix QrCode" />
      <div className="description">
        <h2>Pague com PIX</h2>
        <ol>
          <li>Abra o aplicativo do seu banco</li>
          <li>Escolhe a opção pagar com Qr Code</li>
          <li>Aponte a câmera para o código</li>
        </ol>

        <div className="row">
          <div className="grow">
            <input
              readOnly
              type="text"
              value={payment.qrCode}
              style={{ fontSize: '10pt', textAlign: 'center' }}
            />
          </div>
          <span
            title="Copiar PIX"
            className="button gradient margin-left-8"
            onClick={() => navigator.clipboard.writeText(payment.qrCode)}
          >
            <i className="fa fa-copy" /> Copiar
          </span>
        </div>

        <button className="button remove small" onClick={handleCancel} disabled={isCancelling}>
          {
            isCancelling
              ? <><i className="fa fa-spinner fa-spin"/> Cancelando...</>
              : <><i className="fa fa-times"/> Cancelar</>
          }
        </button>
      </div>
    </div>
  )
}

export default AsaasPix
