import React from 'react'
import Attachment from 'util/modules/Audio/Attachment'
import Audio from 'util/modules/Audio/Audio'

type Props = { url: string, onSelect: (file?: File) => void }
export const AttachmentAudio: React.FC<Props> = ({ onSelect, url }) => {
  return (
    <div className="form-control">
      <label>Anexar Áudio</label>
      <div className="row input required" style={{ height: 72 }}>
        <Audio onRecord={onSelect} />
        <Attachment
          acceptedMimeTypes={['audio/ogg', 'audio/wav', 'audio/mp3', 'audio/mp4', 'audio/mpeg']}
          onSelect={onSelect}
        />
        {
          !!url &&
          <audio controls style={{ flexGrow: 1 }}>
            <source src={url} />
          </audio>
        }
      </div>
    </div>
  )
}
