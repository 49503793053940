import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'
import { formatDate, formatDateTime, maskPhone, formatTime } from 'util/masks'
import { continueSchedule, pauseSchedule, retrySchedule, startSchedule } from '../redux/actions'
import { hasPermission } from 'util/services/AuthService'

const ScheduleExecution: React.FC<{ onClose(): void }> = ({ onClose }) => {
  const dispatch = useDispatch()

  const [isFetching, setFetching] = useState(false)
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)
  const { schedule, sends, status, modes } = useSelector((state: ApplicationState) => state.scheduleReducer)

  async function handleStart () {
    const msg = 'Tem certeza que deseja iniciar? Os envios serão adicionados para envio agora.'
    if (selected && schedule && window.confirm(msg)) {
      setFetching(true)
      await startSchedule(selected, schedule.id)(dispatch)
      setFetching(false)
    }
  }

  async function handlePause () {
    const msg = 'Tem certeza que deseja pausar? Os próximos envios serão interrompidos.'
    if (selected && schedule && window.confirm(msg)) {
      setFetching(true)
      await pauseSchedule(selected, schedule.id)(dispatch)
      setFetching(false)
    }
  }

  async function handleContinue () {
    const msg = 'Tem certeza que deseja continuar? Os próximos envios serão reiniciados agora.'
    if (selected && schedule && window.confirm(msg)) {
      setFetching(true)
      await continueSchedule(selected, schedule.id)(dispatch)
      setFetching(false)
    }
  }

  async function handleRetry () {
    const msg = 'Tem certeza que deseja continuar? As mensagens com erro serão reenviadas agora.'
    if (selected && schedule && window.confirm(msg)) {
      setFetching(true)
      await retrySchedule(selected, schedule.id)(dispatch)
      setFetching(false)
    }
  }

  if (!schedule) return null

  return (
    <div className="row panel no-padding execution">
      <div className="row panel-header">
        <p className="flex">
          <i className="fa fa-cogs" /> Execução de Agendamento
        </p>
        <div className="button-times pointer" onClick={onClose}><i className="fa fa-times" /></div>
      </div>
      <div className="row panel-body">
        <div className="row">
          <div className="hero">
            <div>
              <p className="bold"></p>
              <p>{schedule.name}</p>
            </div>
            <div>
              <p className="bold"></p>
              <p>{status[schedule.status].alias}</p>
            </div>
            <div>
              <p className="bold"></p>
              <p>{formatDate(schedule.date)} {formatTime(schedule.date)}</p>
            </div>
            <div>
              <p className="bold"></p>
              <p>{modes[schedule.mode]}</p>
            </div>
          </div>

          <div className="row margin-top-32 execution-list">
            <div className="row margin-bottom-16">
              <h3 className="grow"><i className="fa fa-history" /> Histórico de Envios</h3>
              {
                (hasPermission('SCHEDULE:STATUS') && schedule.status === 'PENDING') &&
                <button
                  disabled={isFetching}
                  onClick={handleStart}
                  className="btn-config-modal"
                >
                  {
                    isFetching
                      ? <><i className="fa fa-spinner fa-spin" /> Preparando, aguarde...</>
                      : <><i className="fa fa-cogs" /> Iniciar agora</>
                  }
                </button>
              }
              {
                (hasPermission('SCHEDULE:STATUS') && schedule.status === 'RUNNING') &&
                <>
                  <span className="button secondary margin-top-16">
                    <i className="fa fa-cog fa-spin" /> Executando...
                  </span>
                  <span className="button gradient margin-top-16 margin-left-8" onClick={handlePause}>
                    <i className="fa fa-pause" /> Pausar
                  </span>
                </>
              }
              {
                (hasPermission('SCHEDULE:STATUS') && schedule.status === 'PAUSED') &&
                <>
                  <span className="button secondary margin-top-16">
                    <i className="fa fa-pause" /> Pausado
                  </span>
                  <span className="button gradient margin-top-16 margin-left-8" onClick={handleContinue}>
                    {
                      isFetching
                        ? <><i className="fa fa-spinner fa-spin" /> Continuando, aguarde...</>
                        : <><i className="fa fa-play" /> Continuar</>
                    }
                  </span>
                </>
              }
              {
                (
                  schedule.status === 'FINISHED' &&
                  hasPermission('SCHEDULE:RETRY') &&
                  sends.filter(item => item.message?.status === 'ERROR').length > 0
                ) &&
                <>
                  <span className="button gradient margin-top-16 margin-left-8" onClick={handleRetry}>
                    <i className="fa fa-undo" /> Reenviar Falhas
                  </span>
                </>
              }
            </div>

            <div className="list">
              <div className="list-header">
                <div />
                <div>Contato</div>
                <div>Criação</div>
                <div>Atualização</div>
              </div>
              {
                sends.length === 0 &&
                <div className="list-row empty">
                  <div className="grid-span">Nenhum histórico encontrado</div>
                </div>
              }

              {
                sends.map((send, i) => {
                  const status = {
                    DELETED: { icon: 'times', color: 'red' },
                    DEFAULT: { icon: 'clock', color: 'gray' },
                    PENDING: { icon: 'spinner fa-spin', color: 'gray' },
                    ERROR: { icon: 'times', color: 'red' },
                    SENT: { icon: 'check', color: 'gray' },
                    RECEIVED: { icon: 'check-double', color: 'gray' },
                    SEEN: { icon: 'check-double', color: '#0369d1' },
                    PLAYED: { icon: 'check-double', color: '#0369d1' }
                  }[send.message?.status || 'DEFAULT']

                  if (!status) return null

                  return (
                    <div className="list-row" key={i} id={String(send.id)}>
                      <div className="row" style={{ justifyContent: 'center' }}>
                        <i className={`fa fa-${status.icon}`} style={{ color: status.color }} />
                      </div>
                      <div>{maskPhone(send.value)}</div>
                      <div>{formatDateTime(send.createdAt)}</div>
                      <div>{formatDateTime(send.updatedAt)}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScheduleExecution
