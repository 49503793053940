import Axios from 'axios'
import { Dispatch } from 'redux'

import { getContactList } from 'panel/person/redux/action/contact'
import { Group, GroupTypes } from './types'
import { ActionResponse, Id } from 'util/types/index'
import { handleError, createAlert, clearAlert } from 'util/modules/Alert/redux/actions'

export function createGroup (storeId: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Group>> => {
    return Axios
      .post(`/store/${storeId}/group`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: GroupTypes.GROUP_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar o grupo')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateGroup (storeId: Id, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Group>> => {
    return Axios
      .put(`/store/${storeId}/group/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: GroupTypes.GROUP_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao atualizar o grupo')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteGroup (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Group>> => {
    return Axios
      .delete(`/store/${storeId}/group/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: GroupTypes.GROUP_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o grupo')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getGroup (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Group>> => {
    return Axios
      .get(`/store/${storeId}/group/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: GroupTypes.GROUP_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar por grupo')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getGroupList (storeId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Group>> => {
    return Axios
      .get(`/store/${storeId}/group`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: GroupTypes.GROUP_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}

export function addContact (storeId: Id, groupId: Id, personId: Id, contactId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<null>> => {
    return Axios
      .post(`/store/${storeId}/group/${groupId}/person/${personId}/contact/${contactId}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success') {
          getGroup(storeId, groupId)(dispatch)
          getContactList(storeId, personId)(dispatch)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao adicionar o contato ao grupo')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function delContact (storeId: Id, groupId: Id, personId: Id, contactId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<null>> => {
    return Axios
      .delete(`/store/${storeId}/group/${groupId}/person/${personId}/contact/${contactId}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success') {
          getGroup(storeId, groupId)(dispatch)
          getContactList(storeId, personId)(dispatch)
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o contato do grupo')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}
