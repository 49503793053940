import Axios from 'axios'
import { Dispatch } from 'redux'
import { handleError, createAlert, clearAlert } from 'util/modules/Alert/redux/actions'
import { ActionResponse, Id } from 'util/types/index'
import { Schedule, ScheduleTypes, ScheduleSend } from './types'

export function createSchedule (storeId: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Schedule>> => {
    return Axios
      .post(`/store/${storeId}/schedule`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ScheduleTypes.SCHEDULE_CREATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao cadastrar o agendamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function updateSchedule (storeId: Id, id: Id, data: any) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Schedule>> => {
    return Axios
      .put(`/store/${storeId}/schedule/${id}`, data)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ScheduleTypes.SCHEDULE_UPDATE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro au atualizar o agendamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function deleteSchedule (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Schedule>> => {
    return Axios
      .delete(`/store/${storeId}/schedule/${id}`)
      .then(res => res.data)
      .then(res => {
        if (res?.status === 'success' && res.data) {
          dispatch({ type: ScheduleTypes.SCHEDULE_DELETE, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao remover o agendamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getSchedule (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Schedule>> => {
    return Axios
      .get(`/store/${storeId}/schedule/${id}`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ScheduleTypes.SCHEDULE_GET, payload: res.data })
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao buscar o agendamento')
      })
      .then(async res => {
        if (res?.status !== 'success') {
          createAlert(res)(dispatch)
          clearAlert(3000)(dispatch)
        }
        return res
      })
  }
}

export function getScheduleList (storeId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Schedule>> => {
    return Axios
      .get(`/store/${storeId}/schedule`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ScheduleTypes.SCHEDULE_GET_LIST, payload: res.data || [] })
        return res
      })
  }
}

export function startSchedule (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<{ schedule: Schedule, sends: ScheduleSend[] }>> => {
    return Axios
      .post(`/store/${storeId}/schedule/${id}/status/start`)
      .then(res => res.data)
      .then(res => {
        if (res.status === 'success' && res.data) {
          dispatch({ type: ScheduleTypes.SCHEDULE_GET, payload: res.data.schedule })
          dispatch({ type: ScheduleTypes.SCHEDULE_GET_SENDS, payload: res.data.sends })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao iniciar o agendamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function pauseSchedule (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<{ schedule: Schedule, sends: ScheduleSend[] }>> => {
    return Axios
      .post(`/store/${storeId}/schedule/${id}/status/pause`)
      .then(res => res.data)
      .then(res => {
        if (res.status === 'success' && res.data) {
          dispatch({ type: ScheduleTypes.SCHEDULE_GET, payload: res.data.schedule })
          dispatch({ type: ScheduleTypes.SCHEDULE_GET_SENDS, payload: res.data.sends })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao pausar o agendamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function continueSchedule (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<{ schedule: Schedule, sends: ScheduleSend[] }>> => {
    return Axios
      .post(`/store/${storeId}/schedule/${id}/status/continue`)
      .then(res => res.data)
      .then(res => {
        if (res.status === 'success' && res.data) {
          dispatch({ type: ScheduleTypes.SCHEDULE_GET, payload: res.data.schedule })
          dispatch({ type: ScheduleTypes.SCHEDULE_GET_SENDS, payload: res.data.sends })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao continuar o agendamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function retrySchedule (storeId: Id, id: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<{ schedule: Schedule, sends: ScheduleSend[] }>> => {
    return Axios
      .post(`/store/${storeId}/schedule/${id}/retry`)
      .then(res => res.data)
      .then(res => {
        if (res.status === 'success' && res.data) {
          dispatch({ type: ScheduleTypes.SCHEDULE_GET, payload: res.data.schedule })
          dispatch({ type: ScheduleTypes.SCHEDULE_GET_SENDS, payload: res.data.sends })
        }
        return res
      })
      .catch((error) => {
        return handleError(error, 'Ocorreu um erro ao continuar o agendamento')
      })
      .then(async res => {
        createAlert(res)(dispatch)
        clearAlert(3000)(dispatch)

        return res
      })
  }
}

export function getScheduleSends (storeId: Id, scheduleId: Id) {
  return async (dispatch: Dispatch): Promise<ActionResponse<Schedule>> => {
    return Axios
      .get(`/store/${storeId}/schedule/${scheduleId}/send`)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: ScheduleTypes.SCHEDULE_GET_SENDS, payload: res.data || [] })
        return res
      })
  }
}
