import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'AppReducer'

type Props = {
  personId?: number,
  preferredAttendantId?: number,
  onSelect: (attendantId?: number) => void
}
export const ContactConfigPreferredAttendant: React.FC<Props> = ({ personId, preferredAttendantId, onSelect }) => {
  const [selected, setSelected] = useState<number>()
  const { attendants } = useSelector((state: ApplicationState) => state.attendantReducer)

  useEffect(() => {
    if (!preferredAttendantId) return
    if (preferredAttendantId === selected) return

    setSelected(preferredAttendantId)
  }, [preferredAttendantId])

  if (!personId) return null

  return (
    <div className="justify-start form-control">
      <label>Atendente preferido</label>
      <select
        value={selected}
        onChange={e => {
          const value = e.target.value ? Number(e.target.value) : undefined
          onSelect(value)
          setSelected(value)
        }}
      >
        <option value={undefined}>Nenhum</option>
        {attendants.map(attendant => (
          <option key={attendant.id} value={attendant.id}>
            {attendant.user.person?.name}
          </option>
        ))}
      </select>
    </div>
  )
}
