import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './style/Stores.scss'

import { ReactComponent as Company } from 'assets/icons/building.svg'

import { img } from 'assets/online'
import { formatDateTime } from 'util/masks'
import { ApplicationState } from 'AppReducer'

import Overview from 'panel/report/Overview'
import Modal, { ModalHeader } from 'util/modules/Modal'
import { selectStore } from 'panel/store/redux/actions'
import AlertEmptyPlan from './components/AlertEmptyPlan'
import AlertExpiringPlan from './components/AlertExpiringPlan'

const defaultPicture = `${img['default-user']}`
const Stores: React.FC = () => {
  const dispatch = useDispatch()

  const [isModalOpen, setModalOpen] = useState(false)
  const { stores, selected } = useSelector((state: ApplicationState) => state.storeReducer)

  const store = stores.find(item => item.id === selected)

  function handleSelectStore (storeId: number) {
    selectStore(storeId)(dispatch)
  }

  if (stores.length === 0 || !store) {
    return (
      <div className="row panel margin-top-16">
        <Company width={120} height={120} style={{ padding: 24 }} />
        <p>Você não possui nenhuma empresa cadastrada</p>
      </div>
    )
  }

  const gridTemplateColumns = '50px 70px 70px 1fr 200px 150px'

  return (
    <div className="row dashboard-stores">
      {
        stores.length > 1 &&
        <div className="row justify-end margin-bottom-16">
          <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
            <div className="row panel no-padding" style={{ width: 760 }}>
              <ModalHeader title="Minhas Lojas" onClose={() => setModalOpen(false)} />
              <div className="panel-body">
                <div className="list">
                  <div className="list-header" style={{ gridTemplateColumns }}>
                    <span>#</span>
                    <span>Ativo</span>
                    <span>Bloqueado</span>
                    <span>Nome</span>
                    <span>Vencimento</span>
                    <span>Ação</span>
                  </div>
                  {
                    stores.map((store, i) => {
                      return (
                        <div
                          key={i}
                          className="list-row pointer"
                          style={{ gridTemplateColumns }}
                          data-active={selected === store.id}
                          onClick={() => handleSelectStore(store.id)}
                        >
                          <span>{ store.id }</span>
                          <span>{ store.isActive ? 'Sim' : 'Não' }</span>
                          <span>{ store.isBlocked ? 'Sim' : 'Não' }</span>
                          <span>{ store.name }</span>
                          <span>{ formatDateTime(store.dueDate) }</span>
                          {
                            selected === store.id
                              ? (
                                <span className="button gradient small" style={{ height: 30 }}>
                                  <i className="fa fa-check"/> Ativo
                                </span>
                              )
                              : (
                                <span className="button secondary small" style={{ height: 30 }}>
                                  <i className="fa fa-key"/> Acessar
                                </span>
                              )
                          }
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </Modal>
          <button className="white" onClick={() => setModalOpen(true)}>
            <i className="fa fa-exchange-alt"></i>Alterar Loja
          </button>
        </div>
      }
      <div className="row panel shadow dashboard-stores-header" style={{ borderRadius: 10 }}>
        <img src={store.picture || defaultPicture} style={{ borderRadius: '50%', width: 100 }} />
        <div className="grow padding-left-32">
          <div className="header-wrapper">
            <div className="row col-3 justify-start">
              <h2>{store.name} #{store.id}</h2>
              <p className="channel-limit">Limite de Canais: {store.plan?.limitChannel || 0}</p>
            </div>
            <div className="col-4">
              <h2>{ store.plan?.name }</h2>
              <p className="date" title="Validade do plano">
                <i className="fa fa-calendar-alt highlight-text" style={{ fontSize: 18 }} />
                {formatDateTime(store.dueDate)}
              </p>
            </div>

            <div className="row justify-start">
              <p style={{ fontSize: 18 }}>Ativo:</p>
              <label className="switch mark margin-top-8">
                <input
                  readOnly
                  type='checkbox'
                  checked={store.isActive}
                />
                <span className="slider round" />
              </label>
            </div>

            <div className="row justify-start">
              <p style={{ fontSize: 18 }}>Bloqueado</p>
              <label className="switch mark margin-top-8">
                <input
                  readOnly
                  type='checkbox'
                  checked={store.isBlocked}
                />
                <span className="slider round" />
              </label>
            </div>
          </div>
        </div>
      </div>

      <AlertEmptyPlan store={store} />
      <AlertExpiringPlan store={store} />

      <Overview storeId={store.id} />
    </div>
  )
}

export default Stores
