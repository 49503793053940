import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ApplicationState } from 'AppReducer'
import { createMessage } from 'panel/conversation/redux/actions'

import mimeTypes from '../mime-types.json'

const File: React.FC = () => {
  const dispatch = useDispatch()
  const inpRef = useRef<HTMLInputElement>(null)

  const [files, setFiles] = useState<Array<{ uri: string, name: string, file: File }>>([])
  const [opened, setOpened] = useState(0)
  const [message, setMessage] = useState('')
  const [isFetching, setFetching] = useState(false)

  const { id } = useParams<{ id: string }>()
  const { selected } = useSelector((state: ApplicationState) => state.storeReducer)

  function handleClose () {
    setFiles([])
    setMessage('')
    setFetching(false)
  }

  function handleRemove (index: number) {
    setFiles([
      ...files.slice(0, index),
      ...files.slice(index + 1)
    ])
  }

  function handleFile (file: File) {
    const isImage = mimeTypes.image.includes(file.type)
    const isVideo = mimeTypes.video.includes(file.type)

    const isFile = !isImage && !isVideo
    if (isFile) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (): void => {
        if (reader.result) {
          const uri = reader.result.toString()
          setFiles(files => ([...files, { uri, name: file.name, file }]))
        }
      }
    }

    if (inpRef.current) inpRef.current.value = ''
  }

  function handleChange (e: React.ChangeEvent<HTMLInputElement>): void {
    if (inpRef.current && e.target.files) {
      Array.from(e.target.files).slice(0, Math.max(10 - files.length, 0)).map(file => {
        handleFile(file)
      })
    }
  }

  async function handleSubmit (): Promise<void> {
    if (!selected) return

    setFetching(true)
    await Promise.all(files.map(async (item, i) => {
      const formData = new FormData()
      if (i === 0) formData.set('message', message || item.name)
      if (!message) formData.set('message', item.name)
      formData.set('file', item.file)
      formData.set('fileOrigin', 'attachment')

      await createMessage(selected, id, formData)(dispatch)
    }))
    handleClose()
    setFetching(false)
  }

  useEffect(() => {
    document.addEventListener('paste', (event: ClipboardEvent) => {
      const { clipboardData } = event
      if (clipboardData) {
        const file = clipboardData.files[0]

        if (file) handleFile(file)
      }
    })

    return () => {
      document.removeEventListener('paste', () => null)
    }
  }, [])

  return (
    <>
      {
        !!files[opened] &&
        <div className="preview">
          <div className="row">
            <i className="fa fa-times btn-close" onClick={handleClose} />

            <h1 className="text-center text-primary"><i className="fa fa-file fa-3x" /></h1>
            <p>{files[opened].name}</p>

            <div className="row margin-top-16">
              <input
                type="text"
                value={message}
                style={{ width: 400 }}
                placeholder="Mensagem Personalizada"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <div className="row margin-tb-8 attachment-list">
              {
                files.map((item, i) => (
                  <div className="row attachment-item" key={i} style={{ width: 80, height: 80, margin: '0 2px' }}>
                    <div className="close-attachment">
                      <i className="fa fa-times" onClick={() => handleRemove(i)} />
                    </div>
                    <i className="fa fa-file fa-3x" />
                  </div>
                ))
              }
              {
                files.length < 10 &&
                <div
                  className="row attachment-new-item"
                  onClick={() => inpRef.current?.click()}
                  style={{ width: 80, height: 80, margin: '0 2px' }}
                >
                  <i className="fa fa-2x fa-plus" />
                </div>
              }
            </div>

            <div className="row margin-top-32">
              <button className="primary" disabled={isFetching} onClick={handleSubmit}>
                {
                  isFetching
                    ? <><i className="fa fa-spin fa-spinner" /> Enviando. Aguarde...</>
                    : <><i className="fa fa-paper-plane" /> Enviar</>
                }
              </button>
            </div>
          </div>
        </div>
      }
      <input
        type="file"
        ref={inpRef}
        multiple
        style={{ display: 'none' }}
        onChange={(e) => handleChange(e)}
      />
      <span className="secondary button shadow file" onClick={() => inpRef.current?.click()}>
        <i className="fas fa-file" />
      </span>
    </>
  )
}

export default File
