import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Modal from 'util/modules/Modal'
import SellerForm from './components/SellerForm'
import SellerList from './components/SellerList'

import './styles/Seller.scss'

const Seller: React.FC = () => {
  const history = useHistory()

  const [isFormOpen, setFormOpen] = useState(false)

  const { action } = useParams<{ [x: string]: string }>()

  useEffect(() => {
    if (action === 'edit') setFormOpen(true)
  }, [action])

  useEffect(() => {
    if (!isFormOpen) {
      history.push('/panel/seller')
    }
  }, [isFormOpen])

  return (
    <div className="global-style-container">
      <div className="row seller-manage">
        <div className="row margin-bottom-16">
          <p className="grow" style={{ fontSize: 18 }}>Revendedores Cadastrados</p>
          <button className="gradient radius" onClick={() => setFormOpen(true)}>
            Cadastrar
          </button>
        </div>
        <SellerList />
        <Modal isOpen={isFormOpen} onClose={() => setFormOpen(false)}>
          <SellerForm onClose={() => setFormOpen(false)} />
        </Modal>
      </div>
    </div>
  )
}

export default Seller
